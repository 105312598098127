// import React, { useState, useEffect } from "react";
// import api from "../api/baseurl";

// function Notification() {
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);

//   const fetchWareHouse = async () => {
//     const { data } = await api.post("/address/set", {
//       latitude: "28.4320397",
//       longitude: "77.0686159",
//     });
//     if (data.success) {
//     }
//   };

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const lat = position.coords.latitude;
//           const long = position.coords.longitude;
//           setLatitude(lat);
//           setLongitude(long);

//           fetchWareHouse();
//         },
//         (error) => {
//           // handle error
//         }
//       );
//     } else {
//       // Geolocation is not supported by this browser
//     }
//   }, []);

//   return (
//     <div>
//       {/* <p>Latitude: {latitude}</p>
//       <p>Longitude: {longitude}</p> */}
//     </div>
//   );
// }

// export default Notification;

import axios from "axios";
import React, { useState, useEffect } from "react";

function InstagramPost() {
  const [oEmbedData, setOEmbedData] = useState(null);

  //   useEffect(() => {
  //     const fetchOEmbedData = async () => {
  //       const response = await axios.get(
  //         ``
  //       );
  //       const data = await response.json();
  //       setOEmbedData(data);
  //     };
  //     fetchOEmbedData();
  //   }, []);

  return (
    <iframe
      src="https://www.instagram.com/p/CpJ3g9VviBF/"
      width="320"
      height="400"
      frameBorder="0"
      scrolling="no"
      allowtransparency="true"
    ></iframe>
  );
}

export default InstagramPost;
