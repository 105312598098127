import React, { useEffect, useState } from "react";
// import type { TabsProps } from "antd";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Card,
  notification,
  Col,
  Row,
  Statistic,
  Tabs,
  Input,
  Button,
  Spin,
} from "antd";

const { Search } = Input;

import { getWalletApi } from "../../api/wallet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../api/baseurl";
import PaymentHistory from "./PaymentHistory";
import axios from "axios";

const onChange = (key) => {
};
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const WalletContent = () => {
  const history = useHistory();
  const [wallet, setWallet] = useState({});

  const [inputAmount, setAmmount] = useState("");

  const getWallet = async () => {
    const wallet = await getWalletApi();
    if (wallet.data.success) {
      notification.success({
        message: wallet.data.message,
        duration: 1,
        placement: "bottom",
      });
      setWallet(wallet.data.data);
      localStorage.setItem("wallet", JSON.stringify(wallet.data.data));
      window.location.reload(false);
    } else {
      history.push("/");
      notification.error({
        message: wallet.data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  useEffect(() => {
    const wallet = JSON.parse(localStorage.getItem("wallet"));
    setWallet(wallet);
    if (!wallet) {
      // notification.error({
      //   message: "please Login ",
      // });
      // history.push("/");
    }
  }, [0]);
  onSearch = (e) => {
  };

  async function handlePayment(e) {
    e.preventDefault();

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const { data } = await axios.post("/dev/v2/online/payment/wallet", {
      amount: parseInt(inputAmount),
    });
    if (!data.success) {
      return notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
    const { amount, razorpay_order_id, currency, razorpay_app_id } = data.data;

    //   notification.success({
    //     message: data.message,
    //   });
    //   getWallet();
    //   setAmmount("");

    const options = {
      key: razorpay_app_id,
      amount: amount.toString(),
      currency: currency,
      name: "Soumya Corp.",
      description: "Test Transaction",
      //   image: { logo },
      order_id: razorpay_order_id,
      handler: async function (response) {
        const result = await axios.post("/dev/v2/online/confirm/payment", {
          ...response,
          final: true,
          razorpay_order_id: razorpay_order_id,
        });
        const { success, captured } = result.data;
        if (success == true && captured == true) {
          notification.success({
            message: result.data.message,
            duration: 1,
            placement: "bottom",
          });
          getWallet();
          setAmmount("");
        }

        // alert(result);
      },
      prefill: {
        name: "Soumya Dey",
        email: "SoumyaDey@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className="wallet">
      <Card style={{ padding: "3px" }} bordered={false}>
        {(wallet?.amount && (
          <div className="wallet-bilance-box">
            <div>
              <Statistic
                value={`₹${wallet?.amount?.total.toFixed(2)}`}
                precision={2}
                valueStyle={{ color: "#000000" }}
              />
              My Balance
            </div>
            <div>
              <Statistic
                value={`₹${wallet?.amount?.wallet.toFixed(2)}`}
                precision={2}
                valueStyle={{ color: "#FCBE00" }}
              />
              Wallet
            </div>
            <div>
              <Statistic
                value={`₹${wallet?.amount?.cashback.toFixed(2)}`}
                precision={2}
                valueStyle={{ color: "#00B41D" }}
                description={"sdf"}
              />
              Cashback
            </div>
          </div>
        )) || (
          <div style={{ textAlign: "center" }}>
            {" "}
            <Spin />
          </div>
        )}
      </Card>
      <form onSubmit={handlePayment} role="form">
        <>
          <div style={{ marginTop: "2rem", position: "relative" }}>
            <span className="rupee-icon">₹</span>
            <input
              value={inputAmount}
              onChange={(e) => setAmmount(e.target.value)}
              style={{ width: "100%", paddingLeft: "1.4rem" }}
              id="mobileNumber"
              placeholder=" ENTER AMOUNT"
              type="number"
              autoComplete="new-email"
              className="input-group-alternative"
            />

            <button
              style={{
                position: "absolute",
                right: "5px",
                top: "4px",
              }}
              className="btn-primary small"
              color="primary"
              type="submit"
            >
              Add Money
            </button>
          </div>
        </>
      </form>
      <br />
      <Button onClick={() => setAmmount("1000")}>+₹1000</Button>
      <Button
        onClick={() => setAmmount("2000")}
        style={{ marginLeft: "0.8rem" }}
      >
        +₹2000
      </Button>
      <Button
        onClick={() => setAmmount("3000")}
        style={{ marginLeft: "0.8rem" }}
      >
        +₹3000
      </Button>
      <Button
        onClick={() => setAmmount("4000")}
        style={{ marginLeft: "0.8rem" }}
      >
        +₹4000
      </Button>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: `Wallet`,
    children: <WalletContent />,
  },
  {
    key: "2",
    label: `Payment History`,
    children: <PaymentHistory />,
  },
];

const App = () => (
  <Tabs
    style={{ width: "100%" }}
    defaultActiveKey="1"
    items={items}
    onChange={onChange}
  />
);

export default App;
