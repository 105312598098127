// import { useForm } from "antd/es/form/Form";
import { dev } from "./baseurl";

export async function accountDetailApi(id) {
  const data = await dev.get(`/accounts/user-profile/${id}`);
  return data;
}

export async function UploadProfilePhotoApi(id, file) {

  const formData = new FormData();
  const fileFomate = { ...file.originFileObj };
  formData.append("file", file.originFileObj);
  formData.append("id", id);

  const data = await dev.post(`/accounts/upload-profile-pic/${id}`, formData);
  return data;
}

export async function UpadteProfileApi(
  id,
  { fullName, gender, email, mobile }
) {
  const data = await dev.patch(`/accounts/update-profile/${id}`, {
    name: fullName,
    email: email,
    gender: gender,
  });
  return data;
}
