import React from "react";
import Title42 from "../Title42";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCollectionItemApi } from "../../api/collections";

const BannerStyle2 = (props) => {
  const { banner_Style_2, src } = props;
  const history = useHistory();
  const handRedirect = async () => {
    // const { data } = await getCollectionItemApi(
    //   collection?.collection_details?._id
    // );
    history.push({
      pathname: ``,
    });
  };


  return (
    <BannerStyle21 className="cb-banner">
      <OverlapGroup>
        <Image1
          onClick={() => handRedirect()}
          className="cb-img"
          src={src}
          alt="Image"
        />
        {/* <Title42
         
          id={collection?.collection_details?._id}
          title={collection?.collection_details?.description}
        /> */}
      </OverlapGroup>
      {/* <Link
        to={`/shop/collections?${collection?.collection_details?._id}`}
      ></Link> */}
    </BannerStyle21>
  );
};

const BannerStyle21 = styled.article`
  position: relative;
  height: 440px;
  transition: all 0.2s ease;
  background-size: 100% 100%;
  width: fit-content;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const OverlapGroup = styled.div`
  position: relative;
  width: 100%;
  height: 440px;
`;

const Image = styled.img`
  position: absolute;
  width: 439px;
  height: 439px;
  top: 0;
  left: 1px;
`;

const Image1 = styled.img`
  width: 439px;
  height: 439px;
  top: 0;
  left: 0;
`;

export default BannerStyle2;
