import React from "react";
import styled from "styled-components";

class AutoLayoutHorizontal extends React.Component {
  render() {
    const { src, className } = this.props;

    return (
      <AutoLayoutHorizontal1
        className={`auto-layout-horizontal-11 ${className || ""}`}
      >
        <Frame
          className="frame-8 imageContainerBg"
          style={{
            backgroundImage: `url(${src})`,
          }}
        ></Frame>
      </AutoLayoutHorizontal1>
    );
  }
}

const AutoLayoutHorizontal1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  background: transparent;
  &.auto-layout-horizontal-11.auto-layout-horizontal-12 {
    overflow: unset;
  }

  &.auto-layout-horizontal-11.auto-layout-horizontal-13 {
    overflow: unset;
  }

  &.auto-layout-horizontal-11.auto-layout-horizontal-14 {
    overflow: unset;
  }
`;

const Frame = styled.div`
  position: relative;
  min-width: 320px;
  background: transparent;
  height: 425.330078125px;
  background-size: 100% 100%;
`;

const Frame1 = styled.div`
  .auto-layout-horizontal-11.auto-layout-horizontal-12 & {
    height: 425.3299560546875px;
  }
`;

const Frame2 = styled.div`
  .auto-layout-horizontal-11.auto-layout-horizontal-13 & {
    height: 425.3299560546875px;
  }
`;

const Frame3 = styled.div`
  .auto-layout-horizontal-11.auto-layout-horizontal-14 & {
    height: 425.3299560546875px;
  }
`;

export default AutoLayoutHorizontal;
