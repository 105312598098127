import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header4 from "../Header4";
import Frame from "../Frame";
import Checkbox from "../Checkbox";
import Checkbox2 from "../Checkbox2";
import Product from "../Product";
import Footer from "../Footer";
import styled from "styled-components";
import { animateScroll as scroll } from "react-scroll";

import {
  InterNormalBunker13px,
  InterNormalBunker14px,
  InterNormalBlack14px,
  InterNormalStormDust15px,
  InterNormalMountainMist13px,
  InterNormalBunker18px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./Shop.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getCollectionItemApi } from "../../api/collections";
import { Spin, notification } from "antd";
import FilterComp from "./filterComp";
import Pagination from "../Pagination";
import { itemByFilterApi } from "../../api/items";

const ShopCollection = (props) => {
  const [activePage, setActivePage] = useState(1);

  const [pageCount, setPageCount] = useState();
  const [pageSize, setpageSize] = useState(1);

  // http://dev.djtretailers.com/collection/viewmorecollectionitem/believe/
  const params = useLocation();
  const [items, setItems] = useState();
  const paramsId = useParams();
  const [FilterValue, setFilterValue] = useState();
  const collectionId = params.search.split("?")[1];
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  // const handlePageChange = (value) => {
  //   setActivePage(value);
  //   fetchCollectionItem(value);
  //   scroll.scrollToTop();
  // };

  const fetchCollectionItem = async (value) => {
    // if (params.state) {
    //   setItems(params?.state?.response?.item_details);
    // }

    // const fasion = await dev.get("item/fashion");
    setIsLoading(true);
    const { data } = await getCollectionItemApi(collectionId, value);
    setIsLoading(false);
    if (data.success) {
      setItems(data?.data?.items);
      setPageCount(data?.data?.page_count);
    } else {
      notification.error({ message: data.message,
        duration: 1,
        placement:"bottom"
      });
    }
  };

  const handlePageChange = (value) => {
    setActivePage(value);
    localStorage.setItem("collection", value);

    if (
      FilterValue?.size.length > 0 ||
      FilterValue?.sub_item.length > 0 ||
      FilterValue?.product.length > 0 ||
      FilterValue?.price.length > 0
    ) {
      FilterDataGetFunction(
        FilterValue?.size,
        FilterValue?.sub_item,
        FilterValue?.product,
        FilterValue?.price,
        value
      );
    } else {
      fetchCollectionItem(value);
    }
    history.push(
      params.pathname + "?" + params.search.split("?")[1] + "?page=" + value
    );
    scroll.scrollToTop();
  };

  const FilterDataGetFunction = async (
    allSize,
    selectedItems,
    productCat,
    priceValue,
    value = 1
  ) => {
  
    if (
      allSize.length > 0 ||
      selectedItems.length > 0 ||
      productCat.length > 0 ||
      priceValue.length > 0
    ) {
      const { data } = await itemByFilterApi(
        productCat,
        selectedItems,
        allSize,
        priceValue,
        value
      );
      if (data.success) {
        setItems(data?.data?.items);
        setPageCount(data?.data?.page_count);
      }
    } else {
      fetchCollectionItem(activePage);
    }
  };
  const setFilterData = async (
    allSize,
    selectedItems,
    productCat,
    priceValue
  ) => {
    setFilterValue({
      size: allSize,
      sub_item: selectedItems,
      product: productCat,
      price: priceValue,
    });
    FilterDataGetFunction(allSize, selectedItems, productCat, priceValue);
  };

  useEffect(() => {
    const pagination = localStorage.getItem("collection");
   

    if (pagination !== null) {
      const page = params?.search?.split("?")[2]?.replace("page=", "");
      if (params?.search?.split("?")[2]) {
        setActivePage(parseInt(page));
        fetchCollectionItem(page);
      } else {
        fetchCollectionItem(1);
        setActivePage(1);
        // setActivePage(parseInt(pagination));
        // fetchCollectionItem(pagination);
      }
    } else {
      // fetchCollectionItem(1);
    }
  }, [0]);

  const { product1Props } = props;
  return (
    <>
      <FilterComp setFilterData={setFilterData} props={props} />
      {(isLoading && (
        <div style={{ margin: "0 auto", paddingTop: "2rem" }}>
          <Spin size="large" />
        </div>
      )) || (
        <>
          {(items?.length && (
            <div>

            <FlexCol2 className="shop-main">
              {items?.length && (
                <Group2>
                  {items?.map((item, index) => (
                    <Product
                      pageSize={activePage}
                      pageName={"collection"}
                      key={index}
                      item={item}
                      className={product1Props?.className}
                      src={item?.images[0]?.url}
                      fetchData={fetchCollectionItem}
                    />
                  ))}
                </Group2>
              )}

              <Pagination
                length={pageCount}
                active={activePage}
                onSelect={handlePageChange}
              />
            </FlexCol2>
            </div>

          )) || (
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                paddingBottom: "2rem",
              }}
              className="para"
            >
              Data Not Found
            </p>
          )}
        </>
      )}
    </>
  );
};

const FlexCol = styled.div`
  width: 1512px;
  position: relative;
  margin-left: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 69px;
`;

const OverlapGroup2 = styled.div`
  width: 1512px;
  margin-right: 0.5px;
  display: flex;
  flex-direction: column;
  padding: 82.5px 56px;
  align-items: flex-start;
  min-height: 250px;
  gap: 5px;
  background-size: 100% 100%;
`;

const Title = styled.h1`
  ${ValignTextMiddle}
  height: 50px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 14px;
`;

const Place = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
`;

const Vector = styled.img`
  position: relative;
  min-width: 5.45166015625px;
  height: 11px;
`;

const Shop1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Banner1 = styled.img`
  width: 1400px;
  height: 150px;
  margin-right: 0.5px;
  object-fit: cover;
`;

const DivtbayFilter = styled.div`
  height: 48px;
  margin-left: 1.5px;
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  min-width: 1400px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--alto);
`;

const Showing112Of32Results = styled.p`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            height: 28px;
  min-width: 176px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const DivtbayOrdering = styled.div`
  display: flex;
  position: relative;
  margin-left: 922px;
  margin-top: 3px;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0px 4px;
`;

const SortBy = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const PCaptionCont = styled.div`
  position: relative;
  min-width: 138.52000427246094px;
  height: 24px;
  margin-left: -0.4199981689453125px;
  border-radius: 2px;
`;

const DefaultSorting = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 24px;
  top: -1px;
  left: 7px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 125px;
`;

const DivdisplayModeWarpper = styled.div`
  height: 24px;
  margin-left: 24px;
  margin-top: 2.88px;
  display: flex;
  padding: 0.2px 1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 87px;
  gap: 17px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

const Frame3 = styled.img`
  width: 22px;
  height: 22px;
`;

const FlexRow = styled.div`
  margin-top: 24px;
  margin-right: 1px;
  display: flex;
  align-items: flex-start;
  min-width: 1399px;
  gap: 39px;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Frame36949 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 46px;
`;

const Image = styled.img`
  position: relative;
  min-width: 320px;
  height: 588px;
`;

const Frame4 = styled.div`
  position: relative;
  min-width: 320px;
  height: 193px;
`;

const Frame5 = styled.div`
  position: absolute;
  width: 320px;
  top: 63px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 130px;
  gap: 10px;
`;

const Frame6 = styled.div`
  height: 2px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  background-color: var(--seashell);
  border-radius: 16px;
`;

const OverlapGroup = styled.div`
  width: 321px;
  height: 16px;
  position: relative;
  margin-top: -7px;
`;

const Rectangle = styled.div`
  position: absolute;
  width: 320px;
  height: 2px;
  top: 7px;
  left: 0;
  background-color: var(--primarycolor);
  border-radius: 11.2px;
`;

const Rectangle1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Rectangle2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 305px;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Frame7 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 106px;
  gap: 12px;
`;

const Frame8 = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-top: -1px;
  min-width: 43px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 30px;
  margin-left: 1px;
  margin-top: -1px;
  min-width: 35px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text27 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 22px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 5px;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 41.529998779296875px 0px 40.470001220703125px;
  background-color: var(--primarycolor);
`;

const Filter = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: var(--font-size-xxxs);
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 52px;
  white-space: nowrap;
`;

const Frame9 = styled.div`
  position: relative;
  min-width: 320px;
  height: 165px;
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 69px;
  left: 0;
`;

const Frame31 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 191px;
  position: relative;
`;

const XL = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: relative;
  width: 78px;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 53px;
  height: 22px;
  margin-right: -2px;
`;

const Text28 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalMountainMist13px}
            position: absolute;
  width: 19px;
  height: 22px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const FlexCol1 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 159px;
`;

const ProductCategories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
            height: 25px;
  margin-bottom: -3px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Rectangle3 = styled.div`
  width: 320px;
  height: 1px;
  margin-top: 16px;
  background-color: var(--alto);
`;

const FilterList1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
`;

const FlexCol2 = styled.div``;

const Group2 = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const UlpageNumbers = styled.div`
  display: flex;
  padding: 0 400px;
  align-items: center;
  min-width: 1040px;
`;

const SpanpageNumbers = styled.div`
  height: 48px;
  display: flex;
  padding: 12px 19.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 48px;
  background-color: var(--primarycolor);
`;

const Number = styled.div`
  ${ValignTextMiddle}
  width: 9px;
  height: 21px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 36px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 55px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;

export default ShopCollection;
