import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";
import { notification, Space, Spin } from "antd";

import {
  InterNormalBunker13px,
  InterNormalBunker14px,
  InterNormalBlack14px,
  InterNormalStormDust15px,
  InterNormalMountainMist13px,
  InterNormalBunker18px,
  ValignTextMiddle,
} from "../styledMixins";
import WhishCard from "./Product/WhishCard";
import { getWishlistApi } from "../api/wishlist";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WishList = (props) => {
  const [stock, setStock] = useState([]);
  const [outStock, setOutStock] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    const { data } = await getWishlistApi();
    if (data.success) {
      //   notification.success({
      //     message: data.message,
      //   });
      setStock(data?.data);
      setOutStock(data?.data?.out_of_stock);
      //   setStock(data?.data?.in_stock);
    } else {
      if (
        data.message === "please login" ||
        data.message === "You are logged out"
      ) {
        history.push(params.pathname + params.search, {
          state: "login",
        });
      }
      // notification.error({
      //   message: data.message,
      // });
    }

    setIsLoading(false);
  };
  //   const searchValue = location.search.replace(/^\?/, ""); // remove the ? character



  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [0]);

  const autoLayoutHorizontal14Data = {
    src: "/img/image@2x.png",
    className: "auto-layout-horizontal-13",
  };

  const product14Data = {
    className: "product-1",
    autoLayoutHorizontalProps: autoLayoutHorizontal14Data,
  };

  const shopData = {
    overlapGroup2: "/img/breadcrumbs-woo-jpg.png",
    place1: "Home",
    showing112Of32Results: "Showing 1–12 of 32 results",
    sortBy: "Sort by:",
    defaultSorting: "Default sorting",
    image: "/img/image-49.png",
    price1: "Price:",
    price2: "$200",
    text27: " —",
    number1: 1,
    number2: 2,
    number3: 3,
    price3: "$470",
    productCategories: "Product Categories",
    product1Props: product14Data,
    product2Props: product14Data,
  };

  const {
    overlapGroup2,
    place1,
    showing112Of32Results,
    sortBy,
    defaultSorting,
    number1,
    number2,
    number3,
    product1Props,
    product2Props,
  } = shopData;

  return (
    <div>
      {(isLoading && (
        <div
          style={{
            marginTop: "4rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )) || (
        <>
          {stock?.length > 0 && (
            <h2
              className="title text-center"
              style={{
                marginTop: "2rem",
              }}
            >
              My Wishlist
            </h2>
          )}
          <FlexRow style={{ paddingTop: "1rem" }}>
            {(stock?.length > 0 && (
              <FlexCol2>
                {stock?.length > 0 && (
                  <Group2 className="group-wishlist">
                    {stock?.map((item, index) => (
                      <div
                        key={index}
                        className={
                          item?.in_stock === false ? "out-of-stock" : ""
                        }
                      >
                        <WhishCard
                          propsData={props}
                          fetchData={fetchData}
                          className={product1Props?.className}
                          src={item?.images[0]?.url}
                          item={item}
                        />
                      </div>
                    ))}

                    {outStock?.map((item, index) => (
                      <div key={index} className="out-of-stock">
                        <WhishCard
                          propsData={props}
                          fetchData={fetchData}
                          style={{ background: "black" }}
                          key={index}
                          className={product1Props?.className}
                          src={item?.images[0]?.url}
                          item={item}
                        />
                      </div>
                    ))}
                  </Group2>
                )}

                {/* <UlpageNumbers>
                      <SpanpageNumbers>
                        <Number>{number1}</Number>
                      </SpanpageNumbers>
                      <Number1>{number2}</Number1>
                      <Number2>{number3}</Number2>
                      <Frame10 src="/img/frame-26.svg" alt="Frame" />
                    </UlpageNumbers> */}
              </FlexCol2>
            )) || (
              <div className="text-center">
                <br />
                <br />
                <br />
                <br />
                <div className="wishlist-svg">
                  <svg
                    width="638"
                    height="653"
                    viewBox="0 0 638 653"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_102_9)">
                      <path
                        d="M637.67 651.267C637.671 651.424 637.64 651.579 637.58 651.723C637.521 651.868 637.433 651.999 637.323 652.11C637.212 652.221 637.081 652.308 636.936 652.368C636.791 652.427 636.636 652.458 636.48 652.457H1.19003C0.874425 652.457 0.57174 652.332 0.348572 652.109C0.125403 651.886 3.05176e-05 651.583 3.05176e-05 651.267C3.05176e-05 650.952 0.125403 650.649 0.348572 650.426C0.57174 650.203 0.874425 650.077 1.19003 650.077H636.48C636.636 650.077 636.791 650.107 636.936 650.167C637.081 650.227 637.212 650.314 637.323 650.425C637.433 650.536 637.521 650.667 637.58 650.811C637.64 650.956 637.671 651.111 637.67 651.267Z"
                        fill="#CCCCCC"
                      />
                      <path
                        d="M517.096 29.4775C516.312 25.1123 514.371 21.0373 511.476 17.6775C507.359 12.9912 502.358 9.16176 496.761 6.40792C491.163 3.65408 485.078 2.02975 478.853 1.62767C472.628 1.22559 466.384 2.05365 460.479 4.06455C454.574 6.07546 449.122 9.22983 444.436 13.3476L444.036 13.7076C438.442 8.28557 431.624 4.29204 424.159 2.06541C416.694 -0.161209 408.802 -0.555285 401.152 0.916595C393.502 2.38847 386.32 5.68292 380.214 10.5207C374.108 15.3585 369.258 21.597 366.076 28.7076C363.152 35.2144 361.811 42.3216 362.164 49.4466C362.517 56.5716 364.553 63.5115 368.106 69.6976C369.7 72.4868 371.573 75.1069 373.696 77.5176C375.886 80.0176 435.006 145.788 435.596 146.448C435.667 146.53 435.754 146.595 435.852 146.64C435.951 146.685 436.058 146.708 436.166 146.708C436.186 146.708 436.216 146.698 436.246 146.698C436.288 146.697 436.329 146.687 436.366 146.668C436.561 146.62 436.73 146.498 436.836 146.328L437.146 145.818L437.516 145.188L442.986 136.048C458.156 110.698 478.606 86.7776 503.776 64.9576C505.397 63.5503 506.898 62.0118 508.266 60.3576C513.651 53.919 516.901 45.9651 517.566 37.5976C517.756 34.8819 517.598 32.1531 517.096 29.4775Z"
                        fill="#FF3055"
                      />
                      <path
                        d="M245.859 543.634L256.993 507.374C243.597 492.63 226.804 481.379 208.073 474.598C226.373 504.916 208.64 544.468 216.03 579.102C218.417 589.886 223.201 599.995 230.026 608.68C236.852 617.364 245.544 624.401 255.459 629.269L272.355 650.204C281.213 634.729 286.61 617.517 288.175 599.755C289.741 581.992 287.436 564.102 281.422 547.316C277.954 537.933 273.319 529.023 267.625 520.798C257.585 532.602 245.859 543.634 245.859 543.634Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M184.638 548.267L178.432 510.847C159.866 503.625 139.818 501.044 120.028 503.329C149.93 522.303 151.697 565.612 173.757 593.316C180.704 601.903 189.494 608.818 199.476 613.547C209.458 618.275 220.376 620.697 231.422 620.633L255.881 631.835C256.907 614.034 254.062 596.221 247.541 579.625C241.021 563.029 230.979 548.044 218.11 535.702C210.821 528.85 202.699 522.943 193.935 518.121C190.212 533.163 184.638 548.267 184.638 548.267Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M306.87 302.876L303.796 310.026L308.82 335.422L360.075 328.295L359.666 297.329L358.637 290.546L306.87 302.876Z"
                        fill="#FFB6B6"
                      />
                      <path
                        d="M280.389 632.005H291.697L297.078 588.389H280.389V632.005Z"
                        fill="#FFB6B6"
                      />
                      <path
                        d="M311.938 642.786L294.769 630.924V623.178L292.773 623.303L280.389 624.04L276.703 624.255L274.423 651.598H282.532L283.972 643.943L287.681 651.598H309.194C310.224 651.597 311.228 651.267 312.057 650.656C312.887 650.045 313.5 649.185 313.806 648.201C314.113 647.217 314.097 646.161 313.761 645.187C313.425 644.213 312.786 643.372 311.938 642.786Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M343.907 632.005H355.215L361.867 567.792L340.619 566.522L343.907 632.005Z"
                        fill="#FFB6B6"
                      />
                      <path
                        d="M375.457 642.786L358.287 630.924V623.178L356.11 623.303L343.51 624.062L340.222 624.255L337.942 651.598H346.051L347.491 643.943L351.199 651.598H372.713C373.743 651.597 374.746 651.267 375.576 650.656C376.406 650.045 377.019 649.185 377.325 648.201C377.631 647.217 377.615 646.161 377.279 645.187C376.943 644.213 376.305 643.372 375.457 642.786Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M275.589 594.392H275.633L277.808 523.474C276.4 520.759 275.686 517.738 275.729 514.681C275.771 511.624 276.569 508.624 278.051 505.95L278.362 505.386L277.555 501.644C276.518 496.836 276.994 491.825 278.917 487.298L280.946 421.144C273.698 374.17 308.24 343.535 308.24 343.535L305.066 318.915L360.936 320.185L359.986 343.535L371.095 381.704C376.941 438.663 377.289 494.465 371.308 548.935C371.296 549.091 371.319 549.249 371.376 549.395C371.983 551.24 372.197 553.192 372.003 555.125C371.81 557.057 371.214 558.928 370.253 560.616C370.004 561.047 369.833 561.519 369.747 562.009C368.515 571.558 368.355 559.479 366.722 568.943L367.04 569.104C368.184 569.499 368.674 571.084 368.136 572.644C367.597 574.204 366.234 575.149 365.09 574.754L335.223 575.073C334.079 574.678 333.588 573.093 334.126 571.533C334.665 569.973 336.029 569.029 337.173 569.423L339.031 570.363L335.922 560.192L334.833 559.175C334.31 558.688 333.905 558.088 333.648 557.422C333.39 556.756 333.286 556.041 333.344 555.329C333.402 554.617 333.62 553.927 333.982 553.312C334.344 552.696 334.841 552.17 335.435 551.773L336.338 522.246L336.778 509.746L335.872 495.565L331.683 431.78L315.828 510.218L299.633 595.182C300.01 595.522 300.294 595.955 300.457 596.437C300.62 596.919 300.656 597.434 300.561 597.934C300.412 598.633 300.025 599.258 299.466 599.702C298.906 600.146 298.21 600.382 297.496 600.369H275.51C275.049 600.369 274.594 600.262 274.182 600.057C273.769 599.852 273.409 599.554 273.13 599.188C272.85 598.821 272.66 598.394 272.573 597.942C272.485 597.489 272.504 597.022 272.626 596.578C272.812 595.939 273.202 595.379 273.738 594.984C274.273 594.589 274.924 594.381 275.589 594.392Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M295.14 182.202C295.139 186.73 296.249 191.189 298.372 195.188C300.496 199.187 303.57 202.603 307.322 205.137C311.074 207.671 315.392 209.245 319.894 209.721C324.397 210.196 328.948 209.56 333.147 207.866C337.347 206.173 341.066 203.475 343.979 200.008C346.892 196.542 348.91 192.413 349.854 187.985C350.799 183.557 350.643 178.965 349.399 174.611C348.154 170.257 345.861 166.276 342.718 163.016L342.586 162.883C342.364 162.651 342.143 162.418 341.911 162.197L341.906 162.196L341.903 162.194L341.9 162.19L341.9 162.186C341.582 161.954 341.29 161.691 341.025 161.4C337.291 156.182 333.057 151.807 322.802 154.54C308.037 158.476 295.14 166.922 295.14 182.202Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M447.091 170.36C447.154 171.84 446.892 173.316 446.322 174.683C445.753 176.051 444.891 177.277 443.796 178.275C442.702 179.274 441.402 180.02 439.988 180.461C438.574 180.903 437.08 181.029 435.612 180.83L364.275 239.377L353.378 220.225L426.927 168.698C427.424 166.254 428.799 164.077 430.794 162.579C432.788 161.082 435.262 160.368 437.748 160.573C440.233 160.778 442.557 161.887 444.279 163.691C446.001 165.495 447.002 167.868 447.091 170.36Z"
                        fill="#FFB6B6"
                      />
                      <path
                        d="M334.153 256.643C334.153 256.643 336.476 261.465 345.515 254.616C352.751 249.132 387.296 225.474 394.969 215.473C396.579 216.201 398.386 216.372 400.104 215.96C401.822 215.548 403.354 214.576 404.459 213.197L385.585 195.025C379.663 195.425 379.602 199.388 380.11 201.664L364.941 207.691L325.435 230.389L332.411 253.187L334.153 256.643Z"
                        fill="#E6E6E6"
                      />
                      <path
                        d="M329.617 211.797C342.427 211.797 352.812 201.413 352.812 188.602C352.812 175.792 342.427 165.407 329.617 165.407C316.807 165.407 306.422 175.792 306.422 188.602C306.422 201.413 316.807 211.797 329.617 211.797Z"
                        fill="#FFB7B7"
                      />
                      <path
                        d="M361.259 303.656L361.497 290.716C362.438 289.424 370.331 278.14 368.562 266.867C367.734 261.56 364.854 257.103 360.022 253.633L356.904 246.375L352.288 235.646L338.781 227.606L336.91 223.795L335.368 220.631L316.735 218.771L312.142 224.952L312.052 224.964C307.831 225.923 303.867 227.779 300.428 230.407C296.652 233.272 293.591 236.972 291.486 241.218C289.381 245.464 288.288 250.141 288.293 254.88L288.406 269.918L288.429 272.072L288.44 274.159L300.065 305.448L300.507 306.661L305.066 318.92L304.283 320.213C303.441 321.62 303.13 323.282 303.406 324.898C303.683 326.515 304.529 327.978 305.792 329.025L306.336 336.691L307.323 336.419L312.936 334.854L355.396 323.003C356.994 322.557 358.406 321.606 359.421 320.293C359.458 320.245 359.492 320.196 359.523 320.145C359.692 319.937 359.84 319.713 359.966 319.476C360.93 317.038 361.032 316.38 361.043 315.7C362.453 314.094 363.267 312.052 363.35 309.917C363.432 307.782 362.778 305.683 361.497 303.974L361.259 303.656Z"
                        fill="#E6E6E6"
                      />
                      <path
                        d="M305.342 194.683C305.552 194.65 305.762 194.605 305.973 194.572C309.591 193.93 313.209 193.3 316.816 192.647L317.148 184.47L321.518 191.795C331.488 195.103 340.815 193.233 349.589 189.106C352.484 187.732 355.292 186.184 357.999 184.47C358.89 170.642 353.299 161.789 342.895 162.717C342.515 162.751 342.243 162.363 341.911 162.197L341.906 162.196L341.903 162.194L341.9 162.19L341.9 162.186C341.335 161.92 340.76 161.666 340.184 161.434C335.68 159.673 330.769 159.227 326.021 160.148C321.273 161.069 316.884 163.318 313.364 166.634C306.283 173.394 303.096 184.559 305.342 194.683Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M320.455 155.207C320.223 156.007 319.764 156.721 319.134 157.265C318.504 157.808 317.73 158.157 316.905 158.269C313.787 162.125 310.214 166.208 305.327 166.979C303.93 167.275 302.472 167.024 301.255 166.276C301.107 166.182 300.97 166.072 300.847 165.947C299.69 164.847 299.6 162.635 300.972 161.808C294.825 164.246 291.23 170.88 290.323 177.424C289.416 183.967 290.675 190.579 291.502 197.134C292.33 203.688 292.693 210.572 290.119 216.662C287.08 223.841 280.094 229.001 272.473 230.645C271.792 230.793 271.112 230.917 270.42 230.997C269.961 227.884 268.805 224.915 267.041 222.31C267.507 225.325 267.231 228.408 266.236 231.292C260.505 231.212 254.877 229.758 249.826 227.05C242.919 223.41 237.17 217.853 232.463 211.627C240.572 217.377 251.776 219.531 260.599 214.984C268.492 210.901 273.153 202.328 275.195 193.686C277.236 185.033 268.656 174.432 269.586 165.597C270.527 156.763 281.182 149.265 287.011 142.563C290.731 138.287 296.22 135.123 301.879 135.656C303.79 135.838 305.64 136.43 307.301 137.391C308.963 138.353 310.398 139.661 311.509 141.227C312.62 142.793 313.38 144.579 313.738 146.466C314.095 148.352 314.043 150.293 313.583 152.157C315.193 149.877 319.355 150.376 320.387 152.973C320.664 153.688 320.688 154.477 320.455 155.207Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M305.973 194.572C305.973 194.572 312.844 168.924 342.298 163.005C342.331 162.994 342.442 162.949 342.586 162.883C342.674 162.828 342.785 162.772 342.895 162.717C342.563 162.529 342.243 162.363 341.911 162.197L341.906 162.196L341.903 162.194L341.9 162.19L341.9 162.186C341.612 161.92 341.324 161.655 341.025 161.4L340.185 161.434C340.185 161.434 310.631 156.753 305.973 194.572Z"
                        fill="#FD6584"
                      />
                      <path
                        d="M379.893 316.795C379.63 316.127 379.188 315.545 378.616 315.112C378.044 314.679 377.363 314.411 376.65 314.339C375.219 314.214 373.778 314.423 372.441 314.948C367.195 316.695 362.485 319.758 358.759 323.843L358.822 326.44C357.073 328.671 356.139 331.434 356.176 334.27C356.213 337.105 357.218 339.843 359.026 342.028C360.047 343.286 361.44 344.188 363.005 344.604C363.787 344.799 364.607 344.779 365.379 344.546C366.151 344.314 366.846 343.877 367.39 343.282C368.958 341.372 368.2 338.496 367.048 336.31C365.036 332.509 362.091 329.281 358.49 326.928C364.503 326.775 370.409 325.307 375.793 322.627C376.986 322.105 378.063 321.35 378.959 320.406C379.402 319.929 379.72 319.349 379.883 318.718C380.046 318.088 380.049 317.427 379.893 316.795Z"
                        fill="#2F2E41"
                      />
                      <path
                        d="M487.702 469.55L476.478 469.994C473.671 475.183 472.174 480.979 472.115 486.878C478.793 478.792 491.584 479.869 500.538 474.408C503.318 472.683 505.674 470.355 507.431 467.596C509.189 464.837 510.303 461.718 510.691 458.47L514.884 451.696C509.691 450.738 504.352 450.918 499.235 452.222C494.118 453.526 489.345 455.925 485.244 459.251C482.962 461.14 480.927 463.309 479.186 465.707C483.469 467.355 487.702 469.55 487.702 469.55Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M431.702 591.55L420.478 591.994C417.671 597.183 416.174 602.979 416.115 608.878C422.793 600.792 435.584 601.869 444.538 596.408C447.318 594.683 449.674 592.355 451.431 589.596C453.189 586.837 454.303 583.718 454.691 580.47L458.884 573.696C453.691 572.738 448.352 572.918 443.235 574.222C438.118 575.526 433.345 577.925 429.244 581.251C426.962 583.14 424.927 585.309 423.186 587.707C427.469 589.355 431.702 591.55 431.702 591.55Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M167.164 418.814L157.65 412.841C152.403 415.538 147.894 419.476 144.514 424.311C154.593 421.409 164.54 429.522 175.013 430.072C178.281 430.219 181.54 429.628 184.549 428.344C187.557 427.059 190.238 425.115 192.393 422.654L199.679 419.431C195.935 415.708 191.427 412.841 186.468 411.027C181.508 409.214 176.215 408.497 170.952 408.926C168.001 409.196 165.097 409.837 162.307 410.833C164.91 414.612 167.164 418.814 167.164 418.814Z"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M447.499 158.309C447.275 156.806 448.906 155.735 449.516 154.343C449.908 153.101 449.908 151.768 449.515 150.526C449.163 148.839 448.758 147.049 447.536 145.833C446.288 144.784 444.75 144.139 443.126 143.984C439.18 143.295 435.151 143.224 431.183 143.773L434.193 143.956C432.298 143.835 430.396 144.056 428.579 144.608C427.672 144.894 426.853 145.403 426.195 146.088C425.536 146.774 425.061 147.613 424.812 148.53C424.44 150.388 425.71 152.564 427.598 152.723C426.655 152.531 425.675 152.72 424.871 153.249C424.067 153.778 423.505 154.603 423.307 155.545C423.11 156.487 423.294 157.468 423.818 158.275C424.342 159.082 425.164 159.649 426.105 159.851C425.67 161.025 424.378 161.588 423.272 162.173C422.165 162.759 421.001 163.763 421.188 165.001C421.435 166.638 423.622 166.974 425.276 166.902L443.278 166.117C444.829 166.172 446.369 165.852 447.769 165.184C449.104 164.406 450.034 162.7 449.418 161.283C448.942 160.189 447.675 159.49 447.499 158.309Z"
                        fill="#3F3D56"
                      />
                      <path
                        d="M238.708 318.65C236.233 329.519 235.058 341.842 241.657 351.57C244.462 355.743 248.578 358.861 253.354 360.431C258.189 361.865 263.287 362.178 268.261 361.347C279.516 359.788 289.52 353.925 300.589 351.649C305.474 350.644 310.658 350.312 315.361 352.245C318.884 353.694 322.595 356.606 322.612 360.76C322.63 365.026 318.925 367.857 315.24 369.169C310.323 370.919 305.214 370.241 300.114 370.021C294.622 369.783 289.021 370.28 284.458 373.644C280.534 376.707 277.524 380.787 275.757 385.441C273.463 391.007 272.533 396.991 271.066 402.799C269.755 408.02 267.901 413.089 265.534 417.924C260.889 427.401 254.377 435.841 246.39 442.739C242.451 446.129 237.664 449.583 232.284 449.894C228.135 450.133 223.091 448.39 221.491 444.187C220.897 442.372 220.955 440.407 221.656 438.63C222.356 436.854 223.655 435.378 225.328 434.457C228.884 432.663 232.757 435.223 233.905 438.736C234.207 439.546 234.312 440.416 234.213 441.274C234.114 442.133 233.813 442.956 233.335 443.675C232.256 445.283 234.854 446.787 235.925 445.19C238.412 441.481 237.112 436.494 234.066 433.524C232.581 432.009 230.594 431.087 228.478 430.929C226.362 430.771 224.261 431.389 222.567 432.667C220.808 434.007 219.477 435.831 218.739 437.917C218.002 440.002 217.889 442.258 218.414 444.406C219.694 449.023 224.23 451.959 228.76 452.687C234.354 453.585 239.738 451.387 244.253 448.21C248.784 444.94 252.904 441.135 256.523 436.877C263.88 428.416 269.411 418.527 272.769 407.828C276.071 397.247 276.311 383.269 286.123 376.123C291.172 372.446 297.358 372.854 303.278 373.191C308.503 373.489 313.601 373.435 318.395 371.069C322.353 369.116 325.547 365.511 325.613 360.917C325.68 356.28 322.387 352.551 318.508 350.438C308.26 344.857 295.954 349.358 285.886 353.087C275.762 356.837 264.202 361.238 253.443 357.292C248.477 355.362 244.479 351.542 242.325 346.669C239.72 341.144 239.349 334.874 239.951 328.878C240.306 325.703 240.857 322.554 241.6 319.447C242.028 317.567 239.137 316.765 238.708 318.65Z"
                        fill="#3F3D56"
                      />
                      <path
                        d="M288.393 379.138C287.472 377.978 286.816 376.631 286.472 375.19C286.128 373.749 286.104 372.25 286.401 370.799C286.699 369.348 287.311 367.98 288.195 366.791C289.079 365.602 290.212 364.622 291.516 363.918L276.267 318.774L288.719 270.933L308.797 280.01L296.407 316.095L305.677 368.62C306.713 370.889 306.881 373.458 306.15 375.843C305.418 378.227 303.837 380.26 301.707 381.556C299.576 382.853 297.044 383.323 294.59 382.877C292.137 382.431 289.932 381.101 288.393 379.138Z"
                        fill="#FFB6B6"
                      />
                      <path
                        d="M306.772 231.514C306.772 231.514 302.056 228.982 298.776 239.838C296.151 248.529 278.282 295.718 277.96 308.32C276.23 308.678 274.668 309.603 273.522 310.947C272.375 312.291 271.708 313.978 271.625 315.743L297.578 319.334C302.132 315.527 299.849 312.286 298.1 310.744L306.939 295.772L325.414 255.352L310.213 233.284L306.772 231.514Z"
                        fill="#E6E6E6"
                      />
                      <path
                        d="M257.096 288.158C254.129 298.062 249.657 307.452 243.836 315.998C242.396 318.098 240.883 320.144 239.296 322.138C238.063 319.896 236.569 317.808 234.846 315.918C228.796 309.268 220.686 304.918 212.816 300.558C204.946 296.198 196.956 291.518 191.396 284.448C188.901 281.265 187.115 277.586 186.156 273.658C184.896 268.328 185.376 262.768 188.486 258.318C193.006 251.848 202.016 249.568 209.676 251.438C217.346 253.308 223.766 258.638 228.746 264.758C227.116 257.238 235.046 249.978 242.716 250.638C250.386 251.308 256.536 258.008 258.576 265.428C260.626 272.838 259.296 280.778 257.096 288.158Z"
                        fill="#FF3055"
                      />
                      <path
                        d="M405.362 13.0337C401.298 13.6973 397.411 15.1797 393.937 17.391C390.463 19.6022 387.475 22.4963 385.154 25.8974C382.801 29.409 381.233 33.3869 380.558 37.5597C379.882 41.7324 380.115 46.0017 381.24 50.0762C382.504 54.8009 384.904 59.1447 388.232 62.7285C389.55 64.1449 391.668 62.0198 390.354 60.6072C387.733 57.7316 385.752 54.3328 384.542 50.635C383.331 46.9373 382.919 43.0249 383.333 39.1562C383.778 35.4381 385.01 31.8574 386.945 28.652C388.881 25.4467 391.478 22.6902 394.561 20.5657C398.017 18.1436 401.987 16.5556 406.159 15.9265C408.062 15.6401 407.254 12.7489 405.362 13.0337Z"
                        fill="#3F3D56"
                      />
                      <g opacity="0.2">
                        <path
                          d="M508.266 60.3576C506.898 62.0117 505.397 63.5503 503.776 64.9576C478.606 86.7776 458.156 110.698 442.986 136.048L437.516 145.188L437.146 145.818C435.857 144.847 434.733 143.675 433.816 142.348C430.316 137.258 430.296 130.568 431.156 124.448C433.757 106.097 443.093 89.3716 457.346 77.5251C471.599 65.6786 489.75 59.5591 508.266 60.3576Z"
                          fill="black"
                        />
                      </g>
                      <g opacity="0.2">
                        <path
                          d="M243.836 315.997C242.396 318.098 240.883 320.144 239.296 322.137C238.063 319.896 236.569 317.808 234.846 315.917C228.796 309.267 220.686 304.917 212.816 300.557C204.946 296.197 196.956 291.517 191.396 284.447C188.901 281.265 187.115 277.586 186.156 273.657C203.848 276.11 220.104 284.734 232.056 298.007C236.841 303.396 240.81 309.457 243.836 315.997Z"
                          fill="black"
                        />
                      </g>
                      <path
                        d="M435.586 145.598C437.795 145.598 439.586 143.807 439.586 141.598C439.586 139.388 437.795 137.598 435.586 137.598C433.377 137.598 431.586 139.388 431.586 141.598C431.586 143.807 433.377 145.598 435.586 145.598Z"
                        fill="#3F3D56"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_102_9">
                        <rect width="637.67" height="652.457" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                </div>
                <br />

                <p
                  style={{
                    width: "100%",
                    fontSize: "18px",
                    paddingBottom: "0.4rem",
                  }}
                  className="para bold"
                >
                  Hey! Your wishlist is empty.
                </p>
                <p
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    paddingBottom: "2rem",
                    lineHeight: "1.4em",
                  }}
                  className="para text-center"
                >
                  Save your favourites here and make them yours soon!
                </p>
                <button
                  style={{ marginBottom: "3rem" }}
                  className="btn-primary"
                  onClick={() => history.push("/")}
                >
                  Shop now
                </button>
              </div>
            )}
          </FlexRow>
        </>
      )}
    </div>
  );
};

const FlexCol = styled.div`
  width: 1512px;
  position: relative;
  margin-left: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 69px;
`;

const OverlapGroup2 = styled.div`
  width: 1512px;
  margin-right: 0.5px;
  display: flex;
  flex-direction: column;
  padding: 82.5px 56px;
  align-items: flex-start;
  min-height: 250px;
  gap: 5px;
  background-size: 100% 100%;
`;

const Title = styled.h1`
  ${ValignTextMiddle}
  height: 50px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 14px;
`;

const Place = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
`;

const Vector = styled.img`
  position: relative;
  min-width: 5.45166015625px;
  height: 11px;
`;

const Shop1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Banner1 = styled.img`
  width: 1400px;
  height: 150px;
  margin-right: 0.5px;
  object-fit: cover;
`;

const DivtbayFilter = styled.div`
  height: 48px;
  margin-left: 1.5px;
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  min-width: 1400px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--alto);
`;

const Showing112Of32Results = styled.p`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            height: 28px;
  min-width: 176px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const DivtbayOrdering = styled.div`
  display: flex;
  position: relative;
  margin-left: 922px;
  margin-top: 3px;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0px 4px;
`;

const SortBy = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const PCaptionCont = styled.div`
  position: relative;
  min-width: 138.52000427246094px;
  height: 24px;
  margin-left: -0.4199981689453125px;
  border-radius: 2px;
`;

const DefaultSorting = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 24px;
  top: -1px;
  left: 7px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 125px;
`;

const DivdisplayModeWarpper = styled.div`
  height: 24px;
  margin-left: 24px;
  margin-top: 2.88px;
  display: flex;
  padding: 0.2px 1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 87px;
  gap: 17px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

const Frame3 = styled.img`
  width: 22px;
  height: 22px;
`;

const FlexRow = styled.div`
  margin-top: 24px;
  margin-right: 1px;
  align-items: flex-start;
  gap: 39px;
  justify-content: center;
  padding-top: 3rem;
`;

const Frame36949 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 46px;
`;

const Image = styled.img`
  position: relative;
  min-width: 320px;
`;

const Frame4 = styled.div`
  position: relative;
  min-width: 320px;
  height: 193px;
`;

const Frame5 = styled.div`
  position: absolute;
  width: 320px;
  top: 63px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 130px;
  gap: 10px;
`;

const Frame6 = styled.div`
  height: 2px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  background-color: var(--seashell);
  border-radius: 16px;
`;

const OverlapGroup = styled.div`
  width: 321px;
  height: 16px;
  position: relative;
  margin-top: -7px;
`;

const Rectangle = styled.div`
  position: absolute;
  width: 320px;
  height: 2px;
  top: 7px;
  left: 0;
  background-color: var(--primarycolor);
  border-radius: 11.2px;
`;

const Rectangle1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Rectangle2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 305px;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Frame7 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const Frame8 = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-top: -1px;
  min-width: 43px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 30px;
  margin-left: 1px;
  margin-top: -1px;
  min-width: 35px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text27 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 22px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 5px;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 41.529998779296875px 0px 40.470001220703125px;
  background-color: var(--primarycolor);
`;

const Filter = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: var(--font-size-xxxs);
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 52px;
  white-space: nowrap;
`;

const Frame9 = styled.div`
  position: relative;
  min-width: 320px;
  height: 165px;
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 69px;
  left: 0;
`;

const Frame31 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 191px;
  position: relative;
`;

const XL = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: relative;
  width: 78px;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 53px;
  height: 22px;
  margin-right: -2px;
`;

const Text28 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalMountainMist13px}
            position: absolute;
  width: 19px;
  height: 22px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const FlexCol1 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 159px;
`;

const ProductCategories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
            height: 25px;
  margin-bottom: -3px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Rectangle3 = styled.div`
  width: 320px;
  height: 1px;
  margin-top: 16px;
  background-color: var(--alto);
`;

const FilterList1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
`;

// min-height: 636px;
const FlexCol2 = styled.div``;

const Group2 = styled.div`
  position: relative;
  gap: 19px;
  flex-wrap: wrap;
  display: flex;
  width: 93%;
  margin: auto;
`;

const UlpageNumbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const SpanpageNumbers = styled.div`
  height: 48px;
  display: flex;
  padding: 12px 19.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 48px;
  background-color: var(--primarycolor);
`;

const Number = styled.div`
  ${ValignTextMiddle}
  width: 9px;
  height: 21px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 36px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 55px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;

export default WishList;
