import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Product from "./Product";
import styled from "styled-components";
import { Button, Divider, notification, Space, Spin } from "antd";
import {
  InterNormalBunker13px,
  InterNormalBunker14px,
  InterNormalBlack14px,
  InterNormalStormDust15px,
  InterNormalMountainMist13px,
  InterNormalBunker18px,
  ValignTextMiddle,
} from "../styledMixins";
import { searchApi } from "../api/search";
import Pagination from "./Pagination";
import notFoundImg from '../images/data_not_found.jpg'


const SearchItem = (props) => {
  const [resultData, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [pageSize, setpageSize] = useState(1);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const params = useLocation();

  const fetchSeachData = async (value, pageSize) => {
    try {
      if (value) {
        setResultData([]);
        setIsLoading(true);
        const { data } = await searchApi(value, pageSize, 12);
        setIsLoading(false);
        if (data.success) {
          setResultData(data?.data?.items);
        } 
        else {
          setErrorMessage(data.message);
        }
      } 
    }
    catch (err) {
      setErrorMessage(null);
    }
  };
  //   const searchValue = location.search.replace(/^\?/, ""); // remove the ? character

  const handlePageChange = (value) => {
    fetchSeachData(params.search.replace(/^\?/, ""), value);
  };

  useEffect(() => {
    fetchSeachData(params.search.replace(/^\?/, ""), 1);
  }, [params]);

  const autoLayoutHorizontal14Data = {
    src: "/img/image@2x.png",
    className: "auto-layout-horizontal-13",
  };

  const product14Data = {
    className: "product-1",
    autoLayoutHorizontalProps: autoLayoutHorizontal14Data,
  };

  const shopData = {
    overlapGroup2: "/img/breadcrumbs-woo-jpg.png",
    place1: "Home",
    showing112Of32Results: "Showing 1–12 of 32 results",
    sortBy: "Sort by:",
    defaultSorting: "Default sorting",
    image: "/img/image-49.png",
    price1: "Price:",
    price2: "$200",
    text27: " —",
    number1: 1,
    number2: 2,
    number3: 3,
    price3: "$470",
    productCategories: "Product Categories",
    product1Props: product14Data,
    product2Props: product14Data,
  };

  const {
    overlapGroup2,
    place1,
    showing112Of32Results,
    sortBy,
    defaultSorting,
    number1,
    number2,
    number3,
    product1Props,
    product2Props,
  } = shopData;

  return (
    <div className="container-center-horizontal">
      <div className="shop screen">
        <FlexCol>
          <OverlapGroup2 style={{ backgroundImage: `url(${overlapGroup2})` }}>
            <Title>Search</Title>
            <Frame2>
              <Link to="/home">
                <Place>{place1}</Place>
              </Link>
              <Vector src="/img/vector-2.svg" alt="Vector" />
              <Shop1>Search</Shop1>
            </Frame2>
          </OverlapGroup2>
          {/* <Banner1 src={banner1} alt="banner 1" /> */}
          {/* <DivtbayFilter>
            <Showing112Of32Results>
              {showing112Of32Results}
            </Showing112Of32Results>
            <DivtbayOrdering>
              <SortBy>{sortBy}</SortBy>
              <PCaptionCont>
                <DefaultSorting>{defaultSorting}</DefaultSorting>
                <Frame1 src="/img/frame-26.svg" alt="Frame" />
              </PCaptionCont>
            </DivtbayOrdering>
            <DivdisplayModeWarpper>
              <Frame3 src="/img/frame-24.svg" alt="Frame" />
              <Frame3 src="/img/frame-25.svg" alt="Frame" />
            </DivdisplayModeWarpper>
          </DivtbayFilter> */}
        </FlexCol>

        {(isLoading && <Spin size="large" />) || (
          <>
            <h2 style={{ paddingTop: "1rem" }} className="third-heading">
              {resultData[0]?.category?.code}
            </h2>
            <FlexRow>
              {/* <Frame36949>
            <Image src={image} alt="Image" />
            <Frame4>
              <Frame>{frame1Props.children}</Frame>
              <Frame5>
                <Frame6>
                  <OverlapGroup>
                    <Rectangle></Rectangle>
                    <Rectangle1></Rectangle1>
                    <Rectangle2></Rectangle2>
                  </OverlapGroup>
                </Frame6>
                <Frame7>
                  <Frame8>
                    <Price>{price1}</Price>
                    <Price1>{price2}</Price1>
                    <Text27>{text27}</Text27>
                    <Price2>{price3}</Price2>
                  </Frame8>
                  <AutoLayoutHorizontal>
                    <Filter>{filter}</Filter>
                  </AutoLayoutHorizontal>
                </Frame7>
              </Frame5>
            </Frame4>
            <Frame9>
              <Frame className={frame2Props.className}>
                {frame2Props.children}
              </Frame>
              <FilterList>
                <Frame31>
                  <XL>{xl}</XL>
                  <Group3>
                    <Checkbox />
                    <Text28>{text28}</Text28>
                  </Group3>
                </Frame31>
                <Frame31>
                  <XL>{m}</XL>
                  <Group3>
                    <Checkbox2 />
                    <Text28>{text29}</Text28>
                  </Group3>
                </Frame31>
                <Frame31>
                  <XL>{price4}</XL>
                  <Group3>
                    <Checkbox2 className={checkbox21Props.className} />
                    <Text28>{text30}</Text28>
                  </Group3>
                </Frame31>
              </FilterList>
            </Frame9>
            <Frame9>
              <FlexCol1>
                <ProductCategories>{productCategories}</ProductCategories>
                <Rectangle3></Rectangle3>
                <FilterList1>
                  <Frame31>
                    <XL>{place2}</XL>
                    <Group3>
                      <Checkbox2 className={checkbox22Props.className} />
                      <Text28>{text31}</Text28>
                    </Group3>
                  </Frame31>
                  <Frame31>
                    <XL>{women}</XL>
                    <Group3>
                      <Checkbox2 className={checkbox23Props.className} />
                      <Text28>{text32}</Text28>
                    </Group3>
                  </Frame31>
                  <Frame31>
                    <XL>{kids}</XL>
                    <Group3>
                      <Checkbox2 className={checkbox24Props.className} />
                      <Text28>{text33}</Text28>
                    </Group3>
                  </Frame31>
                </FilterList1>
              </FlexCol1>
            </Frame9>
          </Frame36949> */}

              {(resultData?.length && (
                <div>
                  <FlexCol2>
                    {resultData?.length && (
                      <Group2>
                        {resultData?.map((item, index) => (
                          <Product
                            fetchData={fetchSeachData}
                            key={index}
                            className={product1Props?.className}
                            src={item?.images[0]?.url}
                            item={item}
                          />
                        ))}
                      </Group2>
                    )}

                    <Pagination
                      length={pageCount}
                      active={pageSize}
                      onSelect={handlePageChange}
                    />

                    {/* <UlpageNumbers>
                      <SpanpageNumbers>
                        <Number>{number1}</Number>
                      </SpanpageNumbers>
                      <Number1>{number2}</Number1>
                      <Number2>{number3}</Number2>
                      <Frame10 src="/img/frame-26.svg" alt="Frame" />
                    </UlpageNumbers> */}
                  </FlexCol2>
                  <br />
                </div>
              )) || (
                <div className='responsive-product-card-div' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 40 }}>
                  <img src={notFoundImg} style={{ objectFit: 'contain', width: 300 }} />
                  <span style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 12 }}>
                    Couldn't find the product
                  </span>
                  {errorMessage ? 
                    <span style={{ fontSize: 16 }}>{errorMessage}</span> :
                    <span style={{ fontSize: 16 }}>
                      Sorry, We couldn't find anything. You can try<br />another search or browse our catalogue.
                    </span>
                  }
                </div>
              )}
            </FlexRow>
          </>
        )}
      </div>
    </div>
  );
};

const FlexCol = styled.div`
  width: 1512px;
  position: relative;
  margin-left: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 69px;
`;

const OverlapGroup2 = styled.div`
  width: 1512px;
  margin-right: 0.5px;
  display: flex;
  flex-direction: column;
  padding: 82.5px 56px;
  align-items: flex-start;
  min-height: 250px;
  gap: 5px;
  background-size: 100% 100%;
`;

const Title = styled.h1`
  ${ValignTextMiddle}
  height: 50px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 14px;
`;

const Place = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
`;

const Vector = styled.img`
  position: relative;
  min-width: 5.45166015625px;
  height: 11px;
`;

const Shop1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Banner1 = styled.img`
  width: 1400px;
  height: 150px;
  margin-right: 0.5px;
  object-fit: cover;
`;

const DivtbayFilter = styled.div`
  height: 48px;
  margin-left: 1.5px;
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  min-width: 1400px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--alto);
`;

const Showing112Of32Results = styled.p`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            height: 28px;
  min-width: 176px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const DivtbayOrdering = styled.div`
  display: flex;
  position: relative;
  margin-left: 922px;
  margin-top: 3px;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0px 4px;
`;

const SortBy = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const PCaptionCont = styled.div`
  position: relative;
  min-width: 138.52000427246094px;
  height: 24px;
  margin-left: -0.4199981689453125px;
  border-radius: 2px;
`;

const DefaultSorting = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 24px;
  top: -1px;
  left: 7px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 125px;
`;

const DivdisplayModeWarpper = styled.div`
  height: 24px;
  margin-left: 24px;
  margin-top: 2.88px;
  display: flex;
  padding: 0.2px 1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 87px;
  gap: 17px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

const Frame3 = styled.img`
  width: 22px;
  height: 22px;
`;

const FlexRow = styled.div`
  margin-top: 24px;
  margin-right: 1px;
  display: flex;
  align-items: flex-start;
  min-width: 1399px;
  gap: 39px;
  justify-content: center;

`;
// background: #faf9f7;

const Frame36949 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 46px;
`;

const Image = styled.img`
  position: relative;
  min-width: 320px;
`;

const Frame4 = styled.div`
  position: relative;
  min-width: 320px;
  height: 193px;
`;

const Frame5 = styled.div`
  position: absolute;
  width: 320px;
  top: 63px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 130px;
  gap: 10px;
`;

const Frame6 = styled.div`
  height: 2px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  background-color: var(--seashell);
  border-radius: 16px;
`;

const OverlapGroup = styled.div`
  width: 321px;
  height: 16px;
  position: relative;
  margin-top: -7px;
`;

const Rectangle = styled.div`
  position: absolute;
  width: 320px;
  height: 2px;
  top: 7px;
  left: 0;
  background-color: var(--primarycolor);
  border-radius: 11.2px;
`;

const Rectangle1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Rectangle2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 305px;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Frame7 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const Frame8 = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-top: -1px;
  min-width: 43px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 30px;
  margin-left: 1px;
  margin-top: -1px;
  min-width: 35px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text27 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 22px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 5px;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 41.529998779296875px 0px 40.470001220703125px;
  background-color: var(--primarycolor);
`;

const Filter = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: var(--font-size-xxxs);
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 52px;
  white-space: nowrap;
`;

const Frame9 = styled.div`
  position: relative;
  min-width: 320px;
  height: 165px;
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 69px;
  left: 0;
`;

const Frame31 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 191px;
  position: relative;
`;

const XL = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: relative;
  width: 78px;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 53px;
  height: 22px;
  margin-right: -2px;
`;

const Text28 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalMountainMist13px}
            position: absolute;
  width: 19px;
  height: 22px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const FlexCol1 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 159px;
`;

const ProductCategories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
            height: 25px;
  margin-bottom: -3px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Rectangle3 = styled.div`
  width: 320px;
  height: 1px;
  margin-top: 16px;
  background-color: var(--alto);
`;

const FilterList1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
`;

const FlexCol2 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 636px;
  gap: 29px;
`;

const Group2 = styled.div`
  position: relative;
  gap: 39px;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  margin: auto;

  justify-content: center;
`;

const UlpageNumbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const SpanpageNumbers = styled.div`
  height: 48px;
  display: flex;
  padding: 12px 19.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 48px;
  background-color: var(--primarycolor);
`;

const Number = styled.div`
  ${ValignTextMiddle}
  width: 9px;
  height: 21px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 36px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 55px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;

export default SearchItem;
