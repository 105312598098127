import React from "react";
import styled from "styled-components";
import {
  YesevaoneNormalSapphire251px,
  LatoBoldWhite125px,
  ValignTextMiddle,
} from "../../styledMixins";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

class Title42 extends React.Component {
  render() {
    const { className, title, id, handRedirect } = this.props;

    const renderHTML = (rawHTML) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });

    return (
      <Title className={`title-2 ${className || ""}`}>
        <ForWomens
          style={{ textTransform: "uppercase" }}
          className="for-womens-2"
        >
          {(title && renderHTML(title.replace(" ", "<br/>"))) || (
            <div>
              New <br />
              Collection
            </div>
          )}
        </ForWomens>
        {(title && (
          <Sleeplessness
            onClick={(e) => handRedirect(e)}
            className="sleeplessness-1"
          >
            SHOP NOW
          </Sleeplessness>
        )) || (
          <Link to="/collections/2344">
            <Sleeplessness className="sleeplessness-1">SHOP NOW</Sleeplessness>
          </Link>
        )}

        {/* <button style={{ background: "none", border: "none" }}>
          hellow oweri jldakf
        </button> */}
        {/* <Link to={`/shop/${id}`}></Link> */}
      </Title>
    );
  }
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  justify-content: center;
  gap: 22px;
  position: absolute;
  top: 42px;
  left: 40px;

  &.title-2.title-4 {
    position: relative;
    top: unset;
    left: unset;
  }

  &.title-2.title-5 {
    position: relative;
  }
`;

const ForWomens = styled.div`
  ${ValignTextMiddle}
  font-style: normal;
  font-weight: 400;
  font-size: 26.7675px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: black;
  position: relative;
  white-space: pre-wrap;
`;

const Sleeplessness = styled.div`
  ${ValignTextMiddle}
  ${LatoBoldWhite125px}
            position: relative;
  width: fit-content;
  background: linear-gradient(
    180deg,
    rgb(255, 48.000000938773155, 85.0000025331974) 0%,
    rgb(253.0000001192093, 121.00000038743019, 136.00000709295273) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 2.13px;
  line-height: 16.7px;
  text-decoration: underline;
  white-space: nowrap;
`;

export default Title42;
