import { format } from "date-fns";

const formateDate = (date) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const customeData = date && format(new Date(date), "d");
  const month = date && format(new Date(date), "MMMM").slice(0, 3);
  const year = date && format(new Date(date), "yyyy");
  const today = date && weekday[new Date(date).getDay()];
  return `  ${today} ${month} ${customeData},   ${" "} ${year}`;
};

export default formateDate;
