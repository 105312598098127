import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AutoLayoutHorizontal from "../AutoLayoutHorizontal";
import AutoLayoutVertical from "../AutoLayoutVertical";
import styled from "styled-components";

import {
  InterSemiBoldSapphire14px,
  InterNormalBunker14px,
  InterNormalBunker11px,
  InterSemiBoldSapphire16px,
  InterSemiBoldSapphire15px,
  ValignTextMiddle,
} from "../../styledMixins";
import { InterNormalWhite12px, ValignTextMiddle } from "../../styledMixins";
import { api } from "../../api/baseurl";
import { Rate, Row, notification } from "antd";
import axios from "axios";
import { addWishlistApi, deleteWishlistApi } from "../../api/wishlist";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Product = (props) => {
  const { className, src, item, fetchData, pageSize, disableResponsive, setWishListAdded} = props;
  const params = useLocation();
  const history = useHistory();
  const [imageVisible, setImageVisible] = useState(true);


  const handleAddWishlist = async (e, item) => {
    // e.preventDefault();
    const { data } = await addWishlistApi(item?._id);
    if (data.success) {
      if(!fetchData){
        setWishListAdded(Math.random());
      }
      notification.success({
        message: data.message,
        duration: 1,
        placement:"bottom"
      });

      // window.location.reload(false);
      if (fetchData) {
        fetchData();
      }
    } else {
      if (
        data.message === "please login" ||
        data.message === "You are logged out"
      ) {
        history.push(params.pathname + params.search, {
          state: "login",
        });
      }
      // notification.error({
      //   message: data.message,
      // });
    }
  };

  const handleRouting = (id) => {
    history.push("/singleShop", { id: id });
  };

  const handleImageError = () => {
    setImageVisible(false);
  };

  // const removeFromWishlist=(e,item)=>{
  //   console.log("wishlist removing messageeeeeee",e);
  // }

  const removeFromWishlist = async (e,item) => {
    const resultData = await deleteWishlistApi(item?._id);
    if (resultData?.data?.success) {
      if(!fetchData){
        setWishListAdded(Math.random());
      }
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
      if (fetchData) {
        fetchData();
      }
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  // setWishListAdded(false);

  return (
    item?.is_active === true && (
      <Product1 className={`product ${ !disableResponsive && 'responsive-product-card'} ${className || ""}`} >

          <div className="image-inner-contain">
            <div className="productImageContain">
              <div
                className="auto-layout-horizontal-11"
                style={{
                  position: "relative",
                }}
              >
        <Link style={{ width: '100%' }} to={`/singleShop?${item?._id}`}>
                
                {(imageVisible && (
                  <img
                    // style={{ objectFit: "contain" }}
                    style={{
                      objectFit: "contain",
                      width: '100%',
                      aspectRatio: '1/1'
                    }}
                    className="product-image-a imageContainerBg"
                    src={src ? src : "/img/no_image.png"}
                    alt=""
                    onError={handleImageError}
                  />
                )) || (
                  <img
                    style={{ objectFit: "cover", width: '100%', aspectRatio: '1/1' }}
                    className="product-image-a imageContainerBg"
                    src={"/img/no_image.png"}
                    alt=""
                    onError={handleImageError}
                  />
                )}

</Link>

                <div className="card-buttons">
                  <Row style={{ backgroundColor: 'white', width: 32, height: 32, paddingTop: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                    {(item?.in_wishlist === true && (
                      // <Link to="/wishlist">
                  
                        <div
                          style={{
                            alignSelf: "center",
                          }}

                          onClick={(e) =>removeFromWishlist(e,item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#f4792c"
                            className="bi bi-heart-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                            />
                          </svg>
                        </div>

                      // </Link>
                    )) || (
                      <div
                        onClick={(e) => handleAddWishlist(e, item)}
                        // className="add-to-wishlist"
                      >
                        {/* WishList{" "} */}
                        {/* <svg
                        style={{ marginBottom: "-5px", marginLeft: "3px" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-heart"
                        viewBox="0 0 16 16"
                      >
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                      </svg> */}

                        <div className="w-heart" style={{ alignSelf: "center" }}>
                          <svg
                            style={{
                              // marginLeft: "10px",
                              color: "#f4792c",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#f4792c"
                            className="bi bi-heart"
                            viewBox="0 0 16 16"
                          >
                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                          </svg>
                        </div>
                      </div>
                    )}



                  </Row>
                </div>
              </div>
            </div>
          </div>

          <Link style={{ width: '100%' }} to={`/singleShop?${item?._id}`}>


          <FlexCol className="flex-col-4"  style={{ width: '100%' }}>
            <SuperLSLinenTee
              // style={{ fontWeight: "bold", overflow: 'hidden', maxWidth: 'calc(100% - 16px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
              style={{"display": "-webkit-box", "-webkit-box-orient": "vertical", "-webkit-line-clamp": "1", "font-weight": "bold", "overflow": "hidden", maxWidth: 'calc(100% - 16px)',"font-size": "large"}}
              className="super-ls-linen-tee"
            >
              {(item?.name && item?.name) || item?.name}
            </SuperLSLinenTee>

            {/* <Frame1 className="frame-6">
            <Frame2 className="frame-7" src="/img/frame-12.svg" alt="Frame" />
            <Number className="number-1"></Number>
          </Frame1> */}
            {/* <p
              style={{ margin: "0px", fontWeight: "400", overflow: 'hidden', maxWidth: 'calc(100% - 16px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              className="small-para"
            >
              {item?.description}
            </p> */}
            <Frame36950>
              <Rate
                style={{
                  fontSize: 12,
                }}
                allowHalf
                disabled
                defaultValue={item?.rating}
              />
              {/* <Name1 style={{ margin: "0" }}>&nbsp; {item?.rating} Ratings</Name1> */}
            </Frame36950>
            {/* <div style={{ display: "flex", gap: "10px" }}>
           <p > <strike>₹100</strike> </p>
           <p  style={{ color: "#2f4799" }}> ₹100</p>
          </div> */}
            <AutoLayoutHorizontal1 className="auto-layout-horizontal-8">
              {item?.warehouses && (
                <div>
                  {(item?.warehouses[0]?.MRP === item?.warehouses[0]?.ASP && (
                    <AutoLayoutHorizontal2 className="auto-layout-horizontal-9" style={{ marginBottom: 23 }}>
                      <Price className="price-4">
                        ₹ {item?.warehouses && item?.warehouses[0]?.ASP}
                      </Price>

                    </AutoLayoutHorizontal2>

                  )) || (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "0px 4px",
                      }}
                    >
                      <AutoLayoutHorizontal2 className="auto-layout-horizontal-9">
                        {/* <Price
                        style={{
                          color: "#8f8f8f",

                          fontWeight: "400",
                          marginRight: "6px",
                        }}
                        className="price-4"
                      >
                        MRP:
                      </Price> */}
                        <Price
                          style={{
                            color: "#8f8f8f",
                            textDecoration: "line-through",
                            fontWeight: "400",
                          }}
                          className="price-4"
                        >
                          ₹{item?.warehouses && item?.warehouses[0]?.MRP}
                        </Price>
                      </AutoLayoutHorizontal2>
                      {/* <Text3 className="text-3-1">
                      &nbsp; &nbsp; – &nbsp;&nbsp;
                    </Text3> */}
                      <AutoLayoutHorizontal3 className="auto-layout-horizontal-10">
                        <Price1 className="price-5">
                          ₹{item?.warehouses && item?.warehouses[0]?.ASP}
                        </Price1>
                      </AutoLayoutHorizontal3>

                      <div style={{ width: '100%' }}>
                        {item?.warehouses &&
                          item?.warehouses[0]?.discount_per > 0 && (
                            <AutoLayoutHorizontal3 className="auto-layout-horizontal-10">
                              <Price1 className="price-5 color-primary">
                                (
                                {item?.warehouses &&
                                  item?.warehouses[0]?.discount_per}
                                % OFF)
                              </Price1>
                            </AutoLayoutHorizontal3>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </AutoLayoutHorizontal1>
          </FlexCol>

          </Link>

      </Product1>
    )
  );
};

const Product1 = styled.article`
  display: flex;
  flex-direction: column;
  width: 320px;
  max-width: 320px;
  align-items: flex-start;
  margin: 0 16px 20px 16px;
  position: relative;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 34%);
  padding-bottom: 16px;
  &.product.product-3 {
    cursor: pointer;
  }
`;

const Frame = styled.div`
  position: relative;
  min-width: 320px;
  height: 134px;
  margin-bottom: -0.33px;
`;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;

const FlexCol = styled.div`
  position: relative;
  top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`;

const AutoLayoutVertical1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const Text4 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const SuperLSLinenTee = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}

  letter-spacing: 0.1px;
  line-height: 24px;

  cursor: pointer;
`;

const AutoLayoutHorizontal1 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
`;

const AutoLayoutHorizontal2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  ${InterSemiBoldSapphire15px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const Text3 = styled.div`
  ${ValignTextMiddle}
  ${InterSemiBoldSapphire16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterSemiBoldSapphire14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const AutoLayoutHorizontal3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0.8300018310546875px 0px 0px;
  position: relative;
`;

const Frame1 = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  gap: 4px;
`;

const Frame2 = styled.img`
  width: 71px;
  height: 14px;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker11px}
            height: 11px;
  margin-top: -0.5px;
  letter-spacing: 0.05px;
  line-height: 11px;
`;

const SuperLSLinenTee1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            
            
           .product.product-2  & {
    cursor: unset;
  }
`;

const AutoLayoutVertical2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const Text35 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
`;

const AutoLayoutVertical3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const AutoLayoutHorizontal4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const Text49 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
`;

const AutoLayoutHorizontal5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.949996948242188px 4px 12px;
  position: relative;
  background-color: var(--pomegranate);
`;

export default Product;
