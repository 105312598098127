import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getAllFiltersApi, getAllProductsApi, getProductsData, getProductsDataApi, handleInitialRender, handlePageChange } from './FilterProductUtils';
import { FilterProductUI } from './FilterProductUI';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { notification } from 'antd';
import AddProductReview from '../../AddProductReview';
import bannerMen from '../../../images/banner_men.png'
import bannerKids from '../../../images/banner_kids.png'
import bannerWomen from '../../../images/banner_women.png'
import DynamicMetaTag from '../../Helmet/DynamicMetaTag';


export default function FilterProductWrapper(props) {
  const [ api, contextHolder ] = notification.useNotification();

  // --- route variables ---
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialRender = useRef(true);

  

  // --- all initial filters list ---
  const allFilters = JSON.parse(localStorage.getItem('allFilters')) || {};
  const allCategories = params.get('only') ? [params.get('only')] : Object.keys(allFilters);
  const allCategoriesCount = allCategories.reduce((acc, category) => {
      acc[category] = allFilters[category].items;
      return acc;
  }, {});

  console.log('qwerty',allFilters);
  
  const allSubCategories = allCategories.reduce((acc, item) => {
    acc[item] = allFilters[item].classes;
    return acc;
  }, {});

  const allPriceRange = allCategories.reduce((acc, item) => {
    acc[item] = { min: allFilters[item].minimum, max: allFilters[item].maximum };
    return acc;
  }, {});

  const allSizes = allCategories.reduce((acc, item) => {
    acc[item] = allFilters[item].sizes;
    return acc;
  }, {});

  const lastSelectedCategory = useRef(allCategories[0]);
  const blockApiCallFlag = useRef(true);

  // --- all states
  const [ productsData, setProductsData ] = useState([]);

  const [ selectedCategory, setSelectedCategory ] = useState(allCategories[0])
  const [ selectedFilter, setSelectedFilter ] = useState({
    subCategory: [],
    range : [allPriceRange[allCategories[0]].min, allPriceRange[allCategories[0]].max],
    sizes: [],
    sort:"ASC/price",
    currentPage: 1,
    closure: [],
    fabric: [],
    fit: [],
    length: [],
    neck: [],
    occasion: [],
    pattern: [],
    sleeves: []
  });

  console.log(selectedCategory);

  const [ currentPage, setCurrentPage ] = useState(1);
  const [ pageCount, setPageCount ] = useState(1);
  const [wishlistAdded,setWishListAdded]=useState();
  const [ loader, setLoader ] = useState(true);

  const [ bannerImg, setBannerImg ] = useState(bannerWomen);

  const handlePageChange = (page) => {
    params.set('page', page);

    history.push({ search: params.toString() });
  }


  const handleParamsChange = () => {
    
    blockApiCallFlag.current = lastSelectedCategory.current !== params.get('category');
    lastSelectedCategory.current = params.get('category');

    let filters = {};

    filters.subCategory = params.getAll('subCategory') || [];
    filters.range = [params.get('min') || allPriceRange[allCategories[0]].min, params.get('max') || allPriceRange[allCategories[0]].max];
    filters.sizes = params.getAll('size') || [];
    filters.sort = params.get('sort')  || "ASC/price";
    filters.closure = params.getAll('closure') || [];
    filters.fabric = params.getAll('fabric') || [];
    filters.fit = params.getAll('fit') || [];
    filters.length = params.getAll('length') || [];
    filters.neck = params.getAll('neck') || [];
    filters.occasion = params.getAll('occasion') || [];
    filters.pattern = params.getAll('pattern') || [];
    filters.sleeves = params.getAll('sleeves') || [];

    console.log('zxcvbnm',filters);

    setCurrentPage(Number(params.get('page')) || 1);

    setSelectedCategory(params.get('category') || allCategories[0])
    setSelectedFilter(filters)
  };

  // --- reset price range on category change ---
  useEffect(() => {
    if( ( history.action === 'PUSH' || initialRender.current ) && selectedCategory !== 'all' ) {
      initialRender.current = false;
      params.delete('size');

      params.delete('closure');
      params.delete('fabric');
      params.delete('fit');
      params.delete('length');
      params.delete('neck');
      params.delete('occasion');
      params.delete('pattern');
      params.delete('sleeves');


      params.set('min', allPriceRange[selectedCategory].min);
      params.set('max', allPriceRange[selectedCategory].max);

      history.push({ search: params.toString()});
    }
  }, [selectedCategory]);

  useEffect(() => {
    if( selectedCategory === 'all' ) {
      getAllProductsApi(params, setProductsData, setPageCount, api, setLoader);
    }
    else if ( !blockApiCallFlag.current ) {
      getProductsData(params, setProductsData, setPageCount, api, setLoader);
    }
  }, [selectedFilter,wishlistAdded])

  useEffect(() => {
    handleParamsChange();
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    switch(selectedCategory) {
      case 'MEN': 
        setBannerImg(bannerMen);
        break;
      case 'WOMEN':
        setBannerImg(bannerWomen);
        break;
      case 'KIDS':
        setBannerImg(bannerKids);
        break;
    }
  }, [selectedCategory])


  return (
    <>
    {contextHolder}
    <FilterProductUI 
      {...props}
      bannerImg={bannerImg}

      productsData={productsData}

      allFilters={allFilters}

      allCategories={allCategories}
      allCategoriesCount={allCategoriesCount}

      selectedCategory={selectedCategory}
      selectedSubCategory={selectedFilter.subCategory}
      selectedRange={selectedFilter.range}
      selectedSizes={selectedFilter.sizes}

      selectedFilter={selectedFilter}

      pageCount={pageCount}
      currentPage={currentPage}

      allPriceRange={allPriceRange}
      allSizes={allSizes}
      allSubCategories={allSubCategories}
      handlePageChange={handlePageChange}
      params={params}

      loader={loader}
      setWishListAdded={setWishListAdded}
    />

<DynamicMetaTag
categoryName={selectedCategory}
/>

    {/* --- test --- */}
    {/* <AddProductReview /> */}
    
  </>
  )
}
