import React, { useEffect, useState } from "react";
// import type { TabsProps } from "antd";
import styled from "styled-components";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";

import {
  Card,
  notification,
  Col,
  Row,
  Statistic,
  Tabs,
  Input,
  Button,
  Dropdown,
  Spin,
  Collapse,
} from "antd";
const { Panel } = Collapse;

const { Search } = Input;

import { getWalletApi } from "../../api/wallet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../api/baseurl";
import axios from "axios";

const parseDate = (date) => {
  const inputDate = new Date(date);
  const outputDate = inputDate.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return outputDate;
};

const ListHeder = (list) => {
  return (
    <AllHistory>
      <div style={{ display: "flex", width: "78%" }}>
        <HListIcon>
          <svg
            width="48"
            height="46"
            viewBox="0 0 48 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.0525 18.4398L41.9351 26.6134C40.6703 28.6353 38.4498 29.8627 36.0644 29.857L12.4617 29.8027C10.067 29.7971 7.84462 28.551 6.59104 26.5104L1.61416 18.408C-0.119125 15.5841 1.91397 11.9601 5.22127 11.9601H11.8846L19.2993 2.84586C20.5173 1.35243 22.318 0.496094 24.2443 0.496094C24.2443 0.496094 24.2443 0.496094 24.2462 0.496094C26.1706 0.496094 27.9732 1.35243 29.1875 2.84774L36.6041 11.9601H43.4679C46.792 11.9601 48.8251 15.6235 47.0525 18.4398ZM17.521 11.9601H30.9676L25.7959 5.606C25.2749 4.96328 24.6004 4.86772 24.2443 4.86772C23.8883 4.86772 23.2156 4.96328 22.6909 5.606L17.521 11.9601Z"
              fill="#FFB830"
            />
            <path
              d="M35.8966 45.0721L24.2433 39.7148L12.5881 45.0721C9.38389 46.543 5.86298 43.766 6.54317 40.3088L7.46884 35.5906C8.12656 32.2383 11.0666 29.821 14.4844 29.8267L34.0359 29.8567C37.4444 29.8604 40.3732 32.2758 41.029 35.6187L41.9509 40.3088C42.6311 43.766 39.1008 46.543 35.8966 45.0721Z"
              fill="#444A4B"
            />
          </svg>
        </HListIcon>
        <div style={{ alignSelf: "center" }}>
          <h4 style={{ marginTop: "9px" }} className="fourth-heading">
            {list?.description}
          </h4>
          <p className="para text-left">
            {parseDate(list.created_at)} {list.type}
          </p>
        </div>
      </div>
      {(list?.mode === "CR" && (
        <div className="history-price green"> + ₹{list?.amount?.total}</div>
      )) || <div className="history-price"> - ₹{list?.amount?.total}</div>}
    </AllHistory>
  );
};

const ShowListIcon = ({ data }) => {
  const onChangeco = (key) => {
  };

  return (
    <div className="history-collapse-list">
      {(data.length &&
        data.map((list, index) => (
          <div key={index}>
            {(list.description === "Money Added to Wallet" && (
              <div style={{ padding: "0.7rem 1rem" }}>
                <ListHeder {...list} />
              </div>
            )) || (
              <Collapse defaultActiveKey={[]} onChange={onChangeco}>
                <Panel header={<ListHeder {...list} />} key={index}>
                  <div>
                    <h3
                      style={{
                        textTransform: "uppercase",
                        textAlign: "left",
                        fontWeight: "300",
                      }}
                      className="paid-using"
                    ></h3>
                    <AllHistory style={{ marginBottom: "0.4rem" }}>
                      <div style={{ display: "flex", width: "78%" }}>
                        <div style={{ alignSelf: "center" }}>
                          <p className="paid-using">Deerika Cashback </p>
                        </div>
                      </div>
                      {(list?.mode === "CR" && (
                        <div className="history-price green">
                          {" "}
                          + ₹{list?.amount?.cashback}
                        </div>
                      )) || (
                        <div className="history-price">
                          {" "}
                          - ₹{list?.amount?.cashback}
                        </div>
                      )}
                    </AllHistory>
                    <AllHistory>
                      <div style={{ display: "flex", width: "78%" }}>
                        <div style={{ alignSelf: "center" }}>
                          <p className="paid-using">Online Payment </p>
                        </div>
                      </div>
                      {(list?.mode === "CR" && (
                        <div className="history-price green">
                          {" "}
                          + ₹{list?.amount?.online}
                        </div>
                      )) || (
                        <div className="history-price">
                          {" "}
                          - ₹{list?.amount?.online}
                        </div>
                      )}
                    </AllHistory>
                    <AllHistory>
                      <div style={{ display: "flex", width: "78%" }}>
                        <div style={{ alignSelf: "center" }}>
                          <p className="paid-using">Wallet </p>
                        </div>
                      </div>
                      {(list?.mode === "CR" && (
                        <div className="history-price green">
                          {" "}
                          + ₹{list?.amount?.wallet}
                        </div>
                      )) || (
                        <div className="history-price">
                          {" "}
                          - ₹{list?.amount?.wallet}
                        </div>
                      )}
                    </AllHistory>
                    <AllHistory>
                      <div style={{ display: "flex", width: "78%" }}>
                        <div style={{ alignSelf: "center" }}>
                          <p className="paid-using">Total Amount </p>
                        </div>
                      </div>
                      {(list?.mode === "CR" && (
                        <div className="history-price green">
                          {" "}
                          ₹{list?.amount?.total}
                        </div>
                      )) || (
                        <div className="history-price">
                          {" "}
                          ₹{list?.amount?.total}
                        </div>
                      )}
                    </AllHistory>{" "}
                  </div>
                </Panel>
              </Collapse>
            )}
          </div>
        ))) || <p className="para">Data not found</p>}
      {}
    </div>
  );
};

const items = [
  {
    key: "",
    label: `All`,
    children: "",
  },
  {
    key: "Refund",
    label: `REFUNDS`,
    children: "",
  },
  {
    key: "Cashback",
    label: `Cashback`,
    children: "",
  },
];

const PaymentHistory = () => {
  const [walletData, setWalletData] = useState({});

  const fetchHistory = async (type = "", pageN, pageS) => {
    const { data } = await axios.get("/dev/hob/wallet/transactions/", {
      params: {
        page_num: pageN,
        page_size: pageS,
        type: type,
      },
    });

    if (data.success) {
      setWalletData(data?.data?.data);

      // paidHistory = notification.success({
      //   message: data.message,
      // });
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const onChange = (label) => {
    fetchHistory(label, 1, 10);
  };

  useEffect(() => {
    fetchHistory("", 1, 10);
  }, [0]);

  return (
    <div className="">
      <Tabs
        centered
        className="history-table"
        defaultActiveKey=""
        items={items}
        onChange={(item) => onChange(item)}
      />
      <ShowListIcon data={walletData} />
    </div>
  );
};

const AllHistory = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const HListIcon = styled.div`
  background: #ffffff;
    box-shadow:0px 0px 28.2917px rgb(0 0 0 / 13%);
    width: 68.64px;
    height: 68.64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    border-radius: 50%;
    align-self:center;
        margin-right: 1rem;
    
}
`;

export default PaymentHistory;
