import axios from "axios";

export async function getWalletApi() {
  const data = await axios.get("/dev/hob/wallet/");

  return data;
}

export async function getCouponApi(pageSize, pageNum) {
  //  page_num: pageSize,
  //   page_size: pageNum,
  const data = await axios.get("/dev/coupon/activecouponlist/believe", {
    params: {
      pincode: "122018",
    },
  });

  return data;
}
