import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  AddAddressCartApiInProfile,
  checkServiceablePinCode,
  deleteAddressApi,
  updateAddressApi,
} from "../../api/Address";
import { Badge, Button, Card, Divider, Modal, Spin } from "antd";
import { getAddressApi } from "../../api/Address";
import { Button, Form, Input, Radio } from "antd";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { notification } from "antd";
import { RiUserLocationLine } from "react-icons/ri";
import { FaRegAddressBook } from "react-icons/fa";
import { BiPlus } from "react-icons/bi";
import { PlusOutlined } from "@ant-design/icons";
import "./AddAddress.css";
import { Autocomplete } from "@mui/material";

const AddAddress = () => {
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const [AddressApiResponse, setAddressApiResponse] = useState();
  const [editAddressData, setEditAddressData] = useState();
  const [addressData, setAddressData] = useState({
    name: "",
    house_no: "",
    society: "",
    block: "",
    city: "",
    state: "",
    pin_code: "",
    address_type: "",
    is_default: "",
  });
  const [editAddressPopup, setEditAddressPopup] = useState(false);
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [addressApiData, setAddressApiData] = useState();
  const [loader, setLoader] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [pinServiceableRes,setPinServiceableRes] = useState();
  const [pinCodeResponseMsg,setPinCodeResponseMsg]=useState();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const getAddressFunc = async () => {
    setLoader(true);
    const resultData = await getAddressApi();
    setAddressApiData(resultData?.data);
    if (resultData?.data?.success) {
      setLoader(false);
    }
  };


  useEffect(() => {
    getAddressFunc();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAddressApi = async () => {
    const resultData = await AddAddressCartApiInProfile(addressData);
    if (resultData?.data?.success) {
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement:"bottom"
      });
      setAddAddressPopup(false);
      getAddressFunc();
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement:"bottom"
      });
    }
  };

  console.log(addressData);



  useEffect(() => {
    setAddressData({
      ...addressData,
      name: editAddressData?.name,
      house_no: editAddressData?.house_no,
      society: editAddressData?.society,
      block: editAddressData?.block,
      city: editAddressData?.city,
      state: editAddressData?.state,
      pin_code: editAddressData?.pincode,
      address_type: editAddressData?.address_type,
      id: editAddressData?._id,
      is_default: editAddressData?.is_default,
    });
  }, [editAddressData]);


  const editAddress = (value) => {
    setEditAddressPopup(true);
    setEditAddressData(value);
  };

  const removeAddress = async () => {
    const data = deleteData?._id;
    const resultData = await deleteAddressApi(data);
    if (resultData?.data?.success) {
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement:"bottom"
      });
      getAddressFunc();
      setModal2Open(false);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement:"bottom"
      });
      setModal2Open(false);
    }
  };

  const cancelUpdate = () => {
    setPinCodeResponseMsg("");
    setAddressData({
      ...addressData,
      name: "",
      house_no: "",
      society: "",
      block: "",
      city: "",
      state: "",
      pin_code: "",
      address_type: "",
      id: "",
      is_default: "",
    });
    setEditAddressPopup(false);
    setAddAddressPopup(false);
    setEditAddressData("");
  };


  const addAddressFunc = () => {
    if (!isSubmitted) {
      setIsSubmitted(true);
    }
    else {
    const findingLengthName = addressData?.name?.length;
    const findingLengthHouse = addressData?.house_no?.length;
    const findingLengthSociety = addressData?.society?.length;
    const findingLengthBlock = addressData?.block?.length;
    const findingLengthCity = addressData?.city?.length;
    const findingLengthState = addressData?.state?.length;
    const findingLengthPinCode = addressData?.pin_code?.length;

    if (findingLengthName == 0 || addressData?.name == undefined) {
      notification.error({
        message: "Please provide name.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthHouse == 0 || addressData?.house_no == undefined) {
      notification.error({
        message: "Please provide house.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthSociety == 0 || addressData?.society == undefined) {
      notification.error({
        message: "Please provide society.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthBlock == 0 || addressData?.block == undefined) {
      notification.error({
        message: "Please provide block.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthCity == 0 || addressData?.city == undefined) {
      notification.error({
        message: "Please provide city.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthState == 0 || addressData?.state == undefined) {
      notification.error({
        message: "Please provide state.",
        duration: 1,
        placement:"bottom"
      });
    } else if (
      findingLengthPinCode == 0 ||
      addressData?.pin_code == undefined
    ) {
      notification.error({
        message: "Please provide pincode.",
        duration: 1,
        placement:"bottom"
      });
    } else if (!pinServiceableRes?.success){
      notification.error({
        message: "This Pin Code is not serviceable.Please add Serviceable PinCode",
        duration: 1,
        placement:"bottom"
      });
    }
    else {
      addAddressApi();
    }
    }
    setPinCodeResponseMsg("");
  };

  const addNewAddressData = () => {
    setAddAddressPopup(true);
  };

  // updateAddressApi

  const updateAddress = async () => {
    const findingLengthName = addressData?.name?.length;
    const findingLengthHouse = addressData?.house_no?.length;
    const findingLengthSociety = addressData?.society?.length;
    const findingLengthBlock = addressData?.block?.length;
    const findingLengthCity = addressData?.city?.length;
    const findingLengthState = addressData?.state?.length;
    const findingLengthPinCode = addressData?.pin_code?.length;
    // checkPinCode();

   


    if (findingLengthName == 0 || addressData?.name == undefined) {
      notification.error({
        message: "Please provide name.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthHouse == 0 || addressData?.house_no == undefined) {
      notification.error({
        message: "Please provide house.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthSociety == 0 || addressData?.society == undefined) {
      notification.error({
        message: "Please provide society.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthBlock == 0 || addressData?.block == undefined) {
      notification.error({
        message: "Please provide block.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthCity == 0 || addressData?.city == undefined) {
      notification.error({
        message: "Please provide city.",
        duration: 1,
        placement:"bottom"
      });
    } else if (findingLengthState == 0 || addressData?.state == undefined) {
      notification.error({
        message: "Please provide state.",
        duration: 1,
        placement:"bottom"
      });
    } else if (
      findingLengthPinCode == 0 ||
      addressData?.pin_code == undefined
    ) {
      notification.error({
        message: "Please provide pincode.",
        duration: 1,
        placement:"bottom"
      });
    }
    else  if (editAddressData?.pincode == 6) {
      const resultData = await checkServiceablePinCode(editAddressData?.pincode );
      setPinServiceableRes(resultData?.data);
      if (resultData?.data?.success) {
        notification.success({
          message: resultData?.data?.message,
          duration: 1,
          placement:"bottom"
        });
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
        placement:"bottom"
        });
      }
    }
    else {
      const resultData = await updateAddressApi(addressData);
      if (resultData?.data?.success) {
        notification.success({
          message: resultData?.data?.message,
          duration: 1,
          placement:"bottom"
        });
        setEditAddressPopup(false);
        getAddressFunc();
        setEditAddressData("");
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
        placement:"bottom"
        });
      }
    }
    setPinCodeResponseMsg("");
  };

  // const handleKeyDown = (event) => {
  //   // Check if the pressed key is Arrow Up (key code 38)
  //   if (event.keyCode === 38 || event.keyCode === 40) {
  //     event.preventDefault(); // Prevent the default behavior of arrow up key
  //   }
  // };


  const checkPinCode = async (e) => {
    if (e.target.value?.length == 6) {
      const resultData = await checkServiceablePinCode(e.target.value);
      setPinServiceableRes(resultData?.data);
      if (resultData?.data?.success) {
        setPinCodeResponseMsg(resultData?.data);
        notification.success({
          message: resultData?.data?.message,
          duration: 1,
          placement:"bottom"
        });
      } else {
        setPinCodeResponseMsg(resultData?.data);
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
        placement:"bottom"
        });
      }
    } else {
    setPinCodeResponseMsg("");
    }
  };

  console.log(pinCodeResponseMsg);

  //   const states = [
  //   'Andhra Pradesh',
  //   'Arunachal Pradesh',
  //   'Assam',
  //   'Bihar',
  //   'Chhattisgarh',
  //   'Goa',
  //   'Gujarat',
  //   'Haryana',
  //   'Himachal Pradesh',
  //   'Jharkhand',
  //   'Karnataka',
  //   'Kerala',
  //   'Madhya Pradesh',
  //   'Maharashtra',
  //   'Manipur',
  //   'Meghalaya',
  //   'Mizoram',
  //   'Nagaland',
  //   'Odisha',
  //   'Punjab',
  //   'Rajasthan',
  //   'Sikkim',
  //   'Tamil Nadu',
  //   'Telangana',
  //   'Tripura',
  //   'Uttar Pradesh',
  //   'Uttarakhand',
  //   'West Bengal',
  // ];


  const states= [
"ANDAMAN & NICOBAR ISLANDS",
"ANDHRA PRADESH",
"ANDHRA PRADESH(BEFORE DIVISION)",
"ARUNACHAL PRADESH",
"ASSAM",
"BIHAR",
"CHANDIGARH",
"CHHATTISGARH",
"DADRA & NAGAR HAVELI & DAMAN & DIU",
"DELHI",
"GOA",
"GUJARAT",
"HARYANA",
"HIMACHAL PRADESH",
"JAMMU & KASHMIR",
"JHARKHAND",
"KARNATAKA",
"KERALA",
"LADAKH",
"LAKSHDWEEP",
"MADHYA PRADESH",
"MAHARASHTRA",
"MANIPUR",
"MEGHALAYA",
"MIZORAM",
"NAGALAND",
"ODISHA",
"PUDUCHERRY",
"PUNJAB",
"RAJASTHAN",
"SIKKIM",
"TAMIL NADU",
"TELANGANA",
"TRIPURA",
"UTTAR PRADESH",
"UTTARAKHAND",
"WEST BENGAL",
  ]
  

  return (
    <>
      <div style={{ width: '100%' }}>
        {editAddressPopup || addAddressPopup ? (
          <div className="addupdate-box" style={{ marginTop: 32 }}>
            {editAddressPopup ? (
              <p className="para">Update Address</p>
            ) : (
              <p className="para">Add Address</p>
            )}

            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { margin: '10px 12px', maxWidth: '364px' },
              }}
              noValidate
              autoComplete="off"
            >
              
                <TextField
                  error={isSubmitted && !addressData.name}
                  id="outlined-password-input"
                  className="input-section"
                  label="Name"
                  type="text"
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      name: e.target.value,
                    })
                  }
                  defaultValue={editAddressData?.name}
                />

{/* <span> */}
                <TextField
                  error={isSubmitted && !addressData.pin_code}
                  id="outlined-password-input"
                  className="input-section "
                  label="PinCode"
                  type="number"
                  // onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setAddressData({
                      ...addressData,
                      pin_code: e.target.value,
                    }),
                      checkPinCode(e);
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  defaultValue={editAddressData?.pincode}
                />
                {/* <p style={{pinCodeResponseMsg?.success ? }}>{pinCodeResponseMsg?.message}</p> */}
                {pinCodeResponseMsg && pinCodeResponseMsg.success ? (
        <p style={{ color: 'green',display:"flex" }} className="Msg-response">{pinCodeResponseMsg?.message && pinCodeResponseMsg?.message}</p>
      ) : (
        <p style={{ color: 'red',display:"flex"}} className="Msg-response">{pinCodeResponseMsg?.message &&  pinCodeResponseMsg?.message}</p>
      )}
                {/* </span> */}
                

                <TextField
                  error={isSubmitted && !addressData.house_no}
                  id="outlined-password-input"
                  className="input-section"
                  label="House No."
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      house_no: e.target.value,
                    })
                  }
                  defaultValue={editAddressData?.house_no}
                />
                <TextField
                  error={isSubmitted && !addressData.society}
                  id="outlined-password-input"
                  className="input-section"
                  label="Society"
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      society: e.target.value,
                    })
                  }
                  defaultValue={editAddressData?.society}
                />
                <TextField
                  error={isSubmitted && !addressData.block}
                  id="outlined-password-input"
                  className="input-section"
                  label="Block"
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      block: e.target.value,
                    })
                  }
                  defaultValue={editAddressData?.block}
                />
                <TextField
                  error={isSubmitted && !addressData.city}
                  id="outlined-password-input"
                  className="input-section"
                  label="City"
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      city: e.target.value,
                    })
                  }
                  defaultValue={editAddressData?.city}
                />

                {/* <TextField
                  error={isSubmitted && !addressData.state}
                  id="outlined-password-input"
                  className="input-section"
                  label="State"
                  onChange={(e) =>
                    setAddressData({
                      ...addressData,
                      state: e.target.value,
                    })
                  }
                  defaultValue={editAddressData?.state}
                /> */}

   <Autocomplete
      id="state-selector"
      options={states}
      className="input-section"
      getOptionLabel={(option) => option}
      onChange={(event,value) =>{
        setAddressData({
          ...addressData,
          state: value,
        }),
        console.log(value)
      }
      }
      defaultValue={editAddressData?.state}
      renderInput={(params) => (
        <TextField 
       
        error={isSubmitted && !addressData.state}
        {...params} label="Select a State" variant="outlined" />
      )}
    />
    

                <FormControl className="input-section" style={{ margin: '0 12px' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Address Type
                  </FormLabel>
                  <RadioGroup
                  
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(event) =>
                      setAddressData({
                        ...addressData,
                        address_type: event.target.value,
                      })
                    }
                    defaultValue={editAddressData?.address_type}
                    // onChange={(event)=>checkRadioButton(event)}
                  >
                    <FormControlLabel
                      value="Home"
                      control={<Radio />}
                      label="Home"
                    />

                    <FormControlLabel
                      value="Office"
                      control={<Radio />}
                      label="Office"
                    />

                    <FormControlLabel
                      style={{ margin: 0 }}
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>

                <br />

                <Checkbox
                  {...label}
                  onChange={(event) =>
                    setAddressData({
                      ...addressData,
                      is_default: event.target.checked,
                    })
                  }
                  defaultChecked={editAddressData?.is_default}
                />
                <span>Mark as default address</span>
            </Box>
            <div style={{ display: "flex", justifyContent: "end" }}>
              {editAddressPopup ? (
                <Button type="primary" style={{ backgroundColor: '#f4792c', color: 'white' }} onClick={updateAddress}>
                  Update
                </Button>
              ) : (
                <Button type="primary" style={{ backgroundColor: '#f4792c', color: 'white' }} onClick={addAddressFunc}>
                  Add
                </Button>
              )}
              <Button
                type="primary"
                onClick={cancelUpdate}
                className="cancel-button"
                style={{backgroundColor:"rgb(64, 150, 255)"}}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <>
            {addressApiData?.data?.length > 0 ? (
              <>
                <div style={{ width: '100%', maxWidth: "800px" }}>
                  <div className="btn-flex">
                    <h4>Manage Addresses</h4>

                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={addNewAddressData}
                      style={{backgroundColor:"rgb(64, 150, 255)"}}
                      // style={{backgroundColor:"#2f4799",color:"white"}}
                    >
                      Add New Address{" "}
                    </Button>
                  </div>

                  {addressApiData?.data?.map((value, index) => {
                    return (
                      <>
                        <div className="address-box">
                          <div className="parent-value">
                            <div>
                              <h4 className="addtitle">
                                {value?.name}{" "}
                                <span style={{ marginLeft: "1%" }}>
                                  <Badge style={{ backgroundColor: '#f4792c',
                                 "border-radius": "4px" 
                                }} count={value?.address_type} />{" "}
                                  <span>
                                    {value?.is_default === true ? (
                                      <Badge
                                        count="Default"
                                        style={{
                                          backgroundColor: "#4096ff",
                                          // color: "black",
                                          "border-radius": "4px" 
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>{" "}
                                </span>
                              </h4>
                              <p>
                                {value?.house_no} {value?.block}{" "}
                              </p>
                              <p>
                                {value?.society} {value?.city}
                              </p>
                              <p>
                                {value?.state} {value?.pincode}
                              </p>
                              <p>{value?.alternate_mobile}</p>
                            </div>
                          </div>
                          <div className="add-btns">
                            <Button onClick={() => editAddress(value)}>
                              Edit
                            </Button>

                            <Button
                              onClick={() => {
                                setDeleteData(value), setModal2Open(true);
                              }}
                            >
                              {" "}
                              Remove
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                {!loader && (
                  <div className="new-add">
                    <p className="my-3">You Don't have any saved address yet</p>
                    <Button
                      type="pink"
                      icon={<PlusOutlined />}
                      onClick={addNewAddressData}
                    >
                      Add New Address{" "}
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <Modal
        title="Address Delete Confirmation"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={null}
        // style={{paddingBottom:"0px"}}
      >
        <p
          style={{
            fontSize: "14px",
            color: "#7E818C",
            margin: "10px 10px 35px 0px",
          }}
        >
          Are you sure you want to delete this address?
        </p>

          {/* <div style={{"display": "flex","justify-content": "end"}}>
        <Button
        type="primary"
         style={{backgroundColor:"red"}}
          className="cancel-button"
          onClick={removeAddress}
        >
          Delete
        </Button>
        <Button type="primary" onClick={() => setModal2Open(false)}>
          Cancel
        </Button>
        </div> */}

        <div style={{"display": "flex","justify-content": "end"}}>
        <Button  type="primary" style={{ backgroundColor: '#f4792c' }} onClick={removeAddress}>
          Delete
        </Button>

        <Button
          type="primary"
          style={{ marginLeft: "17px",backgroundColor:"rgb(64, 150, 255)" }}
          onClick={() => setModal2Open(false)}
        >
          Cancel
        </Button>
        </div>

        {/* className="cancel-button" */}
        
      </Modal>

      {loader && (
        <div
        style={{ margin: "1.3rem auto", paddingTop: "2rem" , position: "absolute",
        left: "50%"}}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default AddAddress;

// const questionHandler=(id,optId)=>{

//   let newArr;
//    let checkArr=false;

//   if(quesAns?.length === 0){
//     setQuesAns([{"question":id , "answer":optId}])
//   } else {
//      newArr= quesAns?.map((value)=>{
//       if(value?.question === id){
//         checkArr=true;
//         return {...value,answer:optId}
//       } else {
//         return value;
//       }
//     })
//     if(checkArr) {
//       setQuesAns(newArr);

//     } else {
//     setQuesAns(prev => [...prev, {"question":id , "answer":optId}])

//     }
//   }

//   }