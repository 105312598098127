import React, { useEffect, useState } from "react";
import Header4 from "../Header4";
import Spanbox from "../Spanbox";
import Footer from "../Footer";
import styled from "styled-components";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import formateDate from "../../api/formateDate";
import {
  Rate,
  RadioChangeEvent,
  notification,
  Spin,
  Input,
  Radio,
  Space,
  Checkbox,
  Badge,
} from "antd";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
  InterSemiBoldSapphire14px,
} from "../../styledMixins";
import "./MyProfile.css";
import { Modal } from "antd";
import {
  CancelOrder,
  SingleOrderApi,
  cancelOrder,
  orderListApi,
  returnListApi,
} from "../../api/order";
import { dev } from "../../api/baseurl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MapStatus from "./detailComp/MapStatus";
import TimeLine from "./detailComp/cusTimeLine";

const ReturnList = (props) => {
  const [sizeModal, setSizeModal] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [quantityPopUp, setQuantityPopup] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState();
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelItem, setCancelItem] = useState();
  const [reasonError, setReasonError] = useState("");
  const [additionComment, setAdditionComment] = useState("");
  const [nestedCancelItem, setNestedCancelItem] = useState();
  const [selectIssue, setSelectIssue] = useState("");
  const onChangeReason = (item) => {
    setReasonError("");
    const obj = { label: item.target.label, value: item.target.value };

    setCancelReason(obj);
  };
  const selectRegion = [
    {
      label: "Image shown did not match the actual product",
      value: "image_not_match",
    },
    {
      label: "Received a wrong or defective product",
      value: "received_wrong_item",
    },
    {
      label: "I changed my mind",
      value: "change_mind",
    },
    {
      label: "Size or fit issues",
      value: "size_fit",
    },
    {
      label: "Quality Issues",
      value: "quality_issue",
    },
  ];

  const returnRegion = {
    image_not_match: [
      "Color is different",
      "Product image was better than the actual product",
      "Design is different",
    ],
    received_wrong_item: [
      "Wrong size was delivered",
      "Defective product was delivered",
      "Diffrent prdoduct was delivered",
    ],
    change_mind: [
      "Delivery was delayed",
      "I do not need anymore",
      "Found a better price on House of believe",
      "It did not look good on me",
    ],
    size_fit: [
      "Size is too large",
      "Size is too small",
      "I did not like the fit",
    ],
    quality_issue: [
      "Product looked Old",
      "Product was dirty and had stains",
      "Received poor quality product",
    ],
  };

  const handleOrderDetail = (item) => {
    setOrder(item);
    setIsDetail(true);
  };

  const history = useHistory();
  const sizePopUp = () => {
    setSizeModal(true);
  };

  // const onChangeReason = (item) => {
  //   setCancelReason(item.target.value);
  // };

  const getOrderList = async () => {
    setIsLoading(true);
    const { data } = await returnListApi();

    if (data.success) {
      setIsLoading(false);
      setOrderList(data?.return_requests);
    } else {
      notification.error({
        message: data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getOrderList();
  }, [0]);

  const cancelConfirm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { data } = await cancelOrder(
      cancelItem?.order_id,
      cancelReason?.label,
      nestedCancelItem
    );
    if (data.success) {
      notification.success({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
      setCancelReason("");
      setIsCancelOrder(false);
      getOrderList();
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
    setIsLoading(false);
  };

  const { name2, xreturn } = props.someProp;
  return (
    <>
      {(isLoading && (
        <div
          className="profile-loader"
          style={{ margin: "0 auto", paddingTop: "2rem" }}
        >
          <Spin size="large" />
        </div>
      )) || (
        <div className="testing" style={{ width: "100%" }}>
          {(orderList?.length > 0 &&
            orderList.map((item, index) => (
              <OverlapGroup1 key={index} style={{ cursor: "pointer" }}>
                <Group37002>
                  <div style={{ display: "flex" }}>
                    <div>
                      <NameContainer
                        style={{ margin: "0", display: "flex" }}
                        // onClick={() => {
                        //   history.push(`/my-profile/orders/${item?.order_id}`),
                        //     localStorage.setItem("orderId", item?.order_id);
                        // }}
                      >
                        {/* <div style={{ display: "flex" }}>
                          <>
                            {item?.overall_status === "Cancelled" ? (
                              <>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.17004 7.43994L12 12.5499L20.77 7.46991"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 21.61V12.54"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M21.61 9.17V14.83C21.61 14.88 21.61 14.92 21.6 14.97C20.9 14.36 20 14 19 14C18.06 14 17.19 14.33 16.5 14.88C15.58 15.61 15 16.74 15 18C15 18.75 15.21 19.46 15.58 20.06C15.67 20.22 15.78 20.37 15.9 20.51L14.07 21.52C12.93 22.16 11.07 22.16 9.92999 21.52L4.59 18.56C3.38 17.89 2.39001 16.21 2.39001 14.83V9.17C2.39001 7.79 3.38 6.11002 4.59 5.44002L9.92999 2.48C11.07 1.84 12.93 1.84 14.07 2.48L19.41 5.44002C20.62 6.11002 21.61 7.79 21.61 9.17Z"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M23 17.91C23.02 18.66 22.82 19.37 22.46 19.98C22.26 20.34 21.99 20.6701 21.69 20.9401C21 21.5801 20.09 21.9701 19.08 22.0001C17.62 22.0301 16.33 21.2801 15.62 20.1301C15.24 19.5401 15.01 18.8301 15 18.0801C14.97 16.8201 15.53 15.6801 16.43 14.9301C17.11 14.3701 17.97 14.0201 18.91 14.0001C21.12 13.9501 22.95 15.7 23 17.91Z"
                                    fill="red"
                                    stroke="red"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M17.44 18.03L18.45 18.99L20.54 16.97"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>{" "}
                                &nbsp; &nbsp;
                                {item?.order_id && (
                                  <div
                                    style={{
                                      color: "black",
                                      marginTop: "0.3rem",
                                      marginBottom: "0.5rem",
                                    }}
                                  ></div>
                                )}
                                <Name style={{ color: "red" }}>
                                  {" "}
                                  &nbsp; {item?.overall_status}
                                </Name>
                              </>
                            ) : (
                              <>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.17004 7.43994L12 12.5499L20.77 7.46991"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M12 21.61V12.54"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M21.61 9.17V14.83C21.61 14.88 21.61 14.92 21.6 14.97C20.9 14.36 20 14 19 14C18.06 14 17.19 14.33 16.5 14.88C15.58 15.61 15 16.74 15 18C15 18.75 15.21 19.46 15.58 20.06C15.67 20.22 15.78 20.37 15.9 20.51L14.07 21.52C12.93 22.16 11.07 22.16 9.92999 21.52L4.59 18.56C3.38 17.89 2.39001 16.21 2.39001 14.83V9.17C2.39001 7.79 3.38 6.11002 4.59 5.44002L9.92999 2.48C11.07 1.84 12.93 1.84 14.07 2.48L19.41 5.44002C20.62 6.11002 21.61 7.79 21.61 9.17Z"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M23 17.91C23.02 18.66 22.82 19.37 22.46 19.98C22.26 20.34 21.99 20.6701 21.69 20.9401C21 21.5801 20.09 21.9701 19.08 22.0001C17.62 22.0301 16.33 21.2801 15.62 20.1301C15.24 19.5401 15.01 18.8301 15 18.0801C14.97 16.8201 15.53 15.6801 16.43 14.9301C17.11 14.3701 17.97 14.0201 18.91 14.0001C21.12 13.9501 22.95 15.7 23 17.91Z"
                                    fill="rgb(231, 80, 46)"
                                    stroke="rgb(231, 80, 46)"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M17.44 18.03L18.45 18.99L20.54 16.97"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>{" "}
                                &nbsp; &nbsp;
                                {item?.order_id && (
                                  <div
                                    style={{
                                      color: "black",
                                      marginTop: "0.3rem",
                                      marginBottom: "0.5rem",
                                    }}
                                  ></div>
                                )}
                                <Name style={{ color: "#e7502e" }}>
                                  {" "}
                                  &nbsp; {item?.overall_status}
                                </Name>
                              </>
                            )}
                          </>
                        </div> */}

                        <p
                          style={{
                            marginTop: "0.3rem",
                            marginBottom: "0.5rem",
                          }}
                        >
                          Return Id:{" "}
                          <b style={{ fontWeight: "bold" }}>
                            {" "}
                            {item?.return_id}
                          </b>
                        </p>
                      </NameContainer>
                      {/* only order display */}
                      <br />

                      <div
                        style={{
                          display: "flex",
                          marginTop: "0.5rem",
                        }}
                      >
                        {/* <Name1
                          style={{ marginBottom: "0" }}
                          className="price-4"
                        >
                          Status &nbsp; :&nbsp;
                        </Name1> */}
                        {/* {item?.statuses?.map((e) => (
                          <Name
                            style={{
                              alignSelf: "start",
                              fontSize: "14px",
                              color: "#e7502e",
                              display: "flex",
                            }}
                          >
                            {e.status} , &nbsp;
                          </Name>
                        ))} */}
                      </div>

                      {/* <br /> */}
                      {/* 
                        {item?.status?.map((stat) => {
                          if (stat.status === item?.overall_status) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "0.5rem",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Name1
                                  style={{ marginBottom: "0" }}
                                  className="price-4"
                                >
                                  {stat.status}&nbsp; on :&nbsp;&nbsp;
                                </Name1>
                                <Name
                                  style={{
                                    alignSelf: "start",
                                    fontSize: "14px",
                                  }}
                                >
                                  {formateDate(stat?.created_at)}
                                </Name>
                              </div>
                            );
                          }
                        })} */}
                    </div>
                  </div>
                  <div>
                    {/* <div style={{ alignSelf: "center" }}>
                        
                      </div> */}

                    {item?.items.map((nestedItem, index) => (
                      <div style={{ display: "flex" }}>
                        <Group36968
                          // onClick={() => {
                          //   history.push(
                          //     `/my-profile/orders/${item?.order_id}`
                          //   ),
                          //     localStorage.setItem("orderId", item?.order_id);
                          // }}
                          style={{
                            width: "70px",
                            alignSelf: "center",
                            height: "70px",
                            border: "1px solid #dddddd",
                            borderRadius: "2px",
                            backgroundImage: `url(/img/no_image.png)`,
                          }}
                        >
                          {nestedItem?.images.map(
                            (e, index) =>
                              e.image_type === "main" && (
                                <Group36968
                                  key={index}
                                  onClick={() => {
                                    // history.push(
                                    //   `/my-profile/orders/${item?.order_id}`
                                    // ),
                                    localStorage.setItem(
                                      "orderId",
                                      item?.order_id
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundImage: `url(${e?.url})`,
                                  }}
                                ></Group36968>
                              )
                          )}
                        </Group36968>{" "}
                        <div
                          style={{
                            maxWidth: "calc( 100% - 160px )",
                            alignSelf: "end",

                            marginLeft: "1rem",
                          }}
                        >
                          <UrbanoFashion
                            style={{
                              "white-space": "break-spaces",
                              "word-break": "break-word",
                              "line-height": "26px",
                            }}
                          >
                            {nestedItem?.name}
                          </UrbanoFashion>
                          <Name1 style={{ marginTop: "-1px" }}>
                            {nestedItem?.description}
                          </Name1>
                          {/* <Spanbox size30M={spanboxProps.size30M} /> */}
                          <div
                            style={{
                              display: "flex",
                              marginTop: " -10px",
                              marginBottom: "-12px",
                              flexWrap: "wrap",
                              paddingTop: "9px",
                              borderTop: "1px solid #dedede",
                            }}
                          >
                            {nestedItem?.size_of_product !== undefined && (

                              <div style={{ display: "flex" }}> 

                                <Name1
                                  style={{ marginBottom: "0" }}
                                  className="price-4"
                                >
                                  Size : &nbsp;
                                </Name1>

                                <Name
                                  style={{
                                    alignSelf: "start",
                                    fontSize: "14px",
                                    color: "black",
                                  }}
                                >
                                  {nestedItem?.size_of_product} ,
                                  &nbsp;&nbsp;&nbsp;
                                </Name>
                              </div>

                            )}
                            <div style={{ display: "flex" }}>
                              <Name1
                                style={{ marginBottom: "0" }}
                                className="price-4"
                              >
                                Qty: &nbsp;
                              </Name1>
                              <Name
                                style={{
                                  alignSelf: "start",
                                  fontSize: "14px",
                                  color: "black",
                                }}
                              >
                                {nestedItem?.quantity}, &nbsp; &nbsp;&nbsp;
                              </Name>
                            </div>
                            <div style={{ marginTop: "0rem", display: "flex" }}>
                              <Name1 style={{ marginBottom: "0" }}>
                                {" "}
                                Price: &nbsp;
                              </Name1>{" "}
                              {(nestedItem?.warehouses?.MRP ===
                                nestedItem?.warehouses?.ASP && (
                                <div className="auto-layout-horizontal-9">
                                  <Price1 className="price-4">
                                    ₹{" "}
                                    {nestedItem?.warehouses &&
                                      item?.warehouses?.ASP}
                                  </Price1>
                                </div>
                              )) || (
                                <div style={{ display: "flex" }}>
                                  <div className="auto-layout-horizontal-9">
                                    <Price
                                      style={{
                                        color: "#8f8f8f",
                                        textDecoration: "line-through",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                      }}
                                      className="price-4"
                                    >
                                      ₹{" "}
                                      {nestedItem?.warehouses &&
                                        nestedItem?.warehouses?.MRP}{" "}
                                    </Price>
                                  </div>

                                  {/* <Text3 className="text-3-1">
                      &nbsp; &nbsp; – &nbsp;&nbsp;
                    </Text3> */}
                                  <div className="auto-layout-horizontal-10">
                                    <Price1 className="price-5">
                                      &nbsp; ₹{" "}
                                      {nestedItem?.warehouses &&
                                        nestedItem?.warehouses?.ASP}
                                    </Price1>
                                  </div>
                                  <div>
                                    {nestedItem &&
                                      nestedItem?.discount_per !== 0 && (
                                        <Price1 className="price-5 color-primary">
                                          &nbsp; (
                                          {nestedItem &&
                                            nestedItem?.discount_per}
                                          % OFF)
                                        </Price1>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="quantify-button">
                        <div>
                          <Spanbox
                            style={{ marginRight: "1rem" }}
                            size30M={`Size : ${
                              (item?.size_of_product !== undefined &&
                                item?.size_of_product) ||
                              ""
                            }`}
                          />
                        </div>
                        <div>
                          <Spanbox size30M={`Quantity : ${item?.quantity}`} />
                        </div>
                      </div> */}
                        </div>
                      </div>

                      // <div style={{ display: "flex" }}>
                      //   {/* *** /image of the single item/ *** */}
                      //   <Group36968
                      //     onClick={() =>
                      //       history.push(
                      //         `/my-profile/orders/${item?.order_id}`
                      //       )
                      //     }
                      //     style={{
                      //       backgroundImage: `url(/img/no_image.png)`,
                      //     }}
                      //   >
                      //     {nestedItem?.images.map(
                      //       (e, index) =>
                      //         e.image_type === "main" && (
                      //           <Group36968
                      //             key={index}
                      //             onClick={() =>
                      //               history.push(
                      //                 `/my-profile/orders/${item?.order_id}`
                      //               )
                      //             }
                      //             style={{
                      //               backgroundImage: `url(${e?.url})`,
                      //             }}
                      //           ></Group36968>
                      //         )
                      //     )}
                      //   </Group36968>{" "}
                      //   {/* //       *** /body of the single item/ *** */}
                      //   <div
                      //     style={{
                      //       marginLeft: "1.7rem",
                      //       width: "100%",
                      //       alignSelf: "end",
                      //       marginBottom: "-9px",
                      //     }}
                      //   >
                      //     <div
                      //       onClick={() =>
                      //         history.push(
                      //           `/my-profile/orders/${item?.order_id}`
                      //         )
                      //       }
                      //     >
                      //       <UrbanoFashion>{nestedItem?.name}</UrbanoFashion>
                      //       <Name1 style={{ marginTop: "-1px" }}>
                      //         {nestedItem?.description}
                      //       </Name1>
                      //     </div>

                      //     <div className="quantify-button">
                      //       <div>
                      //         <Spanbox
                      //           style={{ marginRight: "1rem" }}
                      //           size30M={`Size : ${
                      //             (nestedItem?.size_of_product !==
                      //               undefined &&
                      //               nestedItem?.size_of_product) ||
                      //             ""
                      //           }`}
                      //         />
                      //       </div>
                      //       <div>
                      //         <Spanbox
                      //           size30M={`Quantity : ${nestedItem?.quantity}`}
                      //         />
                      //       </div>
                      //     </div>
                      //     <div>
                      //       <div
                      //         style={{
                      //           marginTop: "1rem",
                      //           display: "flex",
                      //         }}
                      //       >
                      //         <Name1 style={{ marginBottom: "0" }}>
                      //           {" "}
                      //           Price: &nbsp;
                      //         </Name1>{" "}
                      //         {(nestedItem?.warehouses[0]?.MRP ===
                      //           nestedItem?.warehouses[0]?.ASP && (
                      //           <div className="auto-layout-horizontal-9">
                      //             <Price1 className="price-4">
                      //               ₹{" "}
                      //               {nestedItem?.warehouses &&
                      //                 nestedItem?.warehouses[0]?.ASP}
                      //             </Price1>
                      //           </div>
                      //         )) || (
                      //           <div style={{ display: "flex" }}>
                      //             <div className="auto-layout-horizontal-9">
                      //               <Price
                      //                 style={{
                      //                   color: "#8f8f8f",
                      //                   textDecoration: "line-through",
                      //                   fontWeight: "400",
                      //                   fontSize: "14px",
                      //                 }}
                      //                 className="price-4"
                      //               >
                      //                 ₹{" "}
                      //                 {nestedItem?.warehouses &&
                      //                   nestedItem?.warehouses[0]?.MRP}{" "}
                      //               </Price>
                      //             </div>

                      //             <div className="auto-layout-horizontal-10">
                      //               <Price1 className="price-5">
                      //                 &nbsp; ₹{" "}
                      //                 {nestedItem?.warehouses &&
                      //                   nestedItem?.warehouses[0]?.ASP}
                      //               </Price1>
                      //             </div>
                      //           </div>
                      //         )}
                      //       </div>
                      //       <div style={{ display: "flex" }}>
                      //         <Name1
                      //           style={{ marginBottom: "0" }}
                      //           className="price-4"
                      //         >
                      //           payment Mode: &nbsp;
                      //         </Name1>
                      //         <Name
                      //           style={{
                      //             alignSelf: "start",
                      //             fontSize: "14px",
                      //           }}
                      //         >
                      //           {item?.payment?.payment_mode}
                      //         </Name>
                      //       </div>
                      //     </div>
                      //     <div
                      //       style={{
                      //         display: "flex",
                      //         justifyContent: "space-between",
                      //         marginTop: "0rem",
                      //         marginBottom: "0.5rem",
                      //       }}
                      //     >
                      //       <div style={{ display: "flex" }}>
                      //         <Frame36950>
                      //           <Rate
                      //             style={{
                      //               fontSize: 16,
                      //             }}
                      //             allowHalf
                      //             defaultValue={nestedItem?.rating}
                      //           />
                      //           <Name1 style={{ margin: "0" }}>
                      //             &nbsp; {nestedItem?.rating} Ratings
                      //           </Name1>
                      //         </Frame36950>
                      //       </div>

                      //       {(nestedItem?.item_cancel?.is_cancelled ===
                      //         true && (
                      //         <span style={{ color: "red" }}>Cancelled</span>
                      //       )) || (
                      //         <>
                      //           {item?.overall_status === "Ordered" ||
                      //             (item?.overall_status === "Processing" && (
                      //               <button
                      //                 onClick={() => {
                      //                   setIsCancelOrder(true);
                      //                   setCancelItem(item);
                      //                   setNestedCancelItem(nestedItem);
                      //                 }}
                      //                 style={{ padding: "0.6rem 1.6rem" }}
                      //                 className="outline-button outline-primary "
                      //               >
                      //                 Cancel Order
                      //               </button>
                      //             ))}
                      //         </>
                      //       )}
                      //     </div>
                      //   </div>{" "}
                      // </div>
                    ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div></div>

                    <div>
                      {(item?.overall_status === "Ordered" && (
                        <button
                          onClick={() => {
                            setIsCancelOrder(true);
                            setCancelItem(item);
                            setNestedCancelItem(item?.items);
                          }}
                          style={{
                            padding: "0.3rem 1.6rem",
                            marginLeft: "1rem",
                            fontSize: "14px",
                          }}
                          className="outline-button outline-primary order-buttons"
                        >
                          Cancel Order
                        </button>
                      )) ||
                        (item?.overall_status === "Processing" && (
                          <>
                            <button
                              onClick={() => {
                                setIsCancelOrder(true);
                                setCancelItem(item);
                                setNestedCancelItem(item?.items);
                              }}
                              style={{
                                padding: "0.3rem 1.6rem",
                                marginLeft: "1rem",
                                fontSize: "14px",
                              }}
                              className="outline-button outline-primary order-buttons"
                            >
                              Cancel Order
                            </button>
                          </>
                        ))}

                      <button
                        onClick={() => handleOrderDetail(item)}
                        style={{
                          padding: "0.3rem 1.6rem",
                          marginLeft: "1rem",
                          marginTop: "1rem",
                          fontSize: "14px",
                        }}
                        className="outline-button outline-primary order-buttons"
                      >
                        Return Details
                      </button>
                    </div>
                  </div>
                </Group37002>
              </OverlapGroup1>
            ))) || (
            <div
              style={{ width: "100%", marginTop: 40 }}
              className="text-center"
            >
              <p
                style={{
                  width: "100%",
                  fontSize: "18px",
                  paddingBottom: "2rem",
                  lineHeight: "1.4em",
                }}
                className="para text-center"
              >
                You havent return anything yet
              </p>
              <div className="">
                <svg
                  width="183"
                  height="171"
                  viewBox="0 0 383 571"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M244.445 40.1252H168.445V85.1252H244.445V40.1252Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M93.1517 47.3812H53.9676V33.7173C53.9676 28.5212 56.0317 23.5379 59.7059 19.8636C63.3802 16.1894 68.3635 14.1252 73.5596 14.1252C78.7558 14.1252 83.7392 16.1894 87.4134 19.8636C91.0876 23.5379 93.1517 28.5212 93.1517 33.7173V47.3812ZM54.8351 46.5137H92.2842V33.7173C92.2842 28.7513 90.3115 23.9886 86.7999 20.4771C83.2884 16.9655 78.5257 14.9928 73.5596 14.9928C68.5936 14.9928 63.8309 16.9655 60.3194 20.4771C56.8079 23.9886 54.8351 28.7513 54.8351 33.7173V46.5137Z"
                    fill="#3F3D56"
                  />
                  <path
                    d="M112.527 45.502V40.162L12.877 42.832L9.72302 178.092L24.471 196.31L112.527 45.502Z"
                    fill="#B82D46"
                  />
                  <path
                    d="M130.167 43.622L22.158 46.08L24.471 196.31L128.705 185.075L130.167 43.622Z"
                    fill="#FF3055"
                  />
                  <path
                    d="M221.997 530.355H209.737L203.905 483.067H221.999L221.997 530.355Z"
                    fill="#FFB6B6"
                  />
                  <path
                    d="M225.123 542.239L185.593 542.238V541.738C185.593 537.657 187.214 533.743 190.099 530.858C192.985 527.973 196.898 526.352 200.979 526.351L225.124 526.352L225.123 542.239Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M176.997 530.355H164.737L158.905 483.067H176.999L176.997 530.355Z"
                    fill="#FFB6B6"
                  />
                  <path
                    d="M180.123 542.239L140.593 542.238V541.738C140.593 537.657 142.214 533.743 145.099 530.858C147.985 527.973 151.898 526.352 155.979 526.351L180.124 526.352L180.123 542.239Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M140.992 234.259C140.992 234.259 123.08 272.834 139.262 350.48C155.445 428.125 155.579 513.368 155.579 513.368L180.322 513.771L194.222 330.251L201.5 511.18H225.878L247.362 333.111C247.362 333.111 261.5 253.598 229.973 239.362L140.992 234.259Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M226.733 98.1253L172.555 93.1312C155.565 105.515 146.03 127.756 147.987 150.705C150.641 181.819 149.743 224.639 128.445 243.241C128.445 243.241 186.494 289.057 238.06 245.591C238.06 245.591 220.427 198.353 232.534 179.489C244.64 160.625 249.007 107.625 226.733 98.1253Z"
                    fill="#E4E4E4"
                  />
                  <path
                    d="M65.527 21.2418C65.3608 22.8035 65.5391 24.3827 66.0493 25.868C66.5595 27.3533 67.3892 28.7087 68.4798 29.8388C69.5705 30.9688 70.8956 31.8459 72.362 32.4084C73.8283 32.9708 75.4002 33.205 76.9668 33.0942L148.5 99.8519L161.321 80.3221L86.9745 20.8425C86.6141 18.2231 85.3053 15.8268 83.2961 14.1079C81.287 12.3889 78.717 11.4666 76.0733 11.5159C73.4296 11.5651 70.8959 12.5823 68.9521 14.3749C67.0084 16.1675 65.7896 18.6108 65.527 21.2418Z"
                    fill="#FFB6B6"
                  />
                  <path
                    d="M135.142 89.5391L152.112 72.1073C152.595 71.6113 153.179 71.2253 153.824 70.9754C154.47 70.7255 155.162 70.6176 155.853 70.6592C156.544 70.7008 157.217 70.8908 157.828 71.2162C158.439 71.5417 158.973 71.995 159.393 72.5453L174.105 91.8279C176.628 94.3127 178.063 97.6967 178.095 101.238C178.127 104.779 176.754 108.189 174.276 110.719C171.799 113.249 168.419 114.694 164.878 114.736C161.336 114.778 157.923 113.414 155.386 110.944L135.775 96.8055C135.214 96.4005 134.747 95.8793 134.405 95.2773C134.063 94.6754 133.855 94.0069 133.795 93.3173C133.735 92.6277 133.824 91.9333 134.057 91.2813C134.289 90.6293 134.659 90.0351 135.142 89.5391Z"
                    fill="#E4E4E4"
                  />
                  <path
                    d="M331.284 183.812C331.364 182.243 331.098 180.677 330.506 179.222C329.914 177.767 329.01 176.46 327.859 175.392C326.707 174.325 325.335 173.523 323.84 173.042C322.344 172.562 320.762 172.416 319.204 172.613L244.072 109.933L232.356 130.145L309.892 185.402C310.398 187.998 311.837 190.317 313.939 191.922C316.041 193.527 318.658 194.305 321.295 194.109C323.931 193.913 326.405 192.756 328.246 190.859C330.087 188.961 331.168 186.454 331.284 183.812Z"
                    fill="#FFB6B6"
                  />
                  <path
                    d="M257.982 119.488L242.007 137.835C241.553 138.357 240.991 138.775 240.36 139.061C239.73 139.346 239.045 139.492 238.353 139.489C237.661 139.486 236.977 139.334 236.349 139.043C235.721 138.752 235.163 138.329 234.713 137.803L218.953 119.367C216.295 117.027 214.674 113.727 214.445 110.193C214.216 106.659 215.398 103.178 217.731 100.514C220.064 97.8499 223.359 96.2197 226.893 95.9811C230.426 95.7424 233.91 96.9147 236.581 99.2409L256.946 112.268C257.529 112.641 258.025 113.135 258.4 113.717C258.774 114.299 259.019 114.955 259.118 115.641C259.216 116.326 259.165 117.024 258.97 117.688C258.774 118.352 258.437 118.966 257.982 119.488Z"
                    fill="#E4E4E4"
                  />
                  <path
                    d="M338.918 210.316H309.237V199.966C309.237 196.03 310.801 192.255 313.584 189.472C316.367 186.689 320.142 185.125 324.078 185.125C328.013 185.125 331.788 186.689 334.571 189.472C337.354 192.255 338.918 196.03 338.918 199.966L338.918 210.316ZM309.894 209.659H338.261V199.966C338.261 196.204 336.766 192.597 334.107 189.937C331.447 187.277 327.839 185.782 324.077 185.782C320.316 185.782 316.708 187.277 314.048 189.937C311.388 192.597 309.894 196.204 309.894 199.966V209.659Z"
                    fill="#3F3D56"
                  />
                  <path
                    d="M353.594 208.892V204.847L278.112 206.87L275.723 309.325L286.894 323.125L353.594 208.892Z"
                    fill="#B82D46"
                  />
                  <path
                    d="M366.956 207.468L285.142 209.33L286.894 323.125L365.849 314.615L366.956 207.468Z"
                    fill="#FF3055"
                  />
                  <path
                    d="M203.445 84.1252C221.118 84.1252 235.445 69.7984 235.445 52.1252C235.445 34.4521 221.118 20.1252 203.445 20.1252C185.772 20.1252 171.445 34.4521 171.445 52.1252C171.445 69.7984 185.772 84.1252 203.445 84.1252Z"
                    fill="#FFB6B6"
                  />
                  <path
                    d="M188.712 37.8634C199.782 48.7118 219.046 49.7516 231.219 40.1577C234.598 51.8109 234.968 64.1304 232.294 75.9653C229.621 87.8003 223.991 98.7645 215.931 107.834C222.703 110.28 230.498 108.671 236.551 104.771C242.604 100.871 247.086 94.9167 250.469 88.5605C258.842 72.8259 260.939 53.6133 255.015 36.8025C249.091 19.9918 234.898 6.05218 217.655 1.53844C200.412 -2.9753 180.642 2.6003 169.121 16.2011C157.075 30.4232 155.123 51.2624 160.402 69.1375C165.681 87.0126 177.26 102.363 189.981 115.985C183.871 100.272 179.819 83.8355 177.926 67.0833C177.279 61.346 176.901 55.432 178.521 49.8906C180.142 44.3491 184.131 39.1874 189.691 37.6304"
                    fill="#2F2E41"
                  />
                  <path
                    d="M382 544.125H1C0.734784 544.125 0.480444 544.02 0.292908 543.832C0.105371 543.645 0 543.39 0 543.125C0 542.86 0.105371 542.606 0.292908 542.418C0.480444 542.231 0.734784 542.125 1 542.125H382C382.265 542.125 382.52 542.231 382.707 542.418C382.895 542.606 383 542.86 383 543.125C383 543.39 382.895 543.645 382.707 543.832C382.52 544.02 382.265 544.125 382 544.125Z"
                    fill="#CACACA"
                  />
                  <path
                    d="M322.918 457.316H293.237V446.966C293.237 443.03 294.801 439.255 297.584 436.472C300.367 433.689 304.142 432.125 308.078 432.125C312.013 432.125 315.788 433.689 318.571 436.472C321.354 439.255 322.918 443.03 322.918 446.966L322.918 457.316ZM293.894 456.659H322.261V446.966C322.261 443.204 320.766 439.596 318.107 436.937C315.447 434.277 311.839 432.782 308.077 432.782C304.316 432.782 300.708 434.277 298.048 436.937C295.388 439.596 293.894 443.204 293.894 446.966V456.659Z"
                    fill="#CACACA"
                  />
                  <path
                    d="M337.594 455.892V451.847L262.112 453.87L259.723 556.325L270.894 570.125L337.594 455.892Z"
                    fill="#00155E"
                  />
                  <path
                    d="M350.956 454.468L269.142 456.33L270.894 570.125L349.849 561.615L350.956 454.468Z"
                    fill="#2F4799"
                  />
                </svg>
              </div>

              <button
                style={{ marginTop: "1.6rem", padding: "0.5rem 2rem" }}
                className="outline-primary outline-button"
                onClick={() => history.push("/")}
              >
                Continue Shopping
              </button>
            </div>
          )}

          <Modal
            title="Return Details"
            centered
            open={isDetail}
            onOk={() => setIsDetail(false)}
            onCancel={() => setIsDetail(false)}
            footer={null}
          >
            <Group37002 style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <UrbanoFashion>{"Payment Details"}</UrbanoFashion>
                {order?.return_amount?.cod > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="para">Cod</p>
                    <p className="para bold">₹ {order?.return_amount?.cod} </p>
                  </div>
                )}
                {order?.return_amount?.online > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="para">Online</p>
                    <p className="para bold">
                      ₹ {order?.return_amount?.online}{" "}
                    </p>
                  </div>
                )}
                {order?.return_amount?.wallet > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="para">Wallet</p>
                    <p className="para bold">
                      ₹ {order?.return_amount?.wallet}{" "}
                    </p>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="para">Return Charges</p>
                  <p className="para bold">
                    {" "}
                    {order?.return_charges == 0
                      ? "Free"
                      : "+ ₹ " + order?.return_charges}{" "}
                  </p>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #c1c1c1",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.6rem",
                    paddingTop: "0.5rem",
                  }}
                >
                  <p style={{ fontSize: "14px" }} className="para bold">
                    Return amount
                  </p>
                  <div>
                    <p
                      style={{ textAlign: "right", fontSize: "17px" }}
                      className="para bold"
                    >
                      ₹{" "}
                      {/* {(order?.payment?.pay_amount?.cod !== 0 &&
                      order?.payment?.pay_amount?.cod) ||
                      order?.payment?.pay_amount?.online}{" "} */}
                      {order?.return_amount?.wallet +
                        order?.return_amount?.online +
                        order?.return_amount?.cod +
                        order?.return_charges}
                    </p>
                    {/* <div style={{ display: "flex" }}>
                      <Name1 style={{ margin: 0, padding: 0 }}>
                        Payment Mode:
                      </Name1>{" "}
                      <Name style={{ alignSelf: "start" }}>
                        &nbsp; {order?.payment?.payment_mode}
                      </Name>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
              <div></div>
            </Group37002>

            <br />

            <Group37002 style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <UrbanoFashion>Track Order</UrbanoFashion>
                <br />
                {order?.statuses?.map((e, index) => (
                  <TimeLine
                    key={index}
                    date={e?.created_at}
                    other={e.status}
                    name={e.status}
                    text={`Your ${e.status}  successfully`}
                  />
                ))}
              </div>
            </Group37002>
            <br />

            <Group37002 style={{ width: "100%" }}>
              <div style={{ width: "100%" }}></div>
              <div>
                <p
                  className="para text-left"
                  style={{
                    width: "100%",
                    fontWeight: "400",
                    fontSize: "17px",
                    display: "flex",
                  }}
                >
                  <UrbanoFashion>Shipping Details</UrbanoFashion>
                  <span style={{ marginLeft: "10px" }}>
                    <Badge
                      style={{
                        backgroundColor: "#52c41a",
                      }}
                      count={order?.pickup_address?.address_type}
                    />
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    <Badge
                      count={
                        order?.pickup_address?.is_default ? "Default" : null
                      }
                    />
                  </span>
                </p>
                <div>
                  <Name1
                    style={{
                      marginTop: "3px",
                    }}
                  >
                    {order?.pickup_address?.name}, &nbsp; HOUSE NO -{" "}
                    {order?.pickup_address?.house_no}
                    ,
                    <br />
                    {order?.pickup_address?.society}
                    ,&nbsp;&nbsp;
                    {order?.pickup_address?.state}
                    &nbsp;-&nbsp;
                    {order?.pickup_address?.pincode}
                  </Name1>
                </div>
                <div style={{ display: "flex", marginTop: "0.4rem" }}>
                  <p
                    style={{
                      color: "black",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                    className="para"
                  >
                    &nbsp;&nbsp; {order?.pickup_address?.mobile}
                  </p>
                </div>

                {/* {order?.address?.address_type !== "" && (
                <Name1 style={{ marginTop: "4px" }}>
                  {order?.address?.address_type}
                </Name1>
              )} */}
              </div>
            </Group37002>
          </Modal>

          <Modal
            title="Select Quantity"
            centered
            open={quantityPopUp}
            onOk={() => setQuantityPopup(false)}
            onCancel={() => setQuantityPopup(false)}
            footer={null}
          ></Modal>
          <Modal
            width={600}
            onCancel={() => {
              setIsCancelOrder(false);
              setCancelReason("");
            }}
            footer={[""]}
            open={isCancelOrder}
          >
            <div style={{ padding: "0rem" }}>
              <div className="d-flex">
                <div>
                  <div className="">
                    {/* <divHeader className="bg-transparent ">
                <div className="text-muted text-center ">
                  <small>Sign in with</small>
                </div>
              </divHeader> */}
                    <p
                      className="para"
                      style={{
                        marginTop: "0.3rem",
                        marginBottom: "0.5rem",
                        display: "flex",
                      }}
                    >
                      ORDER ID: &nbsp;{" "}
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {cancelItem?.order_id}
                      </span>
                    </p>
                    <div className="">
                      <h3
                        style={{ marginBottom: "1rem", textAlign: "left" }}
                        className="second-heading"
                      >
                        Why are you cancelling ?
                      </h3>
                      <p
                        style={{ lineHeight: "21px" }}
                        className="para text-left"
                      >
                        Please choose the correct reason for cancel. This
                        information is only used to improve our service.
                      </p>
                      <br />

                      {/* <form onSubmit={(e) => cancelConfirm(e)} role="form">
                        <div style={{ textAlign: "left" }} className="mt-5">
                          <>
                            <Radio.Group
                              style={{ marginTop: "1rem" }}
                              onChange={onChangeReason}
                              value={cancelReason}
                            >
                              <Space direction="vertical">
                                <Radio value={"I Changed my mind"}>
                                  I Changed my mind
                                </Radio>
                                <Radio value={"Delivery was delayed"}>
                                  Delivery was delayed
                                </Radio>
                                <Radio value={"I do not need anymore"}>
                                  I do not need anymore
                                </Radio>
                                <Radio
                                  value={
                                    "Found a better price on House of believe"
                                  }
                                >
                                  Found a better price on House of believe
                                </Radio>
                              </Space>
                            </Radio.Group>
                            <div style={{ marginTop: "1rem" }}>
                              <textarea
                                id="otp"
                                style={{ width: "100%" }}
                                className="input-group-alternative"
                                placeholder="Enter Reason"
                                type="number"
                                autoComplete="new-email"
                                value={cancelReason}
                                onChange={(e) =>
                                  setCancelReason(e.target.value)
                                }
                              />

                              <br />
                            </div>
                          </>
                        </div>
                      </form> */}

                      <p className="para text-left">SELECT REASON *</p>

                      <form onSubmit={(e) => e} role="form">
                        <div style={{ textAlign: "left" }} className="mt-5">
                          <>
                            <Radio.Group
                              style={{
                                marginTop: "0rem",
                                width: "100%",
                              }}
                              onChange={onChangeReason}
                              value={cancelReason.value}
                            >
                              {(Object.keys(cancelReason).length === 0 &&
                                selectRegion.map((item) => (
                                  <div key={item.value}>
                                    <Space direction="vertical">
                                      <Radio
                                        className="custom-radio"
                                        label={item.label}
                                        value={item.value}
                                      >
                                        <span
                                          style={{
                                            textAlign: "start !important",
                                          }}
                                        >
                                          {item.label}
                                        </span>
                                      </Radio>
                                    </Space>
                                  </div>
                                ))) || (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <Space direction="vertical">
                                        <Radio
                                          className="custom-radio"
                                          label={cancelReason.label}
                                          value={cancelReason.value}
                                        >
                                          {cancelReason.label}
                                        </Radio>
                                      </Space>
                                    </div>
                                    <button
                                      onClick={() => setCancelReason("")}
                                      style={{
                                        padding: "0",
                                        textTransform: "capitalize",
                                      }}
                                      className="btn outline-button"
                                    >
                                      Change
                                    </button>
                                  </div>

                                  <div
                                    style={{
                                      marginTop: "0.5rem",
                                      marginBottom: "0.8rem",
                                    }}
                                    className="hr"
                                  ></div>

                                  <p
                                    style={{ fontWeight: "200" }}
                                    className="para text-left"
                                  >
                                    Select Issue Detail
                                  </p>
                                  <Radio.Group
                                    style={{
                                      marginTop: "0.3rem",
                                    }}
                                    onChange={(item) =>
                                      setSelectIssue(item.target.value)
                                    }
                                    value={selectIssue}
                                  >
                                    {returnRegion[cancelReason.value].map(
                                      (nestItem) => (
                                        <div>
                                          <Space direction="vertical">
                                            <Radio
                                              className="custom-radio"
                                              label={nestItem}
                                              value={nestItem}
                                            >
                                              {nestItem}
                                            </Radio>
                                          </Space>
                                        </div>
                                      )
                                    )}
                                  </Radio.Group>
                                </>
                              )}{" "}
                            </Radio.Group>

                            <p
                              style={{
                                textAlign: "left",
                                paddingTop: "0.5rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "300",
                                }}
                                className="error-red"
                              >
                                {" "}
                                {(selectIssue === "" && reasonError) ||
                                  (selectRegion === "" && reasonError)}
                              </span>
                            </p>

                            <div style={{ marginTop: "1rem" }}>
                              {/* <label for="otp">Enter OTP</label> */}
                              <textarea
                                id="otp"
                                style={{ width: "100%" }}
                                className="input-group-alternative"
                                placeholder="Addition Comment"
                                type="number"
                                autoComplete="new-email"
                                value={additionComment}
                                onChange={(e) =>
                                  setAdditionComment(e.target.value)
                                }
                              />
                            </div>
                            <br />
                            <br />
                          </>
                        </div>
                        <div
                          style={{
                            marginTop: "1.5rem",
                            marginBottom: "1rem ",
                          }}
                          className="text-center mt-3"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setIsCancelOrder(false);
                                setCancelReason("");
                              }}
                              style={{
                                padding: "0.2rem 2rem",
                                marginRight: "1rem",
                                fontSize: "14px",
                                "background-color": "#f4792c",
                                "color": "white",
                              }}
                              className="btn outline-primary outline-button"
                            >
                              Keep Order
                            </button>

                            <div>
                              <Spin spinning={isLoading}>
                                <button
                                  type="submit"
                                  onClick={(e) => cancelConfirm(e)}
                                  className={`btn btn-primary ${
                                    cancelReason == "" && "disabled"
                                  }`}
                                  style={{
                                    fontSize: "14px",
                                    padding: "0.6rem 2rem",
                                  }}
                                  disabled={cancelReason === "" ? true : false}
                                >
                                  Cancel Order
                                </button>
                              </Spin>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

const Price1 = styled.div`
  ${InterSemiBoldSapphire14px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Price = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;
const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const X10CustomerReviews = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  position: absolute;
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 100%;
  position: relative;
  margin-top: 36px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  padding: 15px 19px;
  max-width: 100%;
  width: 783px
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  position: relative;
  margin-top: 0.26px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #c4c4c4;
`;

const Name = styled.div`
  ${ValignTextMiddle}

  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #f4792c;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  letter-spacing:1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 151px;
  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;

  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}

  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
      

  
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
    
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default ReturnList;
