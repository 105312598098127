import React from "react";
import Group3 from "../Group3";
import styled from "styled-components";

class Buttons2 extends React.Component {
  render() {
    const { group3Props } = this.props;

    return (
      <Property1one>
        <Group3 className={group3Props.className} />
      </Property1one>
    );
  }
}

const Property1one = styled.div`
  position: relative;
  min-width: 180.5px;
  height: 52px;
  background-color: var(--primarycolor);
  cursor: pointer;
`;

export default Buttons2;
