import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "../Product";
import { Link } from "react-router-dom/cjs/react-router-dom";
import VisibilitySensor from 'react-visibility-sensor';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 2,
  },
};


const ButtonGroup = ({
  url,
  id,
  name,
  catItem,
  next,
  previous,
  goToSlide,
  visible,
  ...rest
}) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  return (
    <div className="carousel-button-group ">
      {" "}
      { visible && (
        <Link to={(url && url) || `/shop/category/${id}?name=${name}`}>
          <button
            className="btn-primary view-more-arrow"
            style={{
              position: "absolute",
              top: "12px",
              right: "0",
              fontSize: "12px",
              padding: "0.2rem 1rem",
            }}
            onClick={() => goToSlide(currentSlide + 1)}
          >
            View More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
        </Link>
      )}
      {/* <button
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => previous()}
      /> */}
      {/* <button onClick={() => next()} /> */}
      {/* <button onClick={() => goToSlide(currentSlide + 1)}>
        {" "}
        Go to any slide{" "}
      </button> */}
    </div>
  );
};

const CustomCarousel = (props) => {
  const { url, catItem, fetchSubCategory, product1Props, name, id } = props;

  const [ visible, setVisible ] = useState(false);


  return (
    <Carousel
      className={`custom-carousal ${visible && 'hide-right-button'}`}
      customButtonGroup={
        <ButtonGroup visible={visible} name={name} id={id} url={url} catItem={catItem?.length} />
      }
      responsive={responsive}
      // arrows={false}
    >
      {catItem !== undefined &&
        catItem.map((item, index) => { 
          if ( index === (catItem.length - 1) ) {
          return (
            <div>
            <VisibilitySensor onChange={(isVisible) => setVisible(isVisible)} partialVisibility={true}>
            <Product
              disableResponsive
              key={index}
              item={item}
              fetchData={fetchSubCategory}
              className={product1Props?.className}
              src={item?.images[0]?.url}
            />
            </VisibilitySensor>
            </div>
        )}
          else {
          return (
            <div>
            <Product
              disableResponsive
              key={index}
              item={item}
              fetchData={fetchSubCategory}
              className={product1Props?.className}
              src={item?.images[0]?.url}
            />
            </div>
        )}
})}
    </Carousel>
  );
};

export default CustomCarousel;
