import React, { useEffect, useState } from "react";
import Header4 from "../Header4";
import Spanbox from "../Spanbox";
import Footer from "../Footer";
import styled from "styled-components";
import {
  useHistory,
  Switch,
  BrowserRouter as Router,
  Route,
  useLocation,
} from "react-router-dom";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./MyProfile.css";
import Wallet from "./Wallet";
import Myprofile from "./myprofile";
import AddAddress from "./AddAddress";
import { api } from "../../api/baseurl";
import Cookies from "js-cookie";
import { message, notification } from "antd";
import axios from "axios";
import { logoutApi } from "../../api/login";
import { getAddressApi } from "../../api/Address";
import Order from "./Order";
import OrderDetail from "./OrderDetail";
import ReturnList from "./ReturnList";
import Notification from "./Notification";
// import OrderReturn from "./OrderReturnRoot";

export const SideBarAccout = ({ menu }) => {
  const history = useHistory();
  const location = useLocation();
  const [addressApiData, setAddressApiData] = useState();

  const handleLogout = async () => {
    const { data } = await logoutApi();

    if (data.success) {
      Cookies.remove("warehouse_id");
      localStorage.removeItem("login");
      localStorage.removeItem("wallet");
      window.location.reload(false);
      // localStorage.setItem("login","logout");
      setLogindata(null);
    } else {
      if (
        data.message == "please login" ||
        data.message === "You are logged out"
      ) {
        Cookies.remove("warehouse_id");
        localStorage.removeItem("login");
        localStorage.removeItem("wallet");
        window.location.reload(false);
        setLogindata(null);
      }
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const [name, setName] = useState("profile");
  const handleTab = (tab, link) => {
    setName(tab);
    history.push(`/my-profile${link}`);
  };

  useEffect(() => {
    setName(location.pathname.replace("/my-profile/", ""));
    if (location.pathname.replace("/my-profile/", "") === name) {
      setName(location.pathname.replace("/my-profile/", ""));
    } else if (location.pathname.replace("/my-profile/", "") === "") {
      setName("profile");
    } else {
      // setName(location.pathname.replace("/my-profile/", ""));
    }

    if (localStorage.getItem("login") !== null) {
    } else {
      // notification.error({
      //   message: "Please login in",
      // });

      history.push("/" + "?login");
    }
  }, [location]);

  const getAddressFunc = async () => {
    const resultData = await getAddressApi();
    setAddressApiData(resultData?.data);
  };

  useEffect(() => {
    getAddressFunc();
  }, []);

  return (
    <Frame37022>
      <Profile
        style={{ cursor: "pointer", marginTop: "0.5rem", fontSize:"x-large" }}
        onClick={() => handleTab("profile", "/")}
        className={name === "profile" && " profile-side-active"}
      >
        Profile
      </Profile>

      {/* <DeerikaWallet
        style={{ cursor: "pointer", marginTop: "0.5rem" }}
        onClick={() => handleTab("wallet", "/wallet")}
        className={name === "wallet" && " profile-side-active"}
      >
        {menu?.deerikaWallet}
      </DeerikaWallet> */}

      <OrdersReturn
        style={{ cursor: "pointer", marginTop: "0.5rem",fontSize:"x-large"  }}
        onClick={() => handleTab("address", "/address")}
        className={name === "address" && " profile-side-active"}
      >
        {menu?.address}
      </OrdersReturn>

      <OrdersReturn
        style={{ cursor: "pointer", marginTop: "0.5rem",fontSize:"x-large"  }}
        onClick={() => handleTab("orders", "/orders")}
        className={name === "orders" && " profile-side-active"}
      >
        Orders
      </OrdersReturn>
      <OrdersReturn
        style={{ cursor: "pointer", marginTop: "0.5rem",fontSize:"x-large"  }}
        onClick={() => handleTab("returns", "/returns")}
        className={name === "returns" && " profile-side-active"}
      >
        Return
      </OrdersReturn>
      <OrdersReturn
        style={{ cursor: "pointer", marginTop: "0.5rem",fontSize:"x-large"  }}
        onClick={() => handleTab("notifications", "/notifications")}
        className={name === "notifications" && " profile-side-active"}
      >
        Notifications
      </OrdersReturn>

      {/* <OrdersReturn
        style={{ cursor: "pointer", marginTop: "0.5rem" }}
        onClick={() => handleTab("orders-return", "/orders-return")}
        className={name === "orders-return" && " profile-side-active"}
      >
        Orders & Return
      </OrdersReturn> */}
      <br />
      {/* <button
        onClick={(e) => handleLogout()}
        style={{ padding: "0.5rem 1.8rem", fontSize: "14px" }}
        className="btn btn-primary"
      >
        logout
      </button> */}
    </Frame37022>
  );
};

class MyProfile extends React.Component {
  render() {
    // const [name, setName] = useState("");

    const { account, phone } = this.props;
    const mobile = JSON.parse(localStorage.getItem("login"));

    return (
      <div className="container-center-horizontal">
        <div className="my-profile screen my-profile-container">
          {window.innerWidth > 1280 && (
            <div className="profile-account-sec">
              <ACCOUNT>{account}</ACCOUNT>
              <Phone>{mobile?.mobile} </Phone>
            </div>
          )}
          <div className="profile-content">
            {window.innerWidth > 1280 && (
              <aside>
                <SideBarAccout menu={this.props} />
              </aside>
            )}
            <main>
              <Group37022 className="pf-item">
                {/* <Line21 src="/img/line-21.svg" alt="Line 21" /> */}
                <Switch>
                  <Route
                    exact
                    path="/my-profile/wallet"
                    component={(props) => (
                      <Wallet someProp={this.props} {...props} />
                    )}
                  />
                  <Route
                    exact
                    path="/my-profile/"
                    component={(props) => (
                      <Myprofile someProp={this.props} {...props} />
                    )}
                  />

                  <Route
                    exact
                    path="/my-profile/address"
                    component={(props) => <AddAddress />}
                  />

                  <Route
                    exact
                    path="/my-profile/orders"
                    component={(props) => (
                      <Order someProp={this.props} {...props} />
                    )}
                  />
                  <Route 
                    exact 
                    path="/my-profile/notifications"
                    component={() => <Notification />}
                  />
                  <Route
                    exact
                    path="/my-profile/returns"
                    component={(props) => (
                      <ReturnList someProp={this.props} {...props} />
                    )}
                  />
                  <Route
                    exact
                    path="/my-profile/orders/:id"
                    component={(props) => (
                      <OrderDetail someProp={this.props} {...props} />
                    )}
                  />
                  {/* <Route
                    exact
                    path="/my-profile/orders-return"
                    component={(props) => (
                      <OrderReturn someProp={this.props} {...props} />
                    )}
                  /> */}
                </Switch>
                {/* <OverlapGroup1>
                  <Group37002>
                    <RadioButtons
                      src="/img/radio-buttons.svg"
                      alt="radio-buttons"
                    />
                    <NameContainer>
                      <Name>{name1}</Name>
                      <Name1>{name2}</Name1>
                    </NameContainer>
                  </Group37002>
                  <Group36968
                    style={{ backgroundImage: `url(${group36968})` }}
                  ></Group36968>
                  <Button>
                    <Return>{xreturn}</Return>
                  </Button>
                  <Rating5Stars
                    src="/img/rating-5-stars.svg"
                    alt="Rating 5 stars"
                  />
                  <Spanbox size30M={spanboxProps.size30M} />
                  <UrbanoFashion>{urbanoFashion}</UrbanoFashion>
                  <MenBlackSlimFitM>{menBlackSlimFitM}</MenBlackSlimFitM>
                  <RateThisProduct>{rateThisProduct}</RateThisProduct>
                </OverlapGroup1> */}
              </Group37022>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;

  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;

  letter-spacing: 0;
  line-height: normal;
`;

const OverlapGroup4 = styled.div`
  margin-top: 22px;
  margin-right: 1px;
  width: 16%;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  position: relative;
  width: 818px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;

  // min-height: 618px;
  gap: 35px;
  border-left: 1px solid #bcbcbc;
  padding-bottom: 2rem;
  padding-left: 2.4rem;
`;

const Line21 = styled.img`
  width: 1px;
  height: 100%;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  position: relative;
  margin-top: 36px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  padding: 15px 19px;
  align-items: flex-start;
  min-width: 783px;
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  width: 94px;
  position: relative;
  margin-top: 0.26px;
`;

const Name = styled.div`
  ${ValignTextMiddle}
  position: relative;
  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  top: 22px;
  left: 3px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  position: relative;
  width: 111px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;
  position: relative;
  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  position: absolute;
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 32px;
  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
            position: absolute;
  height: 32px;
  top: 97px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
            position: absolute;
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  width: 173px;
  height: 100%;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default MyProfile;
