import axios from "axios";



//  baseURL: process.env.NODE_ENV === "development" ? "/" : "/api",
export const api = axios.create({
  baseURL: "/api",
});

export const dev = axios.create({
  baseURL: "/dev",
});
