import React, { useEffect, useRef, useState } from "react";
import Header4 from "../Header4";
import Spanbox from "../Spanbox";
import Footer from "../Footer";
import styled from "styled-components";
import { Switch, BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Rate,
  notification,
  Spin,
  Radio,
  Button,
  Space,
  Checkbox,
  Badge,
} from "antd";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
  InterSemiBoldSapphire14px,
} from "../../styledMixins";
import "./MyProfile.css";
import { Modal } from "antd";
import { SingleOrderApi, orderListApi } from "../../api/order";
import { dev } from "../../api/baseurl";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TimeLine from "./detailComp/cusTimeLine";
import OrderReturn from "./detailComp/OrderReturn";
import OrderStatus from "./detailComp/OrderStatus";
import ReturnConfirm from "./detailComp/ReturnConfirm";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const OrderDetail = (props) => {
  const [chooseRefund, setChooseRefund] = useState(false);
  const [sizeModal, setSizeModal] = useState(false);
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isReturnOrder, setIsReturnOrder] = useState(false);
  const params = useParams();
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState("/");

  const sizePopUp = () => {
    setSizeModal(true);
  };

  // const history = useHistory();
  // const previousPath = history?.location?.state?.from || '/'; // Default to '/' if no previous path exists

  // useEffect(() => {
  //   // Save the previous path when the component mounts
  //   setPreviousPath(location.pathname);

  //   // Update the previous path when the user clicks the browser's back button
  //   const handleBrowserBackButton = () => {
  //     setPreviousPath(location.state?.from || '/');
  //   };

  //   window.addEventListener('popstate', handleBrowserBackButton);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handleBrowserBackButton);
  //   };
  // }, [location]);

  // const pathName=window.history.back();

  const localOrderId = localStorage.getItem("orderId");

  const getOrderList = async () => {
    setIsLoading(true);

    // const { data } = await orderListApi();

    if (params?.id == "undefined") {
      const { data } = await SingleOrderApi(localOrderId);

      if (data.success) {
        setIsLoading(false);
        setIsLoading(false);
        setOrder(data?.order);
      } else {
        notification.error({
          message: data?.message,
        });
      }
    } else {
      const { data } = await SingleOrderApi(params?.id);

      if (data.success) {
        setIsLoading(false);
        setIsLoading(false);
        setOrder(data?.order);
      } else {
        notification.error({
          message: data?.message,
          duration: 1,
          placement: "bottom",
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getOrderList();
    localStorage.removeItem("return-process");
    localStorage.removeItem("choose-refund");
  }, [0]);

  return (
    <>
      {(isLoading && (
        <div
          style={{ margin: "0 auto", paddingTop: "2rem" }}
          className="profile-loader"
        >
          <Spin size="large" />
        </div>
      )) || (
        <>
          {(order?.overall_status === "Delivered" && (
            <OrderReturn order={order} />
          )) ||
            (order?.overall_status === "return" && (
              <ReturnConfirm order={order} />
            )) || <OrderStatus order={order} />}
        </>
      )}
    </>
  );
};

const Price1 = styled.div`
  ${InterSemiBoldSapphire14px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Price = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

// const Group37002 = styled.div`
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   margin-top: 1.4rem;
//   margin-bottom: 1.4rem;
//   display: flex;
//   justify-content: space-between;
//   padding: 15px 19px;
//   align-items: flex-start;
//   gap: 8px;
// `;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;
const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const X10CustomerReviews = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  position: absolute;
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  position: relative;
  margin-top: 16px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  padding: 15px 19px;

  min-width: 783px;
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  position: relative;
  margin-top: 0.26px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #c4c4c4;
`;

const Name = styled.div`
  ${ValignTextMiddle}

  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  letter-spacing:1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 151px;

  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;

  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}

  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
      

  
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
    
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default OrderDetail;
