import React from 'react';


const DynamicMetaTag =({categoryName})=>{

    console.log(categoryName);
    return (
        <>
        {categoryName === "WOMEN" && 
        <>
         <title>
         House Of Believe- Buy Women's Clothes Online in India 
                     </title>
                     <meta
                       name="description"
                       content="Best online women's clothes brand in India, Buy printed graphic T-shirts, Shirts, Kurta sets, Kurta Pajamas, Jogger, etc. for Women"
                     />
                     </>
        }
        
        {categoryName === "MEN" && 
        <>
          <title>
          House Of Believe - Buy Online Shopping Clothes for Men in India  
                     </title>
                     <meta
                       name="description"
                       content="Best Clothing for Men Online in India, Buy printed graphic T-shirts, Shirts, Shorts, Track pants, Jogger for men at the best prices  "
                     />
        </>
        
        }

{categoryName === "KIDS" && 
        <>
          <title>
          House Of Believe - Best Clothing for Kids Online in India 
                     </title>
                     <meta
                       name="description"
                       content="Best Kids Clothes Online huge collection of Kids dresses, Graphic Tees, Jeans, Shorts, Jumpsuit, Jogger & and more for Boys and Girls "
                     />
        </>
        
        }
       
        </>
    )
}

export default DynamicMetaTag;