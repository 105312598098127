import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "antd";

const AddNewAddress = ({ cancelAdd }) => {
  const [cancelAddressData, setCancelAddressData] = useState(false);
  const [addressData, setAddressData] = useState({
    name: "",
    house_no: "",
    society: "",
    block: "",
    city: "",
    state: "",
    pin_code: "",
    address_type: "",
    default_address: "",
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const cancelAddress = () => {
    setCancelAddressData(true);
  };

  const addAddressData = () => {
  };

  return (
    <>
      <div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-password-input"
              label="Name"
              type="text"
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  name: e.target.value,
                })
              }
              //   {editAddressPopup ? defaultValue="name" :null}

              //   autoComplete="current-password"
            />
            <TextField
              id="outlined-password-input"
              label="House No."
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  house_no: e.target.value,
                })
              }
              //   type="text"
              //   autoComplete="current-password"
            />
            <TextField
              id="outlined-password-input"
              label="Society"
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  society: e.target.value,
                })
              }
              //   type="text"
              //   autoComplete="current-password"
            />

            <TextField
              id="outlined-password-input"
              label="Block"
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  block: e.target.value,
                })
              }
              //   type="text"
              //   autoComplete="current-password"
            />

            <TextField
              id="outlined-password-input"
              label="City"
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  city: e.target.value,
                })
              }
              //   type="text"
              //   autoComplete="current-password"
            />
            <TextField
              id="outlined-password-input"
              label="State"
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  state: e.target.value,
                })
              }
              //   type="text"
              //   autoComplete="current-password"
            />
            <TextField
              id="outlined-password-input"
              label="PinCode"
              type="number"
              onChange={(e) =>
                setAddressData({
                  ...addressData,
                  pin_code: e.target.value,
                })
              }
              //   autoComplete="current-password"
            />

            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Address Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event) =>
                  setAddressData({
                    ...addressData,
                    address_type: event.target.value,
                  })
                }
                // onChange={(event)=>checkRadioButton(event)}
              >
                <FormControlLabel
                  value="Home"
                  control={<Radio />}
                  label="Home"
                />
                <FormControlLabel
                  value="Office"
                  control={<Radio />}
                  label="Office"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>

            <Checkbox
              {...label}
              onChange={(event) =>
                setAddressData({
                  ...addressData,
                  default_address: event.target.checked,
                })
              }
            />
            <span>Want To mark as default address</span>
          </div>
        </Box>

        <Button type="primary" onClick={addAddressData}>
          Save
        </Button>

        <Button type="primary" danger onClick={() => cancelAdd(false)}>
          Cancel
        </Button>
      </div>
    </>
  );
};
export default AddNewAddress;
