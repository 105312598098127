import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Switch, BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Rate,
  notification,
  Spin,
  Radio,
  Button,
  Space,
  Checkbox,
  Badge,
  message,
} from "antd";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
  InterSemiBoldSapphire14px,
} from "../../../styledMixins";

import "../MyProfile.css";
import { Modal } from "antd";

import { SingleOrderApi, orderReturnApi } from "../../../api/order";
import { dev } from "../../../api/baseurl";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TimeLine from "./cusTimeLine";
import {
  addOldAddressCartApi,
  getAddressApi,
  updateAddressApi,
} from "../../../api/Address";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  addBankAccountApi,
  getBankDetail,
  getBankDetailApi,
} from "../../../api/payment";
import Item from "antd/es/list/Item";
import axios from "axios";

const OrderReturn = ({ order }) => {
  const [chooseRefund, setChooseRefund] = useState(false);
  const [sizeModal, setSizeModal] = useState(false);
  const [ifsc, setIfsc] = useState("");
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [reAccountNumber, setReAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReturnOrder, setIsReturnOrder] = useState(false);
  const [singleItem, setSingleItem] = useState({});
  const [eligibleDate, setEligibleDate] = useState(null);
  const params = useParams();
  const [isSavedBank, setIsSavedBank] = useState(false);
  const [cancelReason, setCancelReason] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState(true);
  const [editAddress1, setEditAddress1] = useState(true);
  const [cancelItem, setCancelItem] = useState();
  const history = useHistory();
  const [additionComment, setAdditionComment] = useState("");
  const [selectIssue, setSelectIssue] = useState("");
  const [pickupDetail, setPickupDetail] = useState(false);
  const [isError, setIsError] = useState(false);
  const [savedBankAccount, setSavedBankAccount] = useState();
  const [updating, setUpdating] = useState(false);
  const [paymentProcess, setPaymentProcess] = useState("basic");
  const [reasonError, setReasonError] = useState("");
  const [addressApiData, setAddressApiData] = useState();
  const [editData, setEditData] = useState();
  const [returnAddressData, setReturnAddressData] = useState();
  const [bankAccountData, setBankAccountData] = useState([]);
  const [isReturn, setIsRetrun] = useState(false);
  const [confirmationCheckbox,setConfirmationCheckbox]=useState(false);
  const [orderReturnData,setOrderReturnData]=useState();

  const [addressData, setAddressData] = useState({
    name: "",
    house_no: "",
    society: "",
    block: "",
    city: "",
    state: "",
    pin_code: "",
    address_type: "",
    is_default: "",
  });
  const onChangeReason = (item) => {
    setReasonError("");
    const obj = { label: item.target.label, value: item.target.value };

    setCancelReason(obj);
  };
  const addNewAddressFunc = () => {
    setAddAddressPopup(true);
    setEditAddress1(true);
    setEditAddress(false);
  };

  const editAddressFunc = (value) => {
    setEditAddress(false);
    setEditAddress1(false);
    setEditData(value);
  };

  const getAddressFunc = async () => {
    const resultData = await getAddressApi();
    setAddressApiData(resultData?.data);
  };
  useEffect(() => {
    getAddressFunc();
  }, []);

  const sizePopUp = () => {
    setSizeModal(true);
  };

  const selectRegion = [
    {
      label: "Image shown did not match the actual product",
      value: "image_not_match",
    },
    {
      label: "Received a wrong or defective product",
      value: "received_wrong_item",
    },
    {
      label: "I changed my mind",
      value: "change_mind",
    },
    {
      label: "Size or fit issues",
      value: "size_fit",
    },
    {
      label: "Quality Issues",
      value: "quality_issue",
    },
  ];

  const returnRegion = {
    image_not_match: [
      "Color is different",
      "Product image was better than the actual product",
      "Design is different",
    ],
    received_wrong_item: [
      "Wrong size was delivered",
      "Defective product was delivered",
      "Diffrent prdoduct was delivered",
    ],
    change_mind: [
      "Delivery was delayed",
      "I do not need anymore",
      "Found a better price on House of believe",
      "It did not look good on me",
    ],
    size_fit: [
      "Size is too large",
      "Size is too small",
      "I did not like the fit",
    ],
    quality_issue: [
      "Product looked Old",
      "Product was dirty and had stains",
      "Received poor quality product",
    ],
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleSetReason = (e) => {
    e.preventDefault();
    if (Object.keys(cancelReason).length === 0) {
      notification.error({
        message: "Please Select Reason",
        duration: 1,
        placement: "bottom",
      });
      setReasonError("Please Select Reason");
      return;
    } else if (selectIssue === "") {
      notification.error({
        message: "Please Select Issue Detail",
        duration: 1,
        placement: "bottom",
      });
      setReasonError("Please Select Issue Detail");
      return;
    } else if (!confirmationCheckbox){
      notification.error({
        message: "Please Confirm the CheckBox",
        duration: 1,
        placement: "bottom",
      });
    } else {
      const obj = {
        reason: cancelReason,
        issue: selectIssue,
        paymentProcess: "pickup-detail",
        return: isReturnOrder,
      };
      localStorage.setItem("return-process", JSON.stringify(obj));
      setPaymentProcess("pickup-detail");
    }
  };

  console.log(order);

  const handleStartRefund = async () => {

    console.log("return flow checking");
    const refund = {
      address: returnAddressData,
      paymentProcess: "choose-refund",
      return: isReturnOrder,
    };
    // localStorage.setItem("choose-refund", JSON.stringify(obj));
    // setPaymentProcess("choose-refund");

    const storedObject = JSON.parse(localStorage.getItem("return-process"));

    // const refund = JSON.parse(localStorage.getItem("choose-refund"));

    const { data } = await orderReturnApi(
      returnAddressData,
      storedObject?.reason?.label,
      storedObject?.issue,
      savedBankAccount,
      order
    );
    if (data.success) {
      notification.success({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
      history.push("/my-profile/returns");
      // window.location.reload(false);
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const onChangeCheckbox = (e) => {
  };

  // confirmationCheckbox

  const handleKeepOrder = (e) => {
    e.preventDefault();
    localStorage.removeItem("return-process");
    localStorage.removeItem("choose-refund");
    setIsReturnOrder(false);
    window.location.reload(false);
  };

  useEffect(() => {
    setAddressData({
      ...addressData,
      name: editData?.name,
      house_no: editData?.house_no,
      society: editData?.society,
      block: editData?.block,
      city: editData?.city,
      state: editData?.state,
      pin_code: editData?.pincode,
      address_type: editData?.address_type,
      id: editData?._id,
      is_default: editData?.is_default,
    });
  }, [editData]);

  const changeAddress = () => {
    setModalOpen(true);
    setEditAddress(true);
    setEditAddress1(true);
    setAddAddressPopup(false);
  };

  useEffect(() => {
    getBankDetail();
    setReturnAddressData(order?.address);
    const storedObject = localStorage.getItem("return-process");
    const refund = localStorage.getItem("choose-refund");

    if (refund) {
      const obj = JSON.parse(refund);
      setPaymentProcess(obj?.paymentProcess);
      setIsReturnOrder(obj?.return);
      setReturnAddressData(obj?.address);
    } else {
      if (storedObject) {
        const obj = JSON.parse(storedObject);
        setCancelReason(obj?.reason);
        setPaymentProcess(obj?.paymentProcess);
        setSelectIssue(obj?.issue);
        setIsReturnOrder(obj?.return);
      }
    }
  }, [0]);

  const selectDefaultAddres = async (value) => {
    const dataValue = {
      new_address: false,
      address_id: value?._id,
    };

    const { data } = await addOldAddressCartApi(dataValue);
    setReturnAddressData(data?.data);

    if (data?.success) {
      notification.success({
        message: data?.message,
        duration: 1,
        placement: "bottom",
      });
      getAddressFunc();
      setModalOpen(false);
    } else {
      notification.error({
        message: data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const cancellUpdate = () => {
    setEditAddress(true);
    setEditAddress1(true);
    setAddAddressPopup(false);
  };

  const updateData = async () => {
    const resultData = await updateAddressApi(addressData);
    if (resultData?.data?.success) {
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
      // setEditAddressPopup(false);
      getAddressFunc();
      setEditAddress(true);
      setModalOpen(true);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const handleAddBankAccount = async () => {
    if (
      accountNumber === "" ||
      accountHolderName === "" ||
      reAccountNumber === "" ||
      ifsc === ""
    ) {
      setIsError(true);
    } else {
      const { data } = await addBankAccountApi(
        ifsc,
        accountNumber,
        accountHolderName
      );

      if (data.success) {
        getBankDetail();
        setIsError(false);
        setIsSavedBank(true);
        setAccountNumber("");
        setIfsc("");
        setAccountHolderName("");
        setReAccountNumber("");
      } else {
        setIsSavedBank(false);
        notification.error({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });
      }
    }
  };

  const handleBackAddress = () => {
    localStorage.removeItem("return-process");
    setPaymentProcess("basic");
    setCancelReason("");
    setSelectIssue("");
  };

  const handleBackAccount = () => {
    localStorage.removeItem("choose-refund");
    setPaymentProcess("return-process");
    const storedObject = localStorage.getItem("return-process");

    if (storedObject) {
      const obj = JSON.parse(storedObject);
      setCancelReason(obj?.reason);
      setPaymentProcess(obj?.paymentProcess);
      setSelectIssue(obj?.issue);
      setIsReturnOrder(obj?.return);
    }
  };

  const getBankDetail = async () => {
    const { data } = await getBankDetailApi();
    if (data?.success) {
      if (data?.data?.length > 0) {
        setIsSavedBank(true);
        setBankAccountData(data?.data);
        setSavedBankAccount(data?.data[0]);
      }
    }
  };

  const handleChangeBankAccount = (e) => {
    setSavedBankAccount(e.target.label);
  };

  const handleDate = (item) => {
    const filter = order.status.filter((e) => e.status === "Delivered");

    const originalDate = new Date(filter[0]?.created_at);
    const newDate = new Date(originalDate);

    newDate.setDate(originalDate.getDate() + item?.return_cutoff);

    const currentDate = new Date();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = newDate.getDate();
    const monthIndex = newDate.getMonth();

    // Format the date as "day month"
    const formattedDate = `${day} ${monthNames[monthIndex]}`;

    // setEligibleDate(newDate);
    setEligibleDate(formattedDate);
  };

  const handleReturnOrder = (item) => {
    console.log(item);
    setIsReturnOrder(true);
    setSingleItem(item);
    handleDate(item);
  };

  console.log(orderReturnData);

  const handleContinueRetrun = async () => {
    console.log("return flow checking");
    const storedObject = JSON.parse(localStorage.getItem("return-process"));

    const refund = JSON.parse(localStorage.getItem("choose-refund"));

    const { data } = await orderReturnApi(
      returnAddressData,
      storedObject?.reason?.label,
      storedObject?.issue,
      savedBankAccount,
      order
    );
    if (data.success) {
      window.location.reload(false);
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  return (
    <>
      <div>
        <div>
          <br />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Link to={"/my-profile/orders"}>
              <div style={{ alignSelf: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>{" "}
                &nbsp; Back
              </div>
            </Link>
            {/* <div className="color-primary"> </div> */}
          </div>
        </div>
        {Object.keys(order).length !== 0 &&
          order.items.map((item, index) => {
            const filter = order.status.filter((e) => e.status === "Delivered");

            const originalDate = new Date(filter[0]?.created_at);
            const newDate = new Date(originalDate);

            newDate.setDate(originalDate.getDate() + item?.return_cutoff);

            const currentDate = new Date();
            const isNewDatePast = newDate > currentDate;

            return (
              <div key={index}>
                <div
                  text={
                    <div className="order-delivered-badge">
                      {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      className="bi bi-check-all"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                    </svg>{" "}
                    Order Delivered{" "} */}
                    </div>
                  }
                  color="green"
                >
                  <OverlapGroup1
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #D4D4D4",
                    }}
                    key={index}
                  >
                    <Group37002
                      style={{
                        boxShadow: "none",
                        border: "none",
                      }}
                    >
                      {/* <RadioButtons src="/img/" alt="radio-buttons" /> */}
                      {/* <br /> */}

                      <div style={{ display: "flex" }}>
                        <Group36968
                          onClick={() =>
                            history.push(`/my-profile/orders/${item?.order_id}`)
                          }
                          style={{
                            backgroundImage: `url(${"/img/no_image.png"})`,
                          }}
                        >
                          <Link to={`/singleShop?${item && item._id}`}>
                            {item?.images.map(
                              (e) =>
                                e.image_type === "main" && (
                                  <Group36968
                                    style={{
                                      backgroundImage: `url(${e?.url})`,
                                    }}
                                  ></Group36968>
                                )
                            )}
                          </Link>
                        </Group36968>

                        <div
                          style={{
                            marginLeft: "1.7rem",
                            width: "100%",
                            alignSelf: "end",
                            marginBottom: "-9px",
                          }}
                        >
                          <Link to={`/singleShop?${item && item._id}`}>
                            <UrbanoFashion>{item?.name}</UrbanoFashion>
                            <Name1 style={{ marginTop: "-1px" }}>
                              {item?.description}
                            </Name1>
                          </Link>
                          {/* <Spanbox size30M={spanboxProps.size30M} /> */}
                          <div
                            style={{
                              display: "flex",
                              marginTop: " -10px",
                              marginBottom: "-12px",
                              paddingTop: "9px",
                              borderTop: "1px solid #dedede",
                            }}
                          >
                            <Name1
                              style={{ marginBottom: "0" }}
                              className="price-4"
                            >
                              Item Quantity : &nbsp;
                            </Name1>
                            <Name
                              style={{
                                alignSelf: "start",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {item?.quantity}
                            </Name>
                            {item?.size_of_product !== undefined && (
                              <>
                                <Name1
                                  style={{ marginBottom: "0" }}
                                  className="price-4"
                                >
                                  , &nbsp;&nbsp; Item Size : &nbsp;
                                </Name1>
                                <Name
                                  style={{
                                    alignSelf: "start",
                                    fontSize: "14px",
                                    color: "black",
                                  }}
                                >
                                  {item?.size_of_product}
                                </Name>
                              </>
                            )}
                          </div>
                          {/* <div className="quantify-button">
                        <div>
                          <Spanbox
                            style={{ marginRight: "1rem" }}
                            size30M={`Size : ${
                              (item?.size_of_product !== undefined &&
                                item?.size_of_product) ||
                              ""
                            }`}
                          />
                        </div>
                        <div>
                          <Spanbox size30M={`Quantity : ${item?.quantity}`} />
                        </div>
                      </div> */}
                          <div>
                            <div style={{ marginTop: "1rem", display: "flex" }}>
                              <Name1 style={{ marginBottom: "0" }}>
                                {" "}
                                Price: &nbsp;
                              </Name1>{" "}
                              {(item?.warehouses?.MRP ===
                                item?.warehouses?.ASP && (
                                <div className="auto-layout-horizontal-9">
                                  <Price1 className="price-4">
                                    ₹{" "}
                                    {item?.warehouses && item?.warehouses?.ASP}
                                  </Price1>
                                </div>
                              )) || (
                                <div style={{ display: "flex" }}>
                                  <div className="auto-layout-horizontal-9">
                                    <Price
                                      style={{
                                        color: "#8f8f8f",
                                        textDecoration: "line-through",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                      }}
                                      className="price-4"
                                    >
                                      ₹{" "}
                                      {item?.warehouses &&
                                        item?.warehouses?.MRP}{" "}
                                    </Price>
                                  </div>
                                  {/* <Text3 className="text-3-1">
                      &nbsp; &nbsp; – &nbsp;&nbsp;
                    </Text3> */}
                                  <div className="auto-layout-horizontal-10">
                                    <Price1 className="price-5">
                                      &nbsp; ₹
                                      {item?.warehouses &&
                                        item?.warehouses?.ASP}
                                    </Price1>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <div>
                              {(isNewDatePast && (
                                <p className="para">
                                  {(item?.return_cutoff === 0 &&
                                    "No Return available") || (
                                    <>
                                      <span style={{ fontWeight: "bold" }}>
                                        {item?.return_cutoff} days
                                      </span>
                                      &nbsp; &nbsp; Return available
                                    </>
                                  )}
                                </p>
                              )) || <p className="para">No Return available</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Group37002>
                  </OverlapGroup1>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  {isNewDatePast && (
                    <div>
                      {!isReturnOrder && (
                        <button
                          onClick={() => {
                            handleReturnOrder(item),
                            setOrderReturnData(item);
                          }}
                          style={{ padding: "0.6rem 1.6rem" }}
                          className="outline-button outline-primary "
                        >
                          Return
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div>
            {
              // (!isReturnOrder && (
              //   <button
              //     onClick={() => {
              //       setIsReturnOrder(true);
              //     }}
              //     style={{ padding: "0.6rem 1.6rem" }}
              //     className="outline-button outline-primary "
              //   >
              //     Return Order
              //   </button>
              // )) ||
              // ""
              // <button
              //   onClick={(e) => {
              //     handleKeepOrder(e);
              //   }}
              //   style={{ padding: "0.6rem 1.6rem" }}
              //   className="outline-button outline-primary "
              // >
              //   Keep Order
              // </button>
            }
          </div>
        </div>

        <div>
          {isReturnOrder && (
            <div className="d-flex">
              <div>
                <div className="">
                  {/* <divHeader className="bg-transparent ">
                <div className="text-muted text-center ">
                  <small>Sign in with</small>
                </div>
              </divHeader> */}
                  <br />

                  <div className="">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="para">
                        <span className="till-return">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.7002 4.4502L17.6802 6.75018L21.6202 4.46021"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.6802 10.8203V6.74023"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16.7401 2.21L14.3401 3.53996C13.8001 3.83996 13.3501 4.59995 13.3501 5.21995V7.75999C13.3501 8.37999 13.7901 9.13998 14.3401 9.43998L16.7401 10.77C17.2501 11.06 18.0901 11.06 18.6101 10.77L21.0101 9.43998C21.5501 9.13998 22.0001 8.37999 22.0001 7.75999V5.21995C22.0001 4.59995 21.5601 3.83996 21.0101 3.53996L18.6101 2.21C18.1001 1.93 17.2601 1.93 16.7401 2.21Z"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.3501 15.4502L6.3201 17.7502L10.2701 15.4602"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.32031 21.8203V17.7402"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.39 13.21L2.99001 14.54C2.45001 14.84 2 15.5999 2 16.2199V18.76C2 19.38 2.44001 20.14 2.99001 20.44L5.39 21.77C5.9 22.06 6.73999 22.06 7.25999 21.77L9.66 20.44C10.2 20.14 10.65 19.38 10.65 18.76V16.2199C10.65 15.5999 10.21 14.84 9.66 14.54L7.25999 13.21C6.73999 12.93 5.9 12.93 5.39 13.21Z"
                              stroke="#555555"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>

                        <span>
                          &nbsp; Eligible for return till &nbsp;
                          <b> {eligibleDate}</b>
                        </span>
                      </p>

                      {/* <button
                        style={{
                          padding: "0",
                          textTransform: "capitalize",
                        }}
                        className="btn outline-button"
                      >
                        View Policy
                      </button> */}
                    </div>
                    <br />

                    {paymentProcess === "pickup-detail" && (
                      <>
                        <Group37002 style={{ width: "100%" }}>
                          <button
                            onClick={() => handleBackAddress()}
                            style={{
                              fontSize: "14px",
                              padding: "0.3rem 1.3rem",
                              marginBottom: "1rem",
                            }}
                            className="btn outline-button outline-primary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-left"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                              />
                            </svg>{" "}
                            <span>Back</span>
                          </button>
                          <p
                            className="para text-left"
                            style={{
                              width: "100%",
                              fontWeight: "400",
                              fontSize: "17px",
                            }}
                          >
                            Pickup Address{" "}
                            <span style={{ marginLeft: "10px" }}>
                              <Badge
                                style={{
                                  backgroundColor: "#52c41a",
                                }}
                                count={returnAddressData?.address_type}
                              />
                            </span>
                            <span style={{ marginLeft: "10px" }}>
                              <Badge
                                count={
                                  returnAddressData?.is_default
                                    ? "Default"
                                    : null
                                }
                              />
                            </span>
                          </p>
                          <div>
                            <Name1
                              style={{
                                marginTop: "3px",
                              }}
                            >
                              {returnAddressData?.name}, &nbsp; HOUSE NO -{" "}
                              {returnAddressData?.house_no}
                              ,
                              <br />
                              {returnAddressData?.society}
                              ,&nbsp;&nbsp;
                              {returnAddressData?.state}
                              &nbsp;-&nbsp;
                              {returnAddressData?.pincode}
                            </Name1>

                            <div
                              style={{
                                display: "flex",
                                marginTop: "0rem",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  textTransform: "capitalize",
                                }}
                                className="para "
                              >
                                Mobile:
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "400",

                                  lineHeight: "22px",
                                }}
                                className="para"
                              >
                                &nbsp; {order?.user?.mobile}
                              </p>
                            </div>
                            <br />

                            <Button onClick={() => changeAddress()}>
                              Change Pickup Address
                            </Button>
                          </div>
                        </Group37002>

                        <br />
                        <Group37002
                          style={{
                            padding: "0.4rem 1.2rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="delivery-to-box "
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "center",
                                }}
                              ></div>
                              <div>
                                {" "}
                                <p
                                  style={{
                                    lineHeight: "30px",
                                    fontWeight: "300",
                                  }}
                                  className="para text-left"
                                >
                                  {" "}
                                  REFUND DETAILS{" "}
                                </p>
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "18px",
                                  }}
                                  className="para text-left"
                                >
                                  ₹ {order?.amount?.total}
                                </p>
                              </div>
                            </div>{" "}
                            <button
                              className="btn btn-primary"
                              onClick={() => handleStartRefund()}
                              style={{
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            >
                              Return
                            </button>
                          </div>
                        </Group37002>
                      </>
                    )}

                    {paymentProcess === "choose-refund" && (
                      <div>
                        {" "}
                        <Group37002 style={{ width: "100%" }}>
                          <button
                            onClick={() => handleBackAccount()}
                            style={{
                              fontSize: "14px",
                              padding: "0.3rem 1.3rem",
                              marginBottom: "1rem",
                            }}
                            className="btn outline-button outline-primary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-left"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                              />
                            </svg>{" "}
                            <span>Back</span>
                          </button>
                          <h3
                            style={{
                              marginBottom: "1.3rem",
                              textAlign: "left",
                              fontSize: "18px",
                              marginTop: "0.5rem",
                            }}
                            className="second-heading"
                          >
                            Choose a Refund Mode
                          </h3>

                          <br />
                          {(order?.payment?.type === "Bank Payment" && (
                            <>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                }}
                              >
                                {bankAccountData?.length > 0 && (
                                  <Radio.Group
                                    style={{
                                      width: "100%",
                                      marginTop: "0.3rem",
                                    }}
                                    onChange={(e) => handleChangeBankAccount(e)}
                                    value={"online"}
                                  >
                                    <>
                                      <div>
                                        <Radio value={"online"}>
                                          <p
                                            className="para text-left"
                                            style={{
                                              width: "100%",
                                              fontWeight: "500",
                                              fontSize: "17px",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Online Transfer
                                          </p>
                                        </Radio>
                                      </div>

                                      <div
                                        style={{
                                          marginLeft: "1.5rem",
                                        }}
                                      >
                                        <br />
                                        <p
                                          style={{
                                            lineHeight: "25px",
                                            fontSize: "17px",
                                            width: "100%",
                                          }}
                                          className="para text-left para-color"
                                        >
                                          After order cancellation, &nbsp;
                                          <span className="color-primary">
                                            {" "}
                                            Rs. {order?.amount?.total} .00
                                          </span>{" "}
                                          &nbsp; will be credited to <br /> the
                                          chosen bank account. Refund will be
                                          processed to bank account within 1
                                          day.
                                        </p>
                                        <br />
                                        <button
                                          onClick={() => handleContinueRetrun()}
                                          style={{ marginTop: "1rem" }}
                                          className="btn btn-primary"
                                        >
                                          Continue
                                        </button>
                                      </div>
                                    </>
                                  </Radio.Group>
                                )}
                              </div>
                            </>
                          )) || (
                            <>
                              <h3
                                style={{
                                  marginBottom: "0.5rem",
                                  textAlign: "left",
                                  fontSize: "16px",
                                }}
                                className="second-heading"
                              >
                                To Bank Account
                              </h3>

                              <p
                                style={{
                                  lineHeight: "25px",
                                  fontSize: "17px",
                                }}
                                className="para text-left para-color"
                              >
                                After order cancellation, &nbsp;
                                <span className="color-primary">
                                  {" "}
                                  Rs. {order?.amount?.total}
                                  .00
                                </span>{" "}
                                &nbsp; will be credited to the chosen bank
                                account. Refund will be processed to bank
                                account within 1 day.
                              </p>

                              <div
                                style={{ textAlign: "center" }}
                                className="mt-5"
                              >
                                <>
                                  <br />
                                  <br />
                                  {(isSavedBank && (
                                    <>
                                      <p
                                        style={{
                                          lineHeight: "25px",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          textTransform: "uppercase",
                                        }}
                                        className="para text-left"
                                      >
                                        SAVED BANK ACCounts
                                      </p>{" "}
                                      <br />
                                      <div
                                        style={{
                                          width: "60%",
                                          textAlign: "left",
                                        }}
                                      >
                                        {bankAccountData?.length > 0 && (
                                          <Radio.Group
                                            style={{
                                              width: "100%",
                                              marginTop: "0.3rem",
                                            }}
                                            onChange={(e) =>
                                              handleChangeBankAccount(e)
                                            }
                                            value={savedBankAccount?._id}
                                          >
                                            {bankAccountData.map(
                                              (item, index) => (
                                                <Group37002
                                                  key={index}
                                                  style={{
                                                    width: "100%",
                                                    minWidth: "100%",
                                                    textAlign: "left",
                                                    marginBottom: "1rem",
                                                  }}
                                                >
                                                  <div>
                                                    <Radio
                                                      label={item}
                                                      value={item?._id}
                                                    >
                                                      <p
                                                        className="para text-left"
                                                        style={{
                                                          width: "100%",
                                                          fontWeight: "500",
                                                          fontSize: "17px",
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        {item?.account_name}
                                                      </p>
                                                    </Radio>
                                                  </div>

                                                  <div
                                                    style={{
                                                      marginLeft: "1.5rem",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        marginTop: "0.5rem",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "black",
                                                          fontWeight: "300",
                                                          lineHeight: "22px",
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                        className="para "
                                                      >
                                                        ACCOUNT :
                                                      </p>
                                                      <p
                                                        style={{
                                                          color: "black",
                                                          fontWeight: "300",

                                                          lineHeight: "22px",
                                                        }}
                                                        className="para"
                                                      >
                                                        &nbsp;
                                                        {item?.account_number}
                                                      </p>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        marginTop: "0.5rem",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "black",
                                                          fontWeight: "300",
                                                          lineHeight: "22px",
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                        className="para "
                                                      >
                                                        IFSC:
                                                      </p>
                                                      <p
                                                        style={{
                                                          color: "black",
                                                          fontWeight: "300",

                                                          lineHeight: "22px",
                                                        }}
                                                        className="para"
                                                      >
                                                        &nbsp;
                                                        {item?.ifsc_code}
                                                      </p>
                                                    </div>

                                                    <br />
                                                  </div>
                                                </Group37002>
                                              )
                                            )}
                                          </Radio.Group>
                                        )}
                                      </div>
                                      <br />
                                      <br />
                                      <div style={{ display: "flex" }}>
                                        <button
                                          onClick={() => setIsSavedBank(false)}
                                          style={{
                                            marginRight: "1.5rem",
                                            padding: "0.3rem 2.6rem",
                                          }}
                                          className="btn outline-primary outline-button"
                                        >
                                          Add new bank account
                                        </button>
                                        <br />
                                        <div style={{}}>
                                          <button
                                            style={{ width: "100%" }}
                                            onClick={(e) =>
                                              handleContinueRetrun()
                                            }
                                            className="btn btn-primary"
                                          >
                                            Continue
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )) || (
                                    <>
                                      <p
                                        style={{
                                          lineHeight: "25px",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          textTransform: "uppercase",
                                        }}
                                        className="para text-left"
                                      >
                                        Add a new bank account
                                      </p>

                                      <div
                                        style={{
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "68%",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <input
                                            style={{ width: "100%" }}
                                            id="mobileNumber"
                                            value={ifsc}
                                            placeholder="Enter IFSC Code"
                                            type="text"
                                            autoComplete="new-email"
                                            className={`input-group-alternative ${
                                              isError &&
                                              ifsc === "" &&
                                              "error-class"
                                            }`}
                                            onChange={(e) =>
                                              setIfsc(e.target.value)
                                            }
                                          />
                                          <p style={{ textAlign: "left" }}>
                                            <small className="error-red">
                                              {" "}
                                              {isError && ifsc === ""
                                                ? "IFSC Code is Required"
                                                : ""}
                                            </small>
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "68%",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <input
                                            style={{ width: "100%" }}
                                            id="mobileNumber"
                                            placeholder="Enter Account Number "
                                            type="number"
                                            autoComplete="new-email"
                                            className={`input-group-alternative ${
                                              isError &&
                                              accountNumber === "" &&
                                              "error-class"
                                            }`}
                                            value={accountNumber}
                                            onChange={(e) =>
                                              setAccountNumber(e.target.value)
                                            }
                                          />
                                          <p style={{ textAlign: "left" }}>
                                            <small className="error-red">
                                              {" "}
                                              {isError && accountNumber === ""
                                                ? " Account Number is Required"
                                                : ""}
                                            </small>
                                          </p>
                                          {/* <div className="red">
                                              {mobile.length > 10 && (
                                                <>
                                                  <p
                                                    style={{ fontSize: "14px" }}
                                                    className="para"
                                                  >
                                                    This is not a Valid Account
                                                    Number{" "}
                                                    <span
                                                      onClick={(e) =>
                                                        setPhoneDisabled(false)
                                                      }
                                                      className="link-para"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Re-Enter-Number
                                                    </span>{" "}
                                                  </p>
                                                </>
                                              )}
                                            </div> */}
                                        </div>
                                        <div
                                          style={{
                                            width: "68%",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <input
                                            style={{ width: "100%" }}
                                            id="mobileNumber"
                                            value={reAccountNumber}
                                            placeholder="Re-Enter Account Number"
                                            type="number"
                                            autoComplete="new-email"
                                            className={`input-group-alternative ${
                                              isError &&
                                              reAccountNumber === "" &&
                                              "error-class"
                                            }`}
                                            onChange={(e) =>
                                              setReAccountNumber(e.target.value)
                                            }
                                          />
                                          <p style={{ textAlign: "left" }}>
                                            <small className="error-red">
                                              {" "}
                                              {isError && reAccountNumber === ""
                                                ? "RE Enter Account Number"
                                                : ""}
                                            </small>
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "68%",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <input
                                            style={{ width: "100%" }}
                                            id="mobileNumber"
                                            value={accountHolderName}
                                            placeholder="Account Holder Name"
                                            type="text"
                                            autoComplete="new-email"
                                            className={`input-group-alternative ${
                                              isError &&
                                              accountHolderName === "" &&
                                              "error-class"
                                            }`}
                                            onChange={(e) =>
                                              setAccountHolderName(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <p style={{ textAlign: "left" }}>
                                            <small className="error-red">
                                              {" "}
                                              {isError &&
                                              accountHolderName === ""
                                                ? "Account Holder Name is required"
                                                : ""}
                                            </small>
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "68%",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <p
                                            style={{
                                              lineHeight: "25px",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                              textTransform: "uppercase",
                                            }}
                                            className="para text-left"
                                          >
                                            Account Type
                                          </p>
                                          <Radio.Group
                                            style={{
                                              display: "flex",
                                              marginTop: "0.3rem",
                                            }}
                                            value={selectIssue}
                                          >
                                            <div>
                                              <Radio
                                                label={"Saving"}
                                                value={"Saving"}
                                              >
                                                {"Saving"}
                                              </Radio>
                                              <Radio
                                                label={"Current"}
                                                value={"Current"}
                                              >
                                                {"Current"}
                                              </Radio>
                                            </div>
                                          </Radio.Group>

                                          <div
                                            style={{
                                              width: "100%",
                                              textAlign: "left",
                                            }}
                                          ></div>
                                        </div>{" "}
                                      </div>

                                      <br />

                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        {/* <button
                                          onClick={() => false}
                                          style={{
                                            width: "50%",
                                            marginRight: "1rem",
                                          }}
                                          className="btn outline-primary outline-button"
                                        >
                                          Cancel
                                        </button> */}

                                        <button
                                          style={{
                                            marginRight: "1rem",
                                            padding: "0.5rem 2rem",
                                          }}
                                          onClick={() => setIsSavedBank(true)}
                                          className="btn outline-primary outline-button"
                                        >
                                          cancel
                                        </button>
                                        <button
                                          onClick={() => handleAddBankAccount()}
                                          className="btn btn-primary"
                                        >
                                          Add Bank account
                                        </button>
                                        {/* <Spin spinning={updating}></Spin> */}
                                      </div>
                                      <br />
                                      <br />
                                    </>
                                  )}
                                </>
                              </div>
                            </>
                          )}
                        </Group37002>
                      </div>
                    )}

                    {paymentProcess === "basic" && (
                      <div className="reason">
                        <h3
                          style={{
                            marginBottom: "1rem",
                            textAlign: "left",
                            fontSize: "20px",
                          }}
                          className="second-heading"
                        >
                          Why are you returning?
                        </h3>

                        <p
                          style={{ lineHeight: "21px" }}
                          className="para text-left"
                        >
                          Please choose the correct reason for return. This
                          information is only used to improve our service.
                        </p>
                        <br />
                        <p className="para text-left">SELECT REASON *</p>

                        <form onSubmit={(e) => e} role="form">
                          <div style={{ textAlign: "left" }} className="mt-5">
                            <>
                              <Radio.Group
                                style={{
                                  marginTop: "0rem",
                                  width: "100%",
                                }}
                                onChange={onChangeReason}
                                value={cancelReason.value}
                              >
                                {(Object.keys(cancelReason).length === 0 &&
                                  selectRegion.map((item) => (
                                    <div key={item.value}>
                                      <Space direction="vertical">
                                        <Radio
                                          label={item.label}
                                          value={item.value}
                                        >
                                          {item.label}
                                        </Radio>
                                      </Space>
                                    </div>
                                  ))) || (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <Space direction="vertical">
                                          <Radio
                                            label={cancelReason.label}
                                            value={cancelReason.value}
                                          >
                                            {cancelReason.label}
                                          </Radio>
                                        </Space>
                                      </div>
                                      <button
                                        onClick={() => setCancelReason({})}
                                        style={{
                                          padding: "0",
                                          textTransform: "capitalize",
                                        }}
                                        className="btn outline-button"
                                      >
                                        Change
                                      </button>
                                    </div>

                                    <div
                                      style={{
                                        marginTop: "0.5rem",
                                        marginBottom: "0.8rem",
                                      }}
                                      className="hr"
                                    ></div>

                                    <p
                                      style={{ fontWeight: "200" }}
                                      className="para text-left"
                                    >
                                      Select Issue Detail
                                    </p>
                                    <Radio.Group
                                      style={{
                                        marginTop: "0.3rem",
                                      }}
                                      onChange={(item) =>
                                        setSelectIssue(item.target.value)
                                      }
                                      value={selectIssue}
                                    >
                                      {returnRegion[cancelReason.value].map(
                                        (nestItem) => (
                                          <div>
                                            <Space direction="vertical">
                                              <Radio
                                                label={nestItem}
                                                value={nestItem}
                                              >
                                                {nestItem}
                                              </Radio>
                                            </Space>
                                          </div>
                                        )
                                      )}
                                    </Radio.Group>
                                  </>
                                )}{" "}
                              </Radio.Group>

                              <p
                                style={{
                                  textAlign: "left",
                                  paddingTop: "0.5rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "300",
                                  }}
                                  className="error-red"
                                >
                                  {" "}
                                  {(selectIssue === "" && reasonError) ||
                                    (selectRegion === "" && reasonError)}
                                </span>
                              </p>

                              <div style={{ marginTop: "1rem" }}>
                                {/* <label for="otp">Enter OTP</label> */}
                                <textarea
                                  id="otp"
                                  style={{ width: "100%" }}
                                  className="input-group-alternative"
                                  placeholder="Addition Comment"
                                  type="number"
                                  autoComplete="new-email"
                                  value={additionComment}
                                  onChange={(e) =>
                                    setAdditionComment(e.target.value)
                                  }
                                />

                                <Checkbox
                                  style={{ marginTop: "1.5rem" }}
                                  autoFocus={true}
                                  value={"sdjfkasldkf"}
                                  size="large"
                                  onChange={(e)=>setConfirmationCheckbox(e.target.checked)}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: "#555555",
                                      fontWeight: "300",
                                    }}
                                  >
                                    I confirm that the product is unused with
                                    the original tags intact.
                                  </span>
                                </Checkbox>
                              </div>
                              <br />
                              <br />    

                              <Group37002
                                style={{
                                  padding: "0.4rem 1.2rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="delivery-to-box "
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        alignSelf: "center",
                                      }}
                                    ></div>
                                    <div>
                                      {" "}
                                      <p
                                        style={{
                                          lineHeight: "30px",
                                          fontWeight: "300",
                                        }}
                                        className="para text-left"
                                      >
                                        {" "}
                                        REFUND DETAILS{" "}
                                      </p>
                                      <p
                                        style={{
                                          fontWeight: "400",
                                          fontSize: "18px",
                                        }}
                                        className="para text-left"
                                      >
                                        ₹ {order?.amount?.total}
                                      </p>
                                    </div>
                                  </div>{" "}
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => handleSetReason(e)}
                                    style={{
                                      alignSelf: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Pickup Details
                                  </button>
                                </div>
                              </Group37002>
                            </>
                          </div>
                        </form>
                      </div>
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <br />
        {modalOpen && (
          <Modal
            title="Select Address"
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={800}
            footer={null}
          >
            {!addAddressPopup
              ? ""
              : // <Button type="primary" block onClick={addNewAddressFunc}>
                //   <div>Add A New Address</div>
                // </Button>
                null}

            {addAddressPopup ? (
              <div>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="outlined-password-input"
                      label="Name"
                      type="text"
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          name: e.target.value,
                        })
                      }
                    />
                    <TextField
                      id="outlined-password-input"
                      label="House No."
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          house_no: e.target.value,
                        })
                      }
                      //   type="text"
                      //   autoComplete="current-password"
                    />
                    <TextField
                      id="outlined-password-input"
                      label="Society"
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          society: e.target.value,
                        })
                      }
                      //   type="text"
                      //   autoComplete="current-password"
                    />
                    <TextField
                      id="outlined-password-input"
                      label="Block"
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          block: e.target.value,
                        })
                      }
                      //   type="text"
                      //   autoComplete="current-password"
                    />
                    <TextField
                      id="outlined-password-input"
                      label="City"
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          city: e.target.value,
                        })
                      }
                      //   type="text"
                      //   autoComplete="current-password"
                    />
                    <TextField
                      id="outlined-password-input"
                      label="State"
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          state: e.target.value,
                        })
                      }
                      //   type="text"
                      //   autoComplete="current-password"
                    />
                    <TextField
                      id="outlined-password-input"
                      label="PinCode"
                      type="number"
                      onChange={(e) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          pin_code: e.target.value,
                        })
                      }
                      //   autoComplete="current-password"
                    />

                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Address Type
                      </FormLabel>
                      <Radio.Group
                        style={{
                          marginTop: "0.3rem",
                        }}
                        onChange={(event) =>
                          setAddNewAddressData({
                            ...addNewAddressData,
                            address_type: event.target.value,
                          })
                        }
                        value={addNewAddressData?.address_type}
                      >
                        <Space direction="vertical">
                          <Radio label="Home" value="Home">
                            "Home"
                          </Radio>
                        </Space>
                        <Space direction="vertical">
                          <Radio label="Office" value="Office">
                            "Office"
                          </Radio>
                        </Space>
                        <Space direction="vertical">
                          <Radio label="Other" value="Other">
                            "Other"
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </FormControl>

                    <Checkbox
                      {...label}
                      onChange={(event) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          is_default: event.target.checked,
                        })
                      }
                    />
                    <span>Want To mark as default address</span>
                  </div>
                </Box>

                <Button type="primary" onClick={addNewAddressFunc1}>
                  Add
                </Button>

                <Button type="primary" danger onClick={cancellUpdate}>
                  Cancel
                </Button>
              </div>
            ) : null}

            {editAddress &&
              addressApiData?.data?.map((value, index) => {
                return (
                  <>
                    <div>
                      <Group37002 style={{ minWidth: "100%" }}>
                        <div className="delivery-to-box">
                          <div>
                            <span
                              style={{
                                fontWeight: "600",
                                textTransform: "uppercase",
                                fontSize: "17px",
                              }}
                            >
                              {value?.name}{" "}
                              <span style={{ marginLeft: "10px" }}>
                                <Badge
                                  style={{
                                    backgroundColor: "#52c41a",
                                  }}
                                  count={value?.address_type}
                                />
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                <Badge
                                  count={value?.is_default ? "Default" : null}
                                />
                              </span>
                            </span>{" "}
                          </div>
                          <p
                            style={{
                              lineHeight: "25px",
                              marginTop: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                            className="para text-left "
                          >
                            {/* Deliver to{" "} */}
                            {value?.block} {value?.house_no} {value?.society} ,
                            <br />
                            {value?.city} {value?.state} {value?.pincode}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {value?._id == returnAddressData?._id ? (
                              <Checkbox
                                {...label}
                                //  onChange={(event) =>
                                //    setAddNewAddressData({
                                //      ...addNewAddressData,
                                //      default_address: event.target.checked,
                                //    })
                                //  }
                                // defaultChecked={true}
                                defaultChecked={true}
                              />
                            ) : (
                              <Checkbox
                                {...label}
                                onChange={() => selectDefaultAddres(value)}
                                defaultChecked={false}
                              />
                            )}
                            &nbsp;&nbsp;{" "}
                            <span className="para text-left">
                              Use as a Pickup Address
                            </span>
                          </div>
                          <Button
                            className="btn btn-outline"
                            danger
                            onClick={() => editAddressFunc(value)}
                          >
                            Edit
                          </Button>
                        </div>
                        {/* <Button onClick={()=>selectDefaultAddres(value)}>Select</Button> */}
                      </Group37002>
                      <br />
                    </div>
                  </>
                );
              })}

            {!editAddress && !editAddress1 && (
              <div style={{ marginRight: "0.5rem", marginLeft: "0.5rem" }}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div className="select-edit-address">
                    <div>
                      <label htmlFor="name">Name</label>
                      <input
                        style={{ width: "100%" }}
                        id="name"
                        placeholder="Enter Name"
                        type="text"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            name: e.target.value,
                          })
                        }
                        defaultValue={editData?.name}
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>
                    <div>
                      <label htmlFor="House No.">House No.</label>
                      <input
                        style={{ width: "100%" }}
                        id="House No."
                        placeholder="Enter House No."
                        type="text"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        defaultValue={editData?.house_no}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            house_no: e.target.value,
                          })
                        }
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>
                    <div>
                      <label htmlFor="Society.">Society</label>
                      <input
                        style={{ width: "100%" }}
                        id="Society"
                        placeholder="Enter Society"
                        type="text"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        defaultValue={editData?.society}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            society: e.target.value,
                          })
                        }
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>
                    <div>
                      <label htmlFor="Block">Block</label>
                      <input
                        style={{ width: "100%" }}
                        id="Block"
                        placeholder="Enter Block"
                        type="text"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        defaultValue={editData?.block}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            block: e.target.value,
                          })
                        }
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>
                    <div>
                      <label htmlFor="City">City</label>
                      <input
                        style={{ width: "100%" }}
                        id="City"
                        placeholder="Enter City"
                        type="text"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        defaultValue={editData?.city}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            city: e.target.value,
                          })
                        }
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>
                    <div>
                      <label htmlFor="State">State</label>
                      <input
                        style={{ width: "100%" }}
                        id="State"
                        placeholder="Enter State"
                        type="text"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        defaultValue={editData?.state}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            state: e.target.value,
                          })
                        }
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>

                    <div>
                      <label htmlFor="PinCode">PinCode</label>
                      <input
                        style={{ width: "100%" }}
                        id="PinCode"
                        placeholder="Enter PinCode"
                        type="number"
                        autoComplete="new-email"
                        className={`input-group-alternative ${
                          isError && accountHolderName === "" && "error-class"
                        }`}
                        defaultValue={editData?.pincode}
                        onChange={(e) =>
                          setAddressData({
                            ...addressData,
                            pin_code: e.target.value,
                          })
                        }
                      />
                      {/* <p style={{ textAlign: "left" }}>
                    <small className="error-red">
                      {" "}
                      {isError && accountHolderName === ""
                        ? "Invalid Account Number"
                        : ""}
                    </small>
                  </p> */}
                    </div>
                    <div style={{ width: " 63%" }}>
                      <FormControl>
                        <label id="demo-row-radio-buttons-group-label">
                          Address Type
                        </label>
                        <Radio.Group
                          style={{
                            marginTop: "0.3rem",
                          }}
                          onChange={(event) =>
                            setAddressData({
                              ...addressData,
                              address_type: event.target.value,
                            })
                          }
                          defaultValue={editData?.address_type}
                        >
                          <Space direction="vertical">
                            <Radio label="Home" value="Home">
                              Home
                            </Radio>
                          </Space>
                          <Space direction="vertical">
                            <Radio label="Office" value="Office">
                              Office
                            </Radio>
                          </Space>
                          <Space direction="vertical">
                            <Radio label="Other" value="Other">
                              Other
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </FormControl>
                    </div>
                  </div>
                </Box>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <div>
                    <Checkbox
                      {...label}
                      onChange={(event) =>
                        setAddressData({
                          ...addressData,
                          is_default: event.target.checked,
                        })
                      }
                      defaultChecked={editData?.is_default}
                    />
                    {/* <Checkbox {...label} onChange={(event)=>setAddressData({...addressData,is_default:event.target.checked})} defaultChecked={editData?.is_default} /><span>Want To mark as default address</span> */}
                    &nbsp;{" "}
                    <span className="para para-left">
                      Want To mark as default address
                    </span>
                  </div>
                  <div>
                    <button
                      style={{ padding: "0.5rem 1.5rem" }}
                      className="btn outline-button outline-primary"
                      onClick={cancellUpdate}
                    >
                      Cancel
                    </button>

                    <button
                      style={{ padding: "0.5rem 2rem", marginLeft: "1rem" }}
                      className="btn btn-primary"
                      type="primary"
                      onClick={updateData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

const Price1 = styled.div`
  ${InterSemiBoldSapphire14px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Price = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

// const Group37002 = styled.div`
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   margin-top: 1.4rem;
//   margin-bottom: 1.4rem;
//   display: flex;
//   justify-content: space-between;
//   padding: 15px 19px;
//   align-items: flex-start;
//   gap: 8px;
// `;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;
const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const X10CustomerReviews = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  position: absolute;
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  position: relative;
  margin-top: 16px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  padding: 15px 19px;

  min-width: 783px;
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  position: relative;
  margin-top: 0.26px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #c4c4c4;
`;

const Name = styled.div`
  ${ValignTextMiddle}

  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  letter-spacing:1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 151px;

  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;

  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}

  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
      

  
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
    
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default OrderReturn;
