import React from "react";
import styled from "styled-components";

class LiswatchItem extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <LiswatchItem1 className={`liswatch-item ${className || ""}`}>
        <AswatchItemTbay className="aswatch-item-tbay"></AswatchItemTbay>
      </LiswatchItem1>
    );
  }
}

const LiswatchItem1 = styled.div`
  position: relative;
  min-width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid;
  border-color: var(--suva-gray);

`;

const AswatchItemTbay = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  top: 2px;
  left: 2px;
  background-color: var(--black);
  border-radius: 22px;
`;

const AswatchItemTbay1 = styled.div`
  .liswatch-item.liswatch-item-1 & {
    background-color: #c4743f;
  }
`;

const AswatchItemTbay2 = styled.div`
  .liswatch-item.liswatch-item-2 & {
    background-color: #fe4037;
  }
`;

export default LiswatchItem;
