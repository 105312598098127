import axios from "axios";



// Api for Getting address
   export const getAddressApi= async ()=>{
    return await axios.get(`/dev/cart/address/believe`)
   }

//    Api for updating Address
export const updateAddressApi= async (data)=>{
    return await axios.put(`/dev/cart/address/believe`,{
            address_id : data?.id,
            name : data?.name,
            city : data?.city,
            society : data?.society,
            block: data?.block,
            state : data?.state,
            pincode : data?.pin_code,
            house_no : data?.house_no,
            address_type : data?.address_type,
            is_default : data?.is_default     
    })
}

// Api for deleting the address
export const deleteAddressApi= async (address_id)=>{
    return await axios.delete(`/dev/cart/address/believe/${address_id}`);
}

// export const deleteItemFromCartApi= async (data)=>{
//     return await axios.delete(`/dev/cart/item/believe/${data}`)
// }

// Api for AddAddress to cart
export const AddAddressCartApi= async (data)=>{
    return await axios.post(`/dev/cart/address/believe`,
    {
            new_address : true,
            name : data?.name,
            city : data?.city,
            society : data?.society,
            block: data?.block,
            state : data?.state,
            pincode : data?.pin_code,
            house_no : data?.house_no,
            address_type : data?.address_type,
            is_default : data?.is_default ,
    })
}

export const AddAddressCartApiInProfile= async (data)=>{
    return await axios.post(`/dev/cart/address/believe`,
    {
            new_address : true,
            name : data?.name,
            city : data?.city,
            society : data?.society,
            block: data?.block,
            state : data?.state,
            pincode : data?.pin_code,
            house_no : data?.house_no,
            address_type : data?.address_type,
            is_default : (data?.is_default==undefined ? false : data?.is_default) ,
    })
}


export const addOldAddressCartApi= async (data)=>{
    return await axios.post(`/dev/cart/address/believe`,
    {
        new_address:data?.new_address,
        address_id:data?.address_id
    }
    )
}

export const checkServiceablePinCode= async (data)=>{
    return await axios.get(`/dev/pincode/pincode_available?pincode=${data}`)
}

//    export async function sendOtpAgain(mobile) {
//     const data = await axios.post("/dev/authentication/resend-otp", {
//       mobile,
//     });
  
//     return data;
//   }