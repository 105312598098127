import { css } from "styled-components";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import MyProfile from "./components/MyProfile";
import Home from "./components/Home";
import SingleProduct from "./components/SingleProduct";
import Header4 from "./components/Header4";
import Footer from "./components/Footer";
import Cart from "./components/Cart";
import SearchItem from "./components/SearchItem";
import WishList from "./components/WishList";
// import ShopCategory from "./components/Shop/shopCategory";
// import ShopCollection from "./components/Shop";
import Shop from "./components/Shop/Shop";
import About from "./components/Footer/About";
import Terms from "./components/Footer/Terms";
import Privacy from "./components/Footer/Privacy";
import ReturnPolicy from "./components/Footer/ReturnPolicy";
import FilterProductWrapper from "./components/pages/FilterProduct";
import { getAllFiltersApi } from "./components/pages/FilterProduct/FilterProductUtils";
import { Helmet, HelmetProvider } from "react-helmet-async";
import DynamicMetaTag from "./components/Helmet/DynamicMetaTag";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddedProduct: false,
      isAddedCart: false,
      isAddedWishlist: false,
      isAddedCartWish: false,
      isCodPayment: false,
      isOnlinePayment: false,
      searchToggle: false,
      refreshWalletAmountToggle: false,
      walletCartCount: false,
    };
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    getAllFiltersApi().then((data) => {
      localStorage.setItem("allFilters", JSON.stringify(data.data.data));
    });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div>
          <HelmetProvider>
          <Router>
            <Header4 state={this?.state} setState={this?.setState} />
            <div className="_responsive_body">
              <Switch>
                <Route path="/my-profile">
                  <MyProfile {...myProfileData} />
                </Route>
                <Route path="/cart">
                  <Cart
                    {...myProfileData}
                    state={this.state}
                    setState={this.setState}
                  />
                </Route>
                <Route path="/:path(|home)">
                <Helmet>
                    <title>
                    House Of Believe- Buy Clothes for Men, Women & Kids Online 
                    </title>
                    <meta
                      name="description"
                      content="Find a variety of sizes and a wide range of luxury collections of tees, shirts, and pants for women, men, and kids at the best prices"
                    />
                  </Helmet>

                  <Home {...homeData} />
                </Route>
                {/* <Route path="/collections/">
                <ShopCollection {...shopData} />
              </Route> */}
                <Route path="/shop">
                  <Shop {...shopData} />
                </Route>

                {/* <Route path="/category/:id">
                <ShopCategory {...shopData} />
              </Route> */}

                <Route exact path="/singleShop">
                  <SingleProduct
                    {...singleProductData}
                    state={this.state}
                    setState={this.setState}
                  />
                </Route>

                <Route exact path="/faq">
                  <Helmet>
                    <title>
                      House Of Believe- Frequently Asked Questions Page
                    </title>
                    <meta
                      name="description"
                      content="House Of Believe- Online clothing stores, best online Women, Men, and Kids clothes brand in India. Buy clothes at the best prices online in India"
                    />
                  </Helmet>
                  <About></About>
                </Route>

                <Route exact path="/termsandconditions">
                <Helmet>
                    <title>
                    House Of Believe- Terms and Conditions Page 
                    </title>
                    <meta
                      name="description"
                      content="House Of Believe- Best online clothing stores for Women, Men, and Kids, Buy clothes at the best prices in India, Online clothing stores, Shop Now!"
                    />
                  </Helmet>
                  <Terms></Terms>
                </Route>

                <Route exact path="/privacypolicy">
                <Helmet>
                    <title>
                    House Of Believe- Privacy Policy Page 
                    </title>
                    <meta
                      name="description"
                      content="House Of Believe is the best online clothing store for Women, Men, and Kids, Buy graphic T-shirts, Shirts, Shorts, Track pants, Joggers, and many more "
                    />
                  </Helmet>
                  <Privacy></Privacy>
                </Route>
                <Route exact path="/returnpolicy">
                <Helmet>
                    <title>
                    House Of Believe- Return Policy Page 
                    </title>
                    <meta
                      name="description"
                      content="HOB is an online clothing store for a variety of sizes and a wide range of luxury collections of t-shirts, shirts, and pants for women, men, and kids"
                    />
                  </Helmet>
                  <ReturnPolicy></ReturnPolicy>
                </Route>
                <Route path="/search">
                  <SearchItem />
                </Route>
                <Route path="/singleproduct">
                  <SingleProduct {...singleProductData} />
                </Route>
                <Route path="/wishlist">
                  <WishList state={this.state} setState={this.setState} />
                </Route>
               
                <Route path="/productFilter">
                {/* <Helmet>
                <DynamicMetaTag/>
                  </Helmet> */}
                  <FilterProductWrapper {...shopData} />
                </Route>
              </Switch>
            </div>

            <Footer
              image={footer1Data.image}
              className={footer1Data.className}
              footerLinksColumn1Props={footer1Data.footerLinksColumn1Props}
              footerLinksColumn2Props={footer1Data.footerLinksColumn2Props}
              footerLinksColumn3Props={footer1Data.footerLinksColumn3Props}
              footerLinksColumn4Props={footer1Data.footerLinksColumn4Props}
              footerLinksColumn5Props={footer1Data.footerLinksColumn5Props}
            />
          </Router>
        </HelmetProvider>
        </div>
      </div>
    );
  }
}

export default App;
const spanbox1Data = {
  size30M: "Size:  30 M",
};

const footerLinksColumn1Data = {
  heading: "Product",
  text1: "Overview",
  text2: "Features",
  text3: "Solutions",
  text4: "Tutorials",
  text5: "Pricing",
  text6: "Releases",
};

const autoLayoutHorizontal14Data = {
  src: "/img/image@2x.png",
  className: "auto-layout-horizontal-13",
};

const footerLinksColumn2Data = {
  heading: "Company",
  text1: "About us",
  text2: "Careers",
  text3: "Press",
  text4: "News",
  text5: "Media kit",
  text6: "Contact",
  className: "footer-links-column-1",
};

const footerLinksColumn3Data = {
  heading: "Resources",
  text1: "Blog",
  text2: "Newsletter",
  text3: "Events",
  text4: "Help centre",
  text5: "Tutorials",
  text6: "Support",
  className: "footer-links-column-2",
};

const footerLinksColumn4Data = {
  heading: "Social",
  text1: "Twitter",
  text2: "LinkedIn",
  text3: "Facebook",
  text4: "GitHub",
  text5: "AngelList",
  text6: "Dribbble",
  className: "footer-links-column-3",
};

const footerLinksColumn5Data = {
  heading: "Legal",
  text1: "Terms",
  text2: "Privacy",
  text3: "Cookies",
  text4: "Licenses",
  text5: "Settings",
  text6: "Contact",
  className: "footer-links-column-4",
};

const footer1Data = {
  image: "/img/image-9@2x.png",
  footerLinksColumn1Props: footerLinksColumn1Data,
  footerLinksColumn2Props: footerLinksColumn2Data,
  footerLinksColumn3Props: footerLinksColumn3Data,
  footerLinksColumn4Props: footerLinksColumn4Data,
  footerLinksColumn5Props: footerLinksColumn5Data,
};

const myProfileData = {
  account: "ACCOUNT",
  phone: "7210562014",
  name1: "Delivered",
  name2: "On Sat, 22 Apr",
  group36968: "/img/h-1-list-product-5-gallery-1-600x800-jpg@2x.png",
  xreturn: "RETURN",
  urbanoFashion: "Urbano Fashion",
  menBlackSlimFitM: "Men Black Slim Fit Mid-Rise Clean Look Stretchable Jeans",
  rateThisProduct: "Rate this product",
  orders: "ORDERS",
  ordersReturn: "Orders & Return",
  profile: "Profile",
  deerikaWallet: " Wallet",
  address: "Address",
  spanboxProps: spanbox1Data,
  footerProps: footer1Data,
};

const group2Data = {
  className: "group-2",
};

const header32Data = {
  groupProps: group2Data,
};

const title221Data = {
  ourBest: "Shop By",
  forWomens: "SHOP BY CATEGORIES",
};

const title222Data = {
  // ourBest: "Shop",
  forWomens: "FOR HER",
};

const title32Data = {
  ourBest: "Exclusive Collection",
  forWomens: (
    <React.Fragment>
      The Summer
      <br />
      Sale
    </React.Fragment>
  ),
};

const group34Data = {
  className: "group-8",
};

const buttons22Data = {
  group3Props: group34Data,
};

const theBannerData = {
  theBanner: "/img/the-banner.png",
  title32Props: title32Data,
  buttons2Props: buttons22Data,
};

const autoLayoutHorizontal1Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product1Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal1Data,
};

const autoLayoutHorizontal2Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product2Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal2Data,
};

const autoLayoutHorizontal3Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product3Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal3Data,
};

const autoLayoutHorizontal4Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product4Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal4Data,
};

const title223Data = {
  ourBest: "Shop",
  forWomens: "Shop For Women’s",
};

const autoLayoutHorizontal5Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product5Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal5Data,
};

const autoLayoutHorizontal6Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product6Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal6Data,
};

const autoLayoutHorizontal7Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product7Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal7Data,
};

const autoLayoutHorizontal8Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product8Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal8Data,
};

const autoLayoutHorizontal9Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product9Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal9Data,
};

const autoLayoutHorizontal10Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product10Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal10Data,
};

const autoLayoutHorizontal11Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product11Data = {
  className: "product-2",
  autoLayoutHorizontalProps: autoLayoutHorizontal11Data,
};

const autoLayoutHorizontal12Data = {
  src: "/img/image-11@2x.png",
  className: "",
};

const product12Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal12Data,
};

const title224Data = {
  forWomens: "Our Best",
  ourBest: "Collections this week",
};

const bannerStyle2Data = {
  banner_Style_2: "/img/image-23@2x.png",
  hp7CategoriesWomanPng: "/img/image-24@2x.png",
  hp7CategoriesSummerPng: "/img/image-25@2x.png",
  hp7CategoriesBagPng: "/img/image-26@2x.png",
  hp7CategoriesSunglassPng: "/img/image-27@2x.png",
  hp7CategoriesBasicPng: "/img/image-28@2x.png",
};

const bannerStyle22Data = {
  banner_Style_2: "/img/image-23@2x.png",
  hp7CategoriesWomanPng: "/img/image-24@2x.png",
  hp7CategoriesSummerPng: "/img/image-25@2x.png",
  hp7CategoriesBagPng: "/img/image-26@2x.png",
  hp7CategoriesSunglassPng: "/img/image-27@2x.png",
};

const bannerStyle23Data = {
  banner_Style_2: "/img/image-23@2x.png",
  hp7CategoriesWomanPng: "/img/image-24@2x.png",
  hp7CategoriesSummerPng: "/img/image-25@2x.png",
  hp7CategoriesBagPng: "/img/image-26@2x.png",
};

const bannerStyle24Data = {
  banner_Style_2: "/img/image-23@2x.png",
  overlapGroup: "/img/image-24@2x.png",
  hp7CategoriesSummerPng: "/img/image-25@2x.png",
};

const title425Data = {
  className: "title-4",
};

const bannerStyle25Data = {
  banner_Style_2: "/img/image-23@2x.png",
  overlapGroup1: "/img/image-24@2x.png",
  title4Props: title425Data,
};

const title426Data = {
  className: "title-5",
};

const bannerStyle26Data = {
  banner_Style_2: "/img/image-23@2x.png",
  title4Props: title426Data,
};

const autoLayoutVertical32Data = {
  title22Props: title224Data,
  bannerStyle2Props: bannerStyle2Data,
  bannerStyle22Props: bannerStyle22Data,
  bannerStyle23Props: bannerStyle23Data,
  bannerStyle24Props: bannerStyle24Data,
  bannerStyle25Props: bannerStyle25Data,
  bannerStyle26Props: bannerStyle26Data,
};

const title225Data = {
  ourBest: "Woman footwear spacial",
  forWomens: "For Women’s",
};

const footerLinksColumn6Data = {
  heading: "Product",
  text1: "Overview",
  text2: "Features",
  text3: "Solutions",
  text4: "Tutorials",
  text5: "Pricing",
  text6: "Releases",
};

const footerLinksColumn7Data = {
  heading: "Company",
  text1: "About us",
  text2: "Careers",
  text3: "Press",
  text4: "News",
  text5: "Media kit",
  text6: "Contact",
  className: "footer-links-column-6",
};

const footerLinksColumn8Data = {
  heading: "Resources",
  text1: "Blog",
  text2: "Newsletter",
  text3: "Events",
  text4: "Help centre",
  text5: "Tutorials",
  text6: "Support",
  className: "footer-links-column-7",
};

const footerLinksColumn9Data = {
  heading: "Social",
  text1: "Twitter",
  text2: "LinkedIn",
  text3: "Facebook",
  text4: "GitHub",
  text5: "AngelList",
  text6: "Dribbble",
  className: "footer-links-column-8",
};

const footerLinksColumn10Data = {
  heading: "Legal",
  text1: "Terms",
  text2: "Privacy",
  text3: "Cookies",
  text4: "Licenses",
  text5: "Settings",
  text6: "Contact",
  className: "footer-links-column-9",
};

const footer2Data = {
  image: "/img/image-9@2x.png",
  className: "footer-1",
  footerLinksColumn1Props: footerLinksColumn6Data,
  footerLinksColumn2Props: footerLinksColumn7Data,
  footerLinksColumn3Props: footerLinksColumn8Data,
  footerLinksColumn4Props: footerLinksColumn9Data,
  footerLinksColumn5Props: footerLinksColumn10Data,
};

const homeData = {
  banner1: "/img/banner.png",
  autoLayoutVertical: "/img/auto-layout-vertical.png",
  image1: "/img/image-44@2x.png",
  butterflyMoes: "Butterfly Moes",
  price1: "$59.00",
  image2: "/img/image-45@2x.png",
  freewillWarriors: "Freewill Warriors",
  price2: "$59.00",
  image3: "/img/image-46@2x.png",
  pantherFan: "Panther Fan",
  price3: "$59.00",
  image4: "/img/image-47@2x.png",
  stilletoeHeels: "Stilletoe Heels",
  price4: "$59.00",
  banner2: "/img/banner-1.png",
  header32Props: header32Data,
  title221Props: title221Data,
  title222Props: title222Data,
  theBannerProps: theBannerData,
  product1Props: product1Data,
  product2Props: product2Data,
  product3Props: product3Data,
  product4Props: product4Data,
  title223Props: title223Data,
  product5Props: product5Data,
  product6Props: product6Data,
  product7Props: product7Data,
  product8Props: product8Data,
  product9Props: product9Data,
  product10Props: product10Data,
  product11Props: product11Data,
  product12Props: product12Data,
  autoLayoutVertical32Props: autoLayoutVertical32Data,
  title224Props: title225Data,
  footerProps: footer2Data,
};

const frame1Data = {
  children: "Price",
};

const frame2Data = {
  children: "Size",
  className: "frame-21",
};

const checkbox22Data = {
  className: "active-no-dark-mode-no-2",
};

const checkbox23Data = {
  className: "active-no-dark-mode-no-3",
};

const checkbox24Data = {
  className: "active-no-dark-mode-no-4",
};

const checkbox25Data = {
  className: "active-no-dark-mode-no-5",
};

const autoLayoutHorizontal13Data = {
  src: "/img/image@2x.png",
  className: "auto-layout-horizontal-12",
};

const product13Data = {
  className: "product-3",
  autoLayoutHorizontalProps: autoLayoutHorizontal13Data,
};

const product14Data = {
  className: "product-1",
  autoLayoutHorizontalProps: autoLayoutHorizontal14Data,
};

const autoLayoutHorizontal15Data = {
  src: "/img/image@2x.png",
  className: "auto-layout-horizontal-14",
};

const product15Data = {
  className: "product-1",
  autoLayoutHorizontalProps: autoLayoutHorizontal15Data,
};

const footerLinksColumn11Data = {
  heading: "Product",
  text1: "Overview",
  text2: "Features",
  text3: "Solutions",
  text4: "Tutorials",
  text5: "Pricing",
  text6: "Releases",
};

const footerLinksColumn12Data = {
  heading: "Company",
  text1: "About us",
  text2: "Careers",
  text3: "Press",
  text4: "News",
  text5: "Media kit",
  text6: "Contact",
  className: "footer-links-column-10",
};

const footerLinksColumn13Data = {
  heading: "Resources",
  text1: "Blog",
  text2: "Newsletter",
  text3: "Events",
  text4: "Help centre",
  text5: "Tutorials",
  text6: "Support",
  className: "footer-links-column-11",
};

const footerLinksColumn14Data = {
  heading: "Social",
  text1: "Twitter",
  text2: "LinkedIn",
  text3: "Facebook",
  text4: "GitHub",
  text5: "AngelList",
  text6: "Dribbble",
  className: "footer-links-column-12",
};

const footerLinksColumn15Data = {
  heading: "Legal",
  text1: "Terms",
  text2: "Privacy",
  text3: "Cookies",
  text4: "Licenses",
  text5: "Settings",
  text6: "Contact",
  className: "footer-links-column-13",
};

const footer3Data = {
  image: "/img/image-8@2x.png",
  className: "footer-2",
  footerLinksColumn1Props: footerLinksColumn11Data,
  footerLinksColumn2Props: footerLinksColumn12Data,
  footerLinksColumn3Props: footerLinksColumn13Data,
  footerLinksColumn4Props: footerLinksColumn14Data,
  footerLinksColumn5Props: footerLinksColumn15Data,
};

const shopData = {
  overlapGroup2: "/img/breadcrumbs-woo-jpg.png",
  title: "Shop",
  place1: "Home",
  shop: "Shop",
  banner1: "/img/banner-1-1.png",
  showing112Of32Results: "Showing 1–12 of 32 results",
  sortBy: "Sort by:",
  defaultSorting: "Default sorting",
  image: "/img/image-49.png",
  price1: "Price:",
  price2: "$200",
  text27: " —",
  price3: "$470",
  filter: "FILTER",
  xl: "XL",
  text28: "(6)",
  m: "M",
  text29: "(6)",
  price4: "S",
  text30: "(6)",
  productCategories: "Product Categories",
  place2: "Mens",
  text31: "(6)",
  women: "Women",
  text32: "(6)",
  kids: "Kids",
  text33: "(6)",
  number1: "1",
  number2: "2",
  number3: "3",
  frame1Props: frame1Data,
  frame2Props: frame2Data,
  checkbox21Props: checkbox22Data,
  checkbox22Props: checkbox23Data,
  checkbox23Props: checkbox24Data,
  checkbox24Props: checkbox25Data,
  product1Props: product13Data,
  product2Props: product14Data,
  product3Props: product15Data,
  footerProps: footer3Data,
};

const header43Data = {
  className: "header-2",
};

const spanbox2Data = {
  size30M: "Size:  30M",
  className: "spanbox-1",
};

const size1Data = {
  spanboxProps: spanbox2Data,
};

const spanbox3Data = {
  size30M: "QTY:  1",
  className: "spanbox-2",
};

const size2Data = {
  className: "qty",
  spanboxProps: spanbox3Data,
};

const liswatchItem2Data = {
  className: "liswatch-item-1",
};

const liswatchItem3Data = {
  className: "liswatch-item-2",
};

const title6Data = {
  ourBest: "Top",
  forWomens: "Related Products",
};

const autoLayoutHorizontal16Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product16Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal16Data,
};

const autoLayoutHorizontal17Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product17Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal17Data,
};

const autoLayoutHorizontal18Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product18Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal18Data,
};

const autoLayoutHorizontal19Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product19Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal19Data,
};

const autoLayoutHorizontal20Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product20Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal20Data,
};

const autoLayoutHorizontal21Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product21Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal21Data,
};

const autoLayoutHorizontal22Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product22Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal22Data,
};

const autoLayoutHorizontal23Data = {
  src: "/img/image@2x.png",
  className: "header-1",
};

const product23Data = {
  autoLayoutHorizontalProps: autoLayoutHorizontal23Data,
};

const footerLinksColumn16Data = {
  heading: "Product",
  text1: "Overview",
  text2: "Features",
  text3: "Solutions",
  text4: "Tutorials",
  text5: "Pricing",
  text6: "Releases",
};

const footerLinksColumn17Data = {
  heading: "Company",
  text1: "About us",
  text2: "Careers",
  text3: "Press",
  text4: "News",
  text5: "Media kit",
  text6: "Contact",
  className: "footer-links-column-14",
};

const footerLinksColumn18Data = {
  heading: "Resources",
  text1: "Blog",
  text2: "Newsletter",
  text3: "Events",
  text4: "Help centre",
  text5: "Tutorials",
  text6: "Support",
  className: "footer-links-column-15",
};

const footerLinksColumn19Data = {
  heading: "Social",
  text1: "Twitter",
  text2: "LinkedIn",
  text3: "Facebook",
  text4: "GitHub",
  text5: "AngelList",
  text6: "Dribbble",
  className: "footer-links-column-16",
};

const footerLinksColumn20Data = {
  heading: "Legal",
  text1: "Terms",
  text2: "Privacy",
  text3: "Cookies",
  text4: "Licenses",
  text5: "Settings",
  text6: "Contact",
  className: "footer-links-column-17",
};

const footer4Data = {
  image: "/img/image-9@2x.png",
  className: "footer-3",
  footerLinksColumn1Props: footerLinksColumn16Data,
  footerLinksColumn2Props: footerLinksColumn17Data,
  footerLinksColumn3Props: footerLinksColumn18Data,
  footerLinksColumn4Props: footerLinksColumn19Data,
  footerLinksColumn5Props: footerLinksColumn20Data,
};

const singleProductData = {
  navbarLinkPlace: "HOME",
  navbarLinkText41: "  /",
  navbarLinkShop: "SHOP",
  navbarLinkText42: "  /",
  navbarLinkAccessories: "ACCESSORIES",
  navbarLinkText43: "  /",
  navbarLinkBlueNavyBag: "BLUE NAVY BAG",
  shop: "Shop",
  h1ListProduct5Gallery1600X800Jpg:
    "/img/h-1-list-product-5-gallery-1-600x800-jpg@2x.png",
  h1ListProduct5Gallery2600X800Jpg:
    "/img/h-1-list-product-5-gallery-2-600x800-jpg@2x.png",
  h1ListProduct5Gallery3600X800Jpg:
    "/img/h-1-list-product-5-gallery-3-600x800-jpg@2x.png",
  h1ListProduct5Gallery4600X800Jpg:
    "/img/h-1-list-product-5-gallery-4-600x800-jpg@2x.png",
  h1ListProduct5Gallery5600X800Jpg:
    "/img/h-1-list-product-5-gallery-5-600x800-jpg@2x.png",
  h1ListProduct5Jpg: "/img/h-1-list-product-5-jpg.png",
  surname: "Cotton Parka with Faux",
  x10CustomerReviews: "(10 customer reviews)",
  price: "$290.00",
  balancingLaidbackL:
    "Balancing laidback LA sensibilities with a rock & roll New York edge, Joe’s Jeans crafts flawless, premium",
  qualityPairsSince:
    "quality pairs. Since 2001, a distinctive, fit-first approach to classic silhouettes has made the brand a",
  forerunnerInPremiumDenim: "forerunner in premium denim",
  x79OrganicCotton:
    "79% organic cotton, 13% lyocell, 6% polyester, 2% elastane",
  addToCart: "Add To Cart",
  viewCart: "View Cart",
  addToWishlist: "Add to wishlist",
  deliveryReturn15Days: "Delivery Return 15 days",
  sizeGuide: "Size Guide",
  sku: "SKU:",
  megaJewe11: "MEGA-JEWE-11",
  categories: "Categories:",
  accessories: "Accessories",
  text44: ",",
  dresses: "Dresses",
  text45: ",",
  shirt: "Shirt",
  tags: "Tags:",
  anklets: "Anklets",
  text46: ",",
  bracelets: "Bracelets",
  text47: ",",
  jewelry: "Jewelry",
  brand: "Brand:",
  name: "Elsa Peretti®",
  deliveryOptions: "DELIVERY OPTIONS",
  inputType: "number",
  inputPlaceholder: "Enter Pin Code",
  check: "Check",
  description: "Description",
  additionalInformation: "Additional information",
  reviews0: "Reviews (0)",
  loremIpsumDolorSi:
    "Lorem ipsum dolor sit amet, saepe vitae gubergren pro cu, sit eros animal invidunt ei, ex nec munere legere incorrupte. Eum an vocent commodo referrentur,",
  noVeniamMaiestatis:
    "no veniam maiestatis vim. Est verear nusquam delicata id, mea natum movet eripuit at. Eu quot virtute usu, nonumy eruditi suscipit duo et. Nam eu dicta",
  tamquamApeirianHa:
    "tamquam apeirian, has ei verear periculis vituperatoribus. Est summo nemore ne. Nam ex posse argumentum eloquentiam, saepe similique interpretaris nec",
  exRidensSuscipitScriptoremExMel: "ex, ridens suscipit scriptorem ex mel.",
  header4Props: header43Data,
  size1Props: size1Data,
  size2Props: size2Data,
  liswatchItem1Props: liswatchItem2Data,
  liswatchItem2Props: liswatchItem3Data,
  title6Props: title6Data,
  product1Props: product16Data,
  product2Props: product17Data,
  product3Props: product18Data,
  product4Props: product19Data,
  product5Props: product20Data,
  product6Props: product21Data,
  product7Props: product22Data,
  product8Props: product23Data,
  footerProps: footer4Data,
};
