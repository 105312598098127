import { dev } from "./baseurl";

export async function orderListApi() {
  const data = await dev.get("/orders/believe-orders/");
  return data;
}

export async function returnListApi() {
  const data = await dev.get("/orders/return/requests/");
  return data;
}

export async function SingleOrderApi(id) {
  const data = await dev.get(`/orders/believe-orders/`, {
    params: {
      order_id: id,
    },
  });
  return data;
}

export async function cancelOrder(orderId, cancelReasion, nestedCancelItem) {
  // quantity: nestedCancelItem[0]?.quantity,
  // item_id: nestedCancelItem[0]?._id,
  const data = await dev.post(`/orders/believe/cancel/order`, {
    order_id: orderId,
    reason: cancelReasion,
  });
  return data;
}

export async function orderReturnList(orderId, cancelReasion) {
  const data = await dev.get(`/orders/return/requests/`);
  return data;
}

// order_id: "",

export async function orderReturnApi(
  address,
  reason,
  issue,
  savedBankAccount,
  order
) {

  // console.log(order);
  const mapItem = order?.items.map((e) => {
    const { _id, quantity } = e;
    return {
      item_id: _id,
      quantity: quantity,
    };
  });

  console.log(mapItem);

  const orderDetail = {
    order_id: order?.order_id?.toString(),
    reason: reason,
    remarks: issue,
    pick_from_delivery_address: true,
    pickup_address: { ...address },
    items: mapItem,
  };



  let postData = {
    ...orderDetail,
  };

  if (order?.payment?.payment_mode === "COD") {
    postData = {
      ...orderDetail,
      bank_details: { ...savedBankAccount },
    };
  }

  console.log(postData);

  const data = await dev.post(`/orders/return/believe`, postData);
  return data;
}
