export const sizeChartColumnsList = [{
    title: 'Size',
    dataIndex: 'size',
    key: 'name',
  },
//   {
//     title: 'Age',
//     dataIndex: 'age',
//     key: 'age',
//   },
//   {
//     title: 'Address',
//     dataIndex: 'address',
//     key: 'address',
//   },
];

export const sizeChartDataSource = [{
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
];

const capitalize = (str) => {
    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");

}

export const createSizeChartTableFormat = (sizesData) => {
    console.log(sizesData);
    const columnsList = {
        in: Object.keys(sizesData[0])
            .filter((key) => key.substring(key.lastIndexOf("_") + 1, key.length) !== 'cm')
            .map((key) => {
                console.log(key);
                return {
                    key,
                    dataIndex: key,
                    title: capitalize(key.split("_").join(" "))
                }
            }),
            // .map((key) => capitalize(key.split("_").join(" "))),
        cm: Object.keys(sizesData[0])
            .filter((key) => key.substring(key.lastIndexOf("_") + 1, key.length) !== 'in')
            .map((key) => {
                return {
                    key,
                    dataIndex: key,
                    title: capitalize(key.split("_").join(" ")),
                }
            }),
            // .map((key) => capitalize(key.split("_").join(" ")))
    }

    const dataSource = sizesData.map((size, i) => {
        return {
            key: i,
            ...size
        }
    })
    console.log(dataSource);
    console.log(columnsList);

    // console.log('sizeqwerty', columnsList, dataSource);

    return {dataSource, columnsList};

}