import React from "react";
import { format } from "date-fns";
import styled from "styled-components";
import formateDate from "../../../api/formateDate";
const MapStatus = ({ item, overallStatus }) => {
  return (
    <>
      {Array.from(new Set(item?.status?.map((e) => e?.status))).map(
        (status) => {
          const uniqueItem = item?.status?.find((e) => e?.status === status);
          return (
            uniqueItem.status === overallStatus && (
              <div
                style={{
                  display: "flex",
                }}
              >
                <Name1 style={{ marginBottom: "0" }} className="price-4">
                  {uniqueItem.status}&nbsp; on :&nbsp;&nbsp;
                </Name1>
                <Name
                  style={{
                    alignSelf: "start",
                    fontSize: "14px",
                    color:"rgb(231, 80, 46)"
                  }}
                >
                  {formateDate(uniqueItem?.created_at)}
                </Name>
              </div>
            )
          );
        }
      )}
    </>
  );
};

const Name1 = styled.div`
  letter-spacing: 1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name = styled.div`
  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;
export default MapStatus;
