import React, { useEffect, useState } from "react";
import Header32 from "../Header32";
import Title22 from "../Title22";
import CatBanner from "../CatBanner";
import TheBanner from "../TheBanner";
import Product from "../Product";
import Footer from "../Footer";
import styled from "styled-components";
import { Typekit } from "react-typekit";
import CustomCarousel from "./Caroseltem.jsx";
import { Carousel, Col, Row } from "antd";
import {
  PoppinsMediumSonicSilver179px,
  PoppinsSemiBoldWhite179px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./Home.css";
import { api, dev } from "../../api/baseurl";
import BannerStyle2 from "../BannerStyle2";

import axios from "axios";
import { getCollectionsApi } from "../../api/collections";
// import { getBannerApi, getSubcategoryApi } from "../../api/category";

import { getSubCategoryNestedApi, getSubcategoryApi } from "../../api/category";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getBannerApi } from "../../api/category";

const Home = (props) => {
  const [collections, setColections] = useState([]);
  const [byCategory, setByCategory] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const [bottomBanner, setBottomBanner] = useState([]);
  const [menCollection, setMenCollection] = useState([]);
  const [womenCollection, setWomenCollection] = useState([]);
  const [kidCollection, setKidCollection] = useState([]);
  const [women1, setWomen1] = useState();
  const [men1, setMen1] = useState();
  const [kid1, setKid1] = useState();
  const [offerBanner, setOfferBanner] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchSubCategory = async () => {
    const { data } = await getSubcategoryApi();

    if (data.success) {
      setByCategory(data?.data);

      const filterWomen = data?.data.filter(
        (item) => item?.name === "Womens"
      )[0];

      const filterMen = data?.data.filter(
        (item) => item?.name === "Fashion"
      )[0];
      const FilterKid = data?.data.filter(
        (item) => item?.name === "Apparels"
      )[0];

      setWomen1(filterWomen);
      setMen1(filterMen);
      setKid1(FilterKid);

      // For Dev
      // const women = await getSubCategoryNestedApi(filterWomen?._id, 1, 4);
      // const men = await getSubCategoryNestedApi(filterMen?._id, 1, 8);
      // const kid = await getSubCategoryNestedApi(FilterKid?._id, 1, 8);


      // For Qa
      const women = await getSubCategoryNestedApi(
        "64e5c832828785b406a99e51",

        1,
        10
      );
      const men = await getSubCategoryNestedApi(
        "64e5a6eb828785b406a99e50",

        1,
        10
      );
      const kid = await getSubCategoryNestedApi(
        "639c7325f75468b349446ed7",

        1,
        10
      );

      setMenCollection(men?.data?.data?.items);
      setWomenCollection(women?.data?.data?.items);
      setKidCollection(kid?.data?.data?.items);
    }
  };

  const bannerCarouselProperties = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const collectionCarouselProperties = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const fetchCollections = async () => {
    const { data } = await getCollectionsApi();
    if (data.success) {
      setColections(data?.data);
    }
 
  };



  const handRedirect = (e) => {
    e.preventDefault();
  };

  const getBanner = async () => {
    const { data } = await getBannerApi();
    if (data.success) {
      setCarousel(data?.data?.Topbanner);
      setBottomBanner(data?.data?.Bottombanner);
      setOfferBanner(data?.data?.Offerbanner);
    }
  };

  useEffect(() => {
    getBanner();
    fetchCollections();
    fetchSubCategory();
    localStorage.removeItem("collection");
    localStorage.removeItem("category");
    localStorage.removeItem("item");
  }, [0]);
  const {
    title221Props,
    title222Props,
    theBannerProps,
    product1Props,
    title223Props,
    autoLayoutVertical32Props,
  } = props;


  // const carousel = [
  //   { image: banner1 },
  //   {
  //     image:
  //       "https://cdn.shopify.com/s/files/1/0616/4670/0764/files/4_91c27887-129f-4a6f-bb8e-5cca8e5c7d0a_2940x.progressive.jpg?v=1677063502",
  //   },
  //   {
  //     image:
  //       "https://cdn.shopify.com/s/files/1/0616/4670/0764/files/1_701224af-1934-446e-9de7-37ed7a621258_2940x.progressive.jpg?v=1659419397",
  //   },
  //   {
  //     image:
  //       "https://cdn.shopify.com/s/files/1/0616/4670/0764/products/3_2940x.progressive.jpg?v=1669897437",
  //   },
  // ];

  return (
    <form className="home screen" name="form4" action="form4" method="post">

      <Carousel autoplay>
        {carousel.length &&
          carousel.map((item, index) => (
            <div key={index}>
              <h3
                style={{
                  margin: 0,
                  // height: "646px",
                  color: "#000",
                  lineHeight: "160px",
                  textAlign: "center",
                  background: "white",
                }}
              >
                <Link to="/productFilter?category=all">
                  {/* <Banner
                    className="slidebannerimg"
                    style={{ background<Banner
                    className="slidebannerimg"
                    style={{ backgroundImage: `url(${item.image})`,height:"100%" }}
                    // style={{}}
                  ></Banner>Image: `url(${item.image})`,height:"100%" }}
                    // style={{}}
                  ></Banner> */}
                  <img src={item.image} width='100%' />
                </Link>
              </h3>
            </div>
          ))}
      </Carousel>

      <AutoLayoutVertical className="collection-name" style={{marginTop: "40px"}}>
        {/* <Title22
          // style={{background:"black","font-family":"brandon-grotesque"}}
          ourBest={title221Props.ourBest}
          forWomens={title221Props.forWomens}
        /> */}
{/* className="collection-name */}
<h2 className="title1 heading text-center home-page-title">SHOP BY CATEGORIES</h2>


{/* <div className="title-name">SHOP BY CATEGORIES</div> */}

        <AutoLayoutHorizontal className="cat-wrap">
          {/* <a href="https://imgbb.com/"><img src="https://i.ibb.co/wLLYxYs/2.png" alt="2" border="0"></a> */}
          {/* <a href="https://ibb.co/vD6JJ4F"><img src="https://i.ibb.co/251jj39/Microsoft-Teams-image-14.jpg" alt="Microsoft-Teams-image-14" border="0"></a> */}
          <CatBanner
            className="cat-banner"
            src={"https://i.ibb.co/251jj39/Microsoft-Teams-image-14.jpg"}
            item={women1}
            name="WOMEN"
            id="64e5c832828785b406a99e51"
            name1="For Her"
          />
          {/* <a href="https://ibb.co/h99RDsv"><img src="https://i.ibb.co/mTTC6J7/Microsoft-Teams-image-12.jpg" border="0"></a> */}
          <CatBanner
            className="cat-banner"
            src={"https://i.ibb.co/mTTC6J7/Microsoft-Teams-image-12.jpg"}
            item={men1}
            name="MEN"
            id="64e5a6eb828785b406a99e50"
            name1="For Him"
          />
          {/* <a href="https://ibb.co/0fF8X21"><img src="https://i.ibb.co/8jdTBsG/Microsoft-Teams-image-13.jpg" alt="Microsoft-Teams-image-13" border="0"></a> */}
          <CatBanner
            className="cat-banner"
            src={"https://i.ibb.co/8jdTBsG/Microsoft-Teams-image-13.jpg"}
            item={kid1}
            name="KIDS"
            id="639c7325f75468b349446ed7"
            name1="For Them"
          />
        </AutoLayoutHorizontal>
      </AutoLayoutVertical>


  

{offerBanner?.length > 0 && 
<>
<AutoLayoutVertical style={{ marginTop: "5rem" }}>
        {/* <Title22 ourBest={"Shop By"} forWomens={"SHOP BY COLLECTION"} /> */}
        <h2 className="title1 heading text-center home-page-title">SHOP BY COLLECTION</h2>
      </AutoLayoutVertical>
      <br />
      <br />
      <br />

      <Carousel {...bannerCarouselProperties} arrows={true} dots={true}>
        {offerBanner &&
          offerBanner.map((item, index) => (
            <div
              key={index}
              style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            >
              <Link to="/productFilter?category=all">
                <div style={{ padding: '24px 10px' }}>
                <img className="offer-image" src={item?.image} />
                </div>
              </Link>
            </div>
          ))}
      </Carousel>
</>
}

     

      <br />
      <br />
      <br />

      {collections?.length > 0 && 
      <>
        <AutoLayoutVerticalCollection>            
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
        <h2 className="title1 heading text-center home-page-title">TOP PICKS OF THE DAY</h2>


        </div>
      </AutoLayoutVerticalCollection>


      <Carousel {...collectionCarouselProperties} arrows={true} dots={true} style={{marginBottom:"35px"}}>
        {
          collections?.map((item, index) =>   item?.collection_type === "collection" &&
          item?.item_details?.length > 0 && item?.collection_details?.properties?.background_image && (
            <div
              key={item?.collection_details?._id}
              style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            >
              <Link to={`/shop/collections?${item?.collection_details?._id}`}>
                <div style={{ padding: '24px 10px' }}>
                <img className="offer-image" style={{height: "266px"}} src={item?.collection_details?.properties?.background_image} />
                </div>
              </Link>
            </div>
          ))}
      </Carousel>
      </>
      } 

    


{/* older collection details */}
      {/* <div className="collection-product-item" style={{ position: "relative",color:"white" }}>


        {collections?.map(
          (collection, index) =>
            collection?.collection_type === "collection" &&
            collection?.item_details?.length && (
              <div key={index}>

                <div
                  style={{
                    backgroundSize: "cover",
                    backgroundImage: `url(${collection?.collection_details?.properties?.background_image})`,
                  }}
                >
                    <>
                      <div>
                        <CustomCarousel
                          url={`/shop/collections?${collection?.collection_details?._id}`}
                          name="kids"
                          id="639c7325f75468b349446ed7"
                          catItem={collection?.item_details}
                          fetchSubCategory={fetchCollections}
                          product1Props={product1Props}
                        />
                      </div>
                    </>
                </div>
              </div>
            )
        )}
      </div> */}

{womenCollection?.length > 0 && 
<>
<AutoLayoutVertical>
        {/* <Title22 forWomens={title222Props.forWomens} /> */}
        <h2 className="title1 heading text-center home-page-title">FOR HER</h2>

      </AutoLayoutVertical>

      <CustomCarousel
        name="Women"
        url='/productFilter?category=WOMEN&only=WOMEN'
        id="64e5c832828785b406a99e51"
        catItem={womenCollection}
        fetchSubCategory={fetchSubCategory}
        product1Props={product1Props}
      />
</>
}
    

      {/* <AutoLayoutVertical>
        <AutoLayoutHorizontal1 className="bnfo">
          <TheBanner
            theBanner={theBannerProps.theBanner}
            title32Props={theBannerProps.title32Props}
            buttons2Props={theBannerProps.buttons2Props}
          />

          <AutoLayoutVertical2 className="mobile-two-column">
            <AutoLayoutHorizontal2 className="mobile-two-column">
              {womenCollection?.map((item, index) => (
                <Product
                  key={index}
                  item={item}
                  fetchData={fetchSubCategory}
                  className={product1Props?.className}
                  src={item?.images[0]?.url}
                />
              ))}
              {/* 
              <Product
                key={index}
                item={item}
                fetchData={fetchSubCategory}
                className={product1Props?.className}
                src={item?.images[0]?.url}
              /> */}
      {/* <Product
                className={product1Props.autoLayoutHorizontalProps.className}
                src={product1Props.autoLayoutHorizontalProps.src}
                autoLayoutHorizontalProps={
                  product3Props.autoLayoutHorizontalProps
                }
              />
              <Product
                className={product1Props.autoLayoutHorizontalProps.className}
                src={product1Props.autoLayoutHorizontalProps.src}
                autoLayoutHorizontalProps={
                  product4Props.autoLayoutHorizontalProps
                }
              /> 
            </AutoLayoutHorizontal2>
          </AutoLayoutVertical2>
        </AutoLayoutHorizontal1>
      </AutoLayoutVertical> */}
      <br />

      <Collection className="">
        {menCollection?.length > 0 && (
          <>
            {/* <Title22 forWomens="FOR HIM" /> */}
        <h2 className="title1 heading text-center home-page-title">FOR HIM</h2>



            <CollectionItems className="mobile-two-column">
              <AutoLayoutHorizontal3 className="mobile-two-column">
                <CustomCarousel
                  name="men"
                  url='/productFilter?category=MEN&only=MEN'
                  id="64e5a6eb828785b406a99e50"
                  catItem={menCollection}
                  fetchSubCategory={fetchSubCategory}
                  product1Props={product1Props}
                />
                {/* {menCollection?.map((item, index) => (
                  <Product
                    key={index}
                    item={item}
                    fetchData={fetchSubCategory}
                    className={product1Props?.className}
                    src={item?.images[0]?.url}
                  />
                ))} */}

                {/* <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product5Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product6Props.autoLayoutHorizontalProps
              }
            /> */}
                {/* <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product7Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product8Props.autoLayoutHorizontalProps
              }
            /> */}
                {/* 
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product9Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product10Props.autoLayoutHorizontalProps
              }
            /> */}
                {/* <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product11Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product12Props.autoLayoutHorizontalProps
              }
            /> */}
              </AutoLayoutHorizontal3>
            </CollectionItems>
          </>
        )}
      </Collection>



      <Collection className="">
        {kidCollection?.length > 0 && (
          <>
            {/* <Title22 ourBest={title223Props.ourBest} forWomens="FOR THEM" /> */}
        <h2 className="title1 heading text-center home-page-title">FOR THEM</h2>

            <CollectionItems className="mobile-two-column">
              <AutoLayoutHorizontal3 className="mobile-two-column">
                <CustomCarousel
                  name="kids"
                  url='/productFilter?category=KIDS&only=KIDS'
                  id="639c7325f75468b349446ed7"
                  catItem={kidCollection}
                  fetchSubCategory={fetchSubCategory}
                  product1Props={product1Props}
                />
                {/* {kidCollection?.map((item, index) => (
                  <Product
                    key={index}
                    item={item}
                    fetchData={fetchSubCategory}
                    className={product1Props?.className}
                    src={item?.images[0]?.url}
                  />
                ))} */}

                {/* <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product5Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product6Props.autoLayoutHorizontalProps
              }
            /> */}
                {/* <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product7Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product8Props.autoLayoutHorizontalProps
              }
            /> */}
                {/* 
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product9Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product10Props.autoLayoutHorizontalProps
              }
            /> */}
                {/* <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product11Props.autoLayoutHorizontalProps
              }
            />
            <Product
              className={product1Props.autoLayoutHorizontalProps.className}
              src={product1Props.autoLayoutHorizontalProps.src}
              autoLayoutHorizontalProps={
                product12Props.autoLayoutHorizontalProps
              }
            /> */}
              </AutoLayoutHorizontal3>
            </CollectionItems>
          </>
        )}
      </Collection>
      {/* collections */}
      {/* hellowl */}
      

     


      
      {/* <AutoLayoutVerticalCollection className="spacer-bottom">
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            paddingTop: "4rem",
            paddingBottom: "2rem",
          }}
        >
          <Title22 forWomens={"Our Best"} ourBest={"Collections this week"} />
        </div>

        <AutoLayoutHorizontalCollection className="spacer-bottom" style={{justifyContent: "center"}}>
          {collections?.map(
            (collection, index) =>
              collection?.collection_type === "collection" && (
                <BannerStyle2
                  collection={collection}
                  key={index}
                  banner_Style_2={
                    autoLayoutVertical32Props.bannerStyle2Props.banner_Style_2
                  }
                  hp7CategoriesWomanPng={
                    autoLayoutVertical32Props.bannerStyle2Props
                      .hp7CategoriesWomanPng
                  }
                  hp7CategoriesSummerPng={
                    autoLayoutVertical32Props.bannerStyle2Props
                      .hp7CategoriesSummerPng
                  }
                  hp7CategoriesBagPng={
                    autoLayoutVertical32Props.bannerStyle2Props
                      .hp7CategoriesBagPng
                  }
                  hp7CategoriesSunglassPng={
                    autoLayoutVertical32Props.bannerStyle2Props
                      .hp7CategoriesSunglassPng
                  }
                  hp7CategoriesBasicPng={
                    autoLayoutVertical32Props.bannerStyle2Props
                      .hp7CategoriesBasicPng
                  }
                />
              )
          )}
        </AutoLayoutHorizontalCollection>
      </AutoLayoutVerticalCollection> */}
      {/* <BannerStyle22
              banner_Style_2={
                autoLayoutVertical32Props.bannerStyle2Props.banner_Style_2
              }
              hp7CategoriesWomanPng={
                autoLayoutVertical32Props.bannerStyle2Props
                  .hp7CategoriesWomanPng
              }
              hp7CategoriesSummerPng={
                autoLayoutVertical32Props.bannerStyle2Props
                  .hp7CategoriesSummerPng
              }
              hp7CategoriesBagPng={
                autoLayoutVertical32Props.bannerStyle2Props.hp7CategoriesBagPng
              }
            />
               <BannerStyle23
              banner_Style_2={
                autoLayoutVertical32Props.bannerStyle2Props.banner_Style_2
              }
              hp7CategoriesWomanPng={
                autoLayoutVertical32Props.bannerStyle2Props
                  .hp7CategoriesWomanPng
              }
              hp7CategoriesSummerPng={
                autoLayoutVertical32Props.bannerStyle2Props
                  .hp7CategoriesSummerPng
              }
              hp7CategoriesBagPng={
                autoLayoutVertical32Props.bannerStyle2Props.hp7CategoriesBagPng
              }
            />
               <BannerStyle23
              banner_Style_2={
                autoLayoutVertical32Props.bannerStyle2Props.banner_Style_2
              }
              hp7CategoriesWomanPng={
                autoLayoutVertical32Props.bannerStyle2Props
                  .hp7CategoriesWomanPng
              }
              hp7CategoriesSummerPng={
                autoLayoutVertical32Props.bannerStyle2Props
                  .hp7CategoriesSummerPng
              }
              hp7CategoriesBagPng={
                autoLayoutVertical32Props.bannerStyle2Props.hp7CategoriesBagPng
              }
            /> */}
      {/* <AutoLayoutVertical32
        title22Props={autoLayoutVertical32Props.title22Props}
        bannerStyle2Props={autoLayoutVertical32Props.bannerStyle2Props}
        bannerStyle22Props={autoLayoutVertical32Props.bannerStyle22Props}
        bannerStyle23Props={autoLayoutVertical32Props.bannerStyle23Props}
        bannerStyle24Props={autoLayoutVertical32Props.bannerStyle24Props}
        bannerStyle25Props={autoLayoutVertical32Props.bannerStyle25Props}
        bannerStyle26Props={autoLayoutVertical32Props.bannerStyle26Props}
      /> */}
      {/* <Banner1 src={banner2} alt="Banner" /> */}



      {/* bottom Banner */}
      {/* <Carousel autoplay>
        {bottomBanner.length &&
          bottomBanner?.map((item, index) => (
            <div key={index}>
              <h3
                style={{
                  margin: 0,
                  height: "475px",
                  color: "#000",
                  lineHeight: "160px",
                  textAlign: "center",
                  background: "#364d79",
                }}
              >
                <Link to="/shop">
                  <BannerBottom
                    className="slidebannerimg"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      height: "475px",
                    }}
                  ></BannerBottom>
                </Link>
              </h3>
            </div>
          ))}
      </Carousel> */}

      <section className=" spacer-top container">
        <h2 className="title heading text-center">LET'S CONNECT ON SOCIAL</h2>
        <div className="postSection">
          <div style={{ margin: '12px 0' }}>
          
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fviewas%3D100000686899395%26id%3D100094735413897&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=false&hide_cover=false&show_facepile=false&appId"
              width="340"
              height="500"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"
            ></iframe>

          </div>
          <div style={{ margin: '12px 0' }}>
           
            <iframe
              src="https://www.instagram.com/houseofbelieve/embed"
              width="340"
              height="500"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
              allowFullScreen={true}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            ></iframe>
          </div>

          <div style={{ margin: '12px 0' }}>
            <iframe
              className="youtube"
              // src="https://www.youtube.com/embed/iITa8I8NEcg"
              src="https://www.youtube.com/embed/LsfqjDokKi8"
              title="House of Believe ... We can we will :)"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </section>
    </form>
  );
};

const AutoLayoutVerticalCollection = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
`;

const AutoLayoutVertical1Collection = styled.div`
  align-items: center;
  position: relative;
`;

const AutoLayoutHorizontalCollection = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 40px;
  position: relative;
  flex-wrap: wrap;

  padding-left: 2rem;
  padding-right: 2rem;
`;

const Banner = styled.div`
  position: relative;
  min-width: 1512px;
  width:100%;
  // max-width:
  // height: 646px;
  background-size: cover;
  background-position: 50% 50%;
`;
const BannerBottom = styled.div`
  position: relative;
  width: 100%;
  height: 377px;
  background-size: cover;
  background-position: 50% 50%;
`;

const AutoLayoutVertical = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 55px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 0px;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
`;

const AutoLayoutVertical1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 55px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const AutoLayoutHorizontal1 = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
  position: relative;
`;

const AutoLayoutVertical2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0px 0px 6.103515625e-5px;
  position: relative;
`;

const AutoLayoutHorizontal2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
  position: relative;
  flex-wrap: wrap;
`;

const Collection = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;
  margin: auto;
`;

const CollectionItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  position: relative;
  width: 100%;
`;

const AutoLayoutHorizontal3 = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  width: 100%;
`;

const AutoLayoutVertical3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 68.4598388671875px 0px;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
`;

const AutoLayoutVertical4 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 55px;
  position: relative;
`;

const AutoLayoutHorizontal4 = styled.div`
  display: flex;
  width: 100%;
  gap: 35px;
  padding: 0px 17.897430419921875px;
  position: relative;
  flex-wrap: wrap;
`;

const Frame = styled.div`
  position: relative;
  min-width: 322px;
  height: 230px;
`;

const FlexCol = styled.div`
  position: relative;
  width: 239px;
  left: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 199px;
`;

const Image = styled.img`
  width: 100%;
  height: 82px;
`;

const ButterflyMoes = styled.div`
  ${ValignTextMiddle}
  ${PoppinsMediumSonicSilver179px}
            width: 128px;
  height: 25px;
  margin-top: 58px;
  margin-right: 1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  ${PoppinsSemiBoldWhite179px}
            height: 24px;
  margin-top: 10px;
  min-width: 63px;
  background: linear-gradient(
    180deg,
    rgb(255, 48.000000938773155, 85.0000025331974) 0%,
    rgb(253.0000001192093, 121.00000038743019, 136.00000709295273) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
`;

const Frame1 = styled.div`
  position: relative;
  min-width: 322px;
  height: 325px;
`;

const FlexCol1 = styled.div`
  position: relative;
  width: 308px;
  left: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 293px;
`;

const Image1 = styled.img`
  width: 308px;
  height: 177px;
`;

const FreewillWarriors = styled.div`
  ${ValignTextMiddle}
  ${PoppinsMediumSonicSilver179px}
            width: 148px;
  height: 25px;
  margin-top: 57px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${PoppinsSemiBoldWhite179px}
            height: 24px;
  margin-top: 10px;
  margin-left: 1px;
  min-width: 63px;
  background: linear-gradient(
    180deg,
    rgb(255, 48.000000938773155, 85.0000025331974) 0%,
    rgb(253.0000001192093, 121.00000038743019, 136.00000709295273) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  position: relative;
  min-width: 322px;
  height: 271px;
`;

const FlexCol2 = styled.div`
  position: relative;
  width: 239px;
  left: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 240px;
`;

const Image2 = styled.img`
  width: 239px;
  height: 123px;
`;

const PantherFan = styled.div`
  ${ValignTextMiddle}
  ${PoppinsMediumSonicSilver179px}
            width: 108px;
  height: 25px;
  margin-top: 57px;
  margin-right: 1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  ${PoppinsSemiBoldWhite179px}
            height: 24px;
  margin-top: 11px;
  min-width: 63px;
  background: linear-gradient(
    180deg,
    rgb(255, 48.000000938773155, 85.0000025331974) 0%,
    rgb(253.0000001192093, 121.00000038743019, 136.00000709295273) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
`;

const Frame3 = styled.div`
  position: relative;
  min-width: 322px;
  height: 268px;
`;

const FlexCol3 = styled.div`
  position: relative;
  width: 239px;
  left: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 237px;
`;

const Image3 = styled.img`
  width: 239px;
  height: 121px;
`;

const StilletoeHeels = styled.div`
  ${ValignTextMiddle}
  ${PoppinsMediumSonicSilver179px}
            width: 126px;
  height: 25px;
  margin-top: 57px;
  margin-right: 1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.3px;
  white-space: nowrap;
  cursor: pointer;
`;

const Banner1 = styled.img`
  position: relative;
  height: 360px;
  object-fit: cover;
  width: 100%;
`;

export default Home;
