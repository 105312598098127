import React, { useEffect, useState } from "react";
import Header4 from "../Header4";
import Spanbox from "../Spanbox";
import Footer from "../Footer";
import styled from "styled-components";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Radio, notification, Spin } from "antd";
import { useLocation } from "react-router-dom";

import Cookies from "js-cookie";

import { Checkbox } from "antd";

import type, { CheckboxValueType } from "antd/es/checkbox/Group";

import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./MyProfile.css";
import { UpadteProfileApi, accountDetailApi } from "../../api/account";
import UploadProfile from "./UploadProfile";
import { isError } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AccountDetailValue = ({ name }) => {
  return (
    <MenBlackSlimFitM style={{ color: `${name ? "black" : ""}` }}>
      {(name && name) || "- not added -"}
    </MenBlackSlimFitM>
  );
};

const myprofile = (props) => {
  const [updating, setUpdating] = useState(false);
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [mobile, setMobile] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [image, setImage] = useState();
  const [profileDetail, setProfileDetail] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [isError, setIsError] = useState(false);
  const [dob, setDob] = useState("");
  const [id, setId] = useState();
  const [memberNumber, setMemberNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [loginData,setLoginData]=useState(JSON.parse(localStorage.getItem('login')));
  const handleEdit = () => {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = (e) => {
    if (gender === e.target.value) {
      setGender("");
    } else {
      setGender(e.target.value);
    }
  };

  const handleMobileDisabled = (e) => {
    if (e.target.value.length > 10) {
      setPhoneDisabled(true);
    }
  };

  const formData = () => {
    return {
      mobile,
      fullName,
      gender,
      email,
    };
  };

  // const updateLocalData=localStorage.getItem('login');
  // console.log(JSON.parse(updateLocalData));


  const handleUpdate = async (e) => {
    e.preventDefault();
    setUpdating(true);
    const { data } = await UpadteProfileApi(id, formData());
    setUpdating(false);
    if (data.success) {
      getAccountDetail();
      setLoginData({
        ...loginData,
        name: data?.data?.name,
        email: data?.data?.email,
      });
      setIsEdit(false);
    } else {
      setIsError(true);
      notification.error({
        message: data.message,
        duration: 1,
        placement:"bottom"
      });
    }
  };

localStorage.setItem("login",JSON.stringify(loginData));


  const location = useLocation();
  const getAccountDetail = async () => {
    if (localStorage.getItem("login") !== null) {
      setIsLoading(true);
      // fetch id of login data
      const loginData = JSON.parse(localStorage.getItem("login"));
      setId(loginData._id);
      const { data } = await accountDetailApi(loginData._id);
      setIsLoading(false);
      if (data.success) {
        setProfileDetail(data?.data);
        const detail = data?.data;
        setMobile(detail?.mobile);
        setFullName(detail?.name);
        setEmail(detail.email);
        setGender(detail.gender);
        setDob(detail.dob);
        setMemberNumber(detail.member_card_number);
        setImage(detail?.image_url);
      }
    } else {
      Cookies.remove("warehouse_id");
      localStorage.removeItem("login");
      localStorage.removeItem("wallet");
      history.push(location.pathname + location.search, {
        state: "login",
      });
      // window.location.reload(false);

      // notification.error({
      //   message: "You are not logged in",
      // });
    }
  };

  const params = useLocation();
  useEffect(() => {
    getAccountDetail();
  }, [0]);

  useEffect(() => {
    getAccountDetail();
    if (params?.search === "?isEdit") {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [params]);

  return (
    <>
      {(isLoading && (
        <div style={{ margin: "0 auto", paddingTop: "2rem" }} className="profile-loader">
          <Spin size="large" />
        </div>
      )) || (
        <Group37002 className="profile-mobile" style={{ padding: "0.5rem 4rem" }}>
          <div className="delivery-to-box ">
            <div
              style={{
                borderBottom: "0.3px solid #00000045",
                width: "100%",
                padding: "0.5rem 0rem",
              }}
            >
              <br />
              <UrbanoFashion>
                {" "}
                <p
                  style={{ lineHeight: "30px", fontSize: "20px" }}
                  className="para text-left"
                >
                  Profile Detail
                </p>
              </UrbanoFashion>
            </div>{" "}
            {(isEdit && (
              <div>
                <form onSubmit={handleEdit} role="form">
                  <div style={{ textAlign: "center" }} className="mt-5">
                    <>
                      <br />
                      <br />
                      {/* <UploadProfile
                        id={id}
                        image={image}
                        getAccountDetail={getAccountDetail}
                      /> */}
                      <div
                        style={{
                          marginTop: "1rem",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "48%",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <input
                            style={{ width: "100%" }}
                            id="mobileNumber"
                            value={fullName}
                            placeholder="Enter Your Full Name"
                            type="text"
                            autoComplete="new-email"
                            className={`input-group-alternative ${
                              isError && fullName === "" && "error-class"
                            }`}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                          <p style={{ textAlign: "left" }}>
                            <small className="error-red">
                              {" "}
                              {isError && fullName === ""
                                ? "Please Enter name"
                                : ""}
                            </small>
                          </p>
                        </div>
                        <div
                          style={{
                            width: "48%",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <input
                            style={{ width: "100%" }}
                            id="mobileNumber"
                            disabled
                            placeholder="Enter Your Mobile Number"
                            type="number"
                            autoComplete="new-email"
                            className="input-group-alternative"
                            value={mobile}
                            onChange={(e) => (
                              mobile.length === 8 && setIsMobile(true),
                              mobile.length === 9 && setIsMobile(false),
                              handleMobileDisabled(e),
                              setMobile(e.target.value),
                              setErrorMessage("")
                            )}
                          />
                          <div className="red">
                            {mobile.length > 10 && (
                              <>
                                <p
                                  style={{ fontSize: "14px" }}
                                  className="para"
                                >
                                  This is not a Valid Number{" "}
                                  <span
                                    onClick={(e) => setPhoneDisabled(false)}
                                    className="link-para"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Re-Enter-Number
                                  </span>{" "}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "48%",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <input
                            style={{ width: "100%" }}
                            id="mobileNumber"
                            value={email}
                            placeholder="Enter Your Email Id"
                            type="text"
                            autoComplete="new-email"
                            className={`input-group-alternative ${
                              isError && email === "" && "error-class"
                            }`}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <p style={{ textAlign: "left" }}>
                            <small className="error-red">
                              {" "}
                              {isError && email === ""
                                ? "Please Enter Valid Email"
                                : ""}
                            </small>
                          </p>
                        </div>
                        {/* <div
                      style={{
                        width: "48%",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <input
                        style={{ width: "100%" }}
                        id="mobileNumber"
                        value={memberNumber}
                        placeholder="Enter Your member card number"
                        type="text"
                        autoComplete="new-email"
                        className="input-group-alternative"
                        onChange={(e) => setMemberNumber(e.target.value)}
                      />
                    </div> */}
                        {/* <div
                      style={{
                        width: "48%",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        defaultValue={dob}
                        onChange={(value) => setDob(value)}
                        format={"YYYY/MM/DD"}
                        placeholder="Select Date of Birth"
                      />
                    </div> */}
                        <div
                          style={{
                            width: "48%",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <p className="select-gender">Select gender</p>
                          <div style={{ width: "100%", textAlign: "left" }}>
                            <Checkbox
                              onChange={onChange}
                              value={"f"}
                              checked={gender === "f"}
                            >
                              Female
                            </Checkbox>
                            <Checkbox
                              onChange={onChange}
                              value={"m"}
                              checked={gender === "m"}
                            >
                              Male
                            </Checkbox>
                          </div>
                        </div>{" "}
                      </div>

                      <br />
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          onClick={() => setIsEdit(false)}
                          style={{ width: "50%", marginRight: "1rem",backgroundColor:"#f4792c",color:"white" }}
                          className="btn outline-primary outline-button"
                        >
                          Cancel
                        </button>
                        <div style={{ width: "50%", marginLeft: "1rem" }}>
                          <Spin spinning={updating}>
                            <button
                              style={{ width: "100%" }}
                              onClick={(e) => handleUpdate(e)}
                              className="btn btn-primary"
                            >
                              Update
                            </button>
                          </Spin>
                        </div>
                      </div>
                      <br />
                      <br />
                      {/* <p style={{ color: "red" }}>
                    <small>{errorMessage !== "" && errorMessage}</small>
                  </p> */}
                      <div
                        style={{
                          marginTop: "1.5rem",
                          marginBottom: "1rem ",
                        }}
                        className="text-center"
                      >
                        {/* disabled={isMobile} */}
                      </div>
                    </>
                  </div>
                </form>
              </div>
            )) || (
              <div>
                <div style={{ padding: "1rem 0" }}>
                  {/* {(profileDetail.image_url && (
                <Avatar
                  size={64}
                  src={<img src={profileDetail.image_url} alt="avatar" />}
                />
              )) || <Avatar size={64} icon={<UserOutlined />} />} */}
                </div>

                <div className="edit-profile">
                  <div>
                    <MenBlackSlimFitM>Full Name</MenBlackSlimFitM>
                    <MenBlackSlimFitM>Mobile Number</MenBlackSlimFitM>
                    <MenBlackSlimFitM>Email ID</MenBlackSlimFitM>
                    <MenBlackSlimFitM>Gender</MenBlackSlimFitM>
                    {/* <MenBlackSlimFitM>Date of Birth</MenBlackSlimFitM> */}
                    {/* <MenBlackSlimFitM>Location</MenBlackSlimFitM> */}
                    {/* Alternate Mobile */}
                    {/* <MenBlackSlimFitM>Member Card Number</MenBlackSlimFitM> */}
                    {/* <MenBlackSlimFitM>Hint Name</MenBlackSlimFitM> */}
                  </div>

                  <div>
                    <AccountDetailValue name={profileDetail?.name} />
                    <AccountDetailValue name={profileDetail?.mobile} />
                    <AccountDetailValue name={profileDetail?.email} />
                    <AccountDetailValue
                      name={
                        (profileDetail?.gender === "m" && "Male") ||
                        (profileDetail?.gender === "f" && "Female")
                      }
                    />
                    {/* <AccountDetailValue name={profileDetail?.dob} /> */}
                    {/* <AccountDetailValue name={profileDetail?.member_card_number} /> */}
                  </div>
                </div>
                <br />
                <br />
                <button
                  onClick={() => setIsEdit(true)}
                  style={{ width: "100%",backgroundColor:"rgb(244, 121, 44)",border:"1px solid rgb(244, 121, 44)" }}
                  className="btn btn-primary"
                >
                  Edit
                </button>

                <br />
                <br />
              </div>
            )}
          </div>
        </Group37002>
      )}
    </>
  );
};

const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  height: 268px;
  position: relative;
  margin-top: 36px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  top: 0;
  left: 0;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;

  width: 100%;
  justify-content: space-between;
  padding: 15px 19px;
  align-items: flex-start;
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  width: 94px;
  height: 44px;
  position: relative;
  margin-top: 0.26px;
`;

const Name = styled.div`
  ${ValignTextMiddle}

  height: 22px;
  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}

  height: 22px;
  top: 22px;
  left: 3px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 111px;
  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;
  position: absolute;
  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}

  height: 32px;
  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
        color:#555555b3;

  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
            position: absolute;
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default myprofile;
