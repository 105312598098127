import React from "react";
import styled from "styled-components";
import { InterMediumEbonyClay16px, InterSemiBoldEbonyClay14px } from "../../styledMixins";


class FooterLinksColumn extends React.Component {
  render() {
    const { heading, text1, text2, text3, text4, text5, text6, className } = this.props;

    return (
      <FooterLinksColumn1 className={`footer-links-column ${className || ""}`}>

        <Heading className="heading">{heading}</Heading>

        <FooterLinks className="footer-links">
          {/* <Text className="text">{text1}</Text>
          <Text1 className="text-2">{text2}</Text1>
          <Text1 className="text-3">{text3}</Text1>
          <Text1 className="text-4">{text4}</Text1>
          <Text1 className="text-5">{text5}</Text1>
          <Text1 className="text-6">{text6}</Text1> */}
          hell
        </FooterLinks>
      </FooterLinksColumn1>
    );
  }
}

const FooterLinksColumn1 = styled.div`
  position: relative;
  flex: 1;
  min-width: 164px;
  height: 240px;
`;

const Heading = styled.div`
  ${InterSemiBoldEbonyClay14px}
  position: absolute;
  width: 164px;
  top: -1px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const FooterLinks = styled.div`
  ${InterMediumEbonyClay16px}
  display: flex;
  flex-direction: column;
  width: 164px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 36px;
  left: 0;
`;

const Text = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Text1 = styled.div`
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const FooterLinksColumn2 = styled.div`
  .footer-links-column & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: unset;
    height: unset;
  }
`;

const Heading1 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-1  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks1 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-1  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading2 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-2  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks2 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-2  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading3 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-3  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks3 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-3  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading4 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-4  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks4 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-4  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinksColumn3 = styled.div`
  .footer-links-column & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: unset;
    height: unset;
  }
`;

const Heading5 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-6  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks5 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-6  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading6 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-7  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks6 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-7  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading7 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-8  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks7 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-8  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading8 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-9  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks8 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-9  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinksColumn4 = styled.div`
  .footer-links-column & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: unset;
    height: unset;
  }
`;

const Heading9 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-10  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks9 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-10  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading10 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-11  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks10 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-11  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading11 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-12  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks11 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-12  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading12 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-13  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks12 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-13  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinksColumn5 = styled.div`
  .footer-links-column & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: unset;
    height: unset;
  }
`;

const Heading13 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-14  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks13 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-14  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading14 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-15  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks14 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-15  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading15 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-16  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks15 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-16  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Heading16 = styled.div`
  ${InterSemiBoldEbonyClay14px}

  .footer-links-column.footer-links-column-17  & {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const FooterLinks16 = styled.div`
  ${InterMediumEbonyClay16px}

  .footer-links-column.footer-links-column-17  & {
    position: relative;
    align-self: stretch;
    width: unset;
    top: unset;
    left: unset;
  }
`;

export default FooterLinksColumn;
