import React from "react";
import styled from "styled-components";
import { InterNormalWhite13px, ValignTextMiddle } from "../../styledMixins";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Group3 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <Group className={`group-6 ${className || ""}`}>
        <Link to="/shop">
          <OverlapGroup className="overlap-group-1">
            <ShopNow className="shop-now">SHOP NOW</ShopNow>
          </OverlapGroup>
        </Link>
      </Group>
    );
  }
}

const Group = styled.div`
  position: relative;
  width: 182px;
  height: 52px;
  top: 8px;
  left: 8px;
`;

const OverlapGroup = styled.div`
  height: 52px;
  display: flex;
  padding: 7px 43px;
  align-items: flex-start;
  min-width: 180px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const ShopNow = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite13px}
            height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const OverlapGroup1 = styled.div`
  .group-6.group-8 & {
    border-color: var(--primarycolor);
  }
`;

export default Group3;
