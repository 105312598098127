import React from "react";
import styled from "styled-components";
import { InterNormalBunker18px, ValignTextMiddle } from "../../styledMixins";

class Frame extends React.Component {
  render() {
    const { children, className } = this.props;

    return (
      <Frame1 className={`frame-20 ${className || ""}`}>
        <Surname className="surname">{children}</Surname>
        <Rectangle className="rectangle-4"></Rectangle>
      </Frame1>
    );
  }
}

const Frame1 = styled.div`
  margin-bottom: 1rem;
`;

const Surname = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  background-color: #efefef;
  font-weight: bold;
  padding: 0px 12px;
  width: 100%;
  margin: 20px 0;
  height: 36px;
  border-radius: 8px;
`;

const Rectangle = styled.div`
  width: 320px;
  height: 1px;
  background-color: var(--alto);
`;

export default Frame;
