import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
const Confirm = () => (
  <Popconfirm
    title="Delete the task"
    description="Are you sure to delete this task?"
    icon={
      <QuestionCircleOutlined
        style={{
          color: "red",
        }}
      />
    }
  >
    <Button type="link">Delete</Button>
  </Popconfirm>
);
export default Confirm;
