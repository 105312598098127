import React from "react";
import { useEffect } from "react";
import "./FooterLinks.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="footer-container">
      <div className="details" id="details-container">
        <div
          id="details-4"
          className="details-content"
          style={{ display: "block" }}
        >
          <div style={{ "text-align": "center" }}>
            <h1>Frequently Asked Questions</h1>
          </div>
          <br />
          <hr />
          <br />

          <div className="help-box">
            <h2>Help Center</h2>

            <p>
              Kindly check the FAQ below if you are not very familiar with the
              functioning of this Website. If your query is of urgent nature and
              is different from the set of questions, then please contact us at:
            </p>
            <p>
              Email:&nbsp;
              <a href="mailto: help@houseofbelieve.com" target="_blank">
                help@houseofbelieve.com
              </a>
            </p>
            <p>
              Call us: <a href="tel:18003091345">18003091345</a>
            </p>
            <p>
              Call or email us with your query from 9am to 8pm on all 7 days to
              get our immediate help.
            </p>
            <p>
              We are here at your support With our excellent customer support,
              we’re there for you whenever you need assistance.
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              "font-size": "x-large",
              "font-weight": "bolder",
              "margin-top": "27px",
            }}
          >
            Order Status & Tracking
          </div>

          <ol className="faq-list list-return faq-pad">
            <li>
              <h4 className="faq-heading">
                How will I know if my order has been placed successfully?{" "}
              </h4>
              <div className="read faq-text">
                <p>
                  You will receive an email and SMS confirmation from
                  HouseofBelieve.com once your order has been successfully
                  placed. The mail will have all the details related to your
                  order.
                </p>
                {/* <p>
                  {" "}
                  We currently deliver to cities across India. Check the pin
                  codes we deliver to on our product pages.
                </p> */}
              </div>
            </li>
            {/* <li>
              <h4 className="faq-heading">
                How do I contact &nbsp;
                <a href="/">www.houseofbelieve.com</a>?{" "}
              </h4>
              <div className="read faq-text">
                <p>
                  You can write to us at{" "}
                  <a href="mailto: help@houseofbelieve.com" target="_blank">
                    help@houseofbelieve.com
                  </a>{" "}
                  for any queries about HOB.
                </p>
              </div>
            </li> */}

            <li>
              <h4 className="faq-heading">
                How do I check the status of my order?
              </h4>
              <div className="read faq-text">
                <p>
                  To check the status of your order, log into your account on
                  HouseOfBelieve, and go to the Orders section to get all the
                  information.
                </p>
              </div>
            </li>

            <li>
              <h4 className="faq-heading">
                Can I modify the shipping address of my order after it has been
                placed?{" "}
              </h4>
              <div className="read faq-text">
                <p>
                  The shipping address of your order can only be modified if
                  your order hasn’t been packed yet at our warehouse. To modify
                  your shipping address, please get in touch with our Customer
                  Care team at <a href="mailto: help@houseofbelieve.com" target="_blank">
                help@houseofbelieve.com
              </a> or call us at-
              <a href="tel:18003091345">18003091345</a>
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">How do I track my order?</h4>
              <div className="read faq-text">
                <p>
                  You can check your order status on our website&nbsp;
                  <a href="www.houseofbelieve.com">www.houseofbelieve.com</a>&nbsp;
                   in the order section.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                What should I do if the product has reached me in a
                damaged/defective condition?
              </h4>
              <div className="read faq-text">
                <p>
                  If you realize that the package is damaged before accepting
                  the delivery, please do not accept the delivery and
                  immediately inform us. If you have accepted the delivery and
                  then realized that the package is damaged, please feel free to
                  write to us at  <a href="mailto: help@houseofbelieve.com" target="_blank">
                help@houseofbelieve.com
              </a> within 1 hour of
                  receiving the package along with images and videos captured
                  within 1 hour of delivery. With the exception of a few items
                  we have a 15 days return/exchange policy..{" "}
                </p>
              </div>
            </li>

            <li>
              <h4 className="faq-heading">
                How long will it take for my order to reach me?
              </h4>
              <div className="read faq-text">
                <p>
                  {" "}
                  We ship your order within 24 – 48 business hours and it will
                  be delivered within 4-5 business days. In some cases, it may
                  take longer, depending on your location.
                </p>
                {/* <div className="faq-child-li">
                  <ol style={{ "list-style": "order" }}>
                    <li>VISA, Master Card Credit Cards and Debit Cards.</li>
                    <li>Cash on Delivery</li>
                  </ol>
                </div> */}
              </div>
            </li>

            <li>
              <h4 className="faq-heading">What delivery methods do you use?</h4>
              <div className="read faq-text">
                <p>
                  We use leading courier companies such as Xpressbees, DTDC for
                  shipments
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                What should be done if my package has been opened or tampered
                with?
              </h4>
              <div className="read faq-text">
                <p>
                  {" "}
                  If your package has been opened or tampered with, we request
                  you to not accept the shipment and immediately inform our
                  customer care team via phone at <a href="tel:18003091345">18003091345</a>.
                </p>
                <p>
                  Kindly note, any intimation after 24 hours of the attempted
                  delivery, will not be considered.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Can I change shipping address of the order?
              </h4>
              <div className="read faq-text">
                <p>No, you cannot change the shipping address.</p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Does your product price include shipping charges?
              </h4>
              <div className="read faq-text">
                <p>
                  Our prices do not include shipping charges. However, they
                  include product cost and any governmental charges on the
                  product cost e.g., GST. The shipping charges are added to your
                  order amount on the billing page.
                </p>
              </div>
            </li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                "font-size": "x-large",
                "font-weight": "bolder",
                "margin-top": "13px",
              }}
            >
              Return & Exchange
            </div>

            <li>
              <h4 className="faq-heading">
                What is return & exchange policy? How does it work?
              </h4>
              <div className="read faq-text">
                <p>
                  Our returns and exchange policy gives you an option to return
                  or exchange items purchased for any reason within 15 days of
                  receipt of the item. Get store credits for your returned
                  orders to use them for all your future purchases. We only ask
                  that you don't use the product and preserve its original
                  condition, tags and packaging. Kindly note, all exchanges are
                  subject to stock availability. If you wish to exchange your
                  item for Size or an alternate product, we suggest that you
                  return it to obtain store credit and purchase the new item
                  separately.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Do I need to pay return charges for any items returned to you by
                courier?
              </h4>
              <div className="read faq-text">
                <p>
                  Yes, we do charge on returns, which means that any item you
                  wish to return are collected by our courier partner at a cost.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                I want to return a product; how can I do that?
              </h4>
              <div className="read faq-text">
                <p>
                  Most items are returnable. You may visit&nbsp;
                  <a href="www.houseofbelieve.com">www.houseofbelieve.com</a>&nbsp;
             and return the merchandise in unused,
                  saleable condition, along with the original invoice and Price
                  Tag. For details, please refer our Return & Refund Policy.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                How will I receive my money for the products which I returned or
                cancelled?
              </h4>
              <div className="read faq-text">
                <p>
                  In case of return, you shall receive the amount in voucher
                  form which you can use in your next purchase from HOB website.
                  In case of cancellation, you shall receive the amount in the
                  same way you paid at the time of purchase. For all COD orders,
                  you shall receive the amount in voucher form which you can use
                  in your next purchase from HOB website.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                How long will it take for me to receive the refund of my
                returned product?
              </h4>
              <div className="read faq-text">
                <p>
                  Once the product is picked up by our aligned courier partner,
                  we will initiate the refund once we receive the product in our
                  warehouse and QC is done. The whole procedure will take 4-5
                  working days. If you self-ship the return order, the
                  product(s) will first go through a quality check at our end.
                  Once it clears the check, the refund process will be initiated
                  within 3-4 business Days in the form of Store Credits. In case
                  the product does not meet our quality check, we will not be
                  able to process the refund.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                I have received store credits how do I redeem them while placing
                an order?
              </h4>
              <div className="read faq-text">
                <p>
                  Please sign in with your registered email ID to view and
                  utilize your store credits. On the checkout page, a store
                  credit tab will reflect your store credits. Here, the total
                  amount to be paid by you will be calculated after deducting
                  the store credits. Please note, store credits cannot be used
                  to cover COD charges.
                </p>
              </div>
            </li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                "font-size": "x-large",
                "font-weight": "bolder",
                "margin-top": "13px",
              }}
            >
              Cancellation Policy
            </div>

            <li>
              <h4 className="faq-heading">
                What is HousOfBelieve Cancellation Policy?
              </h4>
              <div className="read faq-text">
                <p>
                  You can cancel an order through our website until it has been
                  packed at our warehouse. This includes items purchased on sale
                  also. Any amount paid will be credited into the same payment
                  mode using which the payment was made. Still, if you wish to
                  cancel your order after the order is packed, reach out to our
                  Customer Care team at &nbsp;<a href="mailto: help@houseofbelieve.com" target="_blank">
                help@houseofbelieve.com
              </a>&nbsp;or call us at-
              <a href="tel:18003091345">18003091345</a>.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">How do I cancel my order?</h4>
              <div className="read faq-text">
                <p>
                  For order cancellation, please contact our Customer Care team
                  at &nbsp;<a href="mailto: help@houseofbelieve.com" target="_blank">
                help@houseofbelieve.com
              </a>&nbsp; or call us at-  <a href="tel:18003091345">18003091345</a>. Kindly
                  note, orders can only be cancelled before they are packed in
                  our warehouse.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                I just cancelled my order. When will I receive my refund?
              </h4>
              <div className="read faq-text">
                <p>
                  For all prepaid orders, the refund shall reflect in the source
                  account within 5-7 business days. For wallet & store credits
                  the refund shall reflect within 24 hours. If you had placed
                  the order using Cash on Delivery, there is no amount to be
                  refunded at our end as you haven’t paid for the order yet.
                </p>
              </div>
            </li>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                "font-size": "x-large",
                "font-weight": "bolder",
                "margin-top": "13px",
              }}
            >
              Orders & Payment Policy
            </div>

            <li>
              <h4 className="faq-heading">
                What are the payment options available?
              </h4>
              <div className="read faq-text">
                <p>
                  Currently we accept the following: · VISA, Master Card Credit
                  Cards and Debit Cards. · Cash on Delivery
                </p>
              </div>
            </li>

            <li>
              <h4 className="faq-heading">
                Is it safe to use my credit/debit card on HouseofBelieve?
              </h4>
              <div className="read faq-text">
                <p>
                  You can be assured that HouseofBelieve offers you the highest
                  standards of security currently available online to ensure
                  that your shopping experience is private, safe, and secure. We
                  work with only reliable and secure payment partners who are
                  PCI DSS compliant. Your bank will authorize the credit card
                  transaction directly without any information passing through
                  to us. We do not retain your banking information.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                How do I redeem a coupon/gift card?
              </h4>
              <div className="read faq-text">
                <p>
                  Once you have shortlisted the product you wish to buy, select
                  the size and click ‘Add to Bag’, and view your cart. Here you
                  can enter the discount/coupon code or enter your gift card
                  code, click on Apply Code, and follow the rest of the
                  instructions to place your order.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Is there a purchase limit for a Cash on Delivery (COD) purchase?
              </h4>
              <div className="read faq-text">
                <p>
                  The Cash on Delivery option is available on purchases of up to
                  Rs. 5,000. Beyond this amount, purchases can only be made
                  using Net Banking, Credit/Debit Card, UPI, and Mobile Wallets.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
              How do I pay the courier executive for a COD purchase?
              </h4>
              <div className="read faq-text">
                <p>
                You may pay the courier executive using cash.
                </p>
              </div>
            </li>




{/* 
            <li>
              <h4 className="faq-heading">
                What happen if I am out when you deliver?
              </h4>
              <div className="read faq-text">
                <p>
                  Our shipping partner will leave a message and attempt again.
                  We urge you to plan and coordinate the purchase in a manner
                  that someone is available to accept the shipment at the
                  provided delivery address.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                What if only part of my order has arrived?
              </h4>
              <div className="read faq-text">
                <p>
                  We always strive to ship your entire order at the earliest. In
                  some cases, however, some items may not be ready to ship
                  immediately, and so we may ship a partial order of the items
                  which are ready to ship. The remaining items shall typically
                  be shipped as soon as they become available.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Does your product price include shipping charges?
              </h4>
              <div className="read faq-text">
                <p>
                  Our prices do not include shipping charges. However, they
                  include product cost and any governmental charges on the
                  product cost e.g. GST. The shipping charges are added to your
                  order amount on the billing page.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">How do I track my order?</h4>
              <div className="read faq-text">
                <p>
                  You can check your order status on our website &nbsp;
                  <a href="/">www.houseofbelieve.com</a> in the order section.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                What should I do if the product has reached me in a
                damaged/defective condition?
              </h4>
              <div className="read faq-text">
                <p>
                  If you realize that the package is damaged before accepting
                  the delivery, please do not accept the delivery and
                  immediately inform us.
                </p>
                <p>
                  If you have accepted the delivery and then realized that the
                  package is damaged, please feel free to write to us at{" "}
                  <a href="mailto: help@houseofbelieve.com" target="_blank">
                    {" "}
                    help@houseofbelieve.com
                  </a>
                  &nbsp; within 1 hour of receiving the package along with
                  images and videos captured within 1 hour of delivery. With an
                  exception of a few items we have a 15 days return/exchange
                  policy.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                How can I track my order which is in transit?
              </h4>
              <div className="read faq-text">
                <p>
                  You can track the shipment status of your order on the courier
                  website for which URL and tracking details are emailed to you
                  after your order is shipped. Or you can simply check your
                  order status via our own shipment tracking available on our
                  Website.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Is registration compulsory? Or do I need to register to be able
                to shop at House of Believe?
              </h4>
              <div className="read faq-text">
                <p>
                  For smooth shopping of the products, you will have to register
                  yourself by signing up at our websites in simple steps. After
                  registration, you will be able to know about all the orders,
                  etc pertaining to your account and could avail of the benefits
                  of better service, quicker checkout etc.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                Is there any charge for registration?
              </h4>
              <div className="read faq-text">
                <p>No, registration on House of Believe is absolutely free.</p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">Are my personal details secured?</h4>
              <div className="read faq-text">
                <p>
                  Yes, your personal details are secured. For more details,
                  please refer to our Privacy Policy.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">
                What are the benefits for registration?
              </h4>
              <div className="read faq-text">
                <p>
                  As a registered member you enjoy following benefits: Express
                  checkout: Sign in and proceed to checkout for quick ordering.
                  Order History: Review the details of your Order history and
                  status. Address Book: Create and save addresses of your family
                  and friend for faster check out.
                </p>
              </div>
            </li>
            <li>
              <h4 className="faq-heading">Can I edit my personal details?</h4>
              <div className="read faq-text">
                <p>
                  Yes, most of your personal details are editable. It can be
                  done in the My Account section under edit profile tab.
                </p>
              </div>
            </li> */}


          </ol>
        </div>
      </div>
    </div>
  );
};

export default About;
