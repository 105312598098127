import { dev } from "./baseurl";

export async function getSubcategoryApi(id) {
  const data = await dev.get("/item/getsubcategoryforcategory/", {
    params: {
      category_id: "6229f3cb10afb88e7688f156",
    },
  });
  return data;
}

export async function getSubCategoryNestedApi(id, pageNum, pageSize = 9) {
  const data = await dev.get("/item/getitembysubcategory/believe/", {
    params: {
      id: id,
      page_size: pageSize,
      page_num: pageNum,
    },
  });
  return data;
}

export async function getBannerApi() {
  const data = await dev.get("/collection/getbanners/believe/", {
    params: {
      page_name: ["Category", "Subcategory"],
      page_value: 1,
    },
  });
  return data;
}
