import React from "react";
import Group3 from "../Group3";
import styled from "styled-components";

class Buttons extends React.Component {
  render() {
    const { name, id,name1} = this.props;

    return (
        <TextDivButton className="styled-button">{name1}</TextDivButton>
    );
  }
}

const Group = styled.div`
  position: absolute;
  width: 182px;
  height: 52px;
  top: 8px;
  left: 8px;
`;

const OverlapGroup = styled.div`
  height: 52px;
  width: 181px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const ShopNow = styled.div``;

const OverlapGroup1 = styled.div`
  .group-6.group-8 & {
    border-color: var(--primarycolor);
  }
`;

const Property1two = styled.div`
  position: relative;
`;

const TextDivButton = styled.div``;

export default Buttons;
