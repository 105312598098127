import { dev } from "./baseurl";

export async function getWishlistApi() {
  const data = await dev.get("/fashion_wishlist/get-fashion-items-wishlist");

  return data;
}

export async function deleteWishlistApi(id) {
  const data = await dev.delete(
    `/fashion_wishlist/remove-fashion-item-from-wishlist/${id}`
  );

  return data;
}

export async function addWishlistApi(id) {
  const data = await dev.post(
    `/fashion_wishlist/add-fashion-item-to-wishlist/${id}`
  );
  return data;
}

export async function addToCartApi(id) {
  const data = await dev.post("/cart/believe", {
    _id: id,
    quantity: 1,
  });
  return data;
}
