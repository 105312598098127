import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ValignTextBottom = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const InterMediumEbonyClay16px = css`
  color: var(--ebony-clay);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const InterNormalWhite12px = css`
  color: var(--lg);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalBunker14px = css`
  color: var(--bunker);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalBunker11px = css`
  color: var(--bunker);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxxs);
  font-weight: 400;
  font-style: normal;
`;

export const InterSemiBoldSapphire15px = css`
  color: var(--primarycolor);
  font-family: var(--font-family-inter);
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
`;

export const InterSemiBoldSapphire16px = css`
  color: var(--primarycolor);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const InterSemiBoldSapphire14px = css`
  color: var(--primarycolor);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const InterSemiBoldEbonyClay14px = css`
  color: var(--ebony-clay);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const YesevaoneNormalWhite36px = css`
  font-family: var(--font-family-yeseva_one);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalBunker13px = css`
  color: var(--bunker);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratMediumBlack113px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 11.3px;
  font-weight: 500;
  font-style: normal;
`;

export const SnellroundhandBoldSapphire36px = css`
  color: var(--primarycolor);
  font-family: var(--font-family-snell_roundhand-bold);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalWhite13px = css`
  color: var(--lg);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const YesevaoneNormalSapphire251px = css`
  color: var(--primarycolor);
  font-family: var(--font-family-yeseva_one);
  font-size: 25.1px;
  font-weight: 400;
  font-style: normal;
`;

export const LatoBoldWhite125px = css`
  font-family: var(--font-family-lato);
  font-size: 12.5px;
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalMountainMist13px = css`
  color: var(--mountain-mist);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const AzosansMediumSizzlingRed14px = css`
  color: var(--sizzling-red);
  font-family: var(--font-family-azo_sans-medium);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const LatoLightLogCabin18px = css`
  color: var(--log-cabin);
  font-family: var(--font-family-lato);
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
`;

export const LatoNormalBlack14px = css`
  color: var(--black-2);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const AzosansMediumWhite8px = css`
  color: var(--lg);
  font-family: var(--font-family-azo_sans-medium);
  font-size: 8px;
  font-weight: 500;
  font-style: normal;
`;

export const AzosansRegularNormalBlack14px = css`
  color: var(--black);
  font-family: var(--font-family-azo_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const AzosansRegularNormalEbonyClay16px = css`
  color: var(--ebony-clay);
  font-family: var(--font-family-azo_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalSantasGray16px = css`
  color: var(--santas-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsMediumSonicSilver179px = css`
  color: var(--sonic-silver);
  font-family: var(--font-family-poppins);
  font-size: 17.9px;
  font-weight: 500;
  font-style: normal;
`;

export const PoppinsSemiBoldWhite179px = css`
  font-family: var(--font-family-poppins);
  font-size: 17.9px;
  font-weight: 600;
  font-style: normal;
`;

export const InterNormalBunker12px = css`
  color: var(--bunker);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalStormDust13px = css`
  color: var(--storm-dust);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratNormalBlack169px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const LatoSemiBoldBlack14px = css`
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const AzosansRegularNormalFuscousGray16px = css`
  color: var(--fuscous-gray);
  font-family: var(--font-family-azo_sans-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalBunker18px = css`
  color: var(--bunker);
  font-family: var(--font-family-inter);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalSuvaGray14px = css`
  color: var(--suva-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldBlack191px = css`
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 19.1px;
  font-weight: 700;
  font-style: normal;
`;

export const AzosansRegularNormalFuscousGray14px = css`
  color: var(--fuscous-gray);
  font-family: var(--font-family-azo_sans-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const LatoNormalFuscousGray16px = css`
  color: var(--fuscous-gray);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalBlack14px = css`
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalStormDust15px = css`
  color: var(--storm-dust);
  font-family: var(--font-family-inter);
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
`;
