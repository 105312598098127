import React from "react";
import FooterLinksColumn from "../FooterLinksColumn";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import {
  AzosansRegularNormalEbonyClay16px,
  InterNormalSantasGray16px,
} from "../../styledMixins";
import { FiPhoneCall, FiRefreshCcw, FiTruck } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { BiTimeFive, BiRupee } from "react-icons/bi";
import TruckIcon from "./truck.png";
import RuppeeIcon from "./ruppee.png";
import Cod from "./cod.svg";
import MadeInIndia from "./madeInIndia.png";
import instagramSvg from "../../images/instagram.png"

import "./footer.css";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { Divider, Row, Space } from "antd";

class Footer extends React.Component {




  render() {
    const {
      image,
      className,
      footerLinksColumn1Props,
      footerLinksColumn2Props,
      footerLinksColumn3Props,
      footerLinksColumn4Props,
      footerLinksColumn5Props,
    } = this.props;


    //     const openHTMLFile = () => {
    //   const url = './Policies.html#details-2';
    //   const anchor = document.createElement('a');
    //   anchor.href = url;
    //   anchor.target = '_blank';
    //   anchor.click();
    // };\

    const moveToTop=()=>{
      window.scrollTo({top:0,left:0,behavior:"smooth"})
    }

    return (
      <Footer1 className={`footer ${className || ""}`} style={{paddingTop:"27px"}}>
        <section
          className="container footer-con footer-margin top-icons"
          style={{ justifyContent: "space-around", margin: "30px auto 16px" }}
        >
          <Row justify='space-evenly' style={{ width: '100%' }}>
          <div className="icons-style footer-static-link" >
          {/* <Link to="/returnpolicy" style={{ textDecoration: 'none', color: 'black' }}> */}
          {/* onClick={() => {
                    history.push("/about");} */}
            <span className="icon-pos footer-static-link-black" style={{marginRight:"9px"}}>
              <FiRefreshCcw size={28} color="black"></FiRefreshCcw>
            </span>
           
            <span className="icon-font" style={{color:"#f4792c"}} >Easy Return</span>
            {/* </Link> */}
          </div>

          <div style={{ marginTop: 20, "border-left": "2px solid #373736",height: "77px"}}></div>

          {/* onClick={()=>history.push("/returnpolicy")} */}
          
          <div className="icons-style footer-static-link">
            <span  className="icon-pos" style={{marginRight:"9px"}}>
              {/* <BiRupee size={25}></BiRupee> */}
              {/* <img src={RuppeeIcon} height="35px"></img> */}
              <img src={Cod} height="35px"></img>
            </span>
            <span className="icon-font" style={{color:"#f4792c"}}>COD Available</span>
          </div>

          { window.innerWidth > 480 && <div style={{ marginTop: 20, "border-left": "2px solid #373736",height: "77px"}}></div>}

          <div className="icons-style footer-static-link">
            <span className="icon-pos">
              {/* <FiTruck size={25}></FiTruck> */}
              <img src={TruckIcon} height="75px"></img>
            </span>
            <span className="icon-font truck-icon-text" style={{color:"#f4792c"}}>Free Shipping</span>
          </div>

          <div style={{ marginTop: 20, "border-left": "2px solid #373736",height: "77px"}}></div>


          <div className="icons-style footer-static-link">
            <span className="icon-pos" style={{marginRight:"9px"}}>
              {/* <BiRupee size={25}></BiRupee> */}
              <img src={MadeInIndia} height="40px"></img>
            </span>
            <span className="icon-font">Made in India</span>
          </div>
          </Row>
        </section>
        <section
          className="container footer-con footer-margin"
          style={{ marginTop: "0" }}
        >
          <div className="title-parent">
            <h6 className="title footer-title">Stay Connected with</h6>
            <Link to="/">
              <div className="f-brand" onClick={moveToTop}>
                <Logo className="logo">
                  <Image
                    className="image-1"
                    src="/img/hbLogo.png"
                    alt="Image"
                  />
                </Logo>
              </div>
            </Link>
          </div>
          <div className="tandc">
            <ul className="footer-link">
              <li>
                {/* <a >
                  <h4 onClick={() => {
                    history.push("/about");
                  }}>ABOUT</h4>
                </a> */}

                <Link to="/faq">
                  <h4 style={{fontSize:"20px"}}>FAQ</h4>
                </Link>
              </li>
            </ul>
            <ul className="footer-link">
              <li>
                <Link to="/termsandconditions">
                  <h4 style={{fontSize:"20px"}}>Terms & Conditions</h4>
                </Link>
              </li>
            </ul>

            <ul className="footer-link">
              <li>
                <Link to="/privacypolicy">
                  <h4 style={{fontSize:"20px"}}>Privacy Policy</h4>
                </Link>
              </li>
            </ul>

            <ul className="footer-link">
              <li>
                <Link to="/returnpolicy">
                  <h4 style={{fontSize:"20px"}}>Return Policy</h4>
                </Link>
              </li>
            </ul>
          </div>

          <div className="last-line-foot">
            <div className="last-line-foot-div" style={{fontSize:"20px"}}>
              <FiPhoneCall className="icon-margin"></FiPhoneCall>
              <span>
                <a
                  href="tel:18003091345"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  18003091345
                </a>
              </span>
            </div>
            <div className="last-line-foot-div" style={{fontSize:"20px"}}>
              <AiOutlineMail className="icon-margin icon-pos"></AiOutlineMail>
              <span>
                <a
                  href="mailto: help@houseofbelieve.com"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  help@houseofbelieve.com
                </a>
              </span>
            </div>
            <div className="last-line-foot-div" style={{fontSize:"20px"}}>
              <BiTimeFive className="icon-margin icon-pos"></BiTimeFive>
              <span>Monday-Sunday 9 AM to 8 PM</span>
            </div>
          </div>
        </section>
        {/* <section className="container footer-content footer-margin">
          <div className="f-links " style={{ width: "30%" }}>
            <ul className="footer-link">
              <li>
               

                <Link to="/about">
                  <h4>FAQ</h4>
                </Link>
              </li>
            </ul>

            <ul className="footer-link">
              <li>
                <a href="">
                  {" "}
                  <h4>Terms & Conditions</h4>
                </a>
              </li>
            </ul>

            <ul className="footer-link">
              <li>
                <a href="">
                  <h4>Privacy Policy</h4>
                </a>
              </li>
            </ul>

            <ul className="footer-link">
              <li>
                <a href="">
                  {" "}
                  <h4>Return Policy</h4>
                </a>
              </li>
            </ul>
          </div>
        </section> */}

        {/* <section className="container third-footer footer-margin">
          <div>
            <FiPhoneCall className="icon-margin"></FiPhoneCall>
            <span>18001236477</span>
          </div>
          <div>
            <AiOutlineMail className="icon-margin icon-pos"></AiOutlineMail>
            <span>support@houseofbelieve.com</span>
          </div>
          <div>
            <BiTimeFive className="icon-margin icon-pos"></BiTimeFive>
            <span>Monday-Sunday 9 AM to 8 PM</span>
          </div>
        </section> */}

        <Section1 className="footer-copy">
          <FooterText className="footer-text">
            © 2023 House OF Believe. All rights reserved.
          </FooterText>

          <ul className="social-list">
            <li style={{ margin: '0 12px' }}>
              <a
                href="https://www.facebook.com/houseofbelieve.hob/"
                target="_blank"
              >
                {/* <img src="/img/facebook.svg" alt="" /> */}
                <BsFacebook className="facebook-logo" />
              </a>
            </li>
            <li style={{ margin: '0 12px' }}>
              <a
                href="https://www.instagram.com/houseofbelieve/?hl=en"
                target="_blank"
              >
                <img className="instagram-logo" src={instagramSvg} alt="" />
                {/* <BsInstagram className="instagram-logo" /> */}
              </a>
            </li>
            <li style={{ margin: '0 12px' }}>
              <a href="https://www.youtube.com/@HouseOfBelieve" target="_blank">
                {/* <img src="/img/youtube.svg" alt="" /> */}
                <BsYoutube className="youtube-logo" />
              </a>
            </li>
          </ul>
        </Section1>
      </Footer1>
    );
  }
}

const Footer1 = styled.footer`
  display: flex;
  position: relative;
  flex-shrink: 1;
  margin-top: 50px;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  background-color: transparent;
  mix-blend-mode: normal;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, #9e9e9e 100%);
  padding-top:27px;

  &.footer.footer-1 {
    flex-shrink: unset;
    margin-top: unset;
    margin-left: unset;
  }

  &.footer.footer-2 {
    margin-top: 40px;
    margin-left: unset;
  }

  &.footer.footer-3 {
    margin-top: 107px;
    margin-left: 1px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 64px 0px 48px;
  position: relative;
  align-self: stretch;
`;

const Container = styled.div`
  width: 90%;
  margin: auto;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 64px;
  position: relative;
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LogoAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  gap: 22px;
  position: relative;
`;

const Logo = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
`;

const Image = styled.img`
  position: relative;
  min-width: 171px;
  height: 71px;
  object-fit: cover;
`;

const SupportingText = styled.p`
  ${AzosansRegularNormalEbonyClay16px}
  position: relative;
  align-self: stretch;
  letter-spacing: 0;
  line-height: 24px;
`;

const Links = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 65px;
  position: relative;
  flex-wrap: wrap;
`;

const Section1 = styled.div``;

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 64px;
  padding: 0px 15px;
  position: relative;
`;

const Content1 = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
`;

const FooterText = styled.p`
  ${InterNormalSantasGray16px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const SocialIcons = styled.img`
  position: relative;
  min-width: 264px;
  height: 24px;
`;

export default Footer;
