import React from "react";
import styled from "styled-components";
import { AzosansMediumWhite8px, ValignTextBottom } from "../../styledMixins";


class Group36885 extends React.Component {
  render() {
    return (
      <Group>
        <OverlapGroup1>
          <VuesaxlinearbagHappy src="/img/bag-icon.svg" alt="" />
          <OverlapGroup>
            <Number>2</Number>
          </OverlapGroup>
        </OverlapGroup1>
      </Group>
    );
  }
}

const Group = styled.div`
  position: relative;
  min-width: 27px;
  height: 25px;
`;

const OverlapGroup1 = styled.div`
  position: relative;
  width: 27px;
  height: 25px;
`;

const VuesaxlinearbagHappy = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 1px;
  left: 3px;
`;

const OverlapGroup = styled.div`
  position: absolute;
  height: 14px;
  top: 0;
  left: 0;
  display: flex;
  padding: 2px 3.6px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 14px;
  background-image: url(/img/vector.svg);
  background-size: 100% 100%;
`;

const Number = styled.div`
  ${ValignTextBottom}
  ${AzosansMediumWhite8px}
            width: 6px;
  height: 9px;
  text-align: center;
  letter-spacing: -0.16px;
  line-height: normal;
  white-space: nowrap;
`;

const Group1 = styled.div`
  position: relative;
  min-width: 27px;
  height: 25px;
`;

const OverlapGroup11 = styled.div`
  position: relative;
  width: 27px;
  height: 25px;
`;

const VuesaxlinearbagHappy1 = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 1px;
  left: 3px;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  height: 14px;
  top: 0;
  left: 0;
  display: flex;
  padding: 2px 3.6px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 14px;
  background-image: url(/img/vector.svg);
  background-size: 100% 100%;
`;

const Number1 = styled.div`
  ${ValignTextBottom}
  ${AzosansMediumWhite8px}
            width: 6px;
  height: 9px;
  text-align: center;
  letter-spacing: -0.16px;
  line-height: normal;
  white-space: nowrap;
`;

const Group2 = styled.div`
  position: relative;
  min-width: 27px;
  height: 25px;
`;

const OverlapGroup12 = styled.div`
  position: relative;
  width: 27px;
  height: 25px;
`;

const VuesaxlinearbagHappy2 = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 1px;
  left: 3px;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  height: 14px;
  top: 0;
  left: 0;
  display: flex;
  padding: 2px 3.6px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 14px;
  background-image: url(/img/vector.svg);
  background-size: 100% 100%;
`;

const Number2 = styled.div`
  ${ValignTextBottom}
  ${AzosansMediumWhite8px}
            width: 6px;
  height: 9px;
  text-align: center;
  letter-spacing: -0.16px;
  line-height: normal;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 27px;
  height: 25px;
`;

const OverlapGroup13 = styled.div`
  position: relative;
  width: 27px;
  height: 25px;
`;

const VuesaxlinearbagHappy3 = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 1px;
  left: 3px;
`;

const OverlapGroup4 = styled.div`
  position: absolute;
  height: 14px;
  top: 0;
  left: 0;
  display: flex;
  padding: 2px 3.6px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 14px;
  background-image: url(/img/vector.svg);
  background-size: 100% 100%;
`;

const Number3 = styled.div`
  ${ValignTextBottom}
  ${AzosansMediumWhite8px}
            width: 6px;
  height: 9px;
  text-align: center;
  letter-spacing: -0.16px;
  line-height: normal;
  white-space: nowrap;
`;

export default Group36885;
