import { Avatar, Col, Row } from 'antd'
import React from 'react'

export default function NotificationBox({ title, description, orderid}) {
  return (
    <Row className='' align='middle' wrap={false}>
        <Col>
            <Avatar className='border' size={64} src={<img src="/img/hbLogo.png" style={{ objectFit: 'contain' }} />} />
        </Col>
        <Row className='flex-column flex-grow-1 ms-5'>
            <span className='fw-bold fs-5'>{title}</span>
            <span>{description} for Order Id {orderid}</span>
        </Row>
    </Row>
  )
}
