import React, { memo, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import Frame from "../Frame";
import Checkbox from "../Checkbox";
import Checkbox2 from "../Checkbox2";
import Product from "../Product";
import Footer from "../Footer";
import { Button, Checkbox, Drawer, Row } from "antd";

import styled from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import { Slider } from "antd";

const checkboxStyle = {
  color: "#2f4799", // Change color to your desired value
};
import { Collapse } from "antd";
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

import {
  InterNormalBunker13px,
  InterNormalBunker14px,
  InterNormalBlack14px,
  InterNormalStormDust15px,
  InterNormalMountainMist13px,
  InterNormalBunker18px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./Shop.css";
import {
  Route,
  Switch,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { api, dev } from "../../api/baseurl";
import axios from "axios";
import { getCollectionItemApi, getShopItemApi } from "../../api/collections";
import { notification } from "antd";
// import ShopCollection from "./ShopCollection";
// import ShopCollection from "./shopCollection";
import ShopCategory from "./shopCategory";
import ShopIndex from ".";
import { getFilterItemApi } from "../../api/items";
import { BsFilterLeft } from "react-icons/bs"

const DropdownBody = ({ item, onCheckboxChange, selectedValue }) => {
  const value = Object.values(item);
  const key = Object.keys(item);
  const handleCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    onCheckboxChange(selectedValue);
  };



  return (
    <Frame31 className="custome-size-dropdown">
      <Group3>
        <Checkbox
          style={{ marginRight: "2rem" }}
          autoFocus={false}
          value={key[0]}
          size="large"
        ></Checkbox>
      </Group3>

      <XL>{key[0]} </XL>
      <Text28>({value[0]})</Text28>
    </Frame31>
  );
};

const DropdownHeader = ({ item, onCheckboxChange, name, productCat }) => {
  const handleCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    onCheckboxChange(selectedValue);
  };


  const onChange = (checkedValues) => {};

  return (
    <Frame31 className="custome-size-dropdown">
      <Group3>
        <Checkbox
          checked={name === item[0]}
          onChange={handleCheckboxChange}
          style={{ marginRight: "2rem" }}
          autoFocus={false}
          value={item[0]}
          size="large"
        ></Checkbox>
      </Group3>

      <XL>{item[0]}</XL>
      <Text28>({item[1].items})</Text28>
    </Frame31>
  );
};

const filterComp = ({ setFilterData, props }) => {
  const [name, setName] = useState();
  const params = useLocation();
  const [range, setRange] = useState([200, 300]);
  const [productCat, setProductCat] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterCat, setFilterCat] = useState();
  const [priceValue, setPriceValue] = useState([]);
  const [allSize, setAllSize] = useState([]);
  const [otherFilterValue, setOtherFilterValue] = useState(null);
  const [filterCategory, setFilterCategory] = useState([]);
  const paramsId = useParams();
  const history = useHistory();
  // filter data store in url
  const queryParams = new URLSearchParams(params.search);
  const searchTerm = queryParams.get("query");

  const searchData = JSON.parse(decodeURIComponent(searchTerm));
  const filterItem = searchData?.productCat;

  const categoryid = paramsId?.id;


  const [ filterDrawerToggle, setFilterDrawerToggle ] = useState(false);

  const handleRangeChange = (value) => {
    setRange(value);
  };

  const selectPriceValue = () => {
    setPriceValue(range);
  };

  const handleSelectCat = (value) => {
    setName(value);

    if (productCat.includes(value)) {
      // Remove the selected value if it already exists in the array
      const filtering = productCat.filter((item) => item !== value);
      setProductCat(filtering);

      if (params?.pathname.split("/")[2] === "filter") {
        if (filtering.length === 0) {
          localStorage.removeItem("filterPagination");
          localStorage.removeItem("category");

          history.push(`/shop/category/${categoryid}`);
        }
      }
    } else {
      // Add the selected value to the array
      if (params?.pathname.split("/")[2] === "category") {

        localStorage.removeItem("filterPagination");
        localStorage.removeItem("category");

        // const productCat = value;

        const queryParams = new URLSearchParams();
        queryParams.append(
          "query",
          JSON.stringify({
            allSize,
            selectedItems,
            productCat: [value],
            priceValue,
          })
        );

        history.push(`/shop/filter/${categoryid}?${queryParams.toString()}`);
        // history.push(`?name=${value}`);
      }

      setProductCat([...productCat, value]);
     
      return;
      setOtherFilterValue(filterCat[value]);
      setRange([filterCat[value]?.minimum, filterCat[value]?.maximum]);

    }
  };

  const getFilterItem = async () => {
    const { data } = await getFilterItemApi();
    let name;

    if (data?.data) {
      if (params?.pathname.split("/")[2] === "filter") {
        // url value for search item

        if (searchData) {
          setFilterCategory(Object.entries(data?.data));
          setProductCat(filterItem && filterItem);

          // setName(filterItem && filterItem[filterItem?.length - 1]);

          const catName = filterItem && filterItem[filterItem?.length - 1];
          setAllSize(searchData?.allSize);


          setPriceValue(searchData?.priceValue);
          setRange(
            searchData?.priceValue?.length > 0
              ? searchData?.priceValue
              : [data?.data[catName]?.minimum, data?.data[catName]?.maximum]
          );

          // const categoryName = filterItem[filterItem?.length - 1];

          setFilterCat(data?.data);
          setOtherFilterValue(data?.data && data?.data[catName]);

          // setRange([data?.data[name]?.minimum, data?.data[name]?.maximum]);

          // setAllSize(searchData?.allSize);
         
        }
      } else if (params?.pathname.split("/")[2] === "category") {
        // get url value for category
        setFilterCategory(Object.entries(data?.data));
        if (params?.search?.split("=")[0] === "?name") {
          name = params?.search?.split("=")[1]?.toUpperCase();
          setProductCat([name]);
          setFilterCat(data?.data);
          return;
          setName(name?.toUpperCase());
        }
        // const name1 = params.search
        //   .split("?")[1]
        //   .replace("name=", "")
        //   .toUpperCase();
        // setName(name1?.toUpperCase());
        // setProductCat([name1]);
      } else {
        setFilterCat(data?.data);
        setFilterCategory(Object.entries(data?.data));
      }
    }

    // const name =
    //   params?.search &&
    //   params?.search?.split("=")[0] === "?name" &&
   
  };

  const selectSize = (event) => {
    let value = event.target.value;
    if (allSize.includes(value)) {
      // Remove the selected value if it already exists in the array
      setAllSize(allSize.filter((item) => item !== value));
    } else {
      // Add the selected value to the array
      setAllSize([...allSize, value]);
    }
  };

  useEffect(() => {
    if (setFilterData) {
      setFilterData(allSize, selectedItems, productCat, priceValue);
  
    }
  }, [allSize, selectedItems, productCat, priceValue]);


  useEffect(() => {
    if (searchData) {
      setSelectedItems(searchData?.selectedItems);
    }
    getFilterItem();
  }, [0]);

  const {
    image,
    price1,
    text27,
    filter,
    productCategories,
    frame1Props,
    frame2Props,
  } = props;


  const onChangeSelectedItem = (value) => {
    if (selectedItems.includes(value)) {
      // Item is already selected, remove it from the selectedItems array
      const updatedData = selectedItems.filter((id) => id !== value);
      setSelectedItems(updatedData);
    } else {
      // Item is not selected, add it to the selectedItems array
      setSelectedItems([...selectedItems, value]);
    }
  };


  const onChangeSelectSize = (value) => {
    setAllSize(value);
  };

  return (
    <>
    <Frame36949 className="customer-filter-component mobile-no-display" style={{ width: '100%', maxWidth: 480, margin: '0 16px 20px 16px', display: 'none' }}>
      {/* <Image src={image} alt="Image" /> */}
      <div>
        <Frame9>
          <FlexCol1>
            <ProductCategories>
              <span>{productCategories}</span>
            </ProductCategories>
            <Rectangle3></Rectangle3>
            <Checkbox.Group
              className='custom-filter-dropdown'
              autoFocus={false}
              style={{ width: "100%" }}
              value={productCat}
            >
              <FilterList1>
                <Collapse defaultActiveKey={[]}>
                  {filterCategory?.map((item, index) => (
                    <Panel
                      key={index}
                      header={
                        <DropdownHeader
                          name={name}
                          onCheckboxChange={handleSelectCat}
                          productCat={productCat}
                          item={item}
                        />
                      }
                    >
                      <Checkbox.Group
                        onChange={onChangeSelectedItem}
                        autoFocus={false}
                        style={{ width: "100%" }}
                      >
                        {item[1]?.classes.map((cat, index) => (
                          <div key={index}>
                            <DropdownBody item={cat} />
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Panel>
                  ))}
                </Collapse>

                {/* <Frame31>
                  <XL>{women}</XL>
                  <Group3>
                    <Text28>{text32}</Text28>
                    <Checkbox
                      style={{ marginLeft: "2rem" }}
                      autoFocus={false}
                      value="womens"
                      size="large"
                    ></Checkbox>
                  </Group3>
                </Frame31> */}
                {/* <Frame31>
                  <XL>{kids}</XL>
                  <Group3>
                    <Text28>{text33}</Text28>
                    <Checkbox
                      style={{ marginLeft: "2rem" }}
                      autoFocus={false}
                      value="kids"
                      size="large"
                    ></Checkbox>
                  </Group3>
                </Frame31> */}
              </FilterList1>
            </Checkbox.Group>
          </FlexCol1>
        </Frame9>
        {otherFilterValue && (
          <>
            <Frame9>
              <Frame>{frame1Props.children}</Frame>
              <div style={{ marginTop: "2rem" }}>
                <div>
                  <OverlapGroup>
                    <Slider
                      tipFormatter={null}
                      min={
                        otherFilterValue?.minimum && otherFilterValue?.minimum
                      }
                      max={
                        otherFilterValue?.maximum && otherFilterValue?.maximum
                      }
                      value={range}
                      onChange={(value) => handleRangeChange(value)}
                      range={{
                        draggableTrack: true,
                      }}
                    />
                  </OverlapGroup>
                  {/* 
              <OverlapGroup>
                <Rectangle></Rectangle>
                <Rectangle1></Rectangle1>
                <Rectangle2></Rectangle2>
              </OverlapGroup> */}
                </div>
                <Frame7>
                  <Frame8>
                    <Price>{price1}</Price>
                    <Price1> &nbsp; &nbsp; ₹{range[0]}</Price1>
                    <Text27>{text27}</Text27>
                    <Price2> ₹{range[1]}</Price2>
                  </Frame8>
                  <AutoLayoutHorizontal>
                    <Filter
                      style={{ cursor: "pointer" }}
                      onClick={selectPriceValue}
                    >
                      {filter}
                    </Filter>
                  </AutoLayoutHorizontal>
                </Frame7>
              </div>
            </Frame9>
            <Frame9>
              <Frame className={frame2Props.className}>
                {frame2Props.children}
              </Frame>

              <Checkbox.Group
                onChange={onChangeSelectSize}
                autoFocus={false}
                style={{ width: "100%" }}
                value={allSize}
              >
                <FilterList>
                  {otherFilterValue?.sizes?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    return (
                      <Frame31 key={index}>
                        <Group3>
                          <Checkbox
                            style={{ marginRight: "2rem", color: "black" }}
                            autoFocus={false}
                            value={key}
                            size="large"
                          ></Checkbox>
                        </Group3>

                        <XL>{key}</XL>
                        <Text28>({item[key]})</Text28>
                      </Frame31>
                    );
                  })}
                  {/* <Frame31>
              <XL>{m}</XL>

                <Group3>
                  <div>
                    <Text28>{text29}</Text28>
                  </div>
                  <Checkbox
                    style={{ marginLeft: "2rem" }}
                    autoFocus={false}
                    value="m"
                    size="large"
                  ></Checkbox>
                </Group3>
              </Frame31>
              <Frame31>
                <XL>{price4}</XL>
                <Group3>
                  <Text28>{text30}</Text28>
                  <Checkbox
                    style={{ marginLeft: "2rem" }}
                    autoFocus={false}
                    value="s"
                    size="large"
                  ></Checkbox>
                </Group3>
              </Frame31> */}
                </FilterList>
              </Checkbox.Group>
            </Frame9>
          </>
        )}
      </div>
    </Frame36949>

    <Button className="mobile-filter-drawer-button" icon={<BsFilterLeft style={{ marginRight: 12, fontSize: 20 }} />} size="large" onClick={() => setFilterDrawerToggle(true)}>Filter</Button>

    <Drawer placement="left" open={filterDrawerToggle} onClose={() => setFilterDrawerToggle(false)} closable={false} he>
      <div className="customer-filter-component" style={{ marginBottom: 60 }}>
      <Frame9>
          <FlexCol1>
            <ProductCategories>
              <span>{productCategories}</span>
            </ProductCategories>
            <Rectangle3></Rectangle3>
            <Checkbox.Group
              className='custom-filter-dropdown'
              autoFocus={false}
              style={{ width: "100%" }}
              value={productCat}
            >
              <FilterList1>
                <Collapse defaultActiveKey={[]}>
                  {filterCategory?.map((item, index) => (
                    <Panel
                      key={index}
                      header={
                        <DropdownHeader
                          name={name}
                          onCheckboxChange={handleSelectCat}
                          productCat={productCat}
                          item={item}
                        />
                      }
                    >
                      <Checkbox.Group
                        onChange={onChangeSelectedItem}
                        autoFocus={false}
                        style={{ width: "100%" }}
                      >
                        {item[1]?.classes.map((cat, index) => (
                          <div key={index}>
                            <DropdownBody item={cat} />
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Panel>
                  ))}
                </Collapse>

                {/* <Frame31>
                  <XL>{women}</XL>
                  <Group3>
                    <Text28>{text32}</Text28>
                    <Checkbox
                      style={{ marginLeft: "2rem" }}
                      autoFocus={false}
                      value="womens"
                      size="large"
                    ></Checkbox>
                  </Group3>
                </Frame31> */}
                {/* <Frame31>
                  <XL>{kids}</XL>
                  <Group3>
                    <Text28>{text33}</Text28>
                    <Checkbox
                      style={{ marginLeft: "2rem" }}
                      autoFocus={false}
                      value="kids"
                      size="large"
                    ></Checkbox>
                  </Group3>
                </Frame31> */}
              </FilterList1>
            </Checkbox.Group>
          </FlexCol1>
        </Frame9>
        {otherFilterValue && (
          <>
            <Frame9>
              <Frame>{frame1Props.children}</Frame>
              <div style={{ marginTop: "2rem" }}>
                <div>
                  <OverlapGroup>
                    <Slider
                      tipFormatter={null}
                      min={
                        otherFilterValue?.minimum && otherFilterValue?.minimum
                      }
                      max={
                        otherFilterValue?.maximum && otherFilterValue?.maximum
                      }
                      value={range}
                      onChange={(value) => handleRangeChange(value)}
                      range={{
                        draggableTrack: true,
                      }}
                    />
                  </OverlapGroup>
                  {/* 
              <OverlapGroup>
                <Rectangle></Rectangle>
                <Rectangle1></Rectangle1>
                <Rectangle2></Rectangle2>
              </OverlapGroup> */}
                </div>
                <Frame7>
                  <Frame8>
                    <Price>{price1}</Price>
                    <Price1> &nbsp; &nbsp; ₹{range[0]}</Price1>
                    <Text27>{text27}</Text27>
                    <Price2> ₹{range[1]}</Price2>
                  </Frame8>
                  <AutoLayoutHorizontal>
                    <Filter
                      style={{ cursor: "pointer" }}
                      onClick={selectPriceValue}
                    >
                      {filter}
                    </Filter>
                  </AutoLayoutHorizontal>
                </Frame7>
              </div>
            </Frame9>
            <Frame9>
              <Frame className={frame2Props.className}>
                {frame2Props.children}
              </Frame>

              <Checkbox.Group
                onChange={onChangeSelectSize}
                autoFocus={false}
                style={{ width: "100%", flexWrap: 'wrap', display: 'flex' }}
                value={allSize}
              >
                <FilterList>
                  {otherFilterValue?.sizes?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    return (
                      <Frame31 key={index}>
                        <Group3>
                          <Checkbox
                            style={{ marginRight: "2rem", color: "black" }}
                            autoFocus={false}
                            value={key}
                            size="large"
                          ></Checkbox>
                        </Group3>

                        <XL>{key}</XL>
                        <Text28>({item[key]})</Text28>
                      </Frame31>
                    );
                  })}
                  {/* <Frame31>
              <XL>{m}</XL>

                <Group3>
                  <div>
                    <Text28>{text29}</Text28>
                  </div>
                  <Checkbox
                    style={{ marginLeft: "2rem" }}
                    autoFocus={false}
                    value="m"
                    size="large"
                  ></Checkbox>
                </Group3>
              </Frame31>
              <Frame31>
                <XL>{price4}</XL>
                <Group3>
                  <Text28>{text30}</Text28>
                  <Checkbox
                    style={{ marginLeft: "2rem" }}
                    autoFocus={false}
                    value="s"
                    size="large"
                  ></Checkbox>
                </Group3>
              </Frame31> */}
                </FilterList>
              </Checkbox.Group>
            </Frame9>
          </>
        )}
      </div>

      <Row justify='center' align='middle' onClick={() => setFilterDrawerToggle(false)} style={{ fontSize: 20, position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: '#2f4799', color: 'white', fontWeight: 'bold', padding: 20 }}>
        Submit
      </Row>
    </Drawer>
    </>
  );
};

const FlexCol = styled.div`
  width: 1512px;
  position: relative;
  margin-left: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 69px;
`;

const OverlapGroup2 = styled.div`
  width: 1512px;
  margin-right: 0.5px;
  display: flex;
  flex-direction: column;
  padding: 82.5px 56px;
  align-items: flex-start;
  min-height: 250px;
  gap: 5px;
  background-size: 100% 100%;
`;

const Title = styled.h1`
  ${ValignTextMiddle}
  height: 50px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 14px;
`;

const Place = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
`;

const Vector = styled.img`
  position: relative;
  min-width: 5.45166015625px;
  height: 11px;
`;

const Shop1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Banner1 = styled.img`
  width: 1400px;
  height: 150px;
  margin-right: 0.5px;
  object-fit: cover;
`;

const DivtbayFilter = styled.div`
  height: 48px;
  margin-left: 1.5px;
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  min-width: 1400px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--alto);
`;

const Showing112Of32Results = styled.p`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            height: 28px;
  min-width: 176px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const DivtbayOrdering = styled.div`
  display: flex;
  position: relative;
  margin-left: 922px;
  margin-top: 3px;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0px 4px;
`;

const SortBy = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const PCaptionCont = styled.div`
  position: relative;
  min-width: 138.52000427246094px;
  height: 24px;
  margin-left: -0.4199981689453125px;
  border-radius: 2px;
`;

const DefaultSorting = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 24px;
  top: -1px;
  left: 7px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 125px;
`;

const DivdisplayModeWarpper = styled.div`
  height: 24px;
  margin-left: 24px;
  margin-top: 2.88px;
  display: flex;
  padding: 0.2px 1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 87px;
  gap: 17px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

const Frame3 = styled.img`
  width: 22px;
  height: 22px;
`;

const FlexRow = styled.div`
  margin-top: 24px;
  margin-right: 1px;
  display: flex;
  align-items: flex-start;
  min-width: 1399px;
  gap: 39px;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Frame36949 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 46px;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  max-height: 600px;
  object-fit: contain;
`;

const Frame4 = styled.div`
  position: relative;
  min-width: 320px;
`;

const Frame5 = styled.div``;

const OverlapGroup = styled.div`
  width: 321px;

  position: relative;
`;

const Rectangle = styled.div`
  position: absolute;
  width: 320px;
  height: 2px;
  top: 7px;
  left: 0;
  background-color: var(--primarycolor);
  border-radius: 11.2px;
`;

const Rectangle1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Rectangle2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 305px;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Frame7 = styled.div``;

const Frame8 = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-top: -1px;
  min-width: 43px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 30px;
  margin-left: 1px;
  margin-top: -1px;
  min-width: 35px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text27 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 22px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 5px;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 41.529998779296875px 0px 40.470001220703125px;
  background-color: var(--primarycolor);
`;

const Filter = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: var(--font-size-xxxs);
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 52px;
  white-space: nowrap;
`;

const Frame9 = styled.div`
  position: relative;
  min-width: 320px;
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  left: 0;
`;

const Frame31 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
`;

const XL = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: relative;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 53px;
  height: 22px;
  display: flex;
`;

const Text28 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalMountainMist13px}
  margin-left: 10px;
  letter-spacing: 0;
  white-space: nowrap;
`;

const FlexCol1 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ProductCategories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
            height: 25px;
  margin-bottom: -3px;
  letter-spacing: 0;
  height: 36px;
  white-space: nowrap;
  background-color: #efefef;
  font-weight: bold;
  border-radius: 8px;
  padding: 0px 12px;
  width: 100%;
`;

const Rectangle3 = styled.div`
  width: 320px;
  height: 1px;
  margin-top: 16px;
  background-color: var(--alto);
`;

const FilterList1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
`;

const FlexCol2 = styled.div`
  width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  flex-wrap: wrap;
`;

const Group2 = styled.div`
  position: relative;
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
  min-width: 1038px;
  gap: 39px;
  flex-wrap: wrap;
`;

const UlpageNumbers = styled.div`
  display: flex;
  padding: 0 400px;
  align-items: center;
  min-width: 1040px;
`;

const SpanpageNumbers = styled.div`
  height: 48px;
  display: flex;
  padding: 12px 19.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 48px;
  background-color: var(--primarycolor);
`;

const Number = styled.div`
  ${ValignTextMiddle}
  width: 9px;
  height: 21px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 36px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 55px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;

export default filterComp;
