import React from "react";
import { Link } from "react-router-dom";
import FilterComp from "./filterComp";
import {
  InterNormalBunker13px,
  InterNormalBunker14px,
  InterNormalBlack14px,
  InterNormalStormDust15px,
  InterNormalMountainMist13px,
  InterNormalBunker18px,
  ValignTextMiddle,
} from "../../../../styledMixins";
import styled from "styled-components";
import { useState } from "react";
import Product from "../../../Product";
// import { Pagination } from "antd";
import Pagination from "../../../Pagination";
import notFoundImg from "../../../../images/data_not_found.jpg";
import { Col, Spin } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const FilterProductUI = (props) => {
  const [sortingData, setSortingData] = useState();

  const history = useHistory();

  const {
    bannerImg,
    title,
    place1,
    shop,
    handlePageChange,
    productsData,
    pageCount,
    currentPage,
    page,
    loader,
    setWishListAdded,
    params,
  } = props;

  const selectSortingData = (value) => {
    // console.log(value?.target?.value);
    setSortingData(value?.target?.value);
    params.set("sort", value?.target?.value);

    history.push({ search: params.toString() });
  };

  console.log(sortingData);

  // sort_type = price or discount_per
  // sort_value ASC or DSC for both
  // price and discount_per

  return (
    <div className="container-center-horizontal">
      <div className="shop screen">
        <FlexCol>
          <OverlapGroup2
            className="shop-title"
            style={{ backgroundImage: `url(${bannerImg})` }}
          >
            <Title>{title}</Title>
            <Frame2>
              <Link to="/home">
                <Place>{place1}</Place>
              </Link>
              <Vector src="/img/vector-2.svg" alt="Vector" />
              <Shop1>{shop}</Shop1>
            </Frame2>
          </OverlapGroup2>

          <DivtbayFilter>
            <span
              style={{
                marginTop: "8px",
                "font-weight": "700",
                color: "#282c3f",
                paddingRight: "10px",
              }}
            >
              Sort By:
            </span>
            <select
              name="cars"
              id="cars"
              className="sorting-options"
              style={{
                marginRight: "10px",
                marginBottom: "4px",
                // width: "17%",
                "border-radius": "4px",
                "background-color": "#fff",
                border: "1px solid",
                // "text-transform": "capitalize",
                "font-weight": "700",
                color: "#282c3f",
                fontSize:"15px",
              }}
              onChange={(value) => selectSortingData(value)}
            >
              <option
                value="ASC/price"
                style={{ "font-weight": "700", color: "#282c3f" }}
              >
                Price: Low to High
              </option>
              <option
                value="DSC/price"
                style={{ "font-weight": "700", color: "#282c3f" }}
              >
                Price: High to Low
              </option>
              <option
                value="ASC/discount_per"
                style={{ "font-weight": "700", color: "#282c3f" }}
              >
                Discount: Low to High
              </option>
              <option
                value="DSC/discount_per"
                style={{ "font-weight": "700", color: "#282c3f" }}
              >
                Discount: High to Low
              </option>
            </select>
          </DivtbayFilter>
        </FlexCol>

        <FlexRow className="shop-box">
          <FilterComp setFilterData="" props={props} />
          {loader ? (
            <div style={{ margin: "0 auto", paddingTop: "2rem" }}>
              <Spin size="large" />
            </div>
          ) : productsData?.length ? (
            <div className="responsive-product-card-div">
              <FlexCol2 className="shop-main">
                {productsData?.length && (
                  <Group2>
                    {productsData?.map((item, index) => (
                      <Product
                        key={index}
                        item={item}
                        setWishListAdded={setWishListAdded}
                        // className={product1Props?.className}
                        src={item?.images[0]?.url}
                        // fetchData={fetchCollectionItem}
                      />
                    ))}
                  </Group2>
                )}

                <Pagination
                  length={pageCount}
                  active={currentPage}
                  onSelect={handlePageChange}
                />
                {/* <Pagination 
                      current={1}
                      pageSize={10}
                    /> */}
              </FlexCol2>
            </div>
          ) : (
            <div
              className="responsive-product-card-div"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <img
                src={notFoundImg}
                style={{ objectFit: "contain", width: 300 }}
              />
              <span
                style={{ fontWeight: "bold", fontSize: 24, marginBottom: 12 }}
              >
                Couldn't find the product
              </span>
              <span style={{ fontSize: 16 }}>
                Sorry, We couldn't find anything. You can try
                <br />
                another search or browse our catalogue.
              </span>
            </div>
          )}
        </FlexRow>
      </div>
    </div>
  );
};

const FlexCol = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
`;

const OverlapGroup2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 82.5px 56px;
  align-items: flex-start;
  min-height: 250px;
  gap: 5px;
  background-size: cover;
`;

const Title = styled.h1`
  ${ValignTextMiddle}
  height: 50px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 14px;
`;

const Place = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
`;

const Vector = styled.img`
  position: relative;
  min-width: 5.45166015625px;
  height: 11px;
`;

const Shop1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Banner1 = styled.img`
  width: 1400px;
  height: 150px;
  margin-right: 0.5px;
  object-fit: cover;
`;

const DivtbayFilter = styled.div`
  height: 40px;

  width: 100%;
  justify-content: end;
  display: flex;
  padding: 1px 0;
  // align-items: flex-start;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--alto);
`;

const Showing112Of32Results = styled.p`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  margin-left: 20px;
`;

const DivtbayOrdering = styled.div`
  display: flex;
  position: relative;
  margin-left: 922px;
  margin-top: 3px;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0px 4px;
`;

const SortBy = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const PCaptionCont = styled.div`
  position: relative;
  min-width: 138.52000427246094px;
  height: 24px;
  margin-left: -0.4199981689453125px;
  border-radius: 2px;
`;

const DefaultSorting = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 24px;
  top: -1px;
  left: 7px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 125px;
`;

const DivdisplayModeWarpper = styled.div`
  height: 24px;
  margin-left: 24px;
  margin-top: 2.88px;
  display: flex;
  padding: 0.2px 1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 87px;
  gap: 17px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

const Frame3 = styled.img`
  width: 22px;
  height: 22px;
`;

const FlexRow = styled.div``;

const Frame36949 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 46px;
`;

const Image = styled.img`
  position: relative;
  min-width: 320px;
  height: 588px;
`;

const Frame4 = styled.div`
  position: relative;
  min-width: 320px;
  height: 193px;
`;

const Frame5 = styled.div`
  position: absolute;
  width: 320px;
  top: 63px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 130px;
  gap: 10px;
`;

const Frame6 = styled.div`
  height: 2px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  background-color: var(--seashell);
  border-radius: 16px;
`;

const OverlapGroup = styled.div`
  width: 321px;
  height: 16px;
  position: relative;
  margin-top: -7px;
`;

const Rectangle = styled.div`
  position: absolute;
  width: 320px;
  height: 2px;
  top: 7px;
  left: 0;
  background-color: var(--primarycolor);
  border-radius: 11.2px;
`;

const Rectangle1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Rectangle2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 305px;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Frame7 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 106px;
  gap: 12px;
`;

const Frame8 = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-top: -1px;
  min-width: 43px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 30px;
  margin-left: 1px;
  margin-top: -1px;
  min-width: 35px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text27 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 22px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 5px;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 41.529998779296875px 0px 40.470001220703125px;
  background-color: var(--primarycolor);
`;

const Filter = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: var(--font-size-xxxs);
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 52px;
  white-space: nowrap;
`;

const Frame9 = styled.div`
  position: relative;
  min-width: 320px;
  height: 165px;
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 69px;
  left: 0;
`;

const Frame31 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 191px;
  position: relative;
`;

const XL = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: relative;
  width: 78px;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 53px;
  height: 22px;
  margin-right: -2px;
`;

const Text28 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalMountainMist13px}
            position: absolute;
  width: 19px;
  height: 22px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const FlexCol1 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 159px;
`;

const ProductCategories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
            height: 25px;
  margin-bottom: -3px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Rectangle3 = styled.div`
  width: 320px;
  height: 1px;
  margin-top: 16px;
  background-color: var(--alto);
`;

const FilterList1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
`;

const FlexCol2 = styled.div`
  // width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // gap: 29px;
  flex-wrap: wrap;
`;

const Group2 = styled.div`
  width: 100%;
  position: relative;
  // margin-left: 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  // min-width: 1038px;
  // gap: 39px;
  flex-wrap: wrap;
`;

const UlpageNumbers = styled.div`
  display: flex;
  padding: 0 400px;
  align-items: center;
  min-width: 1040px;
`;

const SpanpageNumbers = styled.div`
  height: 48px;
  display: flex;
  padding: 12px 19.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 48px;
  background-color: var(--primarycolor);
`;

const Number = styled.div`
  ${ValignTextMiddle}
  width: 9px;
  height: 21px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 36px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 55px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;
