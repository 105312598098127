import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Switch, BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Rate } from "antd";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
  InterSemiBoldSapphire14px,
} from "../../../styledMixins";

import "../MyProfile.css";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TimeLine from "./cusTimeLine";

const OrderStatus = ({ order }) => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const orderTrack = [
    "Ordered",
    "Processing",
    "Processed",
    "Ready To Dispatch",
    "Out For Delivery",
    "Delivered",
  ];

  return (
    <>
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
            className="order-detail-header"
          >
            <Link style={{ color: "#fff" }} to={"/my-profile/orders"}>
              <div style={{ alignSelf: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>{" "}
                Back
              </div>
            </Link>

            {order?.order_id && (
              <div
                style={{
                  color: "#fff",
                  marginTop: "0.3rem",
                  marginBottom: "0.5rem",
                }}
              >
                {(order.overall_status === orderTrack[2] ||
                  order.overall_status === orderTrack[3] ||
                  order.overall_status === orderTrack[4] ||
                  order.overall_status === orderTrack[5]) && (
                  <a
                    href={order?.invoice?.url}
                    download="Invoice.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        padding: "4px 8px",
                        fontSize: 12,
                        fontWeight: "bold",
                        border: "1px white solid",
                      }}
                    >
                      View Invoice
                    </button>
                  </a>
                )}
                <span>
                  {" "}
                  {/* {order?.items?.length} item{order?.items?.length > 1 && "s"}{" "} */}
                  {/* {order?.overall_status} */}
                </span>
                &nbsp; &nbsp; <span> ORDER ID: {order?.order_id}</span>
              </div>
            )}
          </div>

          {(Object.keys(order).length !== 0 &&
            order.items.map((item, index) => (
              <Link key={index} to={`/singleShop?${item && item._id}`}>
                <OverlapGroup1 style={{ cursor: "pointer" }} key={index}>
                  <Group37002 style={{ padding: 12 }}>
                    {/* <RadioButtons src="/img/" alt="radio-buttons" /> */}
                    {/* <br /> */}

                    <div style={{ display: "flex", maxWidth: "100%" }}>
                      <Group36968
                        onClick={() =>
                          history.push(`/my-profile/orders/${item?.order_id}`)
                        }
                        style={{
                          backgroundImage: `url(${"/img/no_image.png"})`,
                        }}
                      >
                        {item?.images.map(
                          (e) =>
                            e.image_type === "main" && (
                              <div
                                style={{
                                  height: "100%",
                                  backgroundColor: "white",
                                }}
                              >
                                <img
                                  src={e?.url}
                                  width="100%"
                                  height="100%"
                                  alt="image"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                            )
                        )}
                      </Group36968>

                      <div
                        style={{
                          maxWidth: "calc( 100% - 160px )",
                          alignSelf: "end",
                          marginBottom: "-9px",
                        }}
                      >
                        <UrbanoFashion
                          style={{
                            "white-space": "break-spaces",
                            "word-break": "break-word",
                            "line-height": "26px",
                          }}
                        >
                          {item?.name}
                        </UrbanoFashion>
                        <Name1 style={{ marginTop: "-1px" }}>
                          {item?.description}
                        </Name1>
                        {/* <Spanbox size30M={spanboxProps.size30M} /> */}
                        <div
                          style={{
                            display: "flex",
                            marginTop: " -10px",
                            marginBottom: "-12px",
                            paddingTop: "9px",
                            borderTop: "1px solid #dedede",
                          }}
                        >
                          {item?.size_of_product !== undefined && (
                            <>
                              <Name1
                                style={{ marginBottom: "0" }}
                                className="price-4"
                              >
                                Size : &nbsp;
                              </Name1>
                              <Name
                                style={{
                                  alignSelf: "start",
                                  fontSize: "14px",
                                  color: "black",
                                }}
                              >
                                {item?.size_of_product} &nbsp;&nbsp;&nbsp;
                              </Name>
                            </>
                          )}

                          <Name1
                            style={{ marginBottom: "0" }}
                            className="price-4"
                          >
                            Qty: &nbsp;
                          </Name1>
                          <Name
                            style={{
                              alignSelf: "start",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            {item?.quantity}
                          </Name>
                        </div>
                        {/* <div className="quantify-button">
                        <div>
                          <Spanbox
                            style={{ marginRight: "1rem" }}
                            size30M={`Size : ${
                              (item?.size_of_product !== undefined &&
                                item?.size_of_product) ||
                              ""
                            }`}
                          />
                        </div>
                        <div>
                          <Spanbox size30M={`Quantity : ${item?.quantity}`} />
                        </div>
                      </div> */}
                        <div>
                          <div style={{ marginTop: "1rem", display: "flex" }}>
                            {/* <Name1 style={{ marginBottom: "0" }}>
                              {" "}
                              Price: &nbsp;
                            </Name1>{" "} */}
                            {(item?.warehouses?.MRP ===
                              item?.warehouses?.ASP && (
                              <div className="auto-layout-horizontal-9">
                                <Price1 className="price-4">
                                  ₹ {item?.warehouses && item?.warehouses?.ASP}
                                </Price1>
                              </div>
                            )) || (
                              <div style={{ display: "flex" }}>
                                <div className="auto-layout-horizontal-9">
                                  <Price
                                    style={{
                                      color: "#8f8f8f",
                                      textDecoration: "line-through",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                    }}
                                    className="price-4"
                                  >
                                    ₹{" "}
                                    {item?.warehouses && item?.warehouses?.MRP}{" "}
                                  </Price>
                                </div>

                                {/* <Text3 className="text-3-1">
                      &nbsp; &nbsp; – &nbsp;&nbsp;
                    </Text3> */}
                                <div className="auto-layout-horizontal-10">
                                  <Price1 className="price-5">
                                    &nbsp; ₹{" "}
                                    {item?.warehouses && item?.warehouses?.ASP}
                                  </Price1>
                                </div>
                                <div>
                                  {item?.warehouses &&
                                    item?.warehouses?.discount_per !== 0 && (
                                      <Price1 className="price-5 color-primary">
                                        &nbsp; (
                                        {item?.warehouses &&
                                          item?.warehouses?.discount_per}
                                        % OFF)
                                      </Price1>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "0rem",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {/* <Frame src="/img/frame.svg" alt="Frame" /> */}
                            {/* <MenBlackSlimFitM>
                          Ratings &nbsp; &nbsp;
                        </MenBlackSlimFitM> */}
                            {/* <Frame36950>
                              <Rate
                                style={{
                                  fontSize: 16,
                                }}
                                allowHalf
                                defaultValue={item?.rating}
                              />
                              <Name1 style={{ margin: "0" }}>
                                &nbsp; {item?.rating} Ratings
                              </Name1>
                            </Frame36950> */}
                            {/* <Rating5Stars
                    src="/img/rating-5-stars.svg"
                    alt="Rating 5 stars"
                  /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Group37002>
                </OverlapGroup1>
              </Link>
            ))) || <p className="para">Data Not Found</p>}
          <br />

          <Group37002 style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <UrbanoFashion>{"Payment Details"}</UrbanoFashion>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="para">Total MRP</p>
                <p className="para bold">₹ {order?.amount?.total_mrp} </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="para">Bag Discount</p>
                <p className="para bold">- ₹ {order?.amount?.discount} </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="para">Sub Total</p>
                <p className="para bold"> {order?.amount?.total_asp}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="para">Shipping charges</p>
                <p className="para bold">
                  {" "}
                  {order?.amount?.delivery_charges == 0
                    ? "Free"
                    : "+ ₹ " + order?.amount?.delivery_charges}{" "}
                </p>
              </div>

{order?.amount?.handling_charges > 0 && 
  <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="para">Handling charges</p>
                <p className="para bold">
                  {" "}
                  {"+ ₹ " + order?.amount?.handling_charges}{" "}
                </p>
              </div>
}
              



{order?.amount?.cod_charge > 0 && 
  <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="para">COD charges</p>
                <p className="para bold">
                  {" "}
                  {"+ ₹ " + order?.amount?.cod_charge}{" "}
                </p>
              </div>
}
              

              <div
                style={{
                  borderTop: "1px solid #c1c1c1",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "0.6rem",
                  paddingTop: "0.5rem",
                }}
              >
                <p style={{ fontSize: "17px" }} className="para bold">
                  You Paid
                </p>
                <div>
                  <p
                    style={{ textAlign: "right", fontSize: "17px" }}
                    className="para bold"
                  >
                    ₹{" "}
                    {/* {(order?.payment?.pay_amount?.cod !== 0 &&
                      order?.payment?.pay_amount?.cod) ||
                      order?.payment?.pay_amount?.online}{" "} */}
                    {order?.amount?.payable}
                  </p>
                  <div style={{ display: "flex" }}>
                    <Name1 style={{ margin: 0, padding: 0 }}>
                      Payment Mode:
                    </Name1>{" "}
                    <Name style={{ alignSelf: "start" }}>
                      &nbsp; {order?.payment?.payment_mode}
                    </Name>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </Group37002>

          <br />

          <Group37002 style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <UrbanoFashion>Track Order</UrbanoFashion>
            </div>
            <div>
              <br />
              <br />
              {/* {order?.status?.map((item) => (
                      <TimeLine date={item?.created_at} name={item?.status} />
                    ))} */}

              {(order?.overall_status === "Cancelled" && (
                <>
                  {Array.from(
                    new Set(order?.status?.map((item) => item?.status))
                  ).map((status) => {
                    const uniqueItem = order?.status?.find(
                      (item) => item?.status === status
                    );
                    return (
                      <div key={status}>
                        <TimeLine
                          other={uniqueItem?.status}
                          date={uniqueItem?.created_at}
                          name={uniqueItem?.status}
                          text={` Your Order
                  {${
                    uniqueItem?.status === "Ordered"
                      ? "has been placed successfully"
                      : uniqueItem?.status
                  }}`}
                        />
                      </div>
                    );
                  })}
                </>
              )) || (
                <>
                  <>
                    {/* {order?.status?.map((item, index) => (
                      <TimeLine
                        key={index}
                        date={item?.created_at}
                        other={item.status}
                        name={item.status}
                        text={"Your Order has been placed successfully"}
                      />
                    ))} */}
                  </>
                  {/* {order?.status?.map((item) => (
                    <TimeLine date={item?.created_at} name={item?.status} />
                  ))} */}
                  {/* <hr /> */}

                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[0]?.created_at
                    }
                    other={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[0]?.status
                    }
                    name={"Ordered"}
                    text={"Your Order has been placed successfully"}
                  />
                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[1]?.created_at
                    }
                    other={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[1]?.status
                    }
                    name={"Processing"}
                    text={"Your Order Processing"}
                  />
                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[2]?.created_at
                    }
                    other={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[2]?.status
                    }
                    name={"Processed"}
                    text={"Your Order has been Processed"}
                  />
                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[3]?.created_at
                    }
                    other={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[3]?.status
                    }
                    name={"Ready To Dispatch"}
                    text={"Your Order is ready to Dispatch"}
                  />
                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[4]?.created_at
                    }
                    other={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[4]?.status
                    }
                    name={"In Transit"}
                    text={"Your Order is In Transit"}
                  />

                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[5]?.created_at
                    }
                    other={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[5]?.status
                    }
                    name={"Out For Delivery"}
                    text={"Your Order is out for delivery"}
                  />

                  <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[6]?.created_at
                    }
                    name={"Delivered"}
                  />
                </>
              )}
            </div>
          </Group37002>
          <br />

          <Group37002 style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <UrbanoFashion>Shipping Details</UrbanoFashion>
            </div>
            <div>
              <div style={{ display: "flex", marginTop: "0.4rem" }}>
                {(order?.user?.name && (
                  <p
                    style={{
                      color: "black",
                      fontWeight: "400",
                      lineHeight: "22px",
                      textTransform: "capitalize",
                      borderRight: "1px solid black",
                    }}
                    className="para "
                  >
                    {order?.address?.name} &nbsp;&nbsp;
                  </p>
                )) ||
                  "Mobile :"}

                <p
                  style={{
                    color: "black",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                  className="para"
                >
                  &nbsp;&nbsp; {order?.user?.mobile}
                </p>
              </div>

              <Name1 style={{ marginTop: "9px" }}>
                {order?.address?.house_no},{order?.address?.block} <br />
                {order?.address?.society} <br />
                {order?.address?.state}&nbsp;-&nbsp;
                {order?.address?.pincode}
              </Name1>
              {/* {order?.address?.address_type !== "" && (
                <Name1 style={{ marginTop: "4px" }}>
                  {order?.address?.address_type}
                </Name1>
              )} */}
            </div>
          </Group37002>
        </div>
      </div>
    </>
  );
};

const Price1 = styled.div`
  ${InterSemiBoldSapphire14px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Price = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

// const Group37002 = styled.div`
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   margin-top: 1.4rem;
//   margin-bottom: 1.4rem;
//   display: flex;
//   justify-content: space-between;
//   padding: 15px 19px;
//   align-items: flex-start;
//   gap: 8px;
// `;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;
const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const X10CustomerReviews = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  position: absolute;
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  max-width: 100%;
  width: 783px;
  position: relative;
  margin-top: 16px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  padding: 15px 19px;
  max-width: 100%;
  width: 783px;
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  position: relative;
  margin-top: 0.26px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #c4c4c4;
`;

const Name = styled.div`
  ${ValignTextMiddle}

  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  letter-spacing:1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
  line-height: 21.4px;
  white-space: nowrap;

  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;

const Group36968 = styled.div`
  width: 151px;

  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
  margin-right: 12px;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;

  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}

  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
      

  
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
    
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default OrderStatus;
