import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Switch, BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Rate, Badge } from "antd";

import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
  InterSemiBoldSapphire14px,
} from "../../../styledMixins";

import "../MyProfile.css";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TimeLine from "./cusTimeLine";
import NextStepComp from "./NextStepComp";

const ReturnConfirm = ({ order }) => {
  const history = useHistory();

  return (
    <>
      <div>
        <div>
          <br />
          <br />

          <div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to={"/my-profile/orders"}>
                <div style={{ alignSelf: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>{" "}
                  &nbsp; Back
                </div>
              </Link>
              {/* <div className="color-primary"> </div> */}
            </div>
          </div>

          {(Object.keys(order).length !== 0 &&
            order.items.map((item, index) => (
              <>
                <div>
                  <div className="check-confirm">
                    <svg
                      width="36"
                      height="26"
                      viewBox="0 0 36 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.125 13L13.03 22.905L32.875 3.09503"
                        stroke="white"
                        stroke-width="5.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <UrbanoFashion
                    style={{ marginTop: "0.7rem", fontSize: "18px" }}
                    className="text-center"
                  >
                    Return Request Submitted
                  </UrbanoFashion>
                  <Name1
                    className="text-center"
                    style={{ marginTop: "1px", fontSize: "16px" }}
                  >
                    Reference number: 4332429538
                  </Name1>
                </div>

                <OverlapGroup1
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid #D4D4D4",
                  }}
                  key={index}
                >
                  <Group37002
                    style={{
                      boxShadow: "none",
                      border: "none",
                    }}
                  >
                    {/* <RadioButtons src="/img/" alt="radio-buttons" /> */}
                    {/* <br /> */}
                  </Group37002>
                </OverlapGroup1>
                <br />

                <div
                  style={{
                    padding: "0.5rem",
                    display: "flex",
                    background: "#F6F6F6",
                  }}
                >
                  <Group36968
                    onClick={() =>
                      history.push(`/my-profile/orders/${item?.order_id}`)
                    }
                    style={{
                      width: "80px",
                      height: "80px",
                      backgroundImage: `url(${"/img/no_image.png"})`,
                    }}
                  >
                    <Link to={`/singleShop?${item && item._id}`}>
                      {item?.images.map(
                        (e) =>
                          e.image_type === "main" && (
                            <Group36968
                              style={{
                                width: "80px",
                                height: "80px",
                                backgroundImage: `url(${e?.url})`,
                              }}
                            ></Group36968>
                          )
                      )}
                    </Link>
                  </Group36968>

                  <div
                    style={{
                      marginLeft: "0.5rem",
                      width: "100%",
                      alignSelf: "center",
                      marginBottom: "-9px",
                    }}
                  >
                    <p
                      style={{
                        lineHeight: "25px",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                      className="para text-left para-color"
                    >
                      A refund of &nbsp;
                      <span className="color-primary">
                        {" "}
                        Rs. {item?.warehouses && item?.warehouses[0]?.ASP}
                        .00
                      </span>{" "}
                      &nbsp; will be initiated after the item is picked up and
                      quality check has passed.
                    </p>
                    {/* <Spanbox size30M={spanboxProps.size30M} /> */}
                  </div>
                </div>
              </>
            ))) || <p className="para">Data Not Found</p>}
          <br />

          <br />

          <Group37002 style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <UrbanoFashion>Information</UrbanoFashion>
            </div>
            <div>
              <br />
              <br />
              {/* {order?.status?.map((item) => (
                      <TimeLine date={item?.created_at} name={item?.status} />
                    ))} */}

              {(order?.overall_status === "Cancelled" && "") || (
                // <>
                //   {Array.from(
                //     new Set(order?.status?.map((item) => item?.status))
                //   ).map((status) => {
                //     const uniqueItem = order?.status?.find(
                //       (item) => item?.status === status
                //     );
                //     return (
                //       <div key={status}>
                //         <TimeLine
                //           date={uniqueItem?.created_at}
                //           name={uniqueItem?.status}
                //         />
                //       </div>
                //     );
                //   })}
                // </>
                <>
                  {/* {order?.status?.map((item) => (
                          <TimeLine
                            date={item?.created_at}
                            name={item?.status}
                          />
                        ))} */}

                  <TimeLine
                    isReturn={true}
                    text={"7 Days"}
                    name={"PICKUP WITHIN"}
                  />
                  <TimeLine
                    text={
                      "Keep your products and brand tags intact to give it back to delivery agent."
                    }
                    isReturn={true}
                    name={"PLEASE NOTE"}
                  />

                  {/* <TimeLine
                    date={
                      order?.status &&
                      order?.status.length > 0 &&
                      order?.status[5]?.created_at
                    }
                    name={"Delivered"}
                  /> */}
                </>
              )}
            </div>
          </Group37002>
          <br />

          <br />

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <UrbanoFashion>Next Step</UrbanoFashion>
            </div>
            <br />
            <div>
              <NextStepComp text={"Return Request Submitted"} isReturn={true} />
              <NextStepComp
                text={
                  "Our Style agent will come & pick up the item from the pickup address."
                }
                isReturn={false}
              />
              <NextStepComp
                text={
                  "Refund will be initiated once we receive the item and it passes the quality check."
                }
                isReturn={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Price1 = styled.div`
  ${InterSemiBoldSapphire14px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Price = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

// const Group37002 = styled.div`
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   margin-top: 1.4rem;
//   margin-bottom: 1.4rem;
//   display: flex;
//   justify-content: space-between;
//   padding: 15px 19px;
//   align-items: flex-start;
//   gap: 8px;
// `;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;
const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const X10CustomerReviews = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  position: absolute;
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  position: relative;
  margin-top: 16px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  padding: 15px 19px;

  min-width: 783px;
  gap: 8px;
  background-color: var(--lg);
  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  position: relative;
  margin-top: 0.26px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #c4c4c4;
`;

const Name = styled.div`
  ${ValignTextMiddle}

  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  letter-spacing:1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 151px;

  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;

  top: 198px;
  left: 616px;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}

  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
      

  
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
    
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  position: absolute;
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default ReturnConfirm;
