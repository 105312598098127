import { Button, Modal } from "antd";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import axios from "axios";
import {
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";

import type, { CheckboxValueType } from "antd/es/checkbox/Group";

import { Button, Divider, notification, Space } from "antd";
// import type { NotificationPlacement } from "antd/es/notification/interface";

const Context = React.createContext({ name: "Default" });

import { sendOTP, verifyOtp, sendOtpAgain } from "../api/login";
import Notification from "./Notification";
import { getCouponApi, getWalletApi } from "../api/wallet";

// import { sendMobileOtp, verifyOtp } from "../../api/loginApi";
// import { useHistory } from "react-router-dom";
// import login from "../../images/login.jpg";
// import { Divider } from "antd";

const Coupon = ({ isOpen, setIsOpen }) => {
  const [isView, setIsView] = useState(false);
  const onChange = (checkedValues) => {
  };
  const [api, contextHolder] = notification.useNotification();

  const Ref = useRef(null);

  const [couponCode, setCouponCode] = useState("");
  const [resended, setResended] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //   let history = useHistory();

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isExpire, setIsExpire] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button

  const handleReset = () => {
    setErrorMessage("");
    setOtpPage(false);
    setIsExpire(false);
    setIsMobile(false);
    setOtp("");
    setResended(true);
  };

  const fetchCoupon = async () => {
    const { data } = await getCouponApi(pageNum, pageSize);
    
  };

  useEffect(() => {
    fetchCoupon();
  }, [0]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const viewDetail = (e) => {
    e.preventDefault();
    setIsView(true);
  };

  return (
    <>
      <Modal width={500} onCancel={handleCancel} footer={[""]} open={isOpen}>
        <div style={{}}>
          <div className="d-flex">
            <div>
              <div className="">
                <div className="bg-transparent ">
                  <div className="text-muted text-center ">
                    <h3
                      style={{
                        borderBottom: "0.4px solid #00000036",
                        marginBottom: "1rem",
                        paddingBottom: "0.7rem",
                        fontSize: "17px",
                        textAlign: "left",
                      }}
                      className="third-heading"
                    >
                      COUPONS
                    </h3>
                  </div>
                </div>

                {(isView === true && (
                  <div>
                    <h2 className="third-heading">
                      {" "}
                      <Button onClick={() => setIsView(false)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="12"
                          fill="currentColor"
                          className="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            fill="#f4792c"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>
                        Back
                      </Button>{" "}
                    </h2>
                    <div className="coupon-section">
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{ width: "40%", margin: "auto" }}
                          className="ticket"
                        >
                          <div className="inner-ticket">
                            <h3 className="small-header">NEWUSER30</h3>
                            <p className="x-small-para">
                              Expires on Sun Dec 31, 2023
                            </p>
                          </div>
                        </div>
                        <br />
                        <h2 className="small-heading">
                          Get 20% Cashback Upto Rs. 200 On Second Order
                        </h2>
                        <p className="small-para ">
                          Applicable on minimum order value of items worth Rs
                          299 or more
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <br />
                      <h3 className="small-header">
                        {" "}
                        <h3 className="third-heading">Terms and Conditions</h3>
                      </h3>
                      <ul>
                        <li className="list-style">
                          <p className="small-para ">
                            Eligible for only user who had applied first order
                            coupon "JULY10"
                          </p>
                        </li>
                        <li className="list-style">
                          <p className="small-para ">
                            Get flat cashback of 20% or upto Rs 200
                          </p>
                        </li>
                        <li className="list-style">
                          <p className="small-para ">
                            Cashback will be credited to Deerika wallet within
                            24-48 hrs
                          </p>
                        </li>
                        <li className="list-style">
                          <p className="small-para ">
                            Two offers/ cashback cannot be clubbed together
                          </p>
                        </li>
                        <li className="list-style">
                          <p className="small-para ">
                            Offer valid once per user
                          </p>
                        </li>
                        <li className="list-style">
                          <p className="small-para ">Other T&C's may apply</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                )) || (
                  <div>
                    <h2 className="third-heading">Your Promo Codes</h2>
                    <div className="coupon-section">
                      <p className="small-para ">
                        Applicable on minimum order value of items worth Rs 299
                        more
                      </p>
                      <a
                        className="link-para"
                        href=""
                        onClick={(e) => viewDetail(e)}
                      >
                        View Details
                      </a>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="ticket">
                          <div className="inner-ticket">
                            <h3 className="small-header">NEWUSER30</h3>
                            <p className="x-small-para">
                              Expires on Sun Dec 31, 2023
                            </p>
                          </div>
                        </div>
                        <div style={{ alignSelf: "center" }}>
                          <Button>Apply</Button>
                        </div>
                      </div>
                    </div>
                    <div className="coupon-section">
                      <h3 className="small-header">NEWUSER30</h3>
                      <p className="small-para ">28-03-2023</p>
                      <a
                        onClick={(e) => viewDetail(e)}
                        className="link-para"
                        href=""
                      >
                        View Details
                      </a>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="ticket">
                          <div className="inner-ticket">
                            <h3 className="small-header">NEWUSER30</h3>
                            <p className="x-small-para">
                              Expires on Sun Dec 31, 2023
                            </p>
                          </div>
                        </div>
                        <div style={{ alignSelf: "center" }}>
                          <button
                            style={{
                              padding: "0.3rem 0.9rem",
                              fontSize: "14px",
                              textTransform: "capitalize",
                            }}
                            className="btn-primary"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="coupon-section">
                      <h3 className="small-header">Coupon on luggage item</h3>
                      <p className="small-para ">Coupon on luggage item</p>
                      <a
                        onClick={(e) => viewDetail(e)}
                        className="link-para"
                        href=""
                      >
                        View Details
                      </a>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="ticket">
                          <div className="inner-ticket">
                            <h3 className="small-header">NEWUSER30</h3>
                            <p className="x-small-para">
                              Expires on Sun Dec 31, 2023
                            </p>
                          </div>
                        </div>
                        <div style={{ alignSelf: "center" }}>
                          <button
                            style={{
                              padding: "0.3rem 0.9rem",
                              fontSize: "14px",
                              textTransform: "capitalize",
                            }}
                            className="btn-primary"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="">
                  <form onSubmit={""} role="form">
                    <div style={{ textAlign: "center" }} className="mt-5">
                      <>
                        <div
                          style={{
                            marginTop: "2rem",
                            position: "relative",
                          }}
                        >
                          <input
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            style={{
                              width: "100%",
                            }}
                            id="mobileNumber"
                            placeholder="Enter Coupon"
                            type="number"
                            autoComplete="new-email"
                            className="input-group-alternative"
                          />

                          <button
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "4px",
                            }}
                            className="outline-button small"
                            type="submit"
                          >
                            Check
                          </button>
                        </div>

                        <span className="red">
                          {couponCode.length > 10 && (
                            <>
                              <p className="para">
                                This is not a Valid Number{" "}
                                <span
                                  onClick={(e) => setPhoneDisabled(false)}
                                  className="link-para"
                                >
                                  Re-Enter-Number
                                </span>{" "}
                              </p>
                            </>
                          )}
                        </span>
                        <br />
                        <div style={{ display: "flex" }}>
                          <div>
                            <Checkbox.Group
                              autoFocus={false}
                              style={{ width: "100%" }}
                              onChange={onChange}
                            >
                              <Checkbox autoFocus={false} value="A"></Checkbox>
                            </Checkbox.Group>
                          </div>
                          <div
                            style={{ marginLeft: "1rem", textAlign: "left" }}
                          >
                            <button className="btn-dashed small" type="submit">
                              NO REturns
                            </button>
                            <div>
                              <p className="para bold text-left">Save ₹17 </p>
                              <p className="para text-left">
                                Once you apply this coupon, items will be
                                non-returnab... <span>Note:</span> Review the
                                non returnable items in your bag Expires onc
                                31st May 2023 | 11:59pm
                              </p>
                            </div>
                          </div>
                        </div>

                        <p style={{ color: "red" }}>
                          <small>{errorMessage !== "" && errorMessage}</small>
                        </p>
                        <div
                          style={{
                            marginTop: "1.5rem",
                            marginBottom: "1rem ",
                          }}
                          className="text-center"
                        ></div>
                      </>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Coupon;
