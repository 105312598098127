import axios from "axios";


// // http://dev.djtretailers.com/cart/believe

// // export const AddAddressCartApi= async (data)=>{
// //     return await axios.post(`/dev/cart/address/believe`,{
// //             name : data?.name
// //     })
// // }

export const addItemToCartApi= async (data)=>{
    return await axios.post(`/dev/cart/believe`,{
        _id:data?.id,
        quantity:data?.quantity
    })
}

export const deleteItemFromCartApi= async (data)=>{
    return await axios.delete(`/dev/cart/item/believe/${data}`)
}

export const getCartApi= async ()=>{
    return await axios.get(`/dev/cart/believe`)
}

export const addItemsToWishlistFromCart= async (data)=>{
    return await axios.post(`/dev/fashion_wishlist/add-fashion-item-to-wishlist/${data}`)
}

export const setCartCount= async ()=>{
    return await axios.get(`/dev/cart/count/believe`)
}

// http://dev.djtretailers.com/cart/believe

// export async function addWishlistApi(item) {
//     const data = await dev.post(
//       `/fashion_wishlist/add-fashion-item-to-wishlist/${item._id}`
//     );
//     return data;
//   }

// export const getAddressApi= async ()=>{
//     return await axios.get(`/dev/cart/address/believe`)
//    }