import React, { memo, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import Frame from "../../../Frame";
import { Button, Checkbox, Row, Tree } from "antd";

import styled from "styled-components";
import { Slider } from "antd";
import { RxCross2 } from 'react-icons/rx';




const checkboxStyle = {
  color: "#2f4799", // Change color to your desired value
};
import { Collapse } from "antd";
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

import {
  InterNormalBunker13px,
  InterNormalBunker14px,
  InterNormalBlack14px,
  InterNormalStormDust15px,
  InterNormalMountainMist13px,
  InterNormalBunker18px,
  ValignTextMiddle,
} from "../../../../styledMixins";
import {
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
// import ShopCollection from "./ShopCollection";
// import ShopCollection from "./shopCollection";
import { getFilterItemApi } from "../../../../api/items";
import { BsFilterLeft } from "react-icons/bs"
import { categoryFilterList, getCheckboxItemList, handleCategoryChange, handleRangeChange, tempList } from "../FilterProductUtils.js";
import { Drawer, IconButton, makeStyles } from "@mui/material";
import FilterSection from "./FilterSection";

// const DropdownBody = ({ item, onCheckboxChange, selectedValue }) => {
//   const value = Object.values(item);
//   const key = Object.keys(item);
//   const handleCheckboxChange = (event) => {
//     const selectedValue = event.target.value;
//     onCheckboxChange(selectedValue);
//   };


//   return (
//     <Frame31 className="custome-size-dropdown">
//       <Group3>
//         <Checkbox
//           style={{ marginRight: "2rem" }}
//           autoFocus={false}
//           value={key[0]}
//           size="large"
//         ></Checkbox>
//       </Group3>

//       <XL>{key[0]} </XL>
//       <Text28>({value[0]})</Text28>
//     </Frame31>
//   );
// };

// const DropdownHeader = ({ item, onCheckboxChange, name, productCat }) => {
//   const handleCheckboxChange = (event) => {
//     const selectedValue = event.target.value;
//     onCheckboxChange(selectedValue);
//   };


//   const onChange = (checkedValues) => {};

//   return (
//     <Frame31 className="custome-size-dropdown">
//       <Group3>
//         <Checkbox
//           checked={name === item[0]}
//           onChange={handleCheckboxChange}
//           style={{ marginRight: "2rem" }}
//           autoFocus={false}
//           value={item[0]}
//           size="large"
//         ></Checkbox>
//       </Group3>

//       <XL>{item[0]}</XL>
//       <Text28>({item[1].items})</Text28>
//     </Frame31>
//   );
// };



const filterComp = ({ setFilterData, props }) => {
  const {
    image,
    price1,
    text27,
    filter,
    productCategories,
    frame1Props,
    frame2Props,
    allCategories,
    allCategoriesCount,
    allSubCategories,
    allPriceRange,
    allSizes,
    selectedCategory,
    selectedSubCategory,
    selectedRange,
    selectedSizes,
    params,
    allFilters,
    selectedFilter
  } = props;

  console.log('qwertyprop', allCategoriesCount);

  const history = useHistory();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);


  const [range, setRange] = useState([selectedRange[0], selectedRange[1]]);
  const [productCat, setProductCat] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [priceValue, setPriceValue] = useState([]);
  const [allSize, setAllSize] = useState([]);

  const [selectedClosure, setSelectedClosure] = useState([])

  const [ filterDrawerToggle, setFilterDrawerToggle ] = useState(false);

  
  // <--- new changes start ---> //

  const onExpand = (keys) => {
    if ( expandedKeys.length === 0 || keys.length === 0 ) {
      setExpandedKeys(keys);
    }
    else {
      const latestExpandedKey = keys.filter((key) => key !== expandedKeys[0]);

      setExpandedKeys(latestExpandedKey);
    }
  };

  // const useStyles = makeStyles((theme) => ({
  //   closeButton: {
  //     position: 'absolute',
  //     top: theme.spacing(1),
  //     right: theme.spacing(1),
  //   },
  // }));

  // const onCheck = (keys) => {
  //   if ( keys.length !== 0 ) {
  //     let isCategoryChanged = false;

  //     for ( let i = 0; i < keys.length; i++ ) {
  //       if ( !keys[i].includes(selectedCategory + '_') ) {
  //         isCategoryChanged = true;
  //         break;
  //       }
  //     }

  //     if ( isCategoryChanged ) {
  //       const latestCheckedKeys = keys.filter((key) => !key.includes( selectedCategory + '_' ))

  //       setSelectedSubCategory(latestCheckedKeys.filter((key) => key.split('_')[1].length !== 0).map((key) => key.split('_')[1]))
  //       setCheckedKeys(latestCheckedKeys)
  //     }
  //     else {
  //       setSelectedSubCategory(latestCheckedKeys.filter((key) => key.split('_')[1].length !== 0).map((key) => key.split('_')[1]))
  //       setCheckedKeys(keys);
  //     }
  //   }
  // };


  // --- update checked keys on every selected subcategory change ---
  useEffect(() => {
    if( selectedCategory === 'all') {
      setCheckedKeys([]);
    }
    if (selectedSubCategory.length !== 0) {
      const keys = selectedSubCategory?.map((subCategory) =>  selectedCategory + '_' + subCategory);
      setCheckedKeys(keys)
    }
    else {
      setCheckedKeys([selectedCategory + '_'])
    }
  }, [selectedSubCategory])

  useEffect(() => {
    setRange(selectedRange)
  }, [selectedRange])

  // <--- new changes end ---/> //

  useEffect(() => {
    if (setFilterData) {
      setFilterData(allSize, selectedItems, productCat, priceValue);
   
    }
  }, [allSize, selectedItems, productCat, priceValue]);




  const onChangeSelectSize = (value) => {
    setAllSize(value);
  };


  useEffect(() => {
  }, [selectedSizes])

  const handleSizeSelect = (sizeArr) => {
    params.delete('size');
    sizeArr.forEach((size) => {
      params.append('size', size);
    });
    params.set('page', 1);


    history.push({ search: params.toString() })

  }

  const handleTagsClick = (type, tagName, checked) => {
    if ( checked ) {
      params.set(type, tagName);
    }
    else {
      params.delete(type)
    }
    
  }
  
  return (
    <>
    <Frame36949 className="customer-filter-component mobile-no-display" style={{ width: '100%', maxWidth: 480, margin: '0 16px 20px 16px', position: 'sticky', top: 0 }}>
      {/* <Image src={image} alt="Image" /> */}
      <div>
        <Frame9>
          <FlexCol1>
            <ProductCategories>
              <span>{productCategories}</span>
            </ProductCategories>
            <Rectangle3></Rectangle3>
            <Tree
              onSelect={onExpand}
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              onCheck={(keys) => handleCategoryChange(keys, params, history, selectedCategory, setCheckedKeys)}
              checkedKeys={checkedKeys}
              treeData={tempList(allCategories, allCategoriesCount, allSubCategories)}
            />
          </FlexCol1>
        </Frame9>
          { selectedCategory !== 'all' && <>
            
            <Frame9>
              <Frame className={frame2Props.className}>
                {frame2Props.children}
              </Frame>

              {/* <Checkbox.Group
                onChange={onChangeSelectSize}
                autoFocus={false}
                style={{ width: "100%" }}
                value={allSize}
              >
                <FilterList>
                  {allSizes[selectedCategory].map((item, index) => {
                    const key = Object.keys(item)[0];
                    return (
                      <Frame31 key={index}>
                        <Group3>
                          <Checkbox
                            style={{ marginRight: "2rem", color: "black" }}
                            autoFocus={false}
                            value={key}
                            size="large"
                          ></Checkbox>
                        </Group3>

                        <XL>{key}</XL>
                        <Text28>({item[key]})</Text28>
                      </Frame31>
                    );
                  })}
                </FilterList>
              </Checkbox.Group> */}
              <Checkbox.Group
                options={getCheckboxItemList(allSizes[selectedCategory])} 
                value={selectedSizes}
                onChange={(val) => handleSizeSelect(val)}
              />
            </Frame9>
            <Frame9>
              <div style={{ marginTop: "2rem" }}>
                
                <Frame7>
                  <FilterSection
                    params={params} 
                    name='closure' 
                    tagsArr={allFilters[selectedCategory]?.closure?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.closure} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection 
                    params={params} 
                    name='fabric' 
                    tagsArr={allFilters[selectedCategory]?.fabric?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.fabric} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection 
                    params={params} 
                    name='fit' 
                    tagsArr={allFilters[selectedCategory]?.fit?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.fit} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection 
                    params={params} 
                    name='length' 
                    tagsArr={allFilters[selectedCategory]?.length?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.length} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection 
                    params={params} 
                    name='neck' 
                    tagsArr={allFilters[selectedCategory]?.neck?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.neck} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection 
                    params={params} 
                    name='occasion' 
                    tagsArr={allFilters[selectedCategory]?.occasion?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.occasion} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='pattern' 
                    tagsArr={allFilters[selectedCategory]?.pattern?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.pattern} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='sleeves' 
                    tagsArr={allFilters[selectedCategory]?.sleeves?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.sleeves} 
                    setSelectedTags={setSelectedClosure} 
                  />

                  <Frame>{frame1Props.children}</Frame>
                  
                  <div>
                    <OverlapGroup>
                      {selectedCategory && <Slider
                        min={
                          allPriceRange[selectedCategory].min
                        }
                        max={
                          allPriceRange[selectedCategory].max
                        }
                        value={range}
                        onChange={setRange}
                        range
                      />}
                    </OverlapGroup>
                    
                  </div>
    

                  <Frame8>
                    <Price>{price1}</Price>
                    <Price1> &nbsp; &nbsp; ₹{range[0]}</Price1>
                    <Text27>{text27}</Text27>
                    <Price2> ₹{range[1]}</Price2>
                  </Frame8>
                  <AutoLayoutHorizontal>
                    <Filter
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRangeChange(range, params, history)}
                    >
                      {filter}
                    </Filter>
                  </AutoLayoutHorizontal>
                </Frame7>
              </div>
            </Frame9>
          </>}
      </div>
    </Frame36949>

    <Button className="mobile-filter-drawer-button" icon={<BsFilterLeft style={{ marginRight: 12, fontSize: 20 }} />} size="large" onClick={() => setFilterDrawerToggle(true)}>Filter</Button>

    <Drawer style={{ width: 400 }} anchor="left" open={filterDrawerToggle} onClose={() => setFilterDrawerToggle(false)} >

      <div className="customer-filter-component" style={{ marginBottom: 60, padding: '36px 12px 12px 12px', position: 'relative', width: '100%', maxHeight: 'calc(100vh - 60px)', overflow: 'auto' }}>
      <RxCross2  onClick={()=>setFilterDrawerToggle(false)} style={{ position: 'absolute', top: 12, right: 12, fontSize: 20 }} />

      <Frame9>
          <FlexCol1>
            <ProductCategories>
              <span>{productCategories}</span>  
            
              {/* <IconButton
              //  className={classes.closeButton}
            edge="end"
            color="inherit"
            onClick={()=>setFilterDrawerToggle(false)}
            aria-label="close"
          > */}
          {/* </IconButton> */}


            </ProductCategories>
   

            <Rectangle3></Rectangle3>
            <Tree
              onSelect={onExpand}
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              onCheck={(keys) => handleCategoryChange(keys, params, history, selectedCategory, setCheckedKeys)}
              checkedKeys={checkedKeys}
              treeData={tempList(allCategories, allCategoriesCount, allSubCategories)}
            />
          </FlexCol1>
        </Frame9>
        { selectedCategory !== 'all' && <>
            
            <Frame9>
              <Frame className={frame2Props.className}>
                {frame2Props.children}
              </Frame>

              
              <Checkbox.Group
                options={getCheckboxItemList(allSizes[selectedCategory])} 
                value={selectedSizes}
                onChange={(val) => handleSizeSelect(val)}
              />
            </Frame9>
            <Frame9>
              <div style={{ marginTop: "2rem" }}>
                
                <Frame7>
                  

                  <FilterSection
                    params={params} 
                    name='closure' 
                    tagsArr={allFilters[selectedCategory]?.closure?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.closure} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='fabric' 
                    tagsArr={allFilters[selectedCategory]?.fabric?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.fabric} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='fit' 
                    tagsArr={allFilters[selectedCategory]?.fit?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.fit} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='length' 
                    tagsArr={allFilters[selectedCategory]?.length?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.length} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='neck' 
                    tagsArr={allFilters[selectedCategory]?.neck?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.neck} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='occasion' 
                    tagsArr={allFilters[selectedCategory]?.occasion?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.occasion} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='pattern' 
                    tagsArr={allFilters[selectedCategory]?.pattern?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.pattern} 
                    setSelectedTags={setSelectedClosure} 
                  />
                  <FilterSection
                    params={params} 
                    name='sleeves' 
                    tagsArr={allFilters[selectedCategory]?.sleeves?.map((item) => Object.keys(item)[0] + ' (' + Object.values(item)[0] + ')')}                    
                    selectedTags={selectedFilter.sleeves} 
                    setSelectedTags={setSelectedClosure} 
                  />

                  <Frame>{frame1Props.children}</Frame>
                  
                  <div>
                    <OverlapGroup>
                      {selectedCategory && <Slider
                        min={
                          allPriceRange[selectedCategory].min
                        }
                        max={
                          allPriceRange[selectedCategory].max
                        }
                        value={range}
                        onChange={setRange}
                        range
                      />}
                    </OverlapGroup>
                    
                  </div>
    

                  <Frame8>
                    <Price>{price1}</Price>
                    <Price1> &nbsp; &nbsp; ₹{range[0]}</Price1>
                    <Text27>{text27}</Text27>
                    <Price2> ₹{range[1]}</Price2>
                  </Frame8>
                  
                </Frame7>
              </div>
            </Frame9>
          </>}
      </div>

      <Row justify='center' align='middle' onClick={() => {setFilterDrawerToggle(false); handleRangeChange(range, params, history)}} style={{ fontSize: 20, position: 'absolute', bottom: 0, left: 0, width: '100%', backgroundColor: '#f4792c', color: 'white', fontWeight: 'bold', padding: 20 }}>
        Filter
      </Row>
    </Drawer>
    </>
  );
};

const FlexCol = styled.div`
  width: 1512px;
  position: relative;
  margin-left: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 69px;
`;

const OverlapGroup2 = styled.div`
  width: 1512px;
  margin-right: 0.5px;
  display: flex;
  flex-direction: column;
  padding: 82.5px 56px;
  align-items: flex-start;
  min-height: 250px;
  gap: 5px;
  background-size: 100% 100%;
`;

const Title = styled.h1`
  ${ValignTextMiddle}
  height: 50px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 14px;
`;

const Place = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
`;

const Vector = styled.img`
  position: relative;
  min-width: 5.45166015625px;
  height: 11px;
`;

const Shop1 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-azo_sans-regular);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Banner1 = styled.img`
  width: 1400px;
  height: 150px;
  margin-right: 0.5px;
  object-fit: cover;
`;

const DivtbayFilter = styled.div`
  height: 48px;
  margin-left: 1.5px;
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  min-width: 1400px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--alto);
`;

const Showing112Of32Results = styled.p`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            height: 28px;
  min-width: 176px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const DivtbayOrdering = styled.div`
  display: flex;
  position: relative;
  margin-left: 922px;
  margin-top: 3px;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0px 4px;
`;

const SortBy = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBlack14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const PCaptionCont = styled.div`
  position: relative;
  min-width: 138.52000427246094px;
  height: 24px;
  margin-left: -0.4199981689453125px;
  border-radius: 2px;
`;

const DefaultSorting = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 24px;
  top: -1px;
  left: 7px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 7px;
  left: 125px;
`;

const DivdisplayModeWarpper = styled.div`
  height: 24px;
  margin-left: 24px;
  margin-top: 2.88px;
  display: flex;
  padding: 0.2px 1px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 87px;
  gap: 17px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

const Frame3 = styled.img`
  width: 22px;
  height: 22px;
`;

const FlexRow = styled.div`
  margin-top: 24px;
  margin-right: 1px;
  display: flex;
  align-items: flex-start;
  min-width: 1399px;
  gap: 39px;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Frame36949 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 46px;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  max-height: 600px;
  object-fit: contain;
`;

const Frame4 = styled.div`
  position: relative;
  min-width: 320px;
`;

const Frame5 = styled.div``;

const OverlapGroup = styled.div`
  width: 321px;

  position: relative;
`;

const Rectangle = styled.div`
  position: absolute;
  width: 320px;
  height: 2px;
  top: 7px;
  left: 0;
  background-color: var(--primarycolor);
  border-radius: 11.2px;
`;

const Rectangle1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Rectangle2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 305px;
  background-color: var(--primarycolor);
  border: 2px solid;
`;

const Frame7 = styled.div``;

const Frame8 = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-top: -1px;
  min-width: 43px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 30px;
  margin-left: 1px;
  margin-top: -1px;
  min-width: 35px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text27 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 22px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  height: 30px;
  margin-left: 5px;
  margin-top: -1px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--bunker);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  padding: 12px 36px;
  background-color: #f4792c;
  border-radius: 8px
`;

const Filter = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-inter);
  color: var(--lg);
  font-size: var(--font-size-xxxs);
  text-align: center;
  white-space: nowrap;
  font-weight: bold
`;

const Frame9 = styled.div`
  position: relative;
  min-width: 320px;
  max-width: 320px;
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  left: 0;
`;

const Frame31 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
`;

const XL = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: relative;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Group3 = styled.div`
  position: relative;
  min-width: 53px;
  height: 22px;
  display: flex;
`;

const Text28 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalMountainMist13px}
  margin-left: 10px;
  letter-spacing: 0;
  white-space: nowrap;
`;

const FlexCol1 = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ProductCategories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker18px}
            height: 25px;
  margin-bottom: -3px;
  letter-spacing: 0;
  height: 36px;
  white-space: nowrap;
  background-color: #efefef;
  font-weight: bold;
  border-radius: 8px;
  padding: 0px 12px;
  width: 100%;
`;

const Rectangle3 = styled.div`
  width: 320px;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 20px;
  background-color: var(--alto);
`;

const FilterList1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
`;

const FlexCol2 = styled.div`
  width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  flex-wrap: wrap;
`;

const Group2 = styled.div`
  position: relative;
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
  min-width: 1038px;
  gap: 39px;
  flex-wrap: wrap;
`;

const UlpageNumbers = styled.div`
  display: flex;
  padding: 0 400px;
  align-items: center;
  min-width: 1040px;
`;

const SpanpageNumbers = styled.div`
  height: 48px;
  display: flex;
  padding: 12px 19.5px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 48px;
  background-color: var(--primarycolor);
`;

const Number = styled.div`
  ${ValignTextMiddle}
  width: 9px;
  height: 21px;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 36px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Number2 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
            width: 9px;
  height: 21px;
  margin-left: 55px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;

export default filterComp;
