import React from "react";
import styled from "styled-components";
import { YesevaoneNormalWhite36px, ValignTextMiddle } from "../../styledMixins";


class Title32 extends React.Component {
  render() {
    const { ourBest, forWomens } = this.props;

    return (
      <Title>
        <OurBest>{ourBest}</OurBest>
        <ForWomens>{forWomens}</ForWomens>
      </Title>
    );
  }
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  position: relative;
`;

const OurBest = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-snell_roundhand-bold);
  font-weight: 700;
  color: var(--primarycolor);
  font-size: 28px;
  letter-spacing: 0;
  line-height: 38px;
  white-space: nowrap;
`;

const ForWomens = styled.div`
  ${ValignTextMiddle}
  ${YesevaoneNormalWhite36px}
            position: relative;
  width: fit-content;
  background: linear-gradient(
    180deg,
    rgb(255, 48.000000938773155, 85.0000025331974) 0%,
    rgb(253.0000001192093, 121.00000038743019, 136.00000709295273) 100%
  );
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  letter-spacing: 0;
  line-height: 48px;
`;

export default Title32;
