import React from "react";
import styled from "styled-components";


class Checkbox2 extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <ActiveNoDarkModeNo className={`active-no-dark-mode-no-1 ${className || ""}`}>
        <VariantFilled className="variant-filled-1" src="/img/variant-filled@2x.png" alt="Variant=Filled" />
      </ActiveNoDarkModeNo>
    );
  }
}

const ActiveNoDarkModeNo = styled.div`
  position: absolute;
  height: 20px;
  top: 1px;
  left: 31px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 20px;
  background-color: var(--lg);
  border-radius: 4px;
  border: 2px solid;
  border-color: var(--cadet-blue);
`;

const VariantFilled = styled.img`
  width: 33px;
  height: 33px;
  margin-top: -2315.67px;
`;

const VariantFilled1 = styled.img`
  .active-no-dark-mode-no-1.active-no-dark-mode-no-2 & {
    margin-top: -2349.67px;
  }
`;

const VariantFilled2 = styled.img`
  .active-no-dark-mode-no-1.active-no-dark-mode-no-3 & {
    margin-top: -2492.67px;
  }
`;

const VariantFilled3 = styled.img`
  .active-no-dark-mode-no-1.active-no-dark-mode-no-4 & {
    margin-top: -2526.67px;
  }
`;

const VariantFilled4 = styled.img`
  .active-no-dark-mode-no-1.active-no-dark-mode-no-5 & {
    margin-top: -2560.67px;
  }
`;

export default Checkbox2;
