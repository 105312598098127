import React from "react";
import styled from "styled-components";
import { LatoSemiBoldBlack14px, ValignTextMiddle } from "../../styledMixins";

class Spanbox extends React.Component {
  render() {
    const { size30M, className } = this.props;

    return (
      <Spanbox1 className={`spanbox select-box  ${className || ""}`}>
        <Size30M className="size-30-m spanbox-a">{size30M}</Size30M>
        <Arrow className="arrow-1" src="/img/arrow-1.svg" alt="Arrow" />
      </Spanbox1>
    );
  }
}

const Spanbox1 = styled.div`

`;

const Size30M = styled.div`
  ${ValignTextMiddle}
  ${LatoSemiBoldBlack14px}
            height: 24px;
  min-width: 70px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
`;

const Size30M1 = styled.div`
  ${ValignTextMiddle}
  ${LatoSemiBoldBlack14px}
            
            
           .spanbox.spanbox-1  & {
    min-width: 66px;
  }
`;

const QTY1 = styled.div`
  ${ValignTextMiddle}
  ${LatoSemiBoldBlack14px}
            
            
           .spanbox.spanbox-2  & {
    min-width: 47px;
  }
`;

export default Spanbox;
