import React from "react";
import { useEffect } from "react";
import "./FooterLinks.css";

const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div class="footer-container">
      <div className="details font-body-a" id="details-container">
      <div id="details-1" class="details-content"  style={{ display: "block" }}>
                <div style={{"text-align": "center"}}>
                    <h1 >
                        Privacy Policy
                    </h1>
                    </div>
                    <br/>
                    <hr class="solid" />

                <ul class="faq-list privacy-list">
                    <li>
                        <h4 class="faq-heading footer-h4">Personal Information</h4>
                        <div class="read faq-text">
                            <p>
                                “House of Believe” is a trademark owned by DJT Corporation & Investment Private Limited
                                and using by DJT Retailers Private Limited (“Company”), a Company incorporated under the
                                provisions of the Companies Act, 2013 having its corporate office at Plot No. A2B, 8th
                                Floor, Sector-125, Noida-201303. The domain name <a href="/">www.houseofbelieve.com</a> is owned by the
                                Company. </p>
                            <p>
                                It is strongly recommended that you read and understand the terms and conditions
                                carefully, as by accessing this site, you agree to be bound by the same and acknowledge
                                that it constitutes an agreement between you and the Company. If you do not agree with
                                the terms, you should not use or access the site for any purpose whatsoever and in that
                                event, the Company shall not be liable or responsible. </p>
                            <p>
                                These terms may be subject to amendment, so you should carefully read them prior to
                                placing your order.
                            </p>

                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">Service Overview</h4>
                        <div class="read faq-text">

                            <p>
                                Products offered through the Website and/or Mobile App are available to only select
                                geographies in India and are subject to restrictions based on business hours and
                                regulations, as enforceable by law. </p>

                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">Access to Website</h4>
                        <div class="read faq-text">

                            <p>
                                House of Believe hereby provides its conditional permission for you to access the
                                Website in accordance with these terms and conditions provided that you use the website
                                on your continued and absolute compliance with these terms and conditions. You shall not
                                rent, transfer, assign, commercially exploit, resell or sublicense access to the
                                services/products available on the website. You may only use this website solely for (1)
                                your personal, non-commercial use (2) you may download a single copy of the materials
                                from the website only for your personal, non-commercial home use. You agree not to use
                                or launch, or cause to be used or launched, any automated/computerized/analog/hybrid
                                system or program in connection with the website or online ordering, including without
                                limitation, publishing or distributing vouchers or codes, robots, spiders, offline
                                readers, place pop-up windows over its pages, or otherwise effect the display of its
                                pages and anything of similar nature.
                            </p>
                            <p>
                                Except as expressly provided herein, you may not modify, copy, distribute, broadcast,
                                transmit, reproduce, publish, license, transfer, sell, mirror, frame, deep link, or
                                otherwise use any information or material obtained from or through this website without
                                the written permission of the Company.
                            </p>
                            <p>
                                We always try to provide you uninterrupted access to the website of House of Believe and
                                that transmissions will be error-free. However, due to the nature of the internet, this
                                cannot be guaranteed.
                            </p>
                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">Use of our Website </h4>
                        <div class="read faq-text">

                            <p>
                                These terms and conditions are applicable only for the use of the website and they
                                replace all other conditions, except, prior writing agreement of the House of Believe.
                                These terms are important for both you and House of Believe as they have been designed
                                to create a legally binding agreement between us, protecting your rights as a valued
                                customer and our rights as a business.
                            </p>
                            <p>
                                By placing your order, you unreservedly accept these terms, having read them and further
                                You agree that:
                            </p>
                            <ol style={{"list-style": "order"}} className="ol-child">
                                <li>
                                    You may only use the Website/Mobile App to make legitimate enquiries or orders.
                                </li>
                                <li>
                                    You will not make any speculative, false or fraudulent orders. If we are reasonably
                                    of the opinion that such an order has been made, we shall be entitled to cancel the
                                    order and inform the relevant authorities.
                                </li>
                                <li>
                                    You also undertake to provide correct and accurate e-mail, postal and/or other
                                    contact details to us and acknowledge that we may use these details to contact you
                                    in the event that this should prove necessary (see our Privacy Policy for more
                                    information on how we use your personal information).
                                </li>
                                <li>
                                    If you do not give us all of the information that we need, we may not be able to
                                    complete your order.
                                </li>

                            </ol>

                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">
                            With whom your information will be shared?
                        </h4>
                        <div class="read faq-text">
                            <p>
                                Company will not use your financial information for any purpose other than to complete a
                                transaction with you. Company does not rent, sell or share your personal information and
                                will not disclose any of your personally identifiable information to third parties. In
                                cases where it has your permission to provide products or services you’ve requested, and
                                such information is necessary to provide these products or services the information may
                                be shared with Company’s business associates and partners.
                            </p>
                            <p>
                                In addition, we may use this information for promotional offers, to help investigate,
                                prevent or take action regarding possible unlawful and illegal activities, suspected
                                fraud, potential threat to the safety or security of any person, violations of the
                                site’s privacy policy or terms of use or to defend against legal claims, special
                                circumstances such as compliance with subpoena, court orders, request or order from
                                legal authorities or law enforcement agencies requiring such disclosure.
                            </p>

                        </div>
                    </li>

                    <li>
                        <h4 class="faq-heading">
                            Choice are available to you regarding collection, use and distribution of your information?

                        </h4>
                        <div class="read faq-text">
                            <p>
                                To protect against the loss, misuse and alteration of the information under its control,
                                the Company has in place appropriate physical, electronic and managerial procedure. For
                                example, the Company servers are accessible only to authorized personnel and your
                                information is shared with employees and authorized personnel on need to know basis to
                                complete the transaction and to provide the services requested by you. Although the
                                Company endeavors to safeguard the confidentiality of your personally identifiable
                                information, transmission made by means of the internet cannot be made absolutely
                                secure. By using the website, you agree that the Company will have no liability for
                                disclosure of your information due to errors in transmission and/or authorized acts of
                                third parties.
                            </p>
                            <p>
                                Please note that the Company will not ask you to share any sensitive data or information
                                via email or telephone. If you receive any such request by email or telephone, please do
                                not respond/divulge any sensitive data or information and forward the information
                                relating to the same to <a href = "mailto: info@houseofbelieve.com" target="_blank" >info@houseofbelieve.com</a>&nbsp;
                                for necessary action.
                            </p>

                        </div>
                    </li>

                    <li>
                        <h4 class="faq-heading">
                            Changes to this privacy policy
                        </h4>
                        <div class="read faq-text">
                            <p>
                                The Company reserves the right to change or update this policy at any time. Such changes
                                shall be effective immediately upon posting to the website. The user hereby acknowledges
                                and agrees that it is their responsibility to review this privacy policy periodically
                                and become aware of modifications.
                            </p>
                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">
                            Your acceptance to these terms

                        </h4>
                        <div class="read faq-text">
                            <p>
                                By using this website, the user(s) signifies their acceptance of this policy as may be
                                modified from time to time. The user(s) is advised not to access this website if they do
                                not agree to our privacy policy. The abovementioned privacy policy shall be applicable
                                for the information and data collection.
                            </p>
                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">
                            Web Browser Cookies
                        </h4>
                        <div class="read faq-text">
                            <p>
                                Our website may use “cookies” to enhance user experience. User’s web browser places
                                cookies on their hard drive for record keeping purpose and sometimes to track
                                information about them. User may choose to set their web browser to refuse cookies, or
                                to alert the users when cookies are being sent. If they do so, note that some parts of
                                the site may not function properly.
                            </p>
                        </div>
                    </li>
                    <li>
                        <h4 class="faq-heading">
                            Communication with Company
                        </h4>
                        <div class="read faq-text">
                            <p>
                                If you wish to correct or update any information you have provided, you may do so
                                online, on the website itself. Alternatively, you may contact the Company to correct or
                                update such information by sending an email to: &nbsp;
                                <a href = "mailto: info@houseofbelieve.com" target="_blank" >info@houseofbelieve.com</a>.
                            </p>
                            <p>
                                In the event you wish to report a breach of the Privacy Policy, you may contact the
                                Grievance Officer of the Company at:
                            </p>
                            <p style={{display : "flex", flexDirection: "column", gap: "5px"}}>
                                <span>Grievance Officer</span>

                                <span>DJT Retailers Private Limited</span>

                                <span>Plot No. A2B, 8th Floor,</span>

                                <span>Sector-125, Noida-201303</span>

                                <span>Email Id: <a href = "mailto: info@houseofbelieve.com" target="_blank" > info@houseofbelieve.com</a>&nbsp;</span>


                            </p>
                        </div>
                    </li>





                </ul>

            </div>
      </div>
    </div>
  );
};

export default Privacy;
