import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header4 from "../Header4";
import Size from "../Size";
import LiswatchItem from "../LiswatchItem";
import Title6 from "../Title6";
import Product from "../Product";
import Footer from "../Footer";
import styled from "styled-components";
import {
  MontserratNormalBlack169px,
  InterNormalBunker14px,
  PoppinsBoldBlack191px,
  InterNormalBunker13px,
  MontserratMediumBlack113px,
  InterNormalBunker12px,
  InterNormalBunker11px,
  InterNormalSuvaGray14px,
  AzosansMediumSizzlingRed14px,
  InterNormalWhite13px,
  InterNormalStormDust13px,
  ValignTextMiddle,
} from "../../styledMixins";
// import Spanbox from "./Spanbox";
import "./SingleProduct.css";
import { dev } from "../../api/baseurl";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  Col,
  Collapse,
  Image,
  Modal,
  notification,
  Progress,
  Rate,
  Row,
  Segmented,
  Spin,
  Table,
} from "antd";
import {
  getItemByIdApi,
  getItemVariationApi,
  getRecommendation,
} from "../../api/collections";
import {
  addToCartApi,
  addWishlistApi,
  deleteWishlistApi,
} from "../../api/wishlist.js";
import { RxCross2 } from "react-icons/rx";
// import SimpleImageSlider from "react-simple-image-slider/dist/ImageSlider";
// import SimpleImageSlider from "react-simple-image-slider";
const { Panel } = Collapse;
import { Carousel } from "antd";
import { BiCube } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { GiBodyHeight } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { GrAlert } from "react-icons/gr";
import { AiOutlineStar } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Spanbox from "../Spanbox";
import { addItemToCartApi } from "../../api/Cart";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Title22 from "../Title22";
import noimage from "../../images/noimage.jpg";
import { checkServiceablePinCode } from "../../api/Address";
import ReactImageMagnify from "react-image-magnify";
import { ButtonGroup, Drawer, IconButton } from "@mui/material";
// import { notification } from "antd";
import ImageMagnify from "react-image-magnify";
import Login from "../Login";
import Pagination from "../Pagination";
import {
  createSizeChartTableFormat,
  sizeChartColumnsList,
  sizeChartDataSource,
} from "./utils";

const SingleProduct = (props) => {
  const [productData, setProductData] = useState();
  const params = useLocation();
  const paramsId = params.search.replace("?", "");
  const [recommendItems, setRecommendItems] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const [productCollapse, setProductCollapse] = useState(true);
  const [knowProdctCollapse, setKnowProductCollapse] = useState(false);
  const [vendorCollapse, setVendorCollapse] = useState(true);
  const [imageModal, setImageModal] = useState(false);
  const [ratingNumber, setRatingNumber] = useState();
  const [quantityPopUp, setQuantityPopup] = useState(false);
  const [loading, setLoading] = useState("");
  const [sizeModal, setSizeModal] = useState();
  const [sizeData, setSizeData] = useState();
  const [outOfStock, setOutOfStock] = useState(false);
  const [pinCode, setPinCode] = useState();
  const [pinCodeResponse, setPinCodeResponse] = useState();
  const [productSize, setProductSize] = useState(false);
  const [imageData, setImageData] = useState();
  const [quantityData, setQuantityData] = useState();
  const [bgColor, setBgColor] = useState();
  const [newSizeData, setNewSizeData] = useState(paramsId);
  const [bgColorSize, setBgColorSize] = useState();
  const [imageError, setImageError] = useState(true);
  const [sizeChartModal, setSizeChartModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalRating, setTotalRating] = useState();
  const [loader, setLoader] = useState(false);
  const [changeColorState,setChangeColorState] = useState(true);

  const [tableData, setTableData] = useState(null);
  const [sizeToggleValue, setSizeToggleValue] = useState("in");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddWishlist = async () => {
    // history.push(location.pathname + "?login");
    setLoading("wishlist");
    const { data } = await addWishlistApi(paramsId);
    setLoading("");
    if (data.success) {
      notification.success({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
      getSingleItem();
    } else {
      if (
        data.message === "please login" ||
        data.message === "You are logged out"
      ) {
        history.push(location.pathname + location.search, {
          state: "login",
        });
      }
      // notification.error({
      //   message: data.message,
      // });
    }
  };

  const localData = JSON.parse(localStorage.getItem("login"));

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // add = () => {
  //   this.setState({
  //     cart: ['ice cream'],
  //     total: 5
  //   })
  // }



  const handleAddCart = async (item) => {
    if (productData?.data?.cart_quantity == 0) {
      setLoading("cart");
      const { data } = await addToCartApi(paramsId);
      setLoading("");
      if (data.success) {
        if (productData?.data?.cart_quantity == 0) {
          let tempPropsState = props?.state;
          // notification.success({ message: data.message });

          props.setState({
            ...tempPropsState,
            isAddedProduct: true,
          });

          getSingleItem();
        }
      } else {
        if (
          data.message === "please login" ||
          data.message === "You are logged out"
        ) {
          history.push(location.pathname + location.search, {
            state: "login",
          });
        }
        // notification.error({ message: data.message });
      }
    } else {
      notification.success({ message: "Item already added in Cart" });
    }
  };

  console.log(paramsId);

  const getSingleItem = async () => {
    setLoader(true);
    const data = await getItemByIdApi(paramsId);
    if (data?.data?.success) {
      setLoader(false);
      // }
      setProductData(data?.data);
      if(data?.data?.data?.sizes?.length > 0){
        setTableData(createSizeChartTableFormat(data?.data?.data?.sizes));
      }

      //  product_id: id,
      // category: cat,
      // product_image: img,

      const image = data?.data?.data?.images;

      const reccomend = await getRecommendation(
        data?.data?.data?.class_of_product,
        data?.data?.data?.sub_category?.code,
        image && image.length > 1 ? image[image.length - 2]?.url : image[0]?.url
      );


      setRecommendItems(reccomend?.data?.data);

      // useEffect(()=>{

      // },[0])

      // if (data?.data?.success) {
      setRatingNumber(data?.data?.data?.rating);
      setSizeModal(false);
    }
  };

  useEffect(() => {
    getSingleItem();

    let tempPropsState = props?.state;
    props.setState({
      ...tempPropsState,
      isAddedProduct: false,
    });
  }, [paramsId]);

  const viewCartFunc = () => {};

  const {
    navbarLinkPlace,
    navbarLinkText41,
    navbarLinkShop,
    navbarLinkText42,
    navbarLinkAccessories,
    navbarLinkText43,
    navbarLinkBlueNavyBag,
    shop,
    h1ListProduct5Gallery1600X800Jpg,
    h1ListProduct5Gallery2600X800Jpg,
    h1ListProduct5Gallery3600X800Jpg,
    h1ListProduct5Gallery4600X800Jpg,
    h1ListProduct5Gallery5600X800Jpg,
    h1ListProduct5Jpg,
    surname,
    x10CustomerReviews,
    price,
    balancingLaidbackL,
    qualityPairsSince,
    forerunnerInPremiumDenim,
    x79OrganicCotton,
    addToCart,
    viewCart,
    addToWishlist,
    deliveryReturn15Days,
    sizeGuide,
    sku,
    megaJewe11,
    categories,
    accessories,
    text44,
    dresses,
    text45,
    shirt,
    tags,
    anklets,
    text46,
    bracelets,
    text47,
    jewelry,
    brand,
    name,
    deliveryOptions,
    inputType,
    inputPlaceholder,
    check,
    description,
    additionalInformation,
    reviews0,
    loremIpsumDolorSi,
    noVeniamMaiestatis,
    tamquamApeirianHa,
    exRidensSuscipitScriptoremExMel,
    header4Props,
    size1Props,
    size2Props,
    liswatchItem1Props,
    liswatchItem2Props,
    title6Props,
    product1Props,
    product2Props,
    product3Props,
    product4Props,
    product5Props,
    product6Props,
    product7Props,
    product8Props,
    footerProps,
  } = props;

  const openProductCollapse = () => {
    setProductCollapse(true);
  };

  const openImageView = (value) => {
    setImageModal(true);
  };

  const openSetImageView = (value) => {
    setImageData(value.target.src);
  };

  const numbers = [];

  if (productData?.data?.warehouses[0]?.maximum_order_quantity > 5) {
    for (let i = 1; i <= 5; i++) {
      numbers.push(i);
    }
  } else {
    for (
      let i = 1;
      i <= productData?.data?.warehouses[0]?.maximum_order_quantity;
      i++
    ) {
      numbers.push(i);
    }
  }

  const selectSizeBox = () => {
    if (localData === null) {
      setLogin(true);
    } else {
      setQuantityPopup(true);
    }
  };

  const selectSizeValue = (value) => {
    const quantityData = {
      id: productData?.data?._id,
      quantity: value,
    };
  };

  const selectSize = async (value) => {
    const data = {
      id: productData?.data?._id,
      quantity: quantityData,
    };
    const resultData = await addItemToCartApi(data);
    if (resultData?.data?.success) {
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
      getSingleItem();
      setQuantityPopup(false);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const itemVariation = async () => {
    const classOfProduct = productData?.data?.class_of_product;
    const resultData = await getItemVariationApi(classOfProduct);
    setSizeData(resultData?.data);
    if (resultData?.data?.data?.length > 0) {
      setProductSize(true);
    }
  };

  useEffect(()=>{
    sizeData?.data?.map((value,index)=>{
      if(value?.is_default === true){
    // setBgColorSize()
    // let purple = "#ff3055";
    // let orange = "#f4792c";
    // let white = "#ffffff";
    // setBgColorSize({ number: index, color: orange, colorW: white });
      }
    })

    const indexNew = sizeData?.data.findIndex((element) => element.is_default === true);


    // let purple = "#ff3055";
    // let orange = "#f4792c";
    // let white = "#ffffff";
    // setBgColorSize({ number: indexNew, color: orange, colorW: white });

    // setBgColorSize()

  },[sizeData])
  
  useEffect(()=>{
    if(changeColorState){
      const indexNew = sizeData?.data.findIndex((element) => element.is_default === true);

  
      let purple = "#ff3055";
      let orange = "#f4792c";
      let white = "#ffffff";
      setBgColorSize({ number: indexNew, color: orange, colorW: white });
    }
   
  },[sizeData])



  useEffect(() => {
    itemVariation();
  }, [productData]);

  useEffect(() => {
    productData?.data?.images?.forEach((value, index) => {
      if (value?.image_type === "main") {
        setImageData(value.url);
      } else {
        setImageData(productData?.data?.images[0]?.url);
      }
    });
  }, [productData]);

  const removeWishList = async () => {
    const resultData = await deleteWishlistApi(productData?.data?._id);
    if (resultData?.data?.success) {
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
      getSingleItem();
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const buyNowProduct = () => {
    handleAddCart();
    history.push("/cart");
  };

  const selectOriginalSizeBox = async (item) => {
    setSizeModal(false);
    history.push(`/singleShop?${item}`);
  };


  const checkDeliveryPincode = async () => {
    // if (localData === null) {
    //   setLogin(true);
    // } else {
      if (pinCode == undefined) {
        setPinCodeResponse("Please Enter Pin Code");
      } else {
        const resultData = await checkServiceablePinCode(pinCode);
        setPinCodeResponse(resultData?.data);
      }
    // }
  };

  // const checkPinCode=async (e)=>{
  //   if(e.target.value ?.length == 6){
  //   const resultData = await checkServiceablePinCode(e.target.value);
  //   if(resultData?.data?.success){
  //     notification.success({
  //       message: resultData?.data?.message,
  //     })
  //   } else{
  //     notification.error({
  //       message: resultData?.data?.message,
  //     })
  //   }
  // }
  //   }

  const dataSourceInchWomenT = [
    {
      key: "1",
      size: "XS",
      bust: "34.25",
      across_shoulder: "13.5",
      front_length: "23.75",
    },
    {
      key: "2",
      size: "S",
      bust: "36.25",
      across_shoulder: "14.25",
      front_length: "24.5",
    },
    {
      key: "3",
      size: "M",
      bust: "38.25",
      across_shoulder: "14.75",
      front_length: "25",
    },
    {
      key: "4",
      size: "L",
      bust: "40.25",
      across_shoulder: "15.5",
      front_length: "25.5",
    },
    {
      key: "5",
      size: "XL",
      bust: "42.5",
      across_shoulder: "16",
      front_length: "26.25",
    },
    {
      key: "6",
      size: "XXL",
      bust: "45",
      across_shoulder: "16.5",
      front_length: "26.75",
    },
  ];

  const dataSourceCmsWomenT = [
    {
      key: "1",
      size: "XS",
      bust: "87",
      across_shoulder: "34.5",
      front_length: "60.5",
    },
    {
      key: "2",
      size: "S",
      bust: "92",
      across_shoulder: "36",
      front_length: "62",
    },
    {
      key: "3",
      size: "M",
      bust: "97",
      across_shoulder: "37.5",
      front_length: "63.5",
    },
    {
      key: "4",
      size: "L",
      bust: "102",
      across_shoulder: "39",
      front_length: "65",
    },
    {
      key: "5",
      size: "XL",
      bust: "108",
      across_shoulder: "40.5",
      front_length: "66.5",
    },
    {
      key: "6",
      size: "XXL",
      bust: "114",
      across_shoulder: "42",
      front_length: "68",
    },
  ];

  // Sample Columns data
  const columnsInchTop = [
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Bust",
      dataIndex: "bust",
      key: "bust",
    },
    {
      title: "Across Shoulder",
      dataIndex: "across_shoulder",
      key: "across_shoulder",
    },
    {
      title: "Front Length (Varies as per Style)",
      dataIndex: "front_length",
      key: "front_length",
    },
  ];

  const dataSourceInchWomenB = [
    {
      key: "1",
      size: "XS/26",
      fit_to_waist: "27.25",
      hip: "29.5",
      out_seam_length: "35.25",
    },
    {
      key: "2",
      size: "S/28",
      fit_to_waist: "29",
      hip: "31.5",
      out_seam_length: "35.5",
    },
    {
      key: "3",
      size: "M/30",
      fit_to_waist: "31",
      hip: "35.5",
      out_seam_length: "36.5",
    },
    {
      key: "4",
      size: "L/32",
      fit_to_waist: "33",
      hip: "34.5",
      out_seam_length: "36.5",
    },
    {
      key: "5",
      size: "XL/34",
      fit_to_waist: "35",
      hip: "37.5",
      out_seam_length: "36.75",
    },
    {
      key: "6",
      size: "XXL/36",
      fit_to_waist: "37",
      hip: "39.5",
      out_seam_length: "37.25",
    },
  ];

  const dataSourceCmsWomenB = [
    {
      key: "1",
      size: "XS/26",
      fit_to_waist: "69",
      hip: "75",
      out_seam_length: "89.5",
    },
    {
      key: "2",
      size: "S/28",
      fit_to_waist: "74",
      hip: "80",
      out_seam_length: "90.5",
    },
    {
      key: "3",
      size: "M/30",
      fit_to_waist: "79",
      hip: "85",
      out_seam_length: "91.5",
    },
    {
      key: "4",
      size: "L/32",
      fit_to_waist: "84",
      hip: "90",
      out_seam_length: "92.5",
    },
    {
      key: "5",
      size: "XL/34",
      fit_to_waist: "89",
      hip: "95",
      out_seam_length: "93.5",
    },
    {
      key: "6",
      size: "XXL/36",
      fit_to_waist: "94",
      hip: "100",
      out_seam_length: "94.5",
    },
  ];

  const columnsInchBottom = [
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Fit to Waist",
      dataIndex: "fit_to_waist",
      key: "fit_to_waist",
    },
    {
      title: "Hip",
      dataIndex: "hip",
      key: "hip",
    },
    {
      title: "Out Seam Length",
      dataIndex: "out_seam_length",
      key: "out_seam_length",
    },
  ];

  // Mens Size Chart
  const MensSizeChartTopInch = [
    {
      key: "1",
      size: "Chest",
      s: "39.75",
      m: "41.75",
      l: "43.75",
      xl: "45.75",
      xxl: "47.5",
    },
    {
      key: "2",
      size: "Across Shoulder",
      s: "17",
      m: "17.75",
      l: "18",
      xl: "18.75",
      xxl: "19.25",
    },
    {
      key: "3",
      size: "Front Length",
      s: "27.0",
      m: "27.5",
      l: "28",
      xl: "28.75",
      xxl: "29.25",
    },
  ];

  const MensSizeChartTopCms = [
    {
      key: "1",
      size: "Chest",
      s: "101.00",
      m: "106.00",
      l: "111.00",
      xl: "116.00",
      xxl: "121.00",
    },
    {
      key: "1",
      size: "Across Shoulder",
      s: "43",
      m: "44.5",
      l: "46",
      xl: "47.5",
      xxl: "49",
    },
    {
      key: "1",
      size: "Front Length",
      s: "68.5",
      m: "70",
      l: "71.5",
      xl: "73",
      xxl: "74.5",
    },
  ];

  const MensColumnsInchTop = [
    {
      title: "Size",
      dataIndex: "size",
      rowScope: "row",
    },
    {
      title: "S",
      dataIndex: "s",
      key: "s",
    },
    {
      title: "M",
      dataIndex: "m",
      key: "m",
    },
    {
      title: "L",
      dataIndex: "l",
      key: "l",
    },
    {
      title: "XL",
      dataIndex: "xl",
      key: "xl",
    },
    {
      title: "XXL",
      dataIndex: "xxl",
      key: "xxl",
    },
  ];

  // Kids Size Chart
  const KidsColumnsInchTop = [
    {
      title: "Size",
      dataIndex: "size",
      rowScope: "row",
    },
    {
      title: "Fit to Waist",
      dataIndex: "fit_to_waist",
      key: "fit_to_waist",
    },
    {
      title: "Hips",
      dataIndex: "hips",
      key: "hips",
    },
    {
      title: "Out seam Length",
      dataIndex: "out_seam_length",
      key: "out_seam_length",
    },
  ];

  const KidsSizeChartTopInch = [
    {
      key: "1",
      size: "2-3YRS",
      fit_to_waist: "21",
      hips: "23",
      out_seam_length: "22",
    },
    {
      key: "2",
      size: "3-4YRS",
      fit_to_waist: "22",
      hips: "25.25",
      out_seam_length: "24.5",
    },
    {
      key: "3",
      size: "5-6YRS",
      fit_to_waist: "23.25",
      hips: "26.75",
      out_seam_length: "26.75",
    },
    {
      key: "4",
      size: "7-8YRS",
      fit_to_waist: "24.5",
      hips: "28.25",
      out_seam_length: "29",
    },
    {
      key: "5",
      size: "8-9YRS",
      fit_to_waist: "25.5",
      hips: "30",
      out_seam_length: "31.5",
    },
    {
      key: "6",
      size: "9-10YRS",
      fit_to_waist: "26.75",
      hips: "31.5",
      out_seam_length: "34",
    },
    {
      key: "7",
      size: "11-12YRS",
      fit_to_waist: "28.25",
      hips: "33",
      out_seam_length: "36.25",
    },
    {
      key: "8",
      size: "13-14YRS",
      fit_to_waist: "29.5",
      hips: "34.5",
      out_seam_length: "38.5",
    },
  ];

  const KidsSizeChartTopCms = [
    {
      key: "1",
      size: "2-3YRS",
      fit_to_waist: "53",
      hips: "60",
      out_seam_length: "56",
    },
    {
      key: "2",
      size: "3-4YRS",
      fit_to_waist: "56",
      hips: "64",
      out_seam_length: "62",
    },
    {
      key: "3",
      size: "5-6YRS",
      fit_to_waist: "59",
      hips: "68",
      out_seam_length: "68",
    },
    {
      key: "4",
      size: "7-8YRS",
      fit_to_waist: "62",
      hips: "72",
      out_seam_length: "74",
    },
    {
      key: "5",
      size: "8-9YRS",
      fit_to_waist: "65",
      hips: "76",
      out_seam_length: "80",
    },
    {
      key: "6",
      size: "9-10YRS",
      fit_to_waist: "68",
      hips: "80",
      out_seam_length: "86",
    },
    {
      key: "7",
      size: "11-12YRS",
      fit_to_waist: "72",
      hips: "84",
      out_seam_length: "92",
    },
    {
      key: "8",
      size: "13-14YRS",
      fit_to_waist: "75",
      hips: "88",
      out_seam_length: "98",
    },
  ];

  const imageProps = {
    smallImage: {
      alt: "Phasellus laoreet",
      // isFluidWidth: true,
      src: imageData,
      height: 590,
      width: 590,
      // style:{maxHeight:"60px"}
    },
    largeImage: {
      src: imageData,
      width: 1400,
      height: 1400,
    },
    enlargedImageContainerStyle: { background: "#fff", zIndex: 9 },
  };



  const changeColor = (value) => {
    let purple = "#ff3055";
    let white = "#ffffff";
    setBgColor({ number: value, color: purple, colorW: white });
  };

  const changeSizeColor = (value, index) => {
    setChangeColorState(false);
    let purple = "#ff3055";
    let orange = "#f4792c";
    let white = "#ffffff";
    setBgColorSize({ number: index, color: orange, colorW: white });
  };

  const handleImageError = () => {
    setImageError(false);
  };

  const sizeBoxOpenOption = () => {
    if (localData === null) {
      setLogin(true);
    } else {
      setSizeModal(true);
    }
  };

  useEffect(() => {
    let summation =
      productData?.data?.rating_count &&
      Object.values(productData?.data?.rating_count).reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );
    setTotalRating(summation);
  }, [productData?.data?.rating_count]);

  const progressColorObj = {
    5: "green",
    4: "orange",
    3: "red",
    2: "violet",
    1: "gray",
  };

  return (
    <>
      {loader ? (
        <Row style={{ margin: "20px 0" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div className="container">
          <form
            className="singleproduct screen"
            name="form6"
            // action="form6"
            method="post"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
          >
            <DiveltdfGrid className="spacer">
              
            </DiveltdfGrid>

            {login && <Login setIsOpen={setLogin} isOpen={login} />}

            <Container className="product-container">
              <Frame37021 className="c1 product-parent">
                <Group36961 className="c2">
                  <FigurewoocommerceProductGalleryWrap
                    className="slideWrap slide-image"
                    style={{ height: "66%" }}
                  >
                    <HListProductGalleryContainer className="sliderthumb">
                      <div
                        style={{
                          overflow: "auto",
                          height: "592px",
                          OverflowX: "hidden",
                        }}
                        // className="photoscroller"
                        className="scrollbar scrollbar1"
                      >
                        {productData?.data?.images?.length > 0 ? (
                          <>
                            {imageError ? (
                              <>
                                {productData?.data?.images?.map(
                                  (value, index) => {
                                    return (
                                      <>
                                        <H1ListProduct5Gallery1600x800jpg
                                          // className={`${vendorCollapse ? "activeimage" : ""}`}
                                          style={{
                                            cursor: "pointer",
                                            border: "1.5px solid lightgray",
                                          }}
                                          // style={{ objectFit: "contain", height: src ? "auto" : "150px" }}

                                          src={
                                            value?.url ? value?.url : noimage
                                          }
                                          // alt="h-1-list-product-5-gallery-1-600x800.jpg"
                                          onClick={(value) =>
                                            openSetImageView(value)
                                          }
                                          onError={handleImageError}
                                        />
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <H1ListProduct5Gallery1600x800jpg
                                style={{
                                  cursor: "pointer",
                                  border: "1.5px solid lightgray",
                                }}
                                src={noimage}
                                // alt="h-1-list-product-5-gallery-1-600x800.jpg"
                                // onClick={(value) => openSetImageView(value)}
                              />
                            )}
                          </>
                        ) : (
                          <H1ListProduct5Gallery1600x800jpg
                            style={{
                              cursor: "pointer",
                              border: "1.5px solid lightgray",
                            }}
                            src={noimage}
                            // alt="h-1-list-product-5-gallery-1-600x800.jpg"
                            // onClick={(value) => openSetImageView(value)}
                          />
                        )}
                      </div>
                    </HListProductGalleryContainer>

                    {productData?.data?.images?.length > 0 ? (
                      <>
                        {imageError ? (
                          <div
                            className="big-image"
                            style={{ marginLeft: "10px" }}
                          >
                            {window.innerWidth < 480 ? (
                              <Image src={imageData} />
                            ) : (
                              <ReactImageMagnify
                                style={{
                                  // border: "0.5px solid lightgray",
                                  height: "590 px !important",
                                  width: "590 px !important",
                                  // backgroundColor:"red"
                                }}
                                {...imageProps}
                                onError={handleImageError}
                                imageClassName="product-image"
                                className="root-image"
                              />
                            )}
                          </div>
                        ) : (
                          <H1ListProduct5jpg
                            className="no-image-a"
                            src={noimage}
                          />
                        )}
                      </>
                    ) : (
                      <H1ListProduct5jpg className="no-image-a" src={noimage} />
                    )}
                  </FigurewoocommerceProductGalleryWrap>
                </Group36961>

                <Frame37020
                  className="c3 scroller"
                  style={{ "overflow-x": "hidden" }}
                >
                  <Group37019 className="above-size">
                    <FlexCol>
                      <Surname
                        className="product-name-bold"
                        style={{
                          "white-space": "break-spaces",
                          width: "450px",
                          "word-break": "break-word",
                          // "margin-bottom": "15px",
                          "line-height": "28px",
                          height: "fit-content",
                        }}
                      >
                        {productData?.data?.name}
                      </Surname>
                      
                      <Frame36950
                        style={{ marginBottom: "10px", "margin-top": "5px" }}
                      >
                        <Rate
                          allowHalf
                          disabled
                          value={productData?.data?.rating}
                        />
                        {productData?.data?.review_rating?.length > 0 && (
                          <Button onClick={showDrawer}>View Rating</Button>
                        )}
                        <Drawer
                          anchor="right"
                          onClose={onClose}
                          open={open}
                          // className="drawer-review"
                          style={{ width: "55%" }}
                          // title="jhefrbfn"
                        >
                          <div>
                            <IconButton
                              edge="end"
                              color="inherit"
                              onClick={onClose}
                              aria-label="close"
                            >
                              <RxCross2 />
                              <div
                                style={{
                                  "margin-left": "14px",
                                  "font-weight": "bold",
                                  "font-family": "brandon-grotesque",
                                }}
                              >
                                Review and Ratings
                              </div>
                            </IconButton>
                          </div>

                          <Card
                            bordered={true}
                          >
                            <div style={{ display: "flex" }}>
                              <div style={{ height: "92px", width: "51%" }}>
                                <span style={{ "font-size": "xxx-large" }}>
                                  {productData?.data?.average_rating}
                                </span>
                                <svg
                                  height="25px"
                                  width="25px"
                                  style={{
                                    marginLeft: "5px",
                                    marginBottom: "5px",
                                  }}
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 47.94 47.94"
                                  xml:space="preserve"
                                  fill="#000000"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    stroke-width="0"
                                  ></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <path
                                      fill="green"
                                      d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  "flex-direction": "column",
                                  width: "64%",
                                }}
                              >
                                {productData?.data?.rating_count &&
                                  totalRating &&
                                  Object.keys(
                                    productData?.data?.rating_count
                                  )?.map((key, index) => {
                                    return (
                                      <>
                                        <span>
                                          {key}
                                          <AiOutlineStar
                                            style={{ marginBottom: "-2px" }}
                                          />{" "}
                                          <Progress
                                            className="progressbarrating"
                                            strokeColor={progressColorObj[key]}
                                            percent={
                                              (productData?.data?.rating_count[
                                                key
                                              ] *
                                                100) /
                                              totalRating
                                            }
                                            showInfo={false}
                                          />{" "}
                                          {productData?.data?.rating_count[key]}
                                        </span>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                            <br />
                            <hr />

                            {productData?.data?.review_rating?.map(
                              (value, index) => {
                                return (
                                  <>
                                    <div
                                      className="review"
                                      style={{
                                        fontFamily: "brandon-grotesque",
                                      }}
                                    >
                                      {value?.Customer_name}
                                    </div>
                                    <div
                                      className="review"
                                      style={{
                                        fontFamily: "brandon-grotesque",
                                      }}
                                    >
                                      {value?.Title}
                                    </div>

                                    <div
                                      style={{
                                        fontFamily: "brandon-grotesque",
                                      }}
                                    >
                                      <Rate
                                        allowHalf
                                        disabled
                                        value={value?.Rating}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        fontFamily: "brandon-grotesque",
                                      }}
                                    >
                                      {value?.Description}
                                    </div>
                                    <br />
                                    <hr />
                                    <br />
                                  </>
                                );
                              }
                            )}

                          </Card>
                        </Drawer>
                      </Frame36950>

                      <AutoLayoutHorizontal2 className="auto-layout-horizontal-9">
                        {productData?.data?.warehouses[0]?.MRP !==
                        productData?.data?.warehouses[0]?.ASP ? (
                          <Price
                            style={{
                              textDecoration: "line-through",
                              marginLeft: "8px",
                              color: "grey",
                            }}
                          >
                            ₹{productData?.data?.warehouses[0]?.MRP}
                          </Price>
                        ) : null}
                        <Price style={{ marginLeft: "8px" }}>
                          ₹{productData?.data?.warehouses[0]?.ASP}
                        </Price>
                        {productData?.data?.warehouses[0]?.MRP !==
                        productData?.data?.warehouses[0]?.ASP ? (
                          <Price style={{ marginLeft: "5px" }}>
                            <div className="para red bold">
                              ({productData?.data?.warehouses[0]?.discount_per}%
                              OFF)
                            </div>
                          </Price>
                        ) : null}
                      </AutoLayoutHorizontal2>
                      <div>
                        <p>* Tax Included</p>
                      </div>
                    </FlexCol>
                  </Group37019>
                  <Group37018>
                  </Group37018>

                  {productData?.data?.in_stock ? (
                    <>
                      <Frame36951 style={{ marginTop: "10px" }}>
                        {productData?.data?.size_of_product && (
                          <Variant>

                            <ul className="numbox">
                              {productSize && (
                                <>
                                  {sizeData?.data?.map((value, index) => {
                                    return (
                                      <>
                                        <li
                                        // key={index}
                                          style={{
                                        
                                            backgroundColor: `${
                                              index === bgColorSize?.number
                                                ? bgColorSize?.color
                                                : ""
                                            }`,

                                            color: `${
                                              index === bgColorSize?.number
                                                ? bgColorSize?.colorW
                                                : "#f4792c"
                                            }`,
                                            
                                          }}
                                          onClick={() => {
                                            setNewSizeData(value?._id),
                                              changeSizeColor(value, index),
                                              selectOriginalSizeBox(value?._id);
                                          }}
                                        >
                                          {value?.size_of_product}
                                        </li>
                                      </>
                                    );
                                  })}
                                </>
                              ) }
                            </ul>
                          </Variant>
                        )}

                        {tableData?.dataSource?.length>0 && (
                          <Button
                            style={{
                              fontFamily: "brandon-grotesque",
                              backgroundColor: " #e4e5e6",
                              color: "black",
                            }}
                            // type="black"
                            onClick={() => setSizeChartModal(true)}
                          >
                            Size Chart
                          </Button>
                        )}
                          
                      </Frame36951>
                    </>
                  ) : null}

                  <Group36964>
                    <FlexCol2>
                      <Formcart className="form-cart-product">
                        <DivshopNow className="Div-shopNow">
                          <Frame37019>
                            {productData?.data?.in_stock ? (
                              <Spin spinning={loading === "cart"}>

                                {productData?.data?.cart_quantity === 0 ? (
                                  <Button
                                    type="pink"
                                    style={{
                                      backgroundColor: "#f4792c",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                    onClick={() => handleAddCart()}
                                  >
                                    Add To Cart
                                  </Button>
                                ) : (
                                  <Button
                                    type="pink"
                                    style={{ backgroundColor: "#f4792c" }}
                                  >
                                    <Link to="/cart">
                                      <AddToCart>{viewCart}</AddToCart>
                                    </Link>
                                  </Button>
                                )}

                              </Spin>
                            ) : (
                              <Button disabled>
                                <AddToCart>
                                  <span>Out Of Stock</span>
                                </AddToCart>
                              </Button>
                            )}

                            <AaddToWishlist style={{ cursor: "pointer" }}>
                              {(productData?.data?.in_wishlist !== true && (
                                <Spin spinning={loading === "wishlist"}>
                                  <div
                                    style={{ display: "flex" }}
                                    onClick={() => handleAddWishlist()}
                                  >
                                    <div
                                      className="w-heart"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <svg
                                        style={{
                                          color: "#f4792c",
                                          zoom: "1.5",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#f4792c"
                                        className="bi bi-heart"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                      </svg>
                                    </div>

                                    {/* <span style={{ margin: '0 8px' }}>Add to Wishlist!</span> */}
                                  </div>
                                </Spin>
                              )) || (
                                <Spin spinning={loading === "wishlist"}>
                                  {" "}
                                  <div
                                    style={{ display: "flex" }}
                                    onClick={removeWishList}
                                  >
                                    {/* <AddToWishlist className="fontwt">
                                  Remove From WishList
                                </AddToWishlist>{" "} */}
                                    <div
                                      style={{
                                        alignSelf: "center",
                                      }}
                                    >
                                      <svg
                                        style={{ zoom: "1.5" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#f4792c"
                                        className="bi bi-heart-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                                        />
                                      </svg>
                                    </div>

                                    {/* <span style={{ margin: '0 8px' }}>Wishlisted!</span> */}
                                  </div>
                                </Spin>
                              )}
                              {/* <Frame1 src="/img/frame-1.svg" alt="Frame" /> */}
                            </AaddToWishlist>
                          </Frame37019>
                        </DivshopNow>
                      </Formcart>

                      {!productData?.data?.in_stock ? (
                        <div
                          style={{
                            // marginTop: "25px",
                            fontSize: "larger",
                            color: "red",
                            fontFamily: "brandon-grotesque",
                            "margin-bottom": "12px",
                          }}
                        >
                          This product is currently sold out
                        </div>
                      ) : null}

                      {/* <div style={{marginTop: "35px",color: "red",fontSize: "x-large"}}>{productData?.data?.is_active ? null : "Out of Stock"}</div> */}

                      <div className="pincode-section">
                        <Group37016>
                          <VuesaxlineartruckTick
                            src="/img/vuesax-linear-truck-tick.svg"
                            alt="vuesax/linear/truck-tick"
                          />
                          <DELIVERYOPTIONS>{deliveryOptions}</DELIVERYOPTIONS>
                          <br />
                        </Group37016>

                        <Group37017>
                          <EnterPinCode
                            name="enterpincode"
                            placeholder={inputPlaceholder}
                            value={pinCode}
                            type={inputType}
                            maxLength={6}
                            onChange={(e) =>
                              setPinCode(
                                e.target.value.replace(/[^0-9\s]/g, "")
                              )
                            }
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                checkDeliveryPincode();
                              }
                              if (
                                event.keyCode === 38 ||
                                event.keyCode === 40
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <Check
                            onClick={() => checkDeliveryPincode()}
                            style={{ cursor: "pointer" }}
                          >
                            {check}
                          </Check>
                        </Group37017>
                        {pinCodeResponse?.success && (
                          <Group37021>
                            <div
                              className="success-line"
                              style={{ color: "green" }}
                            >
                              Expected Delivery in {pinCodeResponse?.tat}.
                            </div>
                          </Group37021>
                        )}

                        {!pinCodeResponse?.success && (
                          <div
                            className="error-line"
                            style={{ color: "red", "margin-top": "15px" }}
                          >
                            {" "}
                            {pinCodeResponse?.message}
                          </div>
                        )}

                        {pinCode == undefined && (
                          <div
                            className="error-line"
                            style={{ color: "red", "margin-top": "15px" }}
                          >
                            {pinCodeResponse}
                          </div>
                        )}
                      </div>

                      <div
                        className="clsp-box"
                        style={{ width: "85%", marginTop: "30px" }}
                      >
                        <Collapse
                          accordion
                          expandIconPosition={"end"}
                          ghost
                          size="large"
                          expandIcon={({ isActive }) =>
                            isActive ? <BsChevronUp /> : <BsChevronDown />
                          }
                        >
                          <Panel
                            header={
                              <p style={{ fontFamily: "brandon-grotesque" }}>
                                <span>
                                  <BiCube
                                    style={{ marginRight: 8, marginBottom: -2 }}
                                  />
                                </span>
                                Product Details
                              </p>
                            }
                            key="1"
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto auto",
                              }}
                            >
                              {productData?.data?.neck && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    {" "}
                                    Neck:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.neck}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.fabric && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Fabric:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.fabric}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.pattern && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Pattern:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.pattern}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.sleeves && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Sleeves:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.sleeves}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.length && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Length:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.length}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.wash_care && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Wash Care:
                                  </p>
                                  <div
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.wash_care}
                                  </div>
                                </div>
                              )}

                              {productData?.data?.occasion && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Ocassion:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.occasion}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.fit && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    Fit:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.fit}
                                  </p>
                                </div>
                              )}

                              {productData?.data?.closure && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontFamily: "brandon-grotesque",
                                    }}
                                  >
                                    {" "}
                                    Closure:
                                  </p>
                                  <p
                                    style={{ fontFamily: "brandon-grotesque" }}
                                  >
                                    {productData?.data?.closure}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div></div>
                          </Panel>

                          <Panel
                            header={
                              <p style={{ fontFamily: "brandon-grotesque" }}>
                                <span>
                                  <AiOutlineExclamationCircle
                                    style={{ marginRight: 8, marginBottom: -2 }}
                                  />
                                </span>
                                Know Your Product
                              </p>
                            }
                            key="2"
                          >
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.product_description}
                            </div>
                          </Panel>

                          <Panel
                            header={
                              <p style={{ fontFamily: "brandon-grotesque" }}>
                                <span>
                                  <GiBodyHeight
                                    style={{ marginRight: 8, marginBottom: -2 }}
                                  />
                                </span>
                                Model Vitals
                              </p>
                            }
                            key="3"
                          >
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.model_vitals}
                            </div>
                          </Panel>

                          <Panel
                            header={
                              <p style={{ fontFamily: "brandon-grotesque" }}>
                                <span>
                                  <GrAlert
                                    style={{ marginRight: 8, marginBottom: -2 }}
                                  />
                                </span>
                                Disclaimer
                              </p>
                            }
                            key="4"
                          >
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.disclaimer}
                            </div>
                          </Panel>

                          <Panel
                            header={
                              <p style={{ fontFamily: "brandon-grotesque" }}>
                                <span>
                                  <BiUser
                                    style={{ marginRight: 8, marginBottom: -2 }}
                                  />
                                </span>
                                Vendor Details
                              </p>
                            }
                            key="5"
                          >
                            <div
                              style={{
                                fontWeight: "bolder",
                                marginTop: "10px",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              HSN No:
                            </div>
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.hsn}
                            </div>

                            <div
                              style={{
                                fontWeight: "bolder",
                                marginTop: "10px",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              Country Of Origin:
                            </div>
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.country_of_origin}
                            </div>

                            <div
                              style={{
                                fontWeight: "bolder",
                                marginTop: "10px",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              Manufactured By:
                            </div>
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.manufactured_by}
                            </div>

                            <div
                              style={{
                                fontWeight: "bolder",
                                marginTop: "10px",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              Packed By:
                            </div>
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.packed_by}
                            </div>

                            <div
                              style={{
                                fontWeight: "bolder",
                                marginTop: "10px",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              Marketed By:
                            </div>
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.marketed_by}
                            </div>

                            <div
                              style={{
                                fontWeight: "bolder",
                                marginTop: "10px",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              Customer Care Address:
                            </div>
                            <div style={{ fontFamily: "brandon-grotesque" }}>
                              {productData?.data?.customer_care_address}
                            </div> 
                          </Panel>
                        </Collapse>
                      </div>

                      {/* <Col span={8}> */}

                      {/* </Col> */}
                      <DivproductMeta>
                        {/* <OverlapGroup>
                      <SKU>{sku}</SKU>
                      <MEGAJEWE11>{megaJewe11}</MEGAJEWE11>
                    </OverlapGroup> */}

                        {/* <SpanpostedIn>
                      <OverlapGroup1>
                        <Categories>{categories}</Categories>
                        <Accessories>{productData?.data?.category?.code}</Accessories>
                        <Text44>{text44}</Text44>
                      </OverlapGroup1>
                      <OverlapGroup2>
                        <Dresses>{dresses}</Dresses>
                        <Text45>{text45}</Text45>
                      </OverlapGroup2>
                      <Shirt>{shirt}</Shirt>
                    </SpanpostedIn> */}

                        {/* <SpantaggedAs>
                      <OverlapGroup4>
                        <Tags>{tags}</Tags>
                        <Anklets>{anklets}</Anklets>
                        <Text46>{text46}</Text46>
                      </OverlapGroup4>
                      <OverlapGroup3>
                        <Bracelets>{bracelets}</Bracelets>
                        <Text47>{text47}</Text47>
                      </OverlapGroup3>
                      <Jewelry>{jewelry}</Jewelry>
                    </SpantaggedAs> */}

                        {/* <OverlapGroup5>
                      <Brand>{brand}</Brand>
                      <Name>{name}</Name>
                    </OverlapGroup5> */}
                      </DivproductMeta>
                    </FlexCol2>
                  </Group36964>
                </Frame37020>
              </Frame37021>
            </Container>
          </form>

          <div className="recommendation spacer">
            {recommendItems?.length > 0 && (
              <h1
                style={{ marginBottom: "1rem" }}
                className="collection-heading text-center"
              >
                Top Related Products
              </h1>
            )}

            {recommendItems?.length > 0 && (
              <Row justify="space-evenly">
                {recommendItems?.map((item, index) => {
                  return (
                    <div style={{ margin: "12px 0" }}>
                      <Product
                        key={index}
                        item={item}
                        className={product1Props?.className}
                        src={item?.images[0]?.url}
                        fetchData={getSingleItem}
                      />
                      {/* dummy data */}
                      {/* <Product
                    disableResponsive
                    key={index}
                    item={item}
                    // className={product1Props?.className}
                    src={item?.images[0]?.url}
                    // fetchData={fetchCollectionItem}
                  /> */}
                    </div>
                  );
                })}
              </Row>
            )}
          </div>
          <Modal
            // title="Modal 1000px width"
            centered
            open={imageModal}
            onOk={() => setImageModal(false)}
            onCancel={() => setImageModal(false)}
            width={1000}
            footer={null}
          >
            {/* <p>nkecjendje</p> */}
            <div>
              {productData?.data?.images?.map((value, index) => {
                return (
                  <Carousel>
                    <img
                      src={value?.url}
                      alt="h-1-list-product-5-gallery-1-600x800.jpg"
                    />
                  </Carousel>
                );
              })}
            </div>
          </Modal>

          <Modal
            title="Select Quantity"
            centered
            open={quantityPopUp}
            onOk={() => setQuantityPopup(false)}
            onCancel={() => setQuantityPopup(false)}
            footer={null}
            width={360}
          >
            <hr />
            <ul className="numbox">
              {numbers?.map((value, index) => {
                return (
                  <>
                    <li
                      className="numbox"
                      style={{
                        backgroundColor: `${
                          value === bgColor?.number ? bgColor?.color : ""
                        }`,
                        color: `${
                          value === bgColor?.number ? bgColor?.colorW : "black"
                        }`,
                      }}
                      onClick={() => {
                        setQuantityData(value), changeColor(value);
                      }}
                    >
                      {value}
                    </li>
                  </>
                );
              })}
            </ul>
            <Button
              style={{
                marginLeft: "24px",
                width: "81%",
                borderColor: "var(--primary-color)",
                color: "var(--sizzling-red)",
              }}
              onClick={() => selectSize()}
            >
              DONE
            </Button>
          </Modal>

          <Modal
            // title="Size Chart"
            style={{
              top: 130,
            }}
            open={sizeChartModal}
            onOk={() => setSizeChartModal(false)}
            onCancel={() => setSizeChartModal(false)}
            footer={null}
          >
            <div
              style={{
                padding: "4px 8px",
                marginBottom: 12,
                borderRadius: 8,
                "font-size": "x-large",
                "font-weight": "bolder",
              }}
            >
              Size Chart
            </div>

              <div
                style={{
                  height: "100%",
                  maxHeight: "calc(100vh - 260px)",
                  overflow: "auto",
                }}
              >
                {/* --- my change start --- */}
                <Row justify="center">
                  <Segmented
                    value={sizeToggleValue}
                    onChange={(val) => setSizeToggleValue(val)}
                    options={[
                      { label: "in", value: "in" },
                      { label: "cms", value: "cm" },
                    ]}
                  />
                </Row>

                
                <div
                  style={{
                    margin: "12px 0",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                >
                  <Table
                    columns={
                      tableData ? tableData?.columnsList[sizeToggleValue] : []
                    }
                    dataSource={tableData ? tableData?.dataSource : []}
                    pagination={false}
                  />
                </div>


                {/* <table style={{"border-collapse": "collapse",width: "100%"}}>
  <tr>
    <th>Company</th>
    <th>Contact</th>
    <th>Country</th>
  </tr>
  <tr>
    <td>Alfreds Futterkiste</td>
    <td>Maria Anders</td>
    <td>Germany</td>
  </tr>
  <tr>
    <td>Centro comercial Moctezuma</td>
    <td>Francisco Chang</td>
    <td>Mexico</td>
  </tr>
  <tr>
    <td>Ernst Handel</td>
    <td>Roland Mendel</td>
    <td>Austria</td>
  </tr>
  <tr>
    <td>Island Trading</td>
    <td>Helen Bennett</td>
    <td>UK</td>
  </tr>
  <tr>
    <td>Laughing Bacchus Winecellars</td>
    <td>Yoshi Tannamuri</td>
    <td>Canada</td>
  </tr>
  <tr>
    <td>Magazzini Alimentari Riuniti</td>
    <td>Giovanni Rovelli</td>
    <td>Italy</td>
  </tr>
</table> */}

              </div>

            {/* {productData?.data?.sub_category?.code == "MENS" && (
              <>
                <div>
                  <h1
                    style={{
                      "font-size": "x-large",
                      "font-weight": "bolder",
                      color: "#f4792c",
                    }}
                  >
                    Men’s Polo Neck T-shirts (Inch)
                  </h1>
                  <Table
                    dataSource={MensSizeChartTopInch}
                    columns={MensColumnsInchTop}
                    pagination={false}
                  />
                </div>

                <div>
                  <h1
                    style={{
                      "font-size": "x-large",
                      "font-weight": "bolder",
                      color: "#f4792c",
                    }}
                  >
                    Men’s Polo Neck T-shirts (Cms)
                  </h1>
                  <Table
                    dataSource={MensSizeChartTopCms}
                    columns={MensColumnsInchTop}
                    pagination={false}
                  />
                </div>
              </>
            )}

            {productData?.data?.sub_category?.code == "KIDS" && (
              <>
                <div>
                  <h1
                    style={{
                      "font-size": "x-large",
                      "font-weight": "bolder",
                      color: "#f4792c",
                    }}
                  >
                    Kids Boys Denim (Inch)
                  </h1>
                  <Table
                    dataSource={KidsSizeChartTopInch}
                    columns={KidsColumnsInchTop}
                    pagination={false}
                  />
                </div>

                <div>
                  <h1
                    style={{
                      "font-size": "x-large",
                      "font-weight": "bolder",
                      color: "#f4792c",
                    }}
                  >
                    Kids Boys Denim (Cms)
                  </h1>
                  <Table
                    dataSource={KidsSizeChartTopCms}
                    columns={KidsColumnsInchTop}
                    pagination={false}
                  />
                </div>
              </>
            )} */}

          </Modal>

          <Modal
            title="Select Size"
            centered
            open={sizeModal}
            onOk={() => setSizeModal(false)}
            onCancel={() => setSizeModal(false)}
            footer={null}
            width={360}
          >
            <hr />
            <ul className="numbox">
              {productSize ? (
                <>
                  {sizeData?.data?.map((value, index) => {
                    return (
                      <>
                        <li
                          style={{
                            backgroundColor: `${
                              index === bgColorSize?.number
                                ? bgColorSize?.color
                                : ""
                            }`,
                            color: `${
                              index === bgColorSize?.number
                                ? bgColorSize?.colorW
                                : "#f4792c"
                            }`,
                          }}
                          onClick={() => {
                            setNewSizeData(value?._id),
                              changeSizeColor(value, index);
                          }}
                        >
                          {value?.size_of_product}
                        </li>
                      </>
                    );
                  })}
                  <Button
                    style={{
                      marginLeft: "24px",
                      width: "81%",
                      borderColor: "var(--primary-color)",
                      color: "var(--sizzling-red)",
                    }}
                    onClick={() => selectOriginalSizeBox()}
                  >
                    DONE
                  </Button>
                </>
              ) : (
                <div style={{ color: "red", fontSize: "large" }}>
                  Sorry! No more size is available of this product.
                </div>
              )}
            </ul>
          </Modal>
        </div>
      )}
    </>
  );
};

const DiveltdfGrid = styled.div`
  width: 100%;
  padding-top: 15px !important;
`;

const NavbarLinkContainer = styled.div`
  ${MontserratMediumBlack113px}
  width: 372px;
  height: 15px;
  position: relative;
  margin-top: 8px;
`;

const Collection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  position: relative;
  margin: auto;
`;

const NavbarLinkPlace = styled.div`
  ${ValignTextMiddle}
  ${MontserratMediumBlack113px}
            position: absolute;
  width: 44px;
  height: 15px;
  top: 0;
  left: 0;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
  cursor: pointer;
`;

const NavbarLinkText41 = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 24px;
  height: 15px;
  top: 0;
  left: 43px;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
`;

const NavbarLinkShop = styled.div`
  ${ValignTextMiddle}
  ${MontserratMediumBlack113px}
            position: absolute;
  width: 40px;
  height: 15px;
  top: 0;
  left: 68px;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
  cursor: pointer;
`;

const NavbarLinkText42 = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 24px;
  height: 15px;
  top: 0;
  left: 108px;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  font-family: brandon-grotesque;
  color: #373736;
`;

const AutoLayoutHorizontal3 = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  flex-wrap: wrap;
  margin-left: 2rem;
  justify-content: start;
  width: 100%;
`;

const CollectionItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  position: relative;
  width: 100%;
`;

const NavbarLinkAccessories = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 100px;
  height: 15px;
  top: 0;
  left: 132px;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
`;

const NavbarLinkText43 = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 24px;
  height: 15px;
  top: 0;
  left: 232px;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
`;

const NavbarLinkBlueNavyBag = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 116px;
  height: 15px;
  top: 0;
  left: 256px;
  letter-spacing: 1.63px;
  line-height: 33.8px;
  white-space: nowrap;
`;

const Shop = styled.div`
  ${ValignTextMiddle}
  width: 73px;
  height: 39px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: 28.2px;
  letter-spacing: 0;
  line-height: 31.5px;
`;

// height: 900px;
const Container = styled.div`
  display: flex;
`;

const Frame37021 = styled.div``;

const Group36961 = styled.div``;

const FigurewoocommerceProductGalleryWrap = styled.div`
  width: 714px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const HListProductGalleryContainer = styled.div`
  width: 111px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 10px;
`;

const Group2 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const H1ListProduct5Gallery1600x800jpg = styled.img`
  width: 121px;
  height: 121px;
`;

const H1ListProduct5jpg = styled.img`
  width: 593px;
  height: 593px;
  align-self: center;
`;

const Frame37020 = styled.div`
  flex: 1;
  overflow: auto;
`;

const Group37019 = styled.div`
  position: relative;
  min-width: 284.9700012207031px;
  height: fit-content;
`;

const Group37021 = styled.div`
  // position: absolute;
  // height: 40px;
  top: 560px;
  left: 733px;
  // display: flex;
  // padding: 0 9px;
  font-family: brandon-grotesque;
  align-items: flex-start;
  min-width: 249px;
  gap: 97px;
  // margin-bottom: 10px;
  margin-top: 15px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
`;

const Surname = styled.div`
  ${ValignTextMiddle}
  height: 35px;
  margin-bottom: -1px;
  // font-family: var(--font-family-azo_sans-regular);
  font-family: brandon-grotesque;
  font-weight: bold;
  // color: var(--bunker);
  font-size: 25px;
  letter-spacing: 0;
  line-height: 36px;
  white-space: nowrap;
  color: #373736;
`;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;

const Frame = styled.img`
  position: relative;
  min-width: 75.05999755859375px;
  height: 12px;
`;

const X10CustomerReviews = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  height: 34px;
  margin-top: 6px;
  // font-family: var(--font-family-azo_sans-medium);
  font-family: brandon-grotesque;
  font-weight: 500;
  color: var(--primarycolor);
  font-size: 17px;
  letter-spacing: 0.1px;
  line-height: 34px;
  white-space: nowrap;
`;

const Group37018 = styled.div`
  position: relative;
`;

const FlexCol1 = styled.div`
  ${InterNormalStormDust13px}

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BalancingLaidbackL = styled.p`
  ${ValignTextMiddle}
  height: 21px;
  margin-bottom: -6px;
  font-family: brandon-grotesque;
  color: #373736;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const QualityPairsSince = styled.p`
  ${ValignTextMiddle}
  height: 21px;
  margin-bottom: -6px;
  margin-top: 6px;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const X79OrganicCotton = styled.p`
  ${ValignTextMiddle}
  height: 21px;
  margin-bottom: -6px;
  margin-top: 13px;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const Frame36951 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 15px;
  position: relative;
`;

const Variant = styled.div`
  position: relative;
  cursor: pointer;
  font-family: brandon-grotesque;
`;

const Ul = styled.div`
  display: flex;
  width: 97px;
  align-items: flex-start;
  gap: 4px;

  position: relative;
  justify-content: center;
`;

const Group36964 = styled.div`
  position: relative;
`;

const FlexCol2 = styled.div`
  width: 657px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 393px;
`;

const Formcart = styled.div`
  width: 657px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // min-height: 91px;
  margin-bottom: 12px !important;
`;

const DivshopNow = styled.div`
  display: flex;
  padding: 10px 0px;
  flex-wrap: wrap;
`;

const Frame37019 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 15px;
`;

const ButtonsingleAddToCartButton = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 55.25px 0px 55.75px;
  position: relative;
  background-color: var(--primarycolor);
  cursor: pointer;
`;

const AddToCart = styled.div`
  font-family: brandon-grotesque;
`;

const ButtonsingleAddToCartButton1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 55.25px 0px 55.75px;
  position: relative;
  background-color: var(--sizzling-red);
  cursor: pointer;
`;

const AaddToWishlist = styled.div`
  position: relative;
  display: flex;
  min-width: 112.13999938964844px;
`;

const Frame1 = styled.img`
  position: absolute;
  width: 18px;
  height: 48px;
  top: 0;
  left: 0;
`;

const AddToWishlist = styled.div`
  ${ValignTextMiddle}

  height: 48px;
  top: -1px;
  left: 26px;
  // font-family: var(--font-family-inter);
  font-family: brandon-grotesque;
  font-weight: 400;
  color: var(--suva-gray);
  // font-size: var(--font-size-xxs);
  font-size: initial;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const DivgroupButton = styled.div`
  width: 657px;
  height: 13px;
  border-right-width: 1px;
  border-right-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: var(--alto);
`;

// const UltbayButtonPopupWrap = styled.div`
//   height: 75px;
//   display: flex;
//   padding: 22px 16px;
//   align-items: flex-start;
//   min-width: 657px;
//   gap: 60px;
//   border-right-width: 1px;
//   border-right-style: solid;
//   border-bottom-width: 1px;
//   border-bottom-style: solid;
//   border-left-width: 1px;
//   border-left-style: solid;
//   border-color: var(--alto);
// `;

const Liitem = styled.div`
  display: flex;
  align-items: center;
  min-width: 165px;
  gap: 65px;
`;

const ApopupButtonOpen = styled.div`
  height: 30px;
  display: flex;
  align-items: flex-start;
  min-width: 134px;
  gap: 8px;
`;

const Frame2 = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-bottom: 2px;
`;

const DeliveryReturn15Days = styled.div`
  ${ValignTextMiddle}
  height: 28px;
  font-family: var(--font-family-inter);
  font-weight: 500;
  color: var(--bunker);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Pseudo = styled.div`
  width: 1px;
  height: 18px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: #efefef;
`;

const Liitem1 = styled.div`
  height: 30px;
  display: flex;
  align-items: flex-start;
  min-width: 137px;
  gap: 8px;
`;

const SizeGuide = styled.div`
  ${ValignTextMiddle}
  height: 28px;
  font-family: var(--font-family-inter);
  font-weight: 500;
  color: var(--bunker);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const DivproductMeta = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  align-items: flex-start;
`;

const OverlapGroup = styled.div`
  width: 133px;
  height: 20px;
  position: relative;
`;

const SKU = styled.div`
  ${ValignTextMiddle}
  ${InterNormalSuvaGray14px}
            position: absolute;
  width: 34px;
  height: 20px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const MEGAJEWE11 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker13px}
            position: absolute;
  width: 100px;
  height: 20px;
  top: 0;
  left: 33px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const SpanpostedIn = styled.div`
  height: 27px;
  margin-top: 6px;
  display: flex;
  padding: 2px 0;
  align-items: flex-start;
  min-width: 680px;
  gap: 5px;
`;

const OverlapGroup1 = styled.div`
  width: 144px;
  height: 20px;
  position: relative;
`;

const Categories = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 72px;
  height: 20px;
  top: 0;
  left: 0;
  font-family: var(--font-family-inter);
  font-weight: 400;
  color: var(--suva-gray);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const Accessories = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker11px}
            position: absolute;
  width: 68px;
  height: 20px;
  top: 0;
  left: 71px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text44 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            position: absolute;
  width: 4px;
  height: 14px;
  top: 6px;
  left: 139px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  width: 48px;
  height: 20px;
  position: relative;
`;

const Dresses = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker11px}
            position: absolute;
  width: 44px;
  height: 20px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text45 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            position: absolute;
  width: 4px;
  height: 14px;
  top: 6px;
  left: 44px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
`;

const Shirt = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker12px}
            height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Jewelry = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker11px}
            height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const SpantaggedAs = styled.div`
  height: 27px;
  margin-top: 1px;
  display: flex;
  padding: 2px 0;
  align-items: flex-start;
  min-width: 680px;
  gap: 5px;
`;

const OverlapGroup4 = styled.div`
  width: 82px;
  height: 20px;
  position: relative;
`;

const Tags = styled.div`
  ${ValignTextMiddle}
  ${InterNormalSuvaGray14px}
            position: absolute;
  width: 36px;
  height: 20px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const Anklets = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker12px}
            position: absolute;
  width: 43px;
  height: 20px;
  top: 0;
  left: 35px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text46 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            position: absolute;
  width: 4px;
  height: 14px;
  top: 6px;
  left: 78px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
`;

const OverlapGroup3 = styled.div`
  width: 56px;
  height: 20px;
  position: relative;
`;

const Bracelets = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker11px}
            position: absolute;
  width: 52px;
  height: 20px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text47 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            position: absolute;
  width: 4px;
  height: 14px;
  top: 6px;
  left: 52px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
`;

const OverlapGroup5 = styled.div`
  width: 118px;
  height: 20px;
  position: relative;
  margin-top: 3px;
`;

const Brand = styled.div`
  ${ValignTextMiddle}
  ${InterNormalSuvaGray14px}
            position: absolute;
  width: 44px;
  height: 20px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
`;

const Name = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker12px}
            position: absolute;
  width: 75px;
  height: 20px;
  top: 0;
  left: 43px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Group37016 = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
`;

const VuesaxlineartruckTick = styled.img`
  width: 24px;
  height: 24px;
`;

const DELIVERYOPTIONS = styled.div`
  ${ValignTextMiddle}
  height: 28px;
  min-width: 124px;
  // font-family: var(--font-family-inter);
  font-family: brandon-grotesque;
  font-weight: 500;
  color: var(--black);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const Group37017 = styled.div`
  height: 40px;
  display: flex;
  padding: 11px 9px;
  align-items: flex-start;
  min-width: 249px;
  font-family: brandon-grotesque;
  width: 249px;
  // gap: 97px;
  background-color: var(--lg);
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 1px 8px #0000000d;
`;

const EnterPinCode = styled.input`
  width: 195px;
  height: 15px;
  background-color: transparent;
  // font-family: var(--font-family-lato);
  font-family: brandon-grotesque;
  font-weight: 300;
  // color: var(--sonic-silver);
  color: black;
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  border: 0;
  padding: 0;

  &::placeholder {
    color: black;
  }
`;

const Check = styled.div`
  ${ValignTextMiddle}
  ${AzosansMediumSizzlingRed14px}
            height: 15px;
  letter-spacing: 0;
  line-height: 20px;
  font-family: brandon-grotesque;
  white-space: nowrap;
`;

const Group36960 = styled.div`
  width: 1357px;
  margin-top: 39px;
  margin-right: 41.84px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 196px;
`;

const FlexRow = styled.div`
  ${PoppinsBoldBlack191px}
  margin-left: 29.28px;
  display: flex;
  align-items: flex-start;
  min-width: 571px;
  gap: 58px;
`;

const Description = styled.div`
  ${ValignTextMiddle}
  width: 114px;
  height: 27px;
  letter-spacing: 0;
  line-height: 29.3px;
  white-space: nowrap;
`;

const AdditionalInformation = styled.div`
  ${ValignTextMiddle}
  width: 225px;
  height: 27px;
  letter-spacing: 0;
  line-height: 29.3px;
  white-space: nowrap;
`;

const Reviews0 = styled.div`
  ${ValignTextMiddle}
  height: 27px;
  letter-spacing: 0;
  line-height: 29.3px;
  white-space: nowrap;
`;

const OverlapGroup6 = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  min-width: 1351px;
  background-color: #cccccc;
`;

const Pseudo1 = styled.div`
  width: 172px;
  height: 1px;
  background-color: var(--black);
`;

const P = styled.div`
  ${MontserratNormalBlack169px}
  width: 1351px;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  padding: 2.3px 0px;
  align-items: flex-start;
  min-height: 117px;
  gap: 8px;
`;

const LoremIpsumDolorSi = styled.p`
  ${ValignTextMiddle}
  height: 21px;
  letter-spacing: 0;
  line-height: 29.3px;
  white-space: nowrap;
`;

const Group36953 = styled.div`
  height: 91px;
  position: relative;
  margin-top: 51px;
  display: flex;
  align-items: flex-start;
  min-width: 314px;
`;

const Frame5 = styled.div`
  display: flex;
  position: relative;
  margin-top: 75px;
  margin-right: 3px;
  width: fit-content;
  align-items: flex-start;
  gap: 39px;
`;

const Frame6 = styled.div`
  display: flex;
  position: relative;
  margin-top: 1px;
  margin-left: 1px;
  width: fit-content;
  align-items: flex-start;
  gap: 39px;
`;

export default SingleProduct;
