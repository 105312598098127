import React from "react";
import Buttons from "../Buttons";
import styled from "styled-components";
import { Link } from "react-router-dom";


class CatBanner extends React.Component {
  render() {
    const { item, src, name, id,name1 } = this.props;

    return (
      <Link to={`/productFilter?category=${name}&only=${name}`}>

      <CatBanner1
        style={{
          margin: '12px 0',
          backgroundImage: `url(${src})`,
        }}
      >
        <Buttons  id={id} name={name} name1={name1} />
        {/* <Buttons id={item?._id} name={name} name1={name1}/> */}
      </CatBanner1>
      </Link>

    );
  }
}

const CatBanner1 = styled.article`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 426px;
  align-items: center;
  justify-content: flex-end;
  padding: 27px 0px;
  position: relative;
  font-weight: bold;
  background-size: cover;
  background-position: 50% 50%;
`;

export default CatBanner;
