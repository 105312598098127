import axios from "axios";
import { dev } from "./baseurl";
import { api } from "./baseurl";

export async function sendOTP(mobile) {
  const data = await dev.post("/authentication/login", {
    mobile,
  });
  return data;
}

export async function sendOtpAgain(mobile) {
  const data = await dev.post("/authentication/resend-otp", {
    mobile,
  });

  return data;
}


export async function verifyOtp(mobile, otp) {
  const data = await dev.post("/authentication/otp-verify", {
    mobile,
    otp: {
      value: otp,
    },
  });

  return data;
}

export async function logoutApi() {
  const data = await dev.get("/authentication/logout", { withCredentials: true });

  return data;
}


export async function getBelieveWareHouse(pincode = "122018") {


  // 122018;
  const data = await dev.post("/address/believe/set", { pincode: "122018" });
  return data;
}

// dev pin-201302
// qa pin-122018
// prod-122101
