import axios from "axios";
import { dev } from "./baseurl";

export async function getCollectionsApi(item) {
  const data = await dev.get("/collection/showcollection/believe/");
  return data;
}

export async function getItemByIdApi(id) {
  const data = await dev.get("/item/getitem/believe/", {
    params: {
      id: id,
    },
  });
  return data;
}

export async function getRecommendation(id, cat, img) {
  const imageUrl =
    "https://blobstoragefors3bucket.blob.core.windows.net/devdata/default/3a6f5677-f29e-4db5-bd3a-a1e3b45ee688.png";

  // axios
  //   .get(imageUrl, { responseType: "blob" })
  //   .then((response) => {
  //     const blob = response.data;
  //     console.log(blob, "blob checcking ksjfaksd jf");
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching image:", error);
  //   });

  // const image = await axios.get(imageUrl, { responseType: "blob" });
  // console.log(image, "image");

  // const file = new File([img], 'image.jpeg', { type: "image/jpeg" });
  // const newBlob = new Blob([file], {
  //   url: imageUrl,
  // });
  // const file = new File([newBlob], img, {
  //   lastModified: Date.now(),
  //   url: img,
  // });
  // file.url = imageUrl;

  // file.name = imageUrl;

  // console.log(file, "file");

  const form = new FormData();
  form.append("product_id", id);
  form.append("category", cat);
  form.append("product_image", img);

  const data = await axios.post("/dev/fashionapi/recommended_products", form, {
    withCredentials: true,
  });
  return data;
  //  product_image: img,
}

// const form = new FormData();
//   form.append("product_id", id);
//   form.append("category", cat);
//   form.append("product_image", img);
//   const data = await axios.post("/dev/fashionapi/recommended_products", form, {

//     withCredentials: true,

//   });

//   return data;

//
export async function getCollectionItemApi(id, pageSize) {
  const data = await dev.get(`/collection/viewmorecollectionitem/believe/`, {
    params: {
      page_size: 12,
      page_num: pageSize,
      id: id,
    },
    withCredentials: true,
  });
  return data;
}

export const getItemVariationApi = (classOfProduct) => {
  return axios.get(`/dev/item/variations/believe?class=${classOfProduct}`);
};
