import React from "react";
import { format } from "date-fns";
const NextStepComp = ({ name, date, text, isReturn }) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const customeData = date && format(new Date(date), "d");
  const month = date && format(new Date(date), "MMMM").slice(0, 3);
  const year = date && format(new Date(date), "yyyy");
  const today = date && weekday[new Date(date).getDay()];

  return (
    <div className="return-timeline">
      {(isReturn && (
        <div className={`completed ${name === "Cancelled" && "cancelled"}`}>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "6px" }}>
              {(name === "Cancelled" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-exclamation-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
              )) || (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#00B41D"
                  className="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              )}
            </div>

            <div style={{ marginLeft: "1rem" }}>
              {/* <h5 className="timeline-heading">{name}</h5> */}

              <p
                style={{
                  fontWeight: "400",
                  color: "black",
                  color: "#00B41D",
                  letterSpacing: "1px",
                }}
                className="para"
              >
                {text}
              </p>

              <p
                style={{ fontWeight: "400", marginTop: "6px" }}
                className="small-para"
              >
                {today} {month} {customeData}, &nbsp;{year}
              </p>
            </div>
          </div>
        </div>
      )) || (
        <div className="return-process ">
          <div style={{ display: "flex" }}>
            <div className="return-time-line-dot-container">
              <div className="round-dot"></div>
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <p
                style={{
                  fontWeight: "300",
                  marginTop: "9px",

                  letterSpacing: "1px",
                }}
                className="para text-left"
              >
                {text}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NextStepComp;
