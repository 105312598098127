import React from "react";
import styled from "styled-components";
import { InterNormalWhite12px, ValignTextMiddle } from "../../styledMixins";

class AutoLayoutVertical extends React.Component {
  render() {
 

    return (
      <AutoLayoutVertical1>
        {this.props?.item && this.props?.item[0]?.discount_per !== 0 && (
          <AutoLayoutHorizontal>
            <Text4>
              -{this.props?.item && this.props?.item[0]?.discount_per} %
            </Text4>
          </AutoLayoutHorizontal>
        )}
        <AutoLayoutHorizontal1>
          <Text4>Featured</Text4>
        </AutoLayoutHorizontal1>
      </AutoLayoutVertical1>
    );
  }
}

const AutoLayoutVertical1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const Text4 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.949996948242188px 4px 12px;
  position: relative;
  background-color: var(--pomegranate);
`;

const AutoLayoutVertical2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const AutoLayoutHorizontal2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const Text35 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.949996948242188px 4px 12px;
  position: relative;
  background-color: var(--pomegranate);
`;

const AutoLayoutVertical3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const AutoLayoutHorizontal4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const Text49 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.949996948242188px 4px 12px;
  position: relative;
  background-color: var(--pomegranate);
`;

export default AutoLayoutVertical;
