import React, { useEffect, useState } from 'react'
import NotificationBox from './NotificationBox'
import { Divider, Row, Space, notification } from 'antd'
import { dev } from '../../../api/baseurl'
import { orderListApi } from '../../../api/order';

export default function Notification() {
  const [ api, contextHolder ] = notification.useNotification();
  const [orderId,setOrderId]=useState();
  const [notificationData,setNotificationData]=useState();

  const GetNotifications = async() => {
    try {
      const data = await dev.get(`/orders/notifications/?order_id=${orderId}`)
      if (data?.data?.success) {
    setNotificationData(data?.data?.notification_data);
      }
    }
    catch (err) {
      console.log('notification error',err);
    }
  }



  const getOrderList = async () => {
    // setIsLoading(true);
    const { data } = await orderListApi();
    setOrderId(data?.orders[0]?.order_id);
    // if (data.success) {
    //   setIsLoading(false);
    //   setOrderList(data?.orders);
    // } else {
    //   notification.error({
    //     message: data?.message,
    //     duration: 1,
    //     placement: "bottom",
    //   });
    // }
    // setIsLoading(false);
  };

  useEffect(() => {
    getOrderList();
  },[])

  useEffect(()=>{
    GetNotifications();
  },[orderId])

  return (
    <Space className='my-4' direction='vertical' split={<Divider />}>
      {notificationData?.map((value,index)=>{
        return (
          <NotificationBox 
          title={value?.status}
          description={value?.message}
          orderid={orderId}
        />
        );
      })}
  
    </Space>
  )
}
