import React from "react";
import { format } from "date-fns";
const TimeLine = ({ name, date, text, isReturn, other = "" }) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const customeData = date && format(new Date(date), "d");
  const month = date && format(new Date(date), "MMMM").slice(0, 3);
  const year = date && format(new Date(date), "yyyy");
  const today = date && weekday[new Date(date).getDay()];

  return (
    <div className="timeline">
      {(other === name && (
        <div className={`completed ${name === "Cancelled" && "cancelled"}`}>
          <div style={{ display: "flex" }}>
            <div>
              {(name === "Cancelled" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-exclamation-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
              )) || (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              )}
            </div>

            <div style={{ marginLeft: "1rem" }}>
              <h5 className="timeline-heading">{name}</h5>
              {(name === "Cancelled" && (
                <p
                  style={{
                    fontWeight: "400",
                    marginTop: "9px",
                    color: "black",
                    letterSpacing: "1px",
                  }}
                  className="small-para"
                >
                  Your Order Cancelled
                </p>
              )) || (
                <p
                  style={{
                    fontWeight: "400",
                    marginTop: "9px",
                    color: "black",
                    letterSpacing: "1px",
                  }}
                  className="small-para"
                >
                  {text}
                </p>
              )}

              <p
                style={{ fontWeight: "400", marginTop: "6px" }}
                className="small-para"
              >
                {today} {month} {customeData}, &nbsp;{year}
              </p>
            </div>
          </div>
        </div>
      )) || (
        <div className="process ">
          <div style={{ display: "flex" }}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-clock"
                viewBox="0 0 16 16"
              >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>{" "}
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <h5 className="timeline-heading">{name}</h5>
              {isReturn && (
                <p
                  style={{
                    fontWeight: "400",
                    marginTop: "9px",
                    color: "black",
                    letterSpacing: "1px",
                  }}
                  className="small-para"
                >
                  {text}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeLine;
