import React, { useEffect, useRef } from "react";
import Group36885 from "../Group36885";
import styled from "styled-components";
import { useState } from "react";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Drawer,
  Radio,
  Space,
  notification,
  Row,
  Input,
  AutoComplete,
  Modal,
} from "antd";
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";
import { AiOutlineShoppingCart } from "react-icons/ai";

import {
  LatoNormalBlack14px,
  AzosansMediumSizzlingRed14px,
  AzosansRegularNormalBlack14px,
  ValignTextMiddle,
} from "../../styledMixins";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space, Button } from "antd";

import { debounce } from "lodash";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Login from "../Login";
import {
  getWherehouseApi,
  suggestionApi,
  searchRecentApi,
} from "../../api/search";
import Product from "../Product";
import { dev } from "../../api/baseurl";
import axios from "axios";
import { getBelieveWareHouse, logoutApi } from "../../api/login";
import { accountDetailApi } from "../../api/account";
import { setCartCount } from "../../api/Cart";
import { RxCross2 } from "react-icons/rx";
import { getWalletApi } from "../../api/wallet";

const BeforeLogin = ({ setOpen, setDropdownVisible }) => {
  return (
    <>
      <div
        style={{
          padding: 10,
          marginTop: 0,
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        <Button
          type="pink"
          onClick={() => {
            setOpen(true), setDropdownVisible(false);
          }}
        >
          LOG IN / SIGN UP
        </Button>

        {/* <Divider plain>OR</Divider> */}
        {/* <LoginSocialGoogle
          client_id={
            "938634949182-esh979fpdcm8jja1dj8db7kssopdki61.apps.googleusercontent.com"
          }
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }) => {
            // localStorage.setItem("userDetails",)
          }}
          onReject={(err) => {
          }}
        >
          <GoogleLoginButton className="custome-google-btn" />
        </LoginSocialGoogle> */}
      </div>
    </>
  );
};

const AfterLogin = ({ loginData, setDropdownVisible }) => {
  const history = useHistory();

  const handleMenuItemClick = (e, url) => {
    e.preventDefault();
    history.push(url);
    setDropdownVisible(false);
  };

  return (
    <>
      <Space
        style={{
          padding: 10,
        }}
      >
        <h3
          onClick={(e) => handleMenuItemClick(e, "/my-profile/")}
          className="para"
          style={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
          }}
          type="primary"
        >
          Hii {loginData?.name}
        </h3>
      </Space>
      <div
        onClick={(e) => handleMenuItemClick(e, "/my-profile")}
        style={{
          cursor: "pointer",
          padding: 10,
          marginTop: -20,
        }}
      >
        <p className="small-para" type="primary">
          {loginData?.mobile}
        </p>
      </div>
    </>
  );
};

const Group = (props) => {
  const history = useHistory();

  // manage profile dropdown item
  const items = [
    {
      key: "/wishlist",
      label: "WishList",
    },
    {
      key: "/my-profile/orders",
      label: "Orders ",
    },
    {
      key: "/my-profile/notifications",
      label: "Notifications"
    },
    {
      key: "/my-profile/address",
      label: "Address ",
    },
    ,
  ];

  const onMenuItemClick = (val) => {
    if (loginData === null) {
      setDropdownVisible(false);
      setOpen(true);
    } else {
      history.push(val.key);
      setDropdownVisible(false);
    }
  };

  const [suggestionList, setSuggestionList] = useState([]);
  const [loginData, setLogindata] = useState(localStorage.getItem("login"));
  const [wallet, setWallet] = useState();
  const [open, setOpen] = useState(false);
  const [searchValue, SetSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSuggestionEmpty = useRef(false);

  const setPinCode = async (pincode) => {
    const { data } = await getBelieveWareHouse(pincode);

    Cookies.set("warehouse_id", data?.data?.warehouse_data[0]?.warehouse?._id);
    Cookies.set("warehouse", data?.data?.warehouse_data[0]?.warehouse?._id);
  };

  const getWallet = async () => {
    const wallet = await getWalletApi();
    if (wallet.data.success) {
      setWallet(wallet.data.data);
      localStorage.setItem("wallet", JSON.stringify(wallet.data.data));
    }
  };

  useEffect(() => {
    getWallet();
  }, [props.state.refreshWalletAmountToggle])

  useEffect(() => {
    setPinCode();
  }, []);


  const getAccountDetail = async (value) => {
    if (value !== null) {
      const { data } = await accountDetailApi(value?._id);
      if (data.message === "You are not authorized") {
        Cookies.remove("warehouse_id");

        localStorage.removeItem("login");
        localStorage.removeItem("wallet");
      } else if (
        data.message === "please login" ||
        data.message === "You are logged out"
      ) {
        Cookies.remove("warehouse_id");
        localStorage.removeItem("login");
        localStorage.removeItem("wallet");
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    if (localStorage.getItem("login") !== null) {
      setLogindata(JSON.parse(localStorage.getItem("login")));
      setWallet(JSON.parse(localStorage.getItem("wallet")));
      getAccountDetail(JSON.parse(localStorage.getItem("login")));
    } else {
      setLogindata(null);
    }
  }, []);

  const params = useLocation();

  useEffect(() => {
    if (params?.state?.state === "login") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (params.pathname === "/search") {
      SetSearchValue(params.search.replace(/^\?/, "").replace(/%20/g, " "));
      handleSearch(params.search.replace(/^\?/, "").replace(/%20/g, " "));
    } else {
      SetSearchValue("");
      // handleSearch("");
    }
  }, [params]);

  const handleLogout = async (e) => {
    setIsModalOpen(false);
    e.preventDefault();
    Cookies.remove("warehouse_id");
    localStorage.removeItem("login");
    localStorage.removeItem("wallet");

    const { data } = await logoutApi();
    if (data.success) {
      notification.success({
        message: "You are logged out!",
        duration: 1,
        placement: "bottom",
      });

      Cookies.remove("warehouse_id");
      localStorage.removeItem("login");
      localStorage.removeItem("wallet");
      setLogindata(null);
      window.location.reload(true);
    } else {
      if (
        data.message == "please login" ||
        data.message === "You are logged out"
      ) {
        Cookies.remove("warehouse_id");
        Cookies.remove("believe_warehouse");
        localStorage.removeItem("login");
        localStorage.removeItem("wallet");
        setLogindata(null);
      }
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const handleSearch = async (value) => {
    SetSearchValue(value);
    if (!value) {
      setSuggestionList([]);
    }
    const { data } = await suggestionApi(value);
    if (data.success) {
      isSuggestionEmpty.current = false;
      return setSuggestionList(
        data.data.map((d, index) => ({
          label: d,
          key: index.toString(),
        }))
      );
    }
  };

  useEffect(() => {
    if (searchValue.length === 0 && !isSuggestionEmpty.current) {
      isSuggestionEmpty.current = true;
      setSuggestionList([]);
    }
  }, [suggestionList]);

  const handleSuggestionClick = (e) => {
    SetSearchValue(e.domEvent.target.textContent);
    navigatToSearch(e.domEvent.target.textContent);
  };

  const navigatToSearch = (value) => {
    history.push({
      pathname: "/search",
      search: `?${value}`,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue.length > 0) {
      navigatToSearch(searchValue);
    } else {
      setSearchToggle(false);
      history.push("/");
    }
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cartCountData, setCartCountData] = useState(0);

  const [searchToggle, setSearchToggle] = useState(false);

 
  const menuStyle = {
    width: "18rem",
    boxShadow: "none",
  };

  const getCartCountData = async () => {
    const resultData = await setCartCount();
    setCartCountData(resultData?.data?.count?.cart);
    // setCartData(resultData?.data?.data);
    // if (!resultData?.data.success) {
    //   if (
    //     resultData?.data?.message === "please login" ||
    //     resultData?.data?.message === "You are logged out"
    //   ) {
    //     history.push(location.pathname + location.search, {
    //       state: "login",
    //     });
    //   }
    //   // notification.error({
    //   //   message: resultData?.data?.message,
    //   // });
    // }
  };

  useEffect(() => {
    getCartCountData();

    let tempPropsState = props?.state;
    props.setState({
      ...tempPropsState,
      isAddedProduct: false,
      isAddedCart: false,
      isAddedWishlist: false,
      isAddedCartWish: false,
      isCodPayment: false,
      isOnlinePayment: false,
      walletCartCount: false,
    });
  }, [
    props?.state?.isAddedProduct,
    props?.state?.isAddedCart,
    props?.state?.isAddedWishlist,
    props?.state?.isAddedCartWish,
    props?.state?.isCodPayment,
    props?.state?.isOnlinePayment,
    props?.state?.walletCartCount,
  ]);

  const contentStyle = {
    marginTop: "1rem",
    backgroundColor: "white",
    borderRadius: "0.3rem",
    boxShadow: "0px 0px 12px 1px #0000001a",
  };

  const openSearchBar = () => {
    setSearchToggle(true);

    // setResponsiveSearchBar(true);

    let tempPropsState = props?.state;
    props.setState({
      ...tempPropsState,
      searchToggle: true,
    });
  };

  const dropDownRef = useRef();

  const handleArrowPress = (e) => {
    if (e.code === "ArrowUp" || e.code === "ArrowDown") {
      e.preventDefault();
      if (dropDownRef.current) dropDownRef.current.focus();
    } else {
      handleSearch(e.target.value);
    }
  };
  const temp = [
    { label: "a", value: "a" },
    { label: "b", value: "b" },
    { label: "c", value: "c" },
  ];

  const suggestionMenu = {
    className: "custom-dropdown",
    items: suggestionList,
    ref: dropDownRef,
    forceSubMenuRender: true,
    onClick: handleSuggestionClick,
    onFocus: (e) => SetSearchValue(e.target.innerText),
  };

  const [ searchFocused, setSearchFocused ] = useState(false);

  useEffect(() => {
  }, [searchFocused])

  return (
    <div
      style={{
        position: "relative",
        // width: 'calc(100% - 92px)',
        display: "flex",
        justifyContent: "end",
      }}
    >
      {searchToggle && (
        <Row
          justify="space-between"
          align="middle"
          style={{
            height: 80,
            width: "100vw",
            padding: "20px 8px",
            position: "absolute",
            top: 0,
            // left: '-168px',
            right: "-24px",
            backgroundColor: "white",
            zIndex: 10000,
          }}
        >
          <Row
            justify="center"
            align="middle"
            style={{
              marginRight: 8,
              width: 28,
              height: 28,
              backgroundColor: "#e2e2e2",
              borderRadius: "50%",
            }}
            onClick={() => setSearchToggle(false)}
          >
            <RxCross2 style={{ fontSize: 16 }} />
          </Row>
          <form
            onSubmit={(e) => handleSubmit(e)}
            style={{ width: "calc( 100% - 36px )" }}
          >
            <Dropdown
              trigger={["click"]}
              open={searchValue.length !== 0}
              menu={suggestionMenu}
              placement={
                suggestionMenu.items.length > 0 ? "bottomLeft" : "none"
              }
            >
              {/* font-size: x-large; */}
              <Frame className="frame">
                <SearchForProductsBrandsAndMore
                  onKeyDown={handleArrowPress}
                  autoComplete="off"
                  value={searchValue}
                  className="search-for-products-brands-and-more"
                  name="searchforproductsbrandsandmore"
                  placeholder="Search Your Style"
                  type="text"
                  // onChange={(e) => handleSearch(e.target.value)}
                />
                <IconSearch
                  className="icon-search"
                  src="/img/vuesax-linear-search-normal.svg"
                  alt="icon-search"
                />
              </Frame>
            </Dropdown>
          </form>
        </Row>
      )}
      <div className="menu-wrapper" style={{ height: 80 }}>
        <form className="header-search" onSubmit={(e) => handleSubmit(e)}>
          <Dropdown
            trigger={[]}
            open={(searchValue.length !== 0) && searchFocused}
            menu={suggestionMenu}
            placement={suggestionMenu.items.length > 0 ? "bottomLeft" : "none"}
          >
            <Frame className="frame">
              <SearchForProductsBrandsAndMore
                onBlur={() => setSearchFocused(false)}
                onFocus={() => setSearchFocused(true)}
                onKeyDown={handleArrowPress}
                autoComplete="off"
                value={searchValue}
                className="search-for-products-brands-and-more"
                name="searchforproductsbrandsandmore"
                placeholder="Search Your Style"
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <IconSearch
                className="icon-search"
                src="/img/vuesax-linear-search-normal.svg"
                alt="icon-search"
              />
            </Frame>
          </Dropdown>
        </form>

        {/* <Dropdown
          menu={suggestionMenu}
          placement={suggestionMenu.items.length > 0 ? "bottomLeft" : "none"}
        >
<input className="search-bar" type="text" placeholder="Search" />

</Dropdown> */}

        <ul className="nav-menu-item">
          <li className="v-on-m" style={{ marginRight: 14 }}>
            <img
              src="/img/vuesax-linear-search-normal.svg"
              alt=""
              onClick={openSearchBar}
            />
          </li>
          {/* <div onSubmit={(e) => handleSubmit(e)}>
  <Dropdown
  menu={suggestionMenu}
  placement={suggestionMenu.items.length > 0 ? "bottomLeft" : "none"}
>
  <div>
<input 
 autoComplete="off"
 value={searchValue}
 className="search-for-products-brands-and-more"
 name="searchforproductsbrandsandmore"
 placeholder="Search for products brands, and more"
 type="text"
 onChange={(e) => handleSearch(e.target.value)}
/>

</div>
</Dropdown>
</div> */}
          <li>
            {wallet && (
              <div
                style={{ textDecoration: "none", display: "flex" }}
                to="/my-profile/wallet"
              >
                {" "}
                <Vuesaxlinearwallet3
                  src="/img/vuesax-linear-wallet-3.svg"
                  alt="vuesax/linear/wallet-3"
                />
                <Text1
                  style={{
                    position: "relative",
                    marginLeft: "-19px",
                    marginRight: "52px",
                  }}
                  className="text-1"
                >
                  ₹{wallet?.amount?.toFixed(2)}
                </Text1>
                <Text1 style={{ position: "relative" }} className="text-1">
                  {"  "}
                </Text1>
              </div>
            )}
          </li>
          {/* <li className="login-text">
          <LoginText
            onClick={() => setOpen(true)}
            className="login"
            style={{ cursor: "pointer" }}
          >
            Login
          </LoginText>
         
        </li> */}
          {/* trigger={["click"]} */}
          <Login setIsOpen={setOpen} isOpen={open} />{" "}
          <>
            <Dropdown
              open={dropdownVisible}
              trigger={["click"]}
              onOpenChange={(visible) => setDropdownVisible(visible)}
              dropdownRender={(menu) => (
                <div style={contentStyle}>
                  {(loginData === null && (
                    <BeforeLogin
                      setOpen={setOpen}
                      setDropdownVisible={setDropdownVisible}
                    />
                  )) || (
                    <AfterLogin
                      loginData={loginData}
                      setDropdownVisible={setDropdownVisible}
                    />
                  )}

                  <Divider
                    style={{
                      margin: 0,
                    }}
                  />
                  {React.cloneElement(menu, {
                    style: menuStyle,
                  })}

                  {loginData === null && (
                    <div
                      style={{
                        padding: 7,
                      }}
                    ></div>
                  )}
                  <Divider
                    style={{
                      margin: 0,
                    }}
                  />
                  {loginData !== null && (
                    <p
                      style={{
                        padding: 17,

                        paddingBottom: 5,
                      }}
                    >
                      {/* setDropdownVisible(false); */}

                      <div
                        onClick={() => {
                          history.push("/my-profile/?isEdit"),
                            setDropdownVisible(false);
                        }}
                        style={{ cursor: "pointer" }}
                        type="primary"
                      >
                        Edit Profile
                      </div>
                    </p>
                  )}
                  {loginData !== null && (
                    <Space
                      style={{
                        padding: 17,
                        paddingTop: 0,
                      }}
                    >
                      <div
                        // onClick={() => handleLogout()}
                        onClick={() => {
                          showModal(), setDropdownVisible(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Logout
                      </div>
                    </Space>
                  )}
                </div>
              )}
              className="custome-width"
              menu={{
                items,
                onClick: onMenuItemClick,
              }}
              placement="bottom"
            >
              <div
                style={{ position: "relative" }}
                onClick={(e) => {
                  e.preventDefault(), setDropdownVisible(!dropdownVisible);
                }}
              >
                <Space className="custom-gap">
                  &nbsp;
                  <IconHeart
                    style={{ marginBottom: "-3px" }}
                    className="vuesaxlinearprofile-circle hover-logo"
                    src="/img/vuesax-linear-profile-circle.svg"
                    alt="vuesax/linear/profile-circle"
                  />
                  {/* {(loginData?.name && loginData?.name) || "User"} */}
                  {/* <DownOutlined style={{ marginTop: "8px" }} /> */}
                </Space>
              </div>
            </Dropdown>

            {/* <LoginText
              style={{
                marginLeft: "-16px",
                alignSelf: "center",
                width: "4rem",
              }}
              onClick={handleLogout}
              className="login"
            >
              Log out
            </LoginText> */}
          </>
          <li className="wishlist-nav hover-logo">
            <Link to="/wishlist">
              <IconHeart
                className="icon-heart"
                src="/img/vuesax-linear-heart.svg"
                alt="icon-heart"
              />
            </Link>
          </li>
          <li
            className="hover-logo"
            style={{
              marginLeft: 8,
              marginTop: "-2px",
            }}
          >
            {/* <Link to="/cart">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="black"
              className="bi bi-cart-check"
              viewBox="0 0 16 16"
            >
              <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </Link> */}

            <Link to="/cart">
              <div style={{ position: "relative", display: "inline-block" }}>
                <AiOutlineShoppingCart size={20} color="black" />
                {cartCountData > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px", // Adjust this value to position the count label as desired
                      right: "-10px", // Adjust this value to position the count label as desired
                      backgroundColor: "rgb(244, 121, 44)", // Customize the background color of the count label
                      color: "white", // Customize the text color of the count label
                      borderRadius: "50%",
                      width: "20px",
                      fontWeight: "bolder",
                      height: "20px",
                      textAlign: "center",
                      fontSize: "15px",
                      lineHeight: "20px",
                    }}
                  >
                    {cartCountData}
                  </div>
                )}
              </div>
            </Link>
          </li>
          <Modal
            title="Logout Confirmation"
            open={isModalOpen}
            centered
            onCancel={handleCancel}
            footer={null}
          >
            Are you sure to want to logout?.
            <div style={{ display: "flex", "justify-content": "end" }}>
              <div style={{ display: "flex", "justify-content": "end" }}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#f4792c" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  style={{ marginLeft: "17px" }}
                  onClick={handleLogout}
                >
                  Ok
                </Button>
              </div>
              {/* 
<Button onClick={handleCancel} style={{backgroundColor:"#f4792c","margin-right": "20px",color:"white"}}>Cancel</Button>
          
<Button onClick={handleLogout} type="primary">Ok</Button> */}
            </div>
          </Modal>
          {/* checking value of login data if login value is true than don't show login text */}
          {/* <li onClick={showDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </li> */}
        </ul>
      </div>
    </div>
  );
};

const Group1 = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
  min-width: 700px;

  &.group.group-2 {
    margin-top: 1px;
  }
`;

const Frame = styled.div`
  height: 48px;
  display: flex;
  padding: 0 16px;
  justify-content: flex-end;
  align-items: center;
  // min-width: 431px;
  width: 342px;
  // gap: 137px;
  background-color: var(--seashell);
  border-radius: 4px;
`;

const SearchForProductsBrandsAndMore = styled.input`
  ${LatoNormalBlack14px}
  width: 100%;
  max-width: 100%;
  height: 20px;
  margin-bottom: 2px;
  background-color: transparent;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  border: 0;
  padding: 0;
  font-size: 16px;

  &::placeholder {
    color: #373736;
    font-size: 16px;
    // font-weight: bolder;
  }
`;

const IconSearch = styled.img`
  width: 24px;
  height: 24px;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  position: relative;
  margin-left: 27px;
  margin-bottom: 1px;
  width: fit-content;
  align-items: flex-end;
  gap: 22px;
`;

const Group11 = styled.div`
  position: relative;
  height: 24px;
`;

const Vuesaxlinearwallet3 = styled.img``;

const Text1 = styled.div`
  ${ValignTextMiddle}
  ${AzosansMediumSizzlingRed14px}
            position: absolute;
  height: 10px;
  top: 8px;
  left: 26px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const IconHeart = styled.img`
  position: relative;
  min-width: 24px;
  height: 24px;
`;

const LoginText = styled.div``;

export default Group;
