import React, { useState } from "react";
import { Link } from "react-router-dom";
import AutoLayoutHorizontal from "../AutoLayoutHorizontal";
import AutoLayoutVertical from "../AutoLayoutVertical";
import styled from "styled-components";
import {
  InterSemiBoldSapphire14px,
  InterNormalBunker14px,
  InterNormalBunker11px,
  InterSemiBoldSapphire16px,
  InterSemiBoldSapphire15px,
  ValignTextMiddle,
} from "../../styledMixins";
import { InterNormalWhite12px, ValignTextMiddle } from "../../styledMixins";
import { api } from "../../api/baseurl";
import { notification, Popconfirm, Rate, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import Confirm from "../Confirm";
import axios from "axios";
import { addToCartApi, deleteWishlistApi } from "../../api/wishlist";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WhishCard = (props) => {
  const [cartAdding, setCartAdding] = useState(false);
  const { className, src, item, fetchData, propsData } = props;
  const [imageVisible, setImageVisible] = useState(true);

  const confirmDelete = async (id) => {
    const { data } = await deleteWishlistApi(id);
    if (data.success) {
      fetchData(id);
      // notification.success({
      //   message: data.message,
      // });
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement:"bottom"
      });
    }


    //
  };
  const handleAddWishlist = async (item) => {
    setCartAdding(true);
    const { data } = await addToCartApi(item._id);
    setCartAdding(false);
    if (data.success) {
      let tempPropsState= propsData?.state

      confirmDelete(item?._id);
      notification.success({ message: data.message,
        duration: 1,
        placement:"bottom"
      });
      propsData.setState({
        ...tempPropsState,
        isAddedWishlist: true,
      })
    } else {
      notification.error({ message: data.message,
        duration: 1,
        placement:"bottom"
      });
    }

  };

  const handleImageError = () => {
    setImageVisible(false);
  };

  const history = useHistory();

  return (
    <Product1 className={`product ${className || ""}`} style={{ width: '100%', maxWidth: '360px', margin: '0 0 12px 0' }}>
      <div className="wishlis-bg-containe auto-layout-horizontal-11">
        <div
          style={{
            position: "relative",
            backgroundColor: "#eeeee",
          }}
        >
          {/* <div onMouseOver={changeColor}> */}

          {/* <img style={{ width: "100%" }} src={src} alt="" /> */}

          {/* <AutoLayoutHorizontal
              src={src ? src : "/img/no_image.png"}
              className={"auto-layout-horizontal-13"}
            /> */}
          <Link to={`/singleShop?${item && item._id}`}>
            <div
              style={{
                overflow: "hidden",
                cursor: "pointer",
                minHeight: "12rem",
              }}
              className="image-container "
            >
              {(imageVisible && (
                <img
                  // style={{ objectFit: "contain" }}
                  style={{ objectFit: "contain", height: src ? "auto" : "21.7rem" }}
                  className={`product-image-a imageContainerBg ${
                    src ? "" : "no-image-placeholder"
                  }`}
                  src={src ? src : "/img/no_image.png "}
                  alt=""
                  onError={handleImageError}
                />
              )) || (
                <img
                  style={{ objectFit: "cover" }}
                  className="product-image-a no-image-placeholder imageContainerBg"
                  src={"/img/no_image.png"}
                  alt=""
                  onError={handleImageError}
                />
              )}
            </div>
          </Link>
          {/* </div> */}
          <div className="remove-card-buttons">
            <div className="remove-card-nested-div" style={{ display: "flex" }}>
              {(item?.cart_quantity !== 0 && (
                <>
                  {(item?.is_active === true && (
                    <Spin className="spin-class-custom" spinning={cartAdding}>
                      <button
                        style={{ borderRadius: "4px" }}
                        onClick={() => handleAddWishlist(item)}
                        className="add-to-wishlist btn btn-primary"
                      >
                        Add to cart{" "}
                        {/* <svg
                  style={{ marginBottom: "-5px", marginLeft: "3px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-heart"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                </svg> */}
                      </button>
                    </Spin>
                  )) || (
                    <button
                      style={{ borderRadius: "4px" }}
                      onClick={() => history.push("/shop")}
                      className="add-to-wishlist btn btn-primary"
                    >
                      Show Similar
                      {/* <svg
                  style={{ marginBottom: "-5px", marginLeft: "3px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-heart"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                </svg> */}
                    </button>
                  )}
                </>
              )) || (
                <Spin spinning={cartAdding}>
                  <button
                    style={{ borderRadius: "4px", color: "#fff" }}
                    onClick={() => handleAddWishlist(item)}
                    className="add-to-wishlist bg-primary btn btn-primary"
                  >
                    Added{" "}
                    {/* <svg
                  style={{ marginBottom: "-5px", marginLeft: "3px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-heart"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                </svg> */}
                  </button>
                </Spin>
              )}

              <Popconfirm
                placement="left"
                title="Remove From Wishlist"
                description="Are you sure to Remove From Wishlist?"
                onConfirm={() => confirmDelete(item?._id)}
                icon={false
                  // <QuestionCircleOutlined
                  //   style={{
                  //     color: "red",
                  //   }}
                  // />
                }
              >
                <button className="delete-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="currentColor"
                    className="bi bi-trash3-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                  </svg>
                </button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>

      <Frame className="frame-5">
        <div style={{ paddingTop: "1.3rem" }} className="">
          <Link to={`/singleShop?${item && item._id}`}>
            <SuperLSLinenTee
              style={{ fontWeight: "bold" }}
              className="super-ls-linen-tee"
            >
              {item?.name}
            </SuperLSLinenTee>
          </Link>
          {/* <p
            style={{ marginTop: "-3px", fontWeight: "400" }}
            className="small-para"
          >
            {item?.description}
          </p> */}
          <Frame36950>
            <Rate
              style={{
                fontSize: 12,
              }}
              allowHalf
              defaultValue={item?.rating}
            />
            {/* <Name1 style={{ margin: "0" }}>&nbsp; {item?.rating} Ratings</Name1> */}
          </Frame36950>
          <AutoLayoutHorizontal1 className="auto-layout-horizontal-8">
            {item?.warehouses && (
              <div>
                {(item?.warehouses[0]?.MRP === item?.warehouses[0]?.ASP && (
                  <AutoLayoutHorizontal2 className="auto-layout-horizontal-9">
                    <Price className="price-4">
                      ₹ {item?.warehouses && item?.warehouses[0]?.ASP}
                    </Price>
                  </AutoLayoutHorizontal2>
                )) || (
                  <div style={{ display: "flex" }}>
                    <AutoLayoutHorizontal2 className="auto-layout-horizontal-9">
                      {/* <Price
                        style={{
                          color: "#8f8f8f",

                          fontWeight: "400",
                          marginRight: "6px",
                        }}
                        className="price-4"
                      >
                        MRP:
                      </Price> */}
                      <Price
                        style={{
                          color: "#8f8f8f",
                          textDecoration: "line-through",
                          fontWeight: "400",
                        }}
                        className="price-4"
                      >
                        ₹ {item?.warehouses && item?.warehouses[0]?.MRP}
                      </Price>
                    </AutoLayoutHorizontal2>
                    {/* <Text3 className="text-3-1">
                      &nbsp; &nbsp; – &nbsp;&nbsp;
                    </Text3> */}
                    <AutoLayoutHorizontal3 className="auto-layout-horizontal-10">
                      <Price1 className="price-5">
                        &nbsp; ₹ {item?.warehouses && item?.warehouses[0]?.ASP}
                      </Price1>
                    </AutoLayoutHorizontal3>

                    {/* {item?.warehouses &&
                      item?.warehouses[0]?.discount_per == 0 && (
                        <AutoLayoutHorizontal3 className="auto-layout-horizontal-10">
                          <Price1 className="price-5 color-primary">
                            &nbsp; (
                            {item?.warehouses &&
                              item?.warehouses[0]?.discount_per}
                            % OFF)
                          </Price1>
                        </AutoLayoutHorizontal3>
                      )} */}
                    {item?.warehouses && (


                      <AutoLayoutHorizontal3 className="auto-layout-horizontal-10">
                {!item?.warehouses[0]?.discount_per == 0 ?  
                <Price1 className="price-5 color-primary">
                &nbsp; (
                { item?.warehouses[0]?.discount_per}% OFF)
              </Price1>
              :
              null
              }
                        
                      </AutoLayoutHorizontal3>
                    )}
                  </div>
                )}
              </div>
            )}
          </AutoLayoutHorizontal1>

          {/* <Frame1 className="frame-6">
            <Frame2 className="frame-7" src="/img/frame-12.svg" alt="Frame" />
            <Number className="number-1">1</Number>
          </Frame1> */}
        </div>
      </Frame>

      <div style={{ position: "absolute" }}>
        <br />
        {/* {item?.warehouses[0]?.discount_per !== 0 && (
          <div className="percentage">
            <h4>- {item?.warehouses[0]?.discount_per} % </h4>
          </div>
        )} */}

        {/* <div className="feature">
          <h4>Featured</h4>
        </div> */}
      </div>

      {/* <AutoLayoutVertical item={item?.warehouses} /> */}
      {/* {item?.warehouses[0]?.discount_per} */}
    </Product1>
  );
};

const Product1 = styled.article`
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 34%);
  padding: 6px;
  padding-bottom: 16px;
  align-items: flex-start;

  position: relative;
  width: 15rem;
  &.product.product-3 {
    cursor: pointer;
  }
`;

const Frame36950 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;
const Frame = styled.div`
  position: relative;
  margin-bottom: -0.33px;
`;

const FlexCol = styled.div`
  position: relative;
  width: 320px;
  top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 78px;
  gap: 8px;
`;

const AutoLayoutVertical1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const Text4 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const SuperLSLinenTee = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            height: 24px;
  letter-spacing: 0.1px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 1rem;
`;

const AutoLayoutHorizontal1 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  gap: 7.8300018310546875px;
`;
//  padding: 0px 174.22999572753906px 0px 0px;

const AutoLayoutHorizontal2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  position: relative;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  ${InterSemiBoldSapphire15px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Text3 = styled.div`
  ${ValignTextMiddle}
  ${InterSemiBoldSapphire16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  ${InterSemiBoldSapphire14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0.1px;
  line-height: 24px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 0px 0.8300018310546875px 0px 0px;
  position: relative;
`;

const Frame1 = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  gap: 4px;
`;

const Frame2 = styled.img`
  width: 71px;
  height: 14px;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker11px}
            height: 11px;
  margin-top: -0.5px;
  letter-spacing: 0.05px;
  line-height: 11px;
  white-space: nowrap;
`;

const SuperLSLinenTee1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalBunker14px}
            
            
           .product.product-2  & {
    cursor: unset;
  }
`;

const AutoLayoutVertical2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const Text35 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
  white-space: nowrap;
`;

const AutoLayoutVertical3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: 64.97px;
`;

const AutoLayoutHorizontal4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.90999984741211px 4px 11px;
  position: relative;
  background-color: var(--tall-poppy);
`;

const Text49 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWhite12px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0.08px;
  line-height: 16px;
  white-space: nowrap;
`;

const AutoLayoutHorizontal5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  padding: 4px 10.949996948242188px 4px 12px;
  position: relative;
  background-color: var(--pomegranate);
`;

export default WhishCard;
