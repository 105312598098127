import React, { useEffect, useRef, useState } from "react";
// import Header4 from "../Header4";
import Header4 from "./Header4";

import Spanbox from "./Spanbox";
// import Footer from "../Footer";
import Footer from "./Footer";

import Coupon from "./Coupon";
import styled from "styled-components";
import { Alert, Button, Row, Space, Spin } from "antd";
import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
} from "../styledMixins";
import AddNewAddress from "./AddNewAddress";
import {
  AddAddressCartApi,
  addOldAddressCartApi,
  checkServiceablePinCode,
  getAddressApi,
  updateAddressApi,
} from "../api/Address";

import { Badge, Card, Modal } from "antd";
import Login from "./Login";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  addItemsToWishlistFromCart,
  addItemToCartApi,
  deleteItemFromCartApi,
  getCartApi,
} from "../api/Cart";
import { MdDelete, MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { notification } from "antd";
// import Button from "@mui/material/Button";
import { notification } from "antd";
import CartOrder from "./CartOrderComponent";
import { BiPlus } from "react-icons/bi";
import { BsHandbagFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useLocale } from "antd/es/locale";
import { getBelieveWareHouse } from "../api/login";
import { dev } from "../api/baseurl";
import { getItemVariationApi } from "../api/collections";
import { addToCartApi } from "../api/wishlist";
import cartImage from "../images/cartImage.jpg";
import { PlusOutlined } from "@ant-design/icons";
import noimage from "../images/noimage.jpg";
import { BiMinus } from "react-icons/bi";
import { Autocomplete } from "@mui/material";

// import cartImage from "images/"

const Cart = (props) => {
  const [open, setOpen] = useState(false);
  const [checkOut1, setCheckOut1] = useState(false);
  const [addNewAddressPopUp, setAddNewAddressPopUp] = useState(false);
  const [addressApiData, setAddressApiData] = useState();
  const [login, setLogin] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState(true);
  const [editAddress1, setEditAddress1] = useState(true);
  const [editData, setEditData] = useState();
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [addAddressResponse, setAddAddressResponse] = useState();
  const [cartData, setCartData] = useState(null);
  const [sizeModal, setSizeModal] = useState(false);
  const [quantityPopUp, setQuantityPopup] = useState(false);
  const [deletePopUpState, setDeletePopUpState] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [quantityData, setQuantityData] = useState();
  const [shippingCheck, setShippingCheck] = useState();
  const [sizeData, setSizeData] = useState();
  const [loading, setLoading] = useState(false);
  const [cartDataLength, setCartDataLength] = useState();
  const history = useHistory();
  const [paymentMode, setPaymentMode] = useState();
  const [individualSizeData, setIndividualSizeData] = useState();
  const [quantityValue, setQuantityValue] = useState();
  const [bgColor, setBgColor] = useState();
  const [stockCondition, setStockCondition] = useState(false);
  const [deleteProductPopup, setDeleteProductPopUp] = useState(false);
  const [expectedDelDate, setExpectedDelDate] = useState();
  const [codCharges, setCodCharges] = useState(false);
  const [sizeValue, setSizeValue] = useState();
  const [errorImage, setErrorImage] = useState(true);
  const [showQuantity, setShowQauntity] = useState(1);

  const [selectedPayment, setSelectedPayment] = useState("");
  const [amountToPay, setAmountToPay] = useState({
    online: 0,
    cod: 0,
  });

  useEffect(() => {
    if (cartData) {
      if (cartData?.amount?.payable >= 999) {
        setAmountToPay({
          online: cartData?.amount?.payable,
          cod: cartData?.amount?.payable + cartData?.amount?.cod_charge,
        });
      } else {
        setAmountToPay({
          online: cartData?.amount?.payable + cartData?.amount?.delivery_charge,
          cod:
            cartData?.amount?.payable +
            cartData?.amount?.delivery_charge +
            cartData?.amount?.cod_charge,
        });
      }
    }
  }, [cartData]);

  const [addressData, setAddressData] = useState({
    name: "",
    house_no: "",
    society: "",
    block: "",
    city: "",
    state: "",
    pin_code: "",
    address_type: "",
    is_default: "",
  });

  const [addNewAddressData, setAddNewAddressData] = useState({
    name: "",
    house_no: "",
    society: "",
    block: "",
    city: "",
    state: "",
    pin_code: "",
    address_type: "",
    is_default: false,
  });

  const getAddressFunc = async () => {
    const resultData = await getAddressApi();
    setAddressApiData(resultData?.data);
  };

  const location = useLocation();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    getAddressFunc();
    window.scrollTo(0, 0);
  }, []);

  let defaultAddress = [];
  addressApiData?.data?.map((value, index) => {
    if (value?.is_default === true) {
      defaultAddress.push(value);
    }
  });

  const localData = JSON.parse(localStorage.getItem("login"));

  // Delete Product Condition
  useEffect(() => {
    for (let i = 0; i < cartData?.items?.length; i++) {
      if (!cartData?.items[i].in_stock) {
        setStockCondition(true);
        return;
      }
    }
    if (cartData) {
      const objLength = Object.keys(cartData?.address)?.length;
      setCartDataLength(objLength);
    }
    getCurrentDate();
  }, [cartData]);

  const checkOut = () => {
    if (localData === null) {
      setLogin(true);
    } else if (stockCondition) {
      setDeleteProductPopUp(true);
    } else if (Object.keys(cartData?.address).length === 0) {
      notification.error({
        message: "Please Select/Add Address.",
        duration: 1,
        placement: "bottom",
      });
    } else {
      setCheckOut1(true);
    }
  };

  const numbers = [];

  if (quantityData?.warehouses[0]?.maximum_order_quantity > 5) {
    for (let i = 1; i <= 5; i++) {
      numbers.push(i);
    }
  } else {
    for (
      let i = 1;
      i <= quantityData?.warehouses[0]?.maximum_order_quantity;
      i++
    ) {
      numbers.push(i);
    }
  }

  const addNewAddress = () => {
    setAddNewAddressPopUp(true);
  };

  const cancelAdd = (xyz) => {
    setAddNewAddressPopUp(xyz);
  };

  const changeAddress = () => {
    setModalOpen(true);
    setEditAddress(true);
    setEditAddress1(true);
    setAddAddressPopup(false);
  };

  const editAddressFunc = (value) => {
    setEditAddress(false);
    setEditAddress1(false);
    setEditData(value);
  };

  useEffect(() => {
    setAddressData({
      ...addressData,
      name: editData?.name,
      house_no: editData?.house_no,
      society: editData?.society,
      block: editData?.block,
      city: editData?.city,
      state: editData?.state,
      pin_code: editData?.pincode,
      address_type: editData?.address_type,
      id: editData?._id,
      is_default: editData?.is_default,
    });
  }, [editData]);

  const updateData = async () => {
    const findingLengthName = addressData?.name?.length;
    const findingLengthHouse = addressData?.house_no?.length;
    const findingLengthSociety = addressData?.society?.length;
    const findingLengthBlock = addressData?.block?.length;
    const findingLengthCity = addressData?.city?.length;
    const findingLengthState = addressData?.state?.length;
    const findingLengthPinCode = addressData?.pin_code?.length;
    const findingLengthAddressType = addressData?.address_type?.length;

    if (findingLengthName == 0 || addressData?.name == undefined) {
      notification.error({
        message: "Please provide name.",
        duration: 1,
        placement: "bottom",
      });
    } else if (findingLengthHouse == 0 || addressData?.house_no == undefined) {
      notification.error({
        message: "Please provide house.",
        duration: 1,
        placement: "bottom",
      });
    } else if (findingLengthSociety == 0 || addressData?.society == undefined) {
      notification.error({
        message: "Please provide society.",
        duration: 1,
        placement: "bottom",
      });
    } else if (findingLengthBlock == 0 || addressData?.block == undefined) {
      notification.error({
        message: "Please provide block.",
        duration: 1,
        placement: "bottom",
      });
    } else if (findingLengthCity == 0 || addressData?.city == undefined) {
      notification.error({
        message: "Please provide city.",
        duration: 1,
        placement: "bottom",
      });
    } else if (findingLengthState == 0 || addressData?.state == undefined) {
      notification.error({
        message: "Please provide state.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthPinCode == 0 ||
      addressData?.pin_code == undefined
    ) {
      notification.error({
        message: "Please provide pincode.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthAddressType == 0 ||
      addressData?.address_type == undefined
    ) {
      notification.error({
        message: "Please provide Address Type.",
        duration: 1,
        placement: "bottom",
      });
    } else {
      const resultData = await updateAddressApi(addressData);
      if (resultData?.data?.success) {
        notification.success({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });

        getAddressFunc();
        setEditAddress(true);
        setModalOpen(true);
        getCartData();
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
      }
    }
  };

  const addNewAddressFunc = () => {
    setAddAddressPopup(true);
    setEditAddress1(true);
    setEditAddress(false);
  };

  const addNewAddressFirstUser = () => {
    setModalOpen(true);
    setAddAddressPopup(true);
  };

  const cancellUpdate = () => {
    setEditAddress(true);
    setEditAddress1(true);
    setAddAddressPopup(false);
  };

  const addNewAddressFunc12 = async () => {
    const findingLengthName = addNewAddressData?.name?.length;
    const findingLengthHouse = addNewAddressData?.house_no?.length;
    const findingLengthSociety = addNewAddressData?.society?.length;
    const findingLengthBlock = addNewAddressData?.block?.length;
    const findingLengthCity = addNewAddressData?.city?.length;
    const findingLengthState = addNewAddressData?.state?.length;
    const findingLengthPinCode = addNewAddressData?.pin_code?.length;
    const findingLengthAddressType = addNewAddressData?.address_type?.length;

    if (findingLengthName == 0 || addNewAddressData?.name == undefined) {
      notification.error({
        message: "Please provide name.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthHouse == 0 ||
      addNewAddressData?.house_no == undefined
    ) {
      notification.error({
        message: "Please provide house.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthSociety == 0 ||
      addNewAddressData?.society == undefined
    ) {
      notification.error({
        message: "Please provide society.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthBlock == 0 ||
      addNewAddressData?.block == undefined
    ) {
      notification.error({
        message: "Please provide block.",
        duration: 1,
        placement: "bottom",
      });
    } else if (findingLengthCity == 0 || addNewAddressData?.city == undefined) {
      notification.error({
        message: "Please provide city.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthState == 0 ||
      addNewAddressData?.state == undefined
    ) {
      notification.error({
        message: "Please provide state.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthPinCode == 0 ||
      addNewAddressData?.pin_code == undefined
    ) {
      notification.error({
        message: "Please provide pincode.",
        duration: 1,
        placement: "bottom",
      });
    } else if (
      findingLengthAddressType == 0 ||
      addNewAddressData?.address_type == undefined
    ) {
      notification.error({
        message: "Please provide Address Type.",
        duration: 1,
        placement: "bottom",
      });
    } else {
      const resultData = await AddAddressCartApi(addNewAddressData);
      setAddAddressResponse(resultData?.data);
      if (resultData?.data?.success) {
        getPaymentDetail(resultData.data?.data?.pincode);
        notification.success({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
        getAddressFunc();
        getCartData();
        setAddAddressPopup(false);
        setEditAddress(true);
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
      }
    }
  };

  // Cart Apis
  const getCartData = async () => {
    setLoading(true);
    const resultData = await getCartApi();
    setLoading(false);
    setCartData(resultData?.data?.data);
    if (!resultData?.data.success) {
      if (
        resultData?.data?.message === "please login" ||
        resultData?.data?.message === "You are logged out"
      ) {
        history.push(location.pathname + location.search, {
          state: "login",
        });
      }
    }
  };

  // const handleKeyDown = (event) => {
  //   // Check if the pressed key is Arrow Up (key code 38)
  //   if (event.keyCode === 38 || event.keyCode === 40) {
  //     event.preventDefault(); // Prevent the default behavior of arrow up key
  //   }
  // };

  useEffect(() => {
    getCartData();

    let tempPropsState = props?.state;
    props.setState({
      ...tempPropsState,
      isAddedCartWish: false,
    });

    props.setState({
      ...tempPropsState,
      isAddedCart: false,
    });
  }, []);

  const sizePopUp = async (value) => {
    setIndividualSizeData(value);
    setSizeModal(true);
    const classOfProduct = value?.class_of_product;
    const resultData = await getItemVariationApi(classOfProduct);
    setSizeData(resultData?.data);
  };

  const tempAddress1 = {};
  const tempAddress2 = {
    hno: 12,
  };

  const deleteItemButton = async () => {
    const data = deleteData?._id;
    const resultData = await deleteItemFromCartApi(data);
    if (resultData?.data?.success) {
      let tempPropsState = props?.state;

      props.setState({
        ...tempPropsState,
        isAddedCart: true,
      });

      getCartData();
      setDeletePopUpState(false);
      setStockCondition(false);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  if (cartData?.address.hasOwnProperty("address_type")) {
  }

  const selectSize = async () => {
    const data = {
      id: quantityData?._id,
      quantity: quantityValue,
    };
    const resultData = await addItemToCartApi(data);
    if (resultData?.data?.success) {
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
      getCartData();
      setQuantityPopup(false);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const selectDefaultAddres = async (value) => {
    const data = {
      new_address: false,
      address_id: value?._id,
    };

    const resultData = await addOldAddressCartApi(data);

    if (resultData?.data?.success) {
      getPaymentDetail(resultData?.data?.data?.pincode);
      notification.success({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
      getAddressFunc();
      getCartData();
      setModalOpen(false);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const getPaymentDetail = async (pincode) => {
    const { data } = await dev.post("/address/believe/set", {
      pincode: pincode,
    });

    if (data.success) {
      setPaymentMode(data?.data?.warehouse_data[0]?.payment_methods[0]);
    }
  };

  useEffect(() => {
    addressApiData?.data?.map((value, index) => {
      if (value?._id == cartData?.address?._id) {
        getPaymentDetail(value?.pincode);
        setShippingCheck(true);
      }
    });
  }, [addressApiData, cartData]);

  const addNewAddressNewData = () => {
    setModalOpen(true);
    setAddAddressPopup(true);
  };

  const newSizeBoxModal = async (value) => {
    const resultData = await deleteItemFromCartApi(individualSizeData?._id);
    setSizeModal(false);
    const { data } = await addToCartApi(sizeValue?._id);
    if (data?.success) {
      getCartData();
    }
  };

  const changeColor = (value) => {
    let purple = "#f4792c";
    let white = "#ffffff";
    setBgColor({ number: value, color: purple, colorW: white });
  };

  const checkPinCode = async (e) => {
    if (e.target.value?.length == 6) {
      const resultData = await checkServiceablePinCode(e.target.value);
      if (resultData?.data?.success) {
        notification.success({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
      }
    }
  };

  const moveToWishlist = async () => {
    const data = deleteData?._id;
    const resultData = await addItemsToWishlistFromCart(data);
    const deleteResultData = await deleteItemFromCartApi(data);
    if (resultData?.data?.success) {
      let tempPropsState = props?.state;
      props.setState({
        ...tempPropsState,
        isAddedCartWish: true,
      });
      getCartData();
      setDeletePopUpState(false);
    } else {
      notification.error({
        message: resultData?.data?.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const getCurrentDate = async () => {
    const resultData = await checkServiceablePinCode(
      cartData?.address?.pincode
    );
    if (resultData?.data?.success) {
      let newArr = [];
      let text = resultData?.data?.tat;
      let numberOfDays = text.match(/\d+/g);

      const current = new Date();
      current.setDate(current.getDate() + Number(numberOfDays[1]));
      const newdateData = current.toDateString();
      setExpectedDelDate(newdateData);
    } else {
      setExpectedDelDate(resultData?.data?.message);
    }
  };

  const handleImageError = (e) => {
    setErrorImage(false);
  };

  console.log(deleteData);

  const decreaseItemQuant = async (value) => {
    console.log(value);

    if (value?.quantity > 1) {
      const newQuantity = value?.quantity - 1;
      const data = {
        id: value?._id,
        quantity: newQuantity,
      };
      const resultData = await addItemToCartApi(data);
      if (resultData?.data?.success) {
        // notification.success({
        //   message: resultData?.data?.message,
        //   duration: 1,
        //   placement: "bottom",
        // });
        getCartData();
        setQuantityPopup(false);
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
      }
    } else {
      setDeletePopUpState(true);
    }
    setShowQauntity(showQuantity - 1);
  };

  const increseItemQuant = async (value) => {
    setShowQauntity(showQuantity + 1);

    if (value?.quantity < value?.warehouses[0]?.available_stock) {
      const newQuantity = value?.quantity + 1;
      const data = {
        id: value?._id,
        quantity: newQuantity,
      };
      const resultData = await addItemToCartApi(data);
      if (resultData?.data?.success) {
        // notification.success({
        //   message: resultData?.data?.message,
        //   duration: 1,
        //   placement: "bottom",
        // });
        getCartData();
        setQuantityPopup(false);
      } else {
        notification.error({
          message: resultData?.data?.message,
          duration: 1,
          placement: "bottom",
        });
      }
    } else {
      notification.error({
        message: "You have reached maximum order quantity",
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const states = [
    "ANDAMAN & NICOBAR ISLANDS",
    "ANDHRA PRADESH",
    "ANDHRA PRADESH(BEFORE DIVISION)",
    "ARUNACHAL PRADESH",
    "ASSAM",
    "BIHAR",
    "CHANDIGARH",
    "CHHATTISGARH",
    "DADRA & NAGAR HAVELI & DAMAN & DIU",
    "DELHI",
    "GOA",
    "GUJARAT",
    "HARYANA",
    "HIMACHAL PRADESH",
    "JAMMU & KASHMIR",
    "JHARKHAND",
    "KARNATAKA",
    "KERALA",
    "LADAKH",
    "LAKSHDWEEP",
    "MADHYA PRADESH",
    "MAHARASHTRA",
    "MANIPUR",
    "MEGHALAYA",
    "MIZORAM",
    "NAGALAND",
    "ODISHA",
    "PUDUCHERRY",
    "PUNJAB",
    "RAJASTHAN",
    "SIKKIM",
    "TAMIL NADU",
    "TELANGANA",
    "TRIPURA",
    "UTTAR PRADESH",
    "UTTARAKHAND",
    "WEST BENGAL",
  ];

  return (
    <>
      {!addNewAddressPopUp ? (
        <div className="custom-container cart-custom-style spacer">
          <div>
            <div className="hr-box">
              {addressApiData?.data?.length == 0 &&
              cartData?.items?.length > 0 ? (
                <div onClick={addNewAddressNewData}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginTop: "3%",
                    }}
                  >
                    {/* <Card
                      bordered={false}
                      style={{ border: "1px solid lightgrey" }}
                    > */}
                    {/* <div style={{ color: "blue" }}>
                        <BiPlus />
                        <span style={{ marginLeft: "5px" }}>
                          Add New Address11{" "}
                        </span>
                      </div> */}
                    <Button
                      type="pink"
                      icon={<PlusOutlined />}
                      // onClick={addNewAddressData}
                    >
                      Add New Address{" "}
                    </Button>

                    {/* </Card> */}
                  </div>
                </div>
              ) : null}

              {cartData?.address?.hasOwnProperty("name") ? (
                <>
                  {cartData?.items.length > 0 ? (
                    <>
                      <Group37002
                        className="delivery-to-box-parent"
                        style={{ maxWidth: "990px !important" }}
                      >
                        <div className="delivery-to-box">
                          <p className="para text-left">
                            Deliver to:{" "}
                            <span
                              style={{
                                fontWeight: "600",
                                fontFamily: "brandon-grotesque",
                              }}
                            >
                              {cartData?.address?.name}
                            </span>{" "}
                            <span style={{ margin: "0 10px" }}>
                              <Badge
                                style={{
                                  backgroundColor: "#f4792c",
                                  "border-radius": "4px",
                                }}
                                count={cartData?.address?.address_type}
                              />
                            </span>
                            <span>
                              <Badge
                                style={{
                                  backgroundColor: "#4096ff",
                                  "border-radius": "4px",
                                }}
                                count={
                                  cartData?.address?.is_default
                                    ? "Default"
                                    : null
                                }
                              />
                            </span>
                            <Row style={{ flexDirection: "column" }}>
                              <span style={{ lineHeight: 1.4 }}>
                                {cartData?.address?.house_no},{" "}
                                {cartData?.address?.block},{" "}
                              </span>
                              <span style={{ lineHeight: 1.4 }}>
                                {cartData?.address?.society}{" "}
                                {cartData?.address?.city}{" "}
                              </span>
                              <span style={{ lineHeight: 1.4 }}>
                                {cartData?.address?.state}{" "}
                                {cartData?.address?.pincode}
                              </span>
                            </Row>
                          </p>
                        </div>

                        <Button
                          style={{ alignSelf: "center", cursor: "pointer" }}
                          onClick={changeAddress}
                        >
                          <Return>CHANGE ADDRESS</Return>
                        </Button>
                      </Group37002>
                      <p style={{ color: "green" }}>
                        {" "}
                        Delivery by{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {expectedDelDate}
                        </span>
                      </p>
                    </>
                  ) : (
                    <div className="custom-container spacer">
                      <div className="text-center cart-image">
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="cart-svg svg">
                          <img
                            src={cartImage}
                            alt="logo"
                            style={{ height: "253px" }}
                          />
                        </div>
                        <br />

                        <p
                          style={{
                            width: "100%",
                            fontSize: "18px",
                            paddingBottom: "0.4rem",
                          }}
                          className="para bold"
                        >
                          Hey! Your Cart is empty
                        </p>
                        <div
                          style={{
                            width: "100%",
                            fontSize: "16px",
                            paddingBottom: "2rem",
                            lineHeight: "1.4em",
                          }}
                          className="para"
                        >
                          Add your favourites here and make them yours soon!
                        </div>
                        <button
                          style={{ marginBottom: "3rem" }}
                          className="btn-primary"
                          onClick={() => history.push("/")}
                        >
                          Shop now
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!addressApiData?.data?.length == 0 ? (
                    <>
                      {cartData?.items?.length > 0 ? (
                        <>
                          {!cartDataLength == 0 ? (
                            <>
                              <Group37002
                                className="delivery-to-box-parent"
                                style={{ maxWidth: "990px !important" }}
                              >
                                <div className="delivery-to-box">
                                  <p className="para text-left">
                                    Deliver to{" "}
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        fontFamily: "brandon-grotesque",
                                      }}
                                    >
                                      {defaultAddress[0]?.name}
                                    </span>{" "}
                                    <span style={{ marginLeft: "10px" }}>
                                      <Badge
                                        count={defaultAddress[0]?.address_type}
                                      />
                                    </span>
                                    <span>
                                      <Badge
                                        count={
                                          defaultAddress[0]?.is_default
                                            ? "Default"
                                            : null
                                        }
                                      />
                                    </span>
                                    <br />
                                    {defaultAddress[0]?.block}{" "}
                                    {defaultAddress[0]?.house_no} <br />
                                    {defaultAddress[0]?.society}{" "}
                                    {defaultAddress[0]?.city}{" "}
                                    {defaultAddress[0]?.state}
                                  </p>
                                </div>

                                <Button
                                  style={{
                                    alignSelf: "center",
                                    cursor: "pointer",
                                    cursor: "pointer",
                                  }}
                                  onClick={changeAddress}
                                >
                                  <Return>CHANGE ADDRESS</Return>
                                </Button>
                              </Group37002>
                              <p style={{ color: "green" }}>
                                Delivery by{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {expectedDelDate}
                                </span>
                              </p>
                            </>
                          ) : (
                            <div
                              onClick={changeAddress}
                              // style={{
                              //   border: "1px solid lightgrey",
                              //   padding: "28px 5px 28px 17px",
                              //   borderRadius: "9px",
                              //   boxShadow: "rgb(0 0 0 / 8%) 0px 0px 5px",
                              // }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                                marginTop: "3%",
                              }}
                            >
                              {/* <span
                                style={{ color: "blue", cursor: "pointer" }}
                              > */}

                              <Button type="pink" style={{ cursor: "pointer" }}>
                                Click Here to select shipping address
                              </Button>
                              {/* </span>{" "} */}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="text-center cart-image">
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className="wishlist-svg">
                            <img
                              src={cartImage}
                              alt="logo"
                              style={{ height: "160px" }}
                            />
                          </div>
                          <br />

                          <p
                            style={{
                              width: "100%",
                              fontSize: "18px",
                              paddingBottom: "0.4rem",
                            }}
                            className="para bold"
                          >
                            Hey! Your Cart is empty.
                          </p>
                          <p
                            style={{
                              width: "100%",
                              fontSize: "16px",
                              paddingBottom: "2rem",
                              lineHeight: "1.4em",
                            }}
                            className="para text-center"
                          >
                            Save your favourites here and make them yours soon!
                          </p>
                          <button
                            style={{ marginBottom: "3rem" }}
                            className="btn-primary"
                            onClick={() => history.push("/")}
                          >
                            Shop now
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {cartData?.items.length > 0 ? (
                        <></>
                      ) : (
                        !loading && (
                          <>
                            <div className="text-center cart-image">
                              <br />
                              <br />
                              <br />
                              <br />
                              <div className="wishlist-svg">
                                <img
                                  src={cartImage}
                                  alt="logo"
                                  style={{ height: "160px" }}
                                />
                              </div>
                              <br />

                              <p
                                style={{
                                  width: "100%",
                                  fontSize: "18px",
                                  paddingBottom: "0.4rem",
                                }}
                                className="para bold"
                              >
                                Hey! Your Cart is empty.
                              </p>
                              <p
                                style={{
                                  width: "100%",
                                  fontSize: "16px",
                                  paddingBottom: "2rem",
                                  lineHeight: "1.4em",
                                }}
                                className="para text-center"
                              >
                                Save your favourites here and make them yours
                                soon!
                              </p>
                              <button
                                style={{ marginBottom: "3rem" }}
                                className="btn-primary"
                                onClick={() => history.push("/")}
                              >
                                Shop now
                              </button>
                            </div>
                          </>
                        )
                      )}
                    </>
                  )}
                </>
              )}

              {cartData?.items?.map((value, index) => {
                return (
                  <>
                    <Group37002 style={{ maxWidth: "990px !important" }}>
                      <div
                        className="delivery-to-box"
                        // onClick={(value) => checkValue(value)}
                      >
                        <div style={{ display: "flex" }}>
                          <Link to={`/singleShop?${value._id}`}>
                            {value?.images?.length ? (
                              //   <Group36968
                              //   style={{
                              //     backgroundImage: `url(${value?.images[0]?.url})`,
                              //   }}
                              //   // onError={errorImageFunc}
                              //   onError={(e)=>handleImageError(e)}
                              // ></Group36968>
                              <img
                                src={value?.images[0]?.url}
                                style={{ width: "100px", height: "100px" }}
                              />
                            ) : (
                              <img
                                src={noimage}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </Link>

                          <div style={{ marginLeft: "1.6rem" }}>
                            {/* <UrbanoFashion>{urbanoFashion}</UrbanoFashion> */}
                            <h4
                              style={{
                                marginBottom: "13px",
                                display: "-webkit-box",
                                "-webkit-box-orient": "vertical",
                                WebkitLineClamp: "1",
                                fontWeight: "bold",
                                overflow: "hidden",
                                maxWidth: "calc(100% - 16px)",
                                fontSize: "large",
                              }}
                            >
                              {value?.name}
                            </h4>
                            {/* <p className="para text-left">
                              {value?.description}
                            </p> */}

                            {value?.in_stock ? (
                              <div className="quantify-button">
                                {value?.size_of_product && (
                                  <div onClick={() => sizePopUp(value)}>
                                    <Spanbox
                                      style={{ marginRight: "1rem" }}
                                      size30M={value?.size_of_product}
                                    />
                                  </div>
                                )}

                                {/* <div
                                  onClick={() => {
                                    setQuantityPopup(true),
                                      setQuantityData(value);
                                  }}
                                >
                                  <Spanbox size30M={value?.quantity} />
                                </div> */}

                                <Row
                                  className="stepper_quantity"
                                  justify="space-between"
                                  align="middle"
                                  style={{
                                    display: "flex",
                                    "border-radius": "8px",
                                    border: "1px solid #ddd",
                                    padding: "6px",
                                    width: "95px",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      decreaseItemQuant(value),
                                        setDeleteData(value);
                                    }}
                                  >
                                    <BiMinus />
                                  </div>
                                  <div style={{ fontSize: "initial" }}>
                                    {value?.quantity}
                                  </div>
                                  <div onClick={() => increseItemQuant(value)}>
                                    <BiPlus />
                                  </div>
                                </Row>
                              </div>
                            ) : null}

                            <div className="quantify-button">
                              {!(
                                value?.warehouses[0]?.ASP ===
                                value?.warehouses[0]?.MRP
                              ) ? (
                                <>
                                  <div className="para discount ">
                                    ₹{value?.warehouses[0]?.MRP}
                                  </div>
                                </>
                              ) : null}

                              <div className="para bold blue">
                                ₹{value?.warehouses[0]?.ASP}
                              </div>

                              {!(
                                value?.warehouses[0]?.ASP ===
                                value?.warehouses[0]?.MRP
                              ) ? (
                                <div className="para red bold">
                                  ({value?.warehouses[0]?.discount_per}% OFF)
                                </div>
                              ) : null}
                            </div>

                            {!value?.in_stock ? (
                              //  <Button danger>Out Of Stock</Button>
                              <div style={{ color: "red" }}>Out Of Stock</div>
                            ) : null}

                            {/* <p style={{ textAlign: "left" }} className="para">
                Deliver to{" "}
                <span style={{ fontWeight: "400" }}>Md Aquil, 110055</span>
              </p> */}
                          </div>
                        </div>{" "}
                      </div>
                      <div
                        onClick={() => {
                          setDeletePopUpState(true), setDeleteData(value);
                        }}
                        style={{ cursor: "Pointer", color: "#ff3055" }}
                        className="delete-button-a"
                      >
                        <MdDelete size={40} style={{ color: "#f4792c" }} />
                      </div>
                    </Group37002>
                  </>
                );
              })}
            </div>
            {login && <Login setIsOpen={setLogin} isOpen={login} />}
            {cartData?.items.length > 0 ? (
              <div className="paybox">
                <Group37002 style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <UrbanoFashion>{"Payment Details"}</UrbanoFashion>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="para">Total MRP</p>
                      <p className="para bold">₹ {cartData?.amount?.total} </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="para">Bag Discount</p>
                      <p className="para bold">
                        - ₹ {cartData?.amount?.discount}{" "}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="para">Sub Total</p>
                      <p className="para bold">
                        ₹ {cartData?.amount?.payable}{" "}
                      </p>
                    </div>

                    {cartData?.amount?.delivery_charge === 0 ||
                    cartData?.amount?.payable >= 999 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="para">Shipping charge</p>
                        <p className="para bold">Free</p>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="para">Shipping charge</p>
                        <p className="para bold">
                          + ₹{cartData?.amount?.delivery_charge}
                        </p>
                      </div>
                    )}

                    {cartData?.amount?.handling_charge > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="para">Handling Charge</p>
                        <p className="para bold">
                          ₹ {cartData?.amount?.handling_charge}{" "}
                        </p>
                      </div>
                    )}

                    {cartData?.amount?.cod_charge > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="para">COD Charge</p>
                        <p className="para bold">
                          {selectedPayment === "cash-payment"
                            ? "₹" + cartData?.amount?.cod_charge
                            : "₹0"}
                        </p>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="para"
                        style={{
                          fontWeight: "bold",
                          fontSize: "larger",
                          fontFamily: "brandon-grotesque",
                        }}
                      >
                        YOU PAY
                      </p>
                      <p
                        className="para bold"
                        style={{
                          fontWeight: "bold",
                          fontSize: "larger",
                          fontFamily: "brandon-grotesque",
                        }}
                      >
                        ₹
                        {selectedPayment === "cash-payment"
                          ? amountToPay.cod
                          : amountToPay.online}
                        {/* {cartData?.amount?.payable +
                          cartData?.amount?.delivery_charge}{" "} */}
                      </p>
                    </div>

                    {cartData?.amount?.payable < 999 && (
                      <Alert
                        style={{ margin: "16px 0 12px 0", fontWeight: "bold" }}
                        message={`Add items worth ₹${
                          999 - cartData?.amount?.payable
                        } to avail Free Delivery`}
                        type="warning"
                        showIcon
                      />
                    )}

                    {!checkOut1 ? (
                      <Button
                        type="pink"
                        className="pst-btn"
                        onClick={checkOut}
                        style={{
                          cursor: "pointer",
                          fontFamily: "brandon-grotesque",
                        }}
                      >
                        PROCEED TO CHECKOUT
                      </Button>
                    ) : null}
                  </div>
                  <div></div>
                </Group37002>

                {checkOut1 && (
                  <>
                    <CartOrder
                      selectedPayment={selectedPayment}
                      setSelectedPayment={setSelectedPayment}
                      amountToPay={amountToPay}
                      paymentMode={paymentMode}
                      getCartData={getCartData}
                      setCodCharges={setCodCharges}
                      cartCount={props?.state}
                      cartCountSetStatus={props?.setState}
                      state={props?.state}
                      setState={props?.setState}
                    />
                  </>
                )}
              </div>
            ) : null}
            <Coupon setIsOpen={setOpen} isOpen={open} />{" "}
          </div>
        </div>
      ) : (
        <AddNewAddress cancelAdd={cancelAdd} />
      )}

      {login && <Login setIsOpen={setLogin} isOpen={login} />}

      {modalOpen && (
        <Modal
          title={
            addAddressPopup
              ? "Add New Address"
              : !editAddress && !editAddress1
              ? "Edit Address"
              : "Select Address"
          }
          centered
          open={modalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          width={800}
          footer={null}
        >
          {!addAddressPopup ? (
            <Card>
              <Button type="primary" block onClick={addNewAddressFunc} style={{backgroundColor:"rgb(64, 150, 255)"}}>
                <div>Add A New Address</div>
              </Button>
            </Card>
          ) : null}

          {addAddressPopup ? (
            <div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    m: 1,
                    maxWidth: "360px",
                    width: "100%",
                  },
                }}
                noValidate
                autoComplete="off"
                InputProps={{
                  style: { fontFamily: "brandon-grotesque" },
                }}
              >
                <div
                  style={{
                    fontFamily: "brandon-grotesque",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    id="outlined-password-input"
                    label="Name"
                    className="input-section-cart"
                    type="text"
                    InputProps={{
                      style: { fontFamily: "brandon-grotesque" },
                    }}
                    onChange={(e) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        name: e.target.value,
                      })
                    }
                  />

                  <TextField
                    id="outlined-password-input"
                    label="PinCode"
                    // onKeyDown={handleKeyDown}
                    type="number"
                    onChange={(e) => {
                      setAddNewAddressData({
                        ...addNewAddressData,
                        pin_code: e.target.value,
                      }),
                        checkPinCode(e);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                  />

                  <TextField
                    id="outlined-password-input"
                    label="House No."
                    onChange={(e) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        house_no: e.target.value,
                      })
                    }
                    //   type="text"
                    //   autoComplete="current-password"
                  />
                  <TextField
                    id="outlined-password-input"
                    label="Society"
                    onChange={(e) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        society: e.target.value,
                      })
                    }
                    //   type="text"
                    //   autoComplete="current-password"
                  />
                  <TextField
                    id="outlined-password-input"
                    label="Block"
                    onChange={(e) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        block: e.target.value,
                      })
                    }
                    //   type="text"
                    //   autoComplete="current-password"
                  />
                  <TextField
                    id="outlined-password-input"
                    label="City"
                    onChange={(e) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        city: e.target.value,
                      })
                    }
                    //   type="text"
                    //   autoComplete="current-password"
                  />
                  
                  {/* <TextField
                    id="outlined-password-input"
                    label="State"
                    onChange={(e) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        state: e.target.value,
                      })
                    }
                  /> */}

<span>
                  <Autocomplete
                  style={{display:"initial"}}
                    // id="state-selector"
                    id="outlined-password-input"
                    options={states}
                    // className="input-section"
                    getOptionLabel={(option) => option}
                    onChange={(event, value) =>
                      setAddNewAddressData({
                        ...addNewAddressData,
                        state: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        // error={isSubmitted && !addressData.state}
                        {...params}
                        label="Select a State"
                        variant="outlined"
                      />
                    )}
                  />

</span>

                  <FormControl
                    style={{ width: "100%", maxWidth: "360px", margin: 8 }}
                  >
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Address Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          address_type: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="Home"
                        control={<Radio />}
                        label="Home"
                      />
                      <FormControlLabel
                        value="Office"
                        control={<Radio />}
                        label="Office"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>

                  <div style={{ width: "100%", textAlign: "start" }}>
                    <Checkbox
                      {...label}
                      onChange={(event) =>
                        setAddNewAddressData({
                          ...addNewAddressData,
                          is_default: event.target.checked,
                        })
                      }
                    />
                    <span>Want to mark as default address</span>
                  </div>
                </div>
              </Box>
              <div style={{ textAlign: "end" }}>
                <Button
                  style={{ backgroundColor: "#f4792c", color: "white" }}
                  type="primary"
                  onClick={addNewAddressFunc12}
                >
                  Add
                </Button>

                <Button
                  type="primary"
                  onClick={cancellUpdate}
                  style={{ marginLeft: "16px",backgroundColor:"rgb(64, 150, 255)" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : null}

          {editAddress &&
            addressApiData?.data?.map((value, index) => {
              return (
                <>
                  <div className="hr-card" style={{ position: "relative" }}>
                    <Group37002>
                      <div
                        className="delivery-to-box"
                        style={{ width: "100%" }}
                      >
                        <p className="para text-left">
                          {/* Deliver to{" "} */}
                          <span style={{ fontWeight: "600" }}>
                            {value?.name}{" "}
                          </span>{" "}
                          <span style={{ marginLeft: "10px" }}>
                            <Badge
                              style={{
                                backgroundColor: "#f4792c",
                              }}
                              count={value?.address_type}
                            />
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            <Badge
                              style={{
                                backgroundColor: "rgb(64, 150, 255)",
                              }}
                              count={value.is_default ? "Default" : null}
                            />
                          </span>
                          <br />
                          {value?.block} {value?.house_no} {value?.society}{" "}
                          {value?.city} {value?.state} {value?.pincode}
                        </p>

                        {value?._id == cartData?.address?._id ? (
                          <Checkbox
                            className="address-checkbox"
                            {...label}
                            defaultChecked={true}
                          />
                        ) : (
                          <Checkbox
                            className="address-checkbox"
                            {...label}
                            onChange={() => selectDefaultAddres(value)}
                            defaultChecked={false}
                          />
                        )}

                        <span>Use as the shipping address</span>
                      </div>

                      {/* <Button onClick={()=>selectDefaultAddres(value)}>Select</Button> */}
                    </Group37002>
                    <Button
                      style={{
                        color: "rgb(244, 121, 44)",
                        position: "absolute",
                        top: 12,
                        right: 12,
                      }}
                      onClick={() => editAddressFunc(value)}
                    >
                      Edit
                    </Button>
                  </div>
                </>
              );
            })}

          {!editAddress && !editAddress1 && (
            <div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    m: 1,
                    width: "100%",
                    maxWidth: "360px",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <div style={{ textAlign: "center" }}>
                  <TextField
                    id="outlined-password-input"
                    label="Name"
                    type="text"
                    onChange={(e) =>
                      setAddressData({
                        ...addressData,
                        name: e.target.value,
                      })
                    }
                    defaultValue={editData?.name}
                  />

                  <TextField
                    id="outlined-password-input"
                    label="PinCode"
                    type="number"
                    // onKeyDown={handleKeyDown}
                    defaultValue={editData?.pincode}
                    onChange={(e) => {
                      setAddressData({
                        ...addressData,
                        pin_code: e.target.value,
                      }),
                        checkPinCode(e);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                  />

                  <TextField
                    id="outlined-password-input"
                    label="House No."
                    defaultValue={editData?.house_no}
                    onChange={(e) =>
                      setAddressData({
                        ...addressData,
                        house_no: e.target.value,
                      })
                    }
                  />
                  <TextField
                    id="outlined-password-input"
                    label="Society"
                    defaultValue={editData?.society}
                    onChange={(e) =>
                      setAddressData({
                        ...addressData,
                        society: e.target.value,
                      })
                    }
                  />
                  <TextField
                    id="outlined-password-input"
                    label="Block"
                    defaultValue={editData?.block}
                    onChange={(e) =>
                      setAddressData({
                        ...addressData,
                        block: e.target.value,
                      })
                    }
                  />
                  <TextField
                    id="outlined-password-input"
                    label="City"
                    defaultValue={editData?.city}
                    onChange={(e) =>
                      setAddressData({
                        ...addressData,
                        city: e.target.value,
                      })
                    }
                  />

                  {/* <TextField
                    id="outlined-password-input"
                    label="State"
                    defaultValue={editData?.state}
                    onChange={(e) =>
                      setAddressData({
                        ...addressData,
                        state: e.target.value,
                      })
                    }
                  /> */}

<span>
                  <Autocomplete
                  style={{display:"initial"}}
                    // id="state-selector"
                    id="outlined-password-input"
                    options={states}
                    // className="input-section"
                    getOptionLabel={(option) => option}
                    onChange={(event, value) =>
                      setAddressData({
                        ...addressData,
                        state: value,
                      })
                    }
                    defaultValue={editData?.state}
                    renderInput={(params) => (
                      <TextField
                        // error={isSubmitted && !addressData.state}
                        {...params}
                        label="Select a State"
                        variant="outlined"
                      />
                    )}
                  />

</span>



                  <FormControl
                    style={{ width: "100%", maxWidth: 360, margin: 8 }}
                  >
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Address Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) =>
                        setAddressData({
                          ...addressData,
                          address_type: event.target.value,
                        })
                      }
                      // onChange={(event)=>checkRadioButton(event)}
                      defaultValue={editData?.address_type}
                    >
                      <FormControlLabel
                        value="Home"
                        control={<Radio />}
                        label="Home"
                      />
                      <FormControlLabel
                        value="Office"
                        control={<Radio />}
                        label="Office"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>

                  <div style={{ width: "100%", textAlign: "start" }}>
                    <Checkbox
                      {...label}
                      onChange={(event) =>
                        setAddressData({
                          ...addressData,
                          is_default: event.target.checked,
                        })
                      }
                      defaultChecked={editData?.is_default}
                    />

                    <span>Want to mark as default address</span>
                  </div>
                </div>
              </Box>

              <div style={{ width: "100%", textAlign: "end" }}>
                <Button
                  style={{ backgroundColor: "#f4792c", color: "white" }}
                  type="primary"
                  onClick={updateData}
                >
                  Update
                </Button>

                <Button
                  type="primary"
                  onClick={cancellUpdate}
                  style={{ marginLeft: "16px",backgroundColor:"rgb(64, 150, 255)" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Modal>
      )}

      <Modal
        title="Select Size"
        centered
        open={sizeModal}
        onOk={() => setSizeModal(false)}
        onCancel={() => setSizeModal(false)}
        footer={null}
        // size={360}
        width={360}
      >
        <hr />
        <ul className="numbox">
          {sizeData?.data.length > 0 ? (
            <>
              {sizeData?.data?.map((value, index) => {
                return (
                  <>
                    <li
                      style={{
                        backgroundColor: `${
                          value === bgColor?.number ? bgColor?.color : ""
                        }`,
                        color: `${
                          value === bgColor?.number ? bgColor?.colorW : "black"
                        }`,
                      }}
                      onClick={() => {
                        setSizeValue(value), changeColor(value);
                      }}
                    >
                      {value?.size_of_product}
                    </li>
                  </>
                );
              })}
              <Button
                style={{
                  width: "100%",
                  borderColor: "#f4792c",
                  color: "#f4792c",
                  marginTop: "12px",
                }}
                onClick={() => newSizeBoxModal()}
              >
                DONE
              </Button>
            </>
          ) : (
            <div style={{ color: "#f4792c", fontSize: "large" }}>
              Sorry! No more size is available of this product.
            </div>
          )}
        </ul>
      </Modal>

      <Modal
        title="Select Quantity"
        centered
        open={quantityPopUp}
        onOk={() => setQuantityPopup(false)}
        onCancel={() => setQuantityPopup(false)}
        footer={null}
        width={360}
      >
        <hr />
        <ul className="numbox">
          {numbers?.length > 0 ? (
            <>
              {numbers?.map((value, index) => {
                return (
                  <>
                    <li
                      style={{
                        backgroundColor: `${
                          value === bgColor?.number ? bgColor?.color : ""
                        }`,
                        color: `${
                          value === bgColor?.number ? bgColor?.colorW : "black"
                        }`,
                      }}
                      onClick={() => {
                        setQuantityValue(value), changeColor(value);
                      }}
                    >
                      <p>{value}</p>
                    </li>
                  </>
                );
              })}
              <Button
                style={{
                  width: "100%",
                  borderColor: "#f4792c",
                  color: "#f4792c",
                  marginTop: "12px",
                }}
                onClick={() => selectSize()}
              >
                DONE
              </Button>
            </>
          ) : (
            <div style={{ color: "#f4792c", fontSize: "large" }}>
              Sorry! No more quantity is available of this product.
            </div>
          )}
        </ul>
      </Modal>

      <Modal
        title="Product Delete Confirmation"
        centered
        open={deletePopUpState}
        onOk={() => setDeletePopUpState(false)}
        onCancel={() => setDeletePopUpState(false)}
        footer={null}
      >
        {/* <h2 style={{ fontSize: "large", color: "darkred" }}>
          Are You Sure To Want To Delete The Product From Cart
        </h2> */}
        <p
          style={{
            fontSize: "14px",
            color: "#7E818C",
            margin: "10px 10px 35px 0px",
          }}
        >
          Are you sure you want to remove this item from the Cart?
        </p>
        <div style={{ display: "flex", "justify-content": "end" }}>
          <Button
            onClick={deleteItemButton}
            type="primary"
            style={{ backgroundColor: "#f4792c" }}
          >
            Remove
          </Button>

          <Button
            onClick={moveToWishlist}
            type="primary"
            style={{ marginLeft: "17px",backgroundColor:"rgb(64, 150, 255)" }}
          >
            Move to Wishlist
          </Button>
        </div>
      </Modal>

      <Modal
        title="Product Delete Alert"
        top
        open={deleteProductPopup}
        onOk={() => setDeleteProductPopUp(false)}
        onCancel={() => setDeleteProductPopUp(false)}
        footer={null}
      >
        <p
          style={{
            fontSize: "14px",
            color: "#666",
            margin: "10px 10px 35px 0px",
          }}
        >
          Please remove out of stock items from cart to proceed
        </p>
      </Modal>

      {loading && (
        <div
          style={{
            marginTop: "4rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

// Add Address Data

const ACCOUNT = styled.div`
  ${ValignTextMiddle}
  height: 26px;
  margin-top: 93px;
  margin-left: 215px;
  font-family: brandon-grotesque;
  font-weight: 500;
  color: var(--black);
  font-size: 22px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            height: 17px;
  margin-top: 5px;
  margin-left: 215px;
  letter-spacing: 0;
  line-height: normal;
`;

const OverlapGroup4 = styled.div`
  width: 1125px;
  height: 571px;
  position: relative;
  align-self: center;
  margin-top: 22px;
  margin-right: 1px;
`;

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  height: 268px;
  position: relative;
  margin-top: 36px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  cursor: pointer;
  top: 0;
  left: 0;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  display: flex;
  justify-content: space-between;
  padding: 15px 19px;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  width: 94px;
  height: 44px;
  position: relative;
  margin-top: 0.26px;
`;

const Name = styled.div`
  ${ValignTextMiddle}
  height: 22px;
  top: 0;
  left: 0;
  font-family: brandon-grotesque;
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  height: 22px;
  top: 22px;
  left: 3px;
  font-family: brandon-grotesque;
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 100px;
  height: 100px;
  background-size: cover;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: brandon-grotesque;
  font-weight: 400;
  color: #f4792c;
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}
  height: 32px;
  top: 71px;
  left: 161px;
  font-family: brandon-grotesque;
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
  height: 32px;
  top: 97px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: brandon-grotesque;
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default Cart;

// max-width: 300px;
//     max-height: 162px;
//     border: ridge;
//     margin: 0 auto;
