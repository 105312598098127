import { dev } from "./baseurl";

export async function getFilterItemApi() {
  const data = await dev.get("/item/filter/fashion");
  return data;
}

export async function itemByFilterApi(
  allSize,
  selectedItems,
  productCat,
  range,
  value
) {
  //   selectedItems,
  //   allSize,
  //   range,
  //   value, "testinggggggggg");

  // let productCat=["shubham","tripathi"]


  const data1 = {
    subcategory: productCat.length !== 0 ? productCat.toString() : [""],
    sap_class: selectedItems.length !== 0 ? selectedItems.toString() : [""],
    size: allSize.length !== 0 ? allSize.toString() : [""],
    min_price: range[0],
    max_price: range[1],
    page_size: 12,
    page_num: value,
  };
  const data = await dev.get("/item/believe/", {
    params: data1,
  });

  return data;
}

export async function getShopItemApi(pageSize) {
  const data = await dev.get(`/item/believe/`, {
    params: {
      page_size: 9,
      page_num: pageSize,
    },
  });
  return data;
}

// subcategory: productCat.length !== 0 ? (productCat.toString()) : [""],
