import React from "react";
import Group from "../Header";
import styled from "styled-components";

class Header32 extends React.Component {
  render() {
    const { groupProps } = this.props;

    return (
      <Header>
        <Group1>
          <Image src="/img/image-9@2x.png" alt="Image" />
          <Group className={groupProps.className} />
        </Group1>
      </Header>
    );
  }
}

const Header = styled.header`
  position: relative;
  min-width: 1512px;
  height: 116px;
  background-color: var(--lg);
  box-shadow: 0px 4px 30px #0000001a;
  mix-blend-mode: normal;
`;

const Group1 = styled.div`
  position: relative;
  top: 23px;
  left: 55px;
  display: flex;
  align-items: center;
  min-width: 1400px;
  gap: 531px;
`;

const Image = styled.img`
  width: 171px;
  height: 71px;
  object-fit: cover;
`;

export default Header32;
