import axios from "axios";

export async function searchApi(productName, pageSize, pageNum) {
  const data = await axios.get(`/dev/search/${productName}`, {
    params: {
      // product_name: productName,
      page_number: 1,
      page_size: 100,
      search_flag: false,
      category: "believe",
    },
  });

  return data;
}
export async function suggestionApi(query) {
  console.log("testingggggggggggggggggggggggggggggg");
  return await axios.get(`/dev/autosuggest/${query}`, {
    params: {
      usage: "hobdeerika",
    },
  });
}

export async function searchRecentApi(productName, pageSize, pageNum) {
  const data = await axios.get(`/dev/search/recent`, {
    params: {
      product_name: productName,
      page_number: pageNum,
      page_size: pageSize,
      search_flag: false,
    },
  });

  return data;
}

export async function getWherehouseApi(latitude, longitude) {
  // "28.4320397"
  // "77.0686159"
  const data = await axios.post("/dev/address/set", {
    latitude: "28.4320397",
    longitude: "77.0686159",
  });
  return data;
}

// dev lat-28.4320397
// dev long-77.0686159

//qa latitude: "28.43486"
//qa longitude: "77.06876"
