import React, { useEffect, useRef, useState } from "react";
// import Header4 from "../Header4";

import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import {
  LatoNormalFuscousGray16px,
  AzosansRegularNormalFuscousGray14px,
  AzosansRegularNormalFuscousGray16px,
  ValignTextMiddle,
} from "../styledMixins";
import {
  AddAddressCartApi,
  getAddressApi,
  updateAddressApi,
} from "../api/Address";

import { Alert, Checkbox, Modal, Spin, notification } from "antd";
import { api, dev } from "../api/baseurl";
import { CashDeliveryApi, sodApi, verifyCashDeliveryApi } from "../api/payment";
import { Link } from "react-router-dom";
import { BiWallet } from "react-icons/bi";

const CartOrder = ({
  amountToPay,
  paymentMode,
  getCartData,
  setCodCharges,
  cartCount,
  cartCountSetStatus,
  state,
  setState,
  selectedPayment,
  setSelectedPayment,
}) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const walletAmount = JSON.parse(localStorage.getItem("wallet")).amount;
  // const walletAmount = 0;

  const [time, setTime] = useState(60);
  const [isExpire, setIsExpire] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [isotp, setisotp] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenOtp, setIsOpenOtp] = useState(false);
  const [empty, setEmtpy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [success, setSuccess] = useState(false);
  const [useWalletToggle, setUseWalletToggle] = useState(false);
  const [allAmounts, setAllAmounts] = useState({
    wallet: 0,
    payableAmount: amountToPay,
  });

  const calculateWalletDeductableAmount = () => {
    if (useWalletToggle) {
      if (walletAmount < amountToPay.online) {
        setAllAmounts({
          wallet: walletAmount,
          payableAmount: {
            online: amountToPay.online - walletAmount,
            cod: amountToPay.cod - walletAmount,
          },
        });
      } else if (
        walletAmount >= amountToPay.online &&
        walletAmount < amountToPay.cod
      ) {
        setAllAmounts({
          wallet: amountToPay.online,
          payableAmount: {
            online: 0,
            cod: amountToPay.cod - walletAmount,
          },
        });
        setSelectedPayment("wallet-only");
      } else {
        setAllAmounts({
          wallet: amountToPay.online,
          payableAmount: {
            online: 0,
            cod: 0,
          },
        });
        setSelectedPayment("wallet-only");
      }
    } else {
      setAllAmounts({
        wallet: 0,
        payableAmount: amountToPay,
      });
    }
  };

  useEffect(
    () => calculateWalletDeductableAmount(),
    [useWalletToggle, amountToPay]
  );

  useEffect(() => {
    let interval;
    if (timerStarted) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time <= 0) {
      setIsExpire(true);
      clearInterval(interval);
      setTimerStarted(false);
      setIsMobile(true);
      setErrorMessage("");
    }

    return () => clearInterval(interval);
  }, [timerStarted, time]);

  const seconds = time % 60;
  const minutes = Math.floor(time / 60) % 60;

  const startTimer = () => {
    setTime(60);
    setTimerStarted(true);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    const { data } = await verifyCashDeliveryApi(otp);
    if (data.status) {
      notification.success({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
      setIsOpenOtp(false);

      // notification.success({
      //   message: resultData?.data?.message,
      //   duration: 1,
      // });

      setOtp("");
      setSuccess(true);
      let tempPropsState = cartCount;

      cartCountSetStatus({
        ...tempPropsState,
        isCodPayment: true,
      });
      setState({
        ...state,
        refreshWalletAmountToggle: !state.refreshWalletAmountToggle,
      });
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement: "bottom",
      });
    }
  };

  const handleCanelSuccess = () => {
    getCartData();
    setSuccess(false);
  };

  const handleReset = () => {};

  const handleOnlinePayment = async (e) => {
    e.preventDefault();
    if (selectedPayment === "") {
      setEmtpy(true);
      return notification.error({
        message: "please Select payment method",
        duration: 1,
        placement: "bottom",
      });

      return;
    }
    if (selectedPayment === "cash-payment") {
      setIsLoading(true);

      const { data } = await CashDeliveryApi(allAmounts.wallet);
      if (data.success) {
        setIsOpenOtp(true);
        notification.success({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });

        setIsLoading(false);
        startTimer();
        setIsExpire(false);

        // window.location.reload(true);
      }
      setIsLoading(false);
    } else if (selectedPayment === "online-payment") {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const { data } = await dev.post("/hob/online/", {
        wallet: allAmounts.wallet,
      });
      if (!data.success) {
        return notification.error({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });
      } else {
        notification.success({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });
      }
      const {
        amount,
        razorpay_order_id,
        currency,
        razorpay_app_id,
        mobile,
        description,
      } = data.data;

      const options = {
        key: razorpay_app_id,
        amount: `${amount}00`,
        order_id: razorpay_order_id,
        currency: currency,
        mobile: mobile,
        description: description,
        //   image: { logo },
        handler: async function (response) {
          const result = await dev.post("/hob/online/confirm/", {
            ...response,
            final: true,
            razorpay_order_id: razorpay_order_id,
          });
          const { success, captured } = result.data;
          if (success == true && captured == true) {
            setSuccess(true);
            notification.success({
              message: result.data.message,
              duration: 1,
              placement: "bottom",
            });

            let tempPropsState = cartCount;

            cartCountSetStatus({
              ...tempPropsState,
              isOnlinePayment: true,
            });

            setState({
              ...state,
              refreshWalletAmountToggle: !state.refreshWalletAmountToggle,
            });
          } else {
            notification.error({
              message: result.data.message,
              duration: 1,
              placement: "bottom",
            });
          }
        },
        prefill: {
          name: "Soumya Dey",
          email: "SoumyaDey@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Soumya Dey Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else if (selectedPayment === "wallet-only") {
      const data = await dev.post("/hob/wallet/");

      if (data.data.status) {
        setSuccess(true);
        // window.location.reload(true);
        setState({
          ...state,
          refreshWalletAmountToggle: !state.refreshWalletAmountToggle,
        });
        let tempPropsState = cartCount;

        cartCountSetStatus({
          ...tempPropsState,
          walletCartCount: true,
        });
      }
    }
    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    }
  };

  return (
    <div>
      <>
        {walletAmount > 0 && (
          <Group37002>
            <p
              style={{ lineHeight: "30px", fontWeight: "600" }}
              className="para text-left"
            >
              <Checkbox
                id="use-wallet"
                checked={useWalletToggle}
                onChange={(e) => setUseWalletToggle(e.target.checked)}
              />
              <label for="use-wallet" style={{ marginLeft: 12 }}>
                Use{"  "}
                <span style={{ color: "green" }}>
                  ₹
                  {walletAmount < amountToPay.online
                    ? walletAmount
                    : amountToPay.online}
                </span>
                {"  "} from Wallet
              </label>
            </p>
            <BiWallet style={{ fontSize: 24 }} />
          </Group37002>
        )}
        {paymentMode?.online === true &&
          allAmounts.payableAmount.online > 0 && (
            <Group37002
              onClick={() => setSelectedPayment("online-payment")}
              className={`${
                selectedPayment === "online-payment"
                  ? "selected-payment-method"
                  : ""
              }`}
            >
              <p
                style={{ lineHeight: "30px", fontWeight: "600" }}
                className="para text-left"
              >
                {" "}
                &nbsp; Online Payments
              </p>
              <span style={{ color: "green" }}>
                ₹{allAmounts.payableAmount.online}/-
              </span>
            </Group37002>
          )}
        {paymentMode?.cod === true && allAmounts.payableAmount.cod > 0 && (
          <>
            <Group37002
              style={{ position: "relative", marginBottom: 0 }}
              onClick={() => {
                setSelectedPayment("cash-payment"), setCodCharges(true);
              }}
              className={`${
                selectedPayment === "cash-payment"
                  ? "selected-payment-method"
                  : ""
              }`}
            >
              <p
                style={{ lineHeight: "30px", fontWeight: "600" }}
                className="para text-left"
              >
                {" "}
                &nbsp; Cash On Delivery
              </p>
              <span style={{ color: "green" }}>
                ₹{allAmounts.payableAmount.cod}/-
              </span>
            </Group37002>
            {selectedPayment === "cash-payment" && (
              <Alert
                message="Switch to online payment to avoid COD charges."
                type="warning"
                showIcon
                style={{ fontWeight: "bold", marginTop: 12 }}
              />
            )}
          </>
        )}
        {/* {paymentMode.wallet === true && (
          <Group37002
            onClick={() => setSelectedPayment("Wallet")}
            className={`${
              selectedPayment === "Wallet" ? "selected-payment-method" : ""
            }`}
          >
            <p
              style={{ lineHeight: "30px", fontWeight: "600" }}
              className="para text-left"
            >
              {" "}
              &nbsp; Wallet Payment
            </p>
          </Group37002>
        )} */}

        {empty && selectedPayment === "" && (
          <div style={{ alignSelf: "center" }}>
            <p style={{ color: "red" }}>
              <small>Please Select payment Method</small>
            </p>
            <br />
          </div>
        )}
        <Spin spinning={isLoading}>
          {selectedPayment !== "" && (
            <button
              onClick={(e) => handleOnlinePayment(e)}
              style={{
                width: "100%",
                fontFamily: "brandon-grotesque",
                marginTop: 24,
                backgroundColor: "rgb(244, 121, 44)",
                border: "1px solid #f4792c",
              }}
              className="btn btn-primary"
            >
              Place Order &nbsp; &nbsp;&nbsp;
            </button>
          )}
        </Spin>
        {/* <Group37002>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ alignSelf: "center" }}></div>
                        <div>
                          {" "}
                          <button
                            style={{ lineHeight: "30px", fontWeight: "600" }}
                            className="para text-left"
                          >
                            {" "}
                            &nbsp;
                          </button>
                        </div>
                      </div>{" "}
                    </div>
                  </Group37002> */}
      </>

      <Modal
        width={800}
        onCancel={() => setIsOpenOtp(false)}
        footer={[""]}
        open={isOpenOtp}
      >
        <div style={{ padding: "4rem" }}>
          <div className="d">
            <div>
              <div className="">
                {/* <divHeader className="bg-transparent ">
                <div className="text-muted text-center ">
                  <small>Sign in with</small>
                </div>
              </divHeader> */}
                <div className="">
                  <h3
                    style={{ marginBottom: "1rem" }}
                    className="second-heading"
                  >
                    Confirm Order
                  </h3>

                  <form onSubmit={(e) => handleVerifyOtp(e)} role="form">
                    <div style={{ textAlign: "center" }} className="mt-5">
                      <>
                        <p className="para">
                          {" "}
                          {(isExpire && (
                            <span>
                              OTP Expired &nbsp;
                              <span
                                onClick={(e) => handleOnlinePayment(e)}
                                style={{ fontWeight: 500, fontSize: "16px" }}
                                className="link-para"
                              >
                                {" "}
                                Resend Otp
                              </span>
                            </span>
                          )) || (
                            <span style={{ color: "FF3055" }}>
                              Verify OTP To Confirm Order, valid Till :
                              {minutes.toString().padStart(2, "0")}:
                              {seconds.toString().padStart(2, "0")}
                            </span>
                          )}
                        </p>

                        <div
                          style={{ marginTop: "1rem" }}
                          className={isotp ? "has-danger" : ""}
                        >
                          {/* <label for="otp">Enter OTP</label> */}
                          <input
                            id="otp"
                            className="input-group-alternative"
                            value={otp}
                            disabled={isExpire}
                            placeholder="Enter OTP"
                            type="number"
                            autoComplete="new-email"
                            onChange={(e) => (
                              otp.length === 3 && setIsMobile(false),
                              setOtp(e.target.value),
                              setErrorMessage("")
                            )}
                          />
                          {errorMessage !== "" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ alignSelf: "center" }}>
                                <p style={{ color: "red" }}>
                                  <small>
                                    {errorMessage !== "" && errorMessage}
                                  </small>
                                </p>
                              </div>
                              <div
                                style={{
                                  alignSelf: "center",
                                  marginLeft: "1rem",
                                }}
                              >
                                <span
                                  onClick={handleReset}
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "16px",
                                  }}
                                  className="link-para"
                                >
                                  {" "}
                                  Resend Otp
                                </span>
                              </div>
                            </div>
                          )}

                          {isotp && (
                            <p style={{ color: "red" }}>
                              <small>Enter Otp</small>
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "1.5rem",
                            marginBottom: "1rem ",
                          }}
                          className="text-center mt-3"
                        >
                          <button
                            disabled={isMobile}
                            // color="primary"
                            style={{
                              backgroundColor: "#f4792c",
                              border: "1px solid #f4792c",
                            }}
                            className={`btn btn-primary ${
                              isMobile === true && "disabled"
                            }`}
                            type="submit"
                          >
                            Verify Otp
                          </button>
                        </div>
                      </>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* success modal open */}
      <Modal
        width={800}
        onCancel={() => handleCanelSuccess()}
        footer={[""]}
        open={success}
      >
        <div style={{ padding: "2rem" }}>
          <div className="d-f">
            <div>
              <div className="">
                {/* <divHeader className="bg-transparent ">
                <div className="text-muted text-center ">
                  <small>Sign in with</small>
                </div>
              </divHeader> */}
                <div className="confirm-order-svg">
                  <svg
                    width="307"
                    height="343"
                    viewBox="0 0 307 343"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M149 246.444L149.774 284.113L173.58 297L216.935 289.07L218 249.913L190.516 240L149 246.444Z"
                      fill="#2F4799"
                    />
                    <path
                      d="M173.986 298L149.788 284.9L149 246.531L191.075 240L191.165 240.032L219 250.073L217.916 289.963L173.986 298ZM150.542 284.423L174.111 297.183L217.17 289.306L218.222 250.621L191.002 240.802L149.777 247.201L150.542 284.423Z"
                      fill="#0E0E0E"
                    />
                    <path
                      d="M173.804 256.613L173.046 256.622L173.529 297.269L174.287 297.259L173.804 256.613Z"
                      fill="black"
                    />
                    <path
                      d="M149.536 257.729L149.236 258.442L159.287 262.875L159.586 262.161L149.536 257.729Z"
                      fill="black"
                    />
                    <path
                      d="M154.087 270.549L153.787 271.263L163.837 275.695L164.137 274.982L154.087 270.549Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M191 252.461L191.898 296.078L219.499 311L269.766 301.817L271 256.478L239.135 245L191 252.461Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M191 252.461L191.898 296.078L219.499 311L269.766 301.817L271 256.478L239.135 245L191 252.461Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M218.912 311L190.912 295.867L190 251.545L238.687 244L238.791 244.037L271 255.636L269.745 301.716L218.912 311ZM191.784 295.317L219.056 310.057L268.883 300.957L270.1 256.269L238.602 244.926L190.899 252.318L191.784 295.317Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M218.318 264L218.209 263.957L190 252.821L190.329 252L218.429 263.092L270.878 255.91L271 256.785L218.318 264Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M218.991 263.339L218.11 263.35L218.671 310.534L219.551 310.523L218.991 263.339Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M212.998 261L205 257.564L253.001 249L261 252.436L212.998 261Z"
                      fill="black"
                    />
                    <path
                      d="M190.819 264.635L190.471 265.463L202.138 270.609L202.486 269.78L190.819 264.635Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M196.103 279.517L195.754 280.346L207.421 285.491L207.769 284.663L196.103 279.517Z"
                      fill="white"
                    />
                    <path
                      d="M87.6289 197.767H83.7184C83.5809 197.767 83.449 197.821 83.3518 197.916C83.2546 198.011 83.2 198.141 83.2 198.276V199.959C83.2 200.026 83.2134 200.092 83.2394 200.154C83.2655 200.215 83.3036 200.272 83.3518 200.319C83.3999 200.366 83.4571 200.403 83.52 200.429C83.5829 200.455 83.6503 200.468 83.7184 200.468H84.3664V202.99H86.9808V200.468H87.6289C87.7664 200.468 87.8982 200.414 87.9954 200.319C88.0927 200.223 88.1473 200.094 88.1473 199.959V198.276C88.1473 198.141 88.0927 198.011 87.9954 197.916C87.8982 197.821 87.7664 197.767 87.6289 197.767Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M91.5458 213.176C89.1509 209.738 87.9266 205.645 88.0482 201.484C88.0504 201.388 88.0186 201.294 87.9583 201.218C87.898 201.142 87.8128 201.09 87.7173 201.069V200.341H83.5873V201.058H83.5304C83.4747 201.059 83.4196 201.069 83.3683 201.09C83.3169 201.111 83.2702 201.142 83.2309 201.181C83.1916 201.219 83.1604 201.265 83.1392 201.316C83.118 201.366 83.1071 201.42 83.1072 201.475C83.1072 201.485 83.1075 201.495 83.1083 201.505C83.4895 205.752 82.3215 209.993 79.8123 213.474C79.6671 213.677 79.593 213.92 79.6009 214.168L80.0525 227.87C80.0634 228.176 80.1942 228.466 80.4175 228.679C80.6409 228.892 80.9397 229.013 81.2514 229.015H90.5928C90.9081 229.013 91.21 228.889 91.4341 228.672C91.6581 228.454 91.7867 228.159 91.7924 227.85L91.9665 214.546C91.9716 214.058 91.8248 213.58 91.5458 213.176Z"
                      fill="#FF3055"
                    />
                    <path
                      opacity="0.2"
                      d="M86.8137 198.316C86.8137 198.607 86.6959 198.886 86.4861 199.091C86.2763 199.297 85.9918 199.413 85.6952 199.413C85.3985 199.413 85.114 199.297 84.9043 199.091C84.6945 198.886 84.5767 198.607 84.5767 198.316"
                      fill="black"
                    />
                    <path
                      d="M89.7263 215.816H81.8406V220.972H89.7263V215.816Z"
                      fill="white"
                    />
                    <path
                      opacity="0.2"
                      d="M209.851 252.323C209.851 252.868 209.631 253.39 209.238 253.775C208.845 254.16 208.313 254.377 207.757 254.377C207.202 254.377 206.67 254.16 206.277 253.775C205.884 253.39 205.664 252.868 205.664 252.323"
                      fill="black"
                    />
                    <path
                      d="M12.0164 131.99L21.8118 131.116L28.0456 116.267L16.9143 110.152L12.0164 131.99Z"
                      fill="#FFB8B8"
                    />
                    <path
                      d="M101.914 172.756C102.24 171.959 102.368 171.097 102.288 170.243C102.207 169.388 101.921 168.564 101.453 167.839C100.984 167.114 100.347 166.508 99.5927 166.072C98.8388 165.636 97.99 165.382 97.1164 165.331L81.9073 164.442L79.668 171.746L95.3249 175.964C96.6332 176.316 98.028 176.184 99.2433 175.592C100.459 175.001 101.409 173.991 101.914 172.756Z"
                      fill="#FFB8B8"
                    />
                    <path
                      d="M85.5832 176.086L85.4158 162.38L51.633 157.093L25.6074 127.814C24.2533 126.291 22.3822 125.3 20.3408 125.027C18.2993 124.753 16.2259 125.214 14.5046 126.325C13.4624 126.997 12.5835 127.886 11.9301 128.929C11.2766 129.971 10.8646 131.142 10.723 132.357C10.5814 133.573 10.7138 134.805 11.1108 135.965C11.5078 137.125 12.1598 138.185 13.0205 139.071L34.4915 164.989L85.5832 176.086Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M4.44702 330.275H13.3521L35.4401 232.317L58.3228 262.578L71.6805 328.091H80.1403L78.3593 257.774L48.5273 182.653H19.1404C11.1098 196.909 4.81005 211.605 12.0164 226.765L4.44702 330.275Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M66.2215 336.225C66.123 336.534 66.0928 336.86 66.1329 337.181C66.1729 337.502 66.2823 337.812 66.4538 338.089C66.6252 338.365 66.8548 338.603 67.1272 338.786C67.3996 338.969 67.7087 339.092 68.0337 339.149L89.8245 342.942C90.6856 343.097 91.5745 342.937 92.3238 342.493C93.073 342.049 93.6307 341.351 93.8918 340.532C94.1458 339.749 94.1103 338.903 93.7916 338.143C93.4729 337.383 92.8914 336.757 92.1492 336.376C87.3578 333.963 83.2343 330.446 80.1319 326.128C77.7369 328.315 75.0601 328.109 72.2176 326.497L69.2027 326.867L66.2215 336.225Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M0.323814 336.225C0.225352 336.534 0.195135 336.86 0.235185 337.181C0.275235 337.502 0.384626 337.812 0.556078 338.089C0.727529 338.365 0.957109 338.603 1.22953 338.786C1.50194 338.969 1.81095 339.092 2.13597 339.149L23.9268 342.942C24.7879 343.097 25.6768 342.937 26.4261 342.493C27.1753 342.049 27.733 341.351 27.9941 340.532C28.2481 339.749 28.2126 338.903 27.8939 338.143C27.5752 337.383 26.9937 336.757 26.2515 336.376C21.4602 333.963 17.3366 330.446 14.2342 326.128C11.8392 328.315 9.16242 328.109 6.31992 326.497L3.30496 326.867L0.323814 336.225Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M28.2682 121.726C35.1536 121.726 40.7353 116.251 40.7353 109.497C40.7353 102.744 35.1536 97.2684 28.2682 97.2684C21.3828 97.2684 15.801 102.744 15.801 109.497C15.801 116.251 21.3828 121.726 28.2682 121.726Z"
                      fill="#FFB8B8"
                    />
                    <path
                      d="M17.1367 185.929L49.6403 185.055L40.0947 155.586C46.0268 146.258 40.0566 136.939 31.6075 127.623L24.9286 123.255L14.2425 122.382C9.61584 127.238 7.08427 133.662 7.17805 140.308C7.38964 154.539 9.95862 170.032 17.1367 185.929Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M91.8152 205.765C92.4342 205.158 92.8974 204.415 93.1664 203.598C93.4355 202.781 93.5027 201.913 93.3628 201.066C93.2228 200.219 92.8795 199.416 92.3612 198.724C91.8429 198.033 91.1645 197.472 90.3818 197.087L76.7558 190.401L71.7676 196.252L84.4706 206.171C85.5321 207 86.8674 207.417 88.222 207.342C89.5766 207.267 90.8557 206.705 91.8152 205.765Z"
                      fill="#FFB8B8"
                    />
                    <path
                      d="M75.465 202.525L80.8081 189.859L51.8697 171.962L39.6846 135.002C39.0506 133.079 37.7275 131.446 35.9605 130.407C34.1935 129.367 32.1023 128.991 30.0743 129.348C28.8464 129.564 27.6819 130.043 26.663 130.749C25.6442 131.455 24.796 132.372 24.1782 133.435C23.5605 134.498 23.1884 135.682 23.0881 136.901C22.9878 138.121 23.1619 139.347 23.5981 140.494L32.9433 172.608L75.465 202.525Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M7.78638 104.693C7.78638 109.451 10.9368 116.821 14.9105 119.979C17.5841 122.105 21.1174 122.015 22.4798 119.106C23.8773 116.121 28.6945 109.106 37.1732 104.693C48.4758 98.8103 33.1649 88.7457 22.4798 90.2804C14.4505 91.4336 7.78638 96.7332 7.78638 104.693Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M15.3558 98.1419C20.2739 98.1419 24.2609 94.2311 24.2609 89.4069C24.2609 84.5827 20.2739 80.6719 15.3558 80.6719C10.4376 80.6719 6.45068 84.5827 6.45068 89.4069C6.45068 94.2311 10.4376 98.1419 15.3558 98.1419Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M25.5966 86.3496C25.5957 84.1475 24.7463 82.027 23.2188 80.4132C21.6912 78.7994 19.5982 77.8116 17.3594 77.6477C17.5806 77.6315 17.8018 77.6146 18.0273 77.6146C20.389 77.6146 22.6541 78.5349 24.3241 80.173C25.9941 81.8112 26.9323 84.033 26.9323 86.3496C26.9323 88.6663 25.9941 90.8881 24.3241 92.5262C22.6541 94.1643 20.389 95.0846 18.0273 95.0846C17.8018 95.0846 17.5806 95.0677 17.3594 95.0515C19.5982 94.8877 21.6911 93.8998 23.2187 92.286C24.7463 90.6723 25.5957 88.5518 25.5966 86.3496Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M28.4907 113.865C29.5973 113.865 30.4943 112.692 30.4943 111.244C30.4943 109.797 29.5973 108.624 28.4907 108.624C27.3841 108.624 26.4871 109.797 26.4871 111.244C26.4871 112.692 27.3841 113.865 28.4907 113.865Z"
                      fill="#FFB8B8"
                    />
                    <path
                      d="M280.8 314.256H142.176L112.82 178.136H87.7678V172.028H117.868L147.223 308.147H280.8V314.256Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M162.49 339.123C169.859 339.123 175.833 333.263 175.833 326.035C175.833 318.806 169.859 312.946 162.49 312.946C155.121 312.946 149.147 318.806 149.147 326.035C149.147 333.263 155.121 339.123 162.49 339.123Z"
                      fill="black"
                    />
                    <path
                      d="M262.12 339.123C269.489 339.123 275.463 333.263 275.463 326.035C275.463 318.806 269.489 312.946 262.12 312.946C254.751 312.946 248.777 318.806 248.777 326.035C248.777 333.263 254.751 339.123 262.12 339.123Z"
                      fill="black"
                    />
                    <path
                      d="M278.273 311.379H143.399L119.602 196.023H300.605C301.529 196.024 302.441 196.22 303.28 196.599C304.119 196.978 304.864 197.531 305.465 198.219C306.065 198.908 306.506 199.716 306.758 200.587C307.009 201.459 307.065 202.374 306.922 203.269L284.589 306.081C284.359 307.56 283.594 308.909 282.435 309.881C281.275 310.854 279.798 311.385 278.273 311.379ZM144.863 309.634H278.273C279.374 309.639 280.44 309.255 281.276 308.553C282.113 307.851 282.665 306.877 282.831 305.81L305.164 202.998C305.268 202.352 305.227 201.692 305.046 201.062C304.864 200.433 304.546 199.85 304.113 199.353C303.679 198.856 303.141 198.457 302.536 198.184C301.93 197.91 301.272 197.768 300.605 197.769H121.761L144.863 309.634Z"
                      fill="black"
                    />
                    <path
                      d="M169.619 311.01L154.034 197.038L155.789 196.754L171.374 310.726L169.619 311.01Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M256.274 310.135L254.518 309.856L269.647 196.756L271.404 197.035L256.274 310.135Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M214.988 196.896H213.208V310.868H214.988V196.896Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M128.69 227.436L128.685 229.181L300.9 229.59L300.904 227.845L128.69 227.436Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M288.751 281.344L137.457 282.359L137.469 284.104L288.764 283.089L288.751 281.344Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M90.4367 179.445C95.8408 179.445 100.222 177.101 100.222 174.209C100.222 171.318 95.8408 168.974 90.4367 168.974C85.0325 168.974 80.6516 171.318 80.6516 174.209C80.6516 177.101 85.0325 179.445 90.4367 179.445Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M214.419 172.285C239.504 172.285 259.838 151.403 259.838 125.643C259.838 99.8826 239.504 79 214.419 79C189.335 79 169 99.8826 169 125.643C169 151.403 189.335 172.285 214.419 172.285Z"
                      fill="#34C700"
                    />
                    <path
                      d="M209.918 143.435C208.897 143.437 207.902 143.098 207.084 142.468L207.034 142.429L196.36 134.044C195.865 133.655 195.45 133.169 195.139 132.615C194.827 132.061 194.625 131.45 194.543 130.816C194.461 130.182 194.502 129.537 194.663 128.919C194.824 128.301 195.102 127.722 195.481 127.214C195.86 126.706 196.333 126.28 196.872 125.96C197.412 125.64 198.007 125.432 198.625 125.349C199.242 125.265 199.87 125.307 200.471 125.472C201.073 125.637 201.637 125.923 202.132 126.312L209.046 131.757L225.383 109.868C225.762 109.361 226.235 108.935 226.774 108.615C227.313 108.295 227.908 108.087 228.525 108.003C229.143 107.92 229.77 107.962 230.372 108.127C230.973 108.292 231.537 108.577 232.031 108.966L232.033 108.967L231.931 109.112L232.035 108.967C233.032 109.754 233.684 110.915 233.849 112.195C234.013 113.475 233.677 114.77 232.912 115.796L213.696 141.53C213.251 142.123 212.679 142.603 212.025 142.932C211.371 143.262 210.651 143.432 209.923 143.43L209.918 143.435Z"
                      fill="white"
                    />
                    <path
                      d="M110.646 46.8112C115.973 46.8112 120.292 42.3763 120.292 36.9056C120.292 31.4349 115.973 27 110.646 27C105.319 27 101 31.4349 101 36.9056C101 42.3763 105.319 46.8112 110.646 46.8112Z"
                      fill="#FF3055"
                      fill-opacity="0.24"
                    />
                    <path
                      d="M242.881 12.0785C246.129 12.0785 248.762 9.37465 248.762 6.03926C248.762 2.70387 246.129 0 242.881 0C239.633 0 237 2.70387 237 6.03926C237 9.37465 239.633 12.0785 242.881 12.0785Z"
                      fill="#FF3055"
                    />
                    <path
                      d="M286.881 103.079C290.129 103.079 292.762 100.375 292.762 97.0393C292.762 93.7039 290.129 91 286.881 91C283.633 91 281 93.7039 281 97.0393C281 100.375 283.633 103.079 286.881 103.079Z"
                      fill="#FF3055"
                      fill-opacity="0.59"
                    />
                  </svg>
                </div>
                <div className="">
                  <h3
                    style={{ marginBottom: "1rem", marginTop: "1.5rem" }}
                    className="second-heading"
                  >
                    Order Placed !
                  </h3>
                  <p className="para">
                    Your order will be delivered soon,{" "}
                    <span className="highlight-text">
                      {" "}
                      Thank you for choosing Us !
                    </span>
                  </p>

                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/">
                      <button color="primary" className={`btn btn-primary `} style={{backgroundColor:"rgb(244, 121, 44)"}}>
                        Continue shopping
                      </button>
                    </Link>

                    <Link to="/my-profile/orders">
                      <button
                        color="primary"
                        style={{
                          background: "#2F4799",
                          marginLeft: "1rem",
                          border: "none",
                        }}
                        className={`btn btn-primary `}
                      >
                        View Order
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Line19 = styled.img`
  width: 90%;
  height: 1px;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const OverlapGroup3 = styled.div`
  width: 818px;
  height: 570px;
  top: 1px;
  left: 243px;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
  top: 222px;
  left: 731px;
`;

const Group37022 = styled.div`
  height: 570px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 818px;
  gap: 35px;
`;

const Line21 = styled.img`
  width: 1px;
  height: 570px;
  margin-left: -1px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  width: 783px;
  height: 268px;
  position: relative;
  margin-top: 36px;
  border-radius: 8.56px;
`;

const Group37002 = styled.div`
  cursor: pointer;
  top: 0;
  left: 0;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  display: flex;
  justify-content: space-between;
  padding: 15px 19px;
  align-items: center;
  gap: 8px;

  border-radius: 8.56px;
  border: 1px solid;
  border-color: var(--alto);
  box-shadow: 0px 0px 5px #00000014;
`;

const RadioButtons = styled.img`
  width: 24px;
  height: 24px;
`;

const NameContainer = styled.div`
  width: 94px;
  height: 44px;
  position: relative;
  margin-top: 0.26px;
`;

const Name = styled.div`
  ${ValignTextMiddle}
  height: 22px;
  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 700;
  color: #00b41d;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Name1 = styled.div`
  ${ValignTextMiddle}
  height: 22px;
  top: 22px;
  left: 3px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--fuscous-gray);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 21.4px;
  white-space: nowrap;
`;

const Group36968 = styled.div`
  width: 111px;
  height: 154px;
  top: 76px;
  left: 23px;
  background-size: 100% 100%;
`;

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 11px 30px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid;
  border-color: var(--sizzling-red);
`;

const Return = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-lato);
  font-weight: 400;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Rating5Stars = styled.img`
  width: 204px;
  height: 31px;
  top: 203px;
  left: 291px;
`;

const UrbanoFashion = styled.div`
  ${ValignTextMiddle}
  height: 32px;
  top: 71px;
  left: 161px;
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const MenBlackSlimFitM = styled.p`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
  height: 32px;
  top: 97px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const RateThisProduct = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalFuscousGray16px}
  height: 32px;
  top: 203px;
  left: 161px;
  letter-spacing: 0;
  line-height: 31.5px;
  white-space: nowrap;
`;

const OverlapGroup2 = styled.div`
  width: 173px;
  height: 570px;
  top: 1px;
  left: 70px;
`;

const Line211 = styled.img`
  width: 1px;
  height: 570px;
  top: 0;
  left: 172px;
  object-fit: cover;
`;

const Frame37023 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 27px;
  position: absolute;
  top: 24px;
  left: 0;
`;

const Frame37022 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
`;

const ORDERS = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray14px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const OrdersReturn = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Line20 = styled.img`
  position: relative;
  min-width: 173px;
  height: 1px;
  object-fit: cover;
`;

const Profile = styled.div`
  ${ValignTextMiddle}
  ${AzosansRegularNormalFuscousGray16px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const DeerikaWallet = styled.div`
  ${ValignTextMiddle}
  position: relative;
  width: fit-content;
  font-family: var(--font-family-azo_sans-medium);
  font-weight: 500;
  color: var(--sizzling-red);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

export default CartOrder;

// max-width: 300px;
//     max-height: 162px;
//     border: ridge;
//     margin: 0 auto;
