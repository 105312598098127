import { dev } from "../../../api/baseurl";
import { getShopItemApi } from "../../../api/items";

// method to fetch filtered products data
export const getProductsDataApi = async (
    category,
    subCategoryArr,
    rangeArr, 
    closureArr,
    fabricArr,
    fitArr,
    lengthArr,
    neckArr,
    occasionArr,
    patternArr,
    sleevesArr,
    sizeArr, 
    page,sort
  ) => {
        const data1 = {
          subcategory: category || '',
          sap_class: subCategoryArr.length !== 0 ? subCategoryArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          size: sizeArr.length !== 0 ? sizeArr.reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          min_price: rangeArr[0],
          max_price: rangeArr[1],

          closure: closureArr.length !== 0 ? closureArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          fabric: fabricArr.length !== 0 ? fabricArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          fit: fitArr.length !== 0 ? fitArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          length: lengthArr.length !== 0 ? lengthArr.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',

          neck: neckArr.length !== 0 ? neckArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          occasion: occasionArr.length !== 0 ? occasionArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          pattern: patternArr.length !== 0 ? patternArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',
          sleeves: sleevesArr.length !== 0 ? sleevesArr?.map( (val) => val.substring( 0, val.lastIndexOf(" (") ) ).reduce((acc, val, i) => acc += `${i !== 0 ? ',' : ''}${val}`, '') : '',


          page_size: 12,
          page_num: page,
          sort_type: sort ? sort.split("/")[1] : 'price',
          sort_value: sort ? sort.split("/")[0] : 'ASC',
        };
        const data = await dev.get("/item/believe/", {
          params: data1,
        });
      
        return data;
}


// method to get all filters from backend
export const getAllFiltersApi = async () => await dev.get("/item/filter/fashion");

export const getCheckboxItemList = (sizeArr) => {
  return sizeArr?.map((item) => {
    const size = Object.keys(item);

    return {
      label: size[0] + ' (' + item[size[0]] + ')',
      value: size[0],
    }
  })
}


// All product Api rendered first time
export const getAllProductsApi = async (params, setProductsData, setPageCount, api, setLoader) => {
  try {
    setLoader(true);
    setProductsData([]);

    const data = await getShopItemApi(Number(params.get('page')) || 1);

    if( data.data.success ) {
      setProductsData(data.data.data.items)
      setPageCount(data.data.data.page_count)
    }
    else {
      api.error({
        message: data.data.message,
        placement: 'top'
      })
    }
    setLoader(false);
  }
  catch (err) {
    setLoader(false);
    api.error({
      message: 'Something went wrong!',
      description: 'Please check your Internet Connection.',
      placement: 'top'
    })
  }

}

export const getProductsData = async (params, setProductsData, setPageCount, api, setLoader) => {
  try {
    setLoader(true);
    setProductsData([]);

    const data = await getProductsDataApi(
      params.get('category'),
      params.getAll('subCategory'),
      [params.get('min'), params.get('max')],

      params.getAll('closure'),
      params.getAll('fabric'),
      params.getAll('fit'),
      params.getAll('length'),
      params.getAll('neck'),
      params.getAll('occasion'),
      params.getAll('pattern'),
      params.getAll('sleeves'),

      params.getAll('size'),
      params.get('page'),
      params.get('sort')
    );

    if( data.data.success ) {
      setProductsData(data.data.data.items)
      setPageCount(data.data.data.page_count)
    }
    else {
      api.error({
        message: data.data.message,
        placement: 'top'
      })
    }
    setLoader(false);

  }
  catch (err) {
    setLoader(false);
    api.error({
      message: 'Something went wrong!',
      description: 'Please check your Internet Connection.',
      placement: 'top'
    })
  }

}



export const handleCategoryChange = (keys, params, history, selectedCategory, setCheckedKeys) => {
  if ( keys.length !== 0 ) {
    let isCategoryChanged = false;

    for ( let i = 0; i < keys.length; i++ ) {
      if ( keys[i].split('_')[0] !== selectedCategory ) {
        isCategoryChanged = true;
        break;
      }
    }

    if ( isCategoryChanged ) {
      const latestCheckedKeys = keys.filter((key) => key.split('_')[0] !== selectedCategory)
      const subCategories = latestCheckedKeys.filter((key) => key.split('_')[1].length !== 0)?.map((key) => key.split('_')[1]);
      // setSelectedCategory(latestCheckedKeys[0].split('_')[0]);
      // setSelectedSubCategory(latestCheckedKeys.filter((key) => key.split('_')[1].length !== 0).map((key) => key.split('_')[1]))
      
      
      params.delete('subCategory');
      subCategories.forEach((subCategory) => {
        params.append('subCategory', subCategory)
      });
      params.set('category', latestCheckedKeys[0].split('_')[0]);
      params.set('page', 1);


      history.push({search: params.toString()});
      // setCheckedKeys(latestCheckedKeys);
    }
    else {
      // setSelectedSubCategory(latestCheckedKeys.filter((key) => key.split('_')[1].length !== 0).map((key) => key.split('_')[1]))
      // setCheckedKeys(keys);
      
      const subCategories = keys.filter((key) => key.split('_')[1].length !== 0)?.map((key) => key.split('_')[1]);
      
      
      params.delete('subCategory');
      subCategories.forEach((subCategory) => {
        params.append('subCategory', subCategory)
      });
      params.set('page', 1);


      history.push({search: params.toString()});
    }
  }
}

export const handleRangeChange = (value, params, history) => {
  params.set('min', value[0]);
  params.set('max', value[1]);
  params.set('page', 1);

  history.push({search: params.toString()});
};

// runs on every page change
export const handlePageChange = () => {
}



export const tempList = (categoryArr, categoryCount, subCategories) => {
 return categoryArr?.map((category) => {
  return {
    title: category + ' (' + categoryCount[category] + ')',
    key: category + '_',
    children: subCategories[category]?.map((subCategoryObj) => {
      const subCategory = Object.keys(subCategoryObj)[0] + ' (' + Object.values(subCategoryObj)[0] + ')'; 
      return {
        title: subCategory,
        key: category + '_' + subCategory
      }
    })
  }
 })
}

export const categoryFilterList =  [
    {
      title: '0-0',
      key: '0-0',
      children: [
        {
          title: '0-0-0',
          key: '0-0-0',
        },
        {
          title: '0-0-1',
          key: '0-0-1',
        },
        {
          title: '0-0-2',
          key: '0-0-2',
        },
      ],
    },
    {
      title: '0-1',
      key: '0-1',
      children: [
        {
          title: '0-1-0-0',
          key: '0-1-0-0',
        },
        {
          title: '0-1-0-1',
          key: '0-1-0-1',
        },
        {
          title: '0-1-0-2',
          key: '0-1-0-2',
        },
      ],
    },
    {
      title: '0-2',
      key: '0-2',
    },
  ];