import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination } from "antd";

import { InterNormalStormDust15px, ValignTextMiddle } from "../styledMixins";
import "./Shop/Shop.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Pagination({ length, active, onSelect }) {
  const next = active + 1;
  const prev = active - 1;
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of items to display per page

  //
  // Handle page change

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <UlPageNumbers style={{ border: "none", justifyContent: "center" }}>
      {active > 1 && (
        <div
          className="is_next"
          style={{ marginTop: "3px", cursor: "pointer" }}
          onClick={() => onSelect(active - 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>
      )}

      {Array.from({ length }, (_, index) => index + 1).map((item) => {
        if (item > active + 5 || item < active - 5) return;
        if (item == active)
          return (
            <div key={item} className="pagination_item active_pagination">
              {item}
            </div>
          );
        return (
          <div
            key={item}
            className="pagination_item"
            onClick={() => onSelect(item)}
          >
            {item}
          </div>
        );
      })}

      {/* {active > 6 && (
        <>
          <Number1 onClick={() => onSelect(1)}>1</Number1>
          <Number1>...</Number1>
        </>
      )} */}
      {/* {active > 1 && (
        <div style={{ display: "flex" }}>
          <Number1
            onClick={() => onSelect(prev)}
            style={{ marginRight: "1.6rem" }}
          >
            {prev}
          </Number1>
        </div>
      )} */}
      {/* <SpanPageNumbers>
        <Number>{active}</Number>
      </SpanPageNumbers> */}
      {/* {length > currentPage &&
        currentPageItems.map((number) => (
          <div>
            {(active === parseInt(number) && (
              <SpanPageNumbers>
                <Number>{number}</Number>
              </SpanPageNumbers>
            )) || <Number1 onClick={() => onSelect(next)}>{number}</Number1>}
          </div>
        ))} */}
      {/* {length > 1 && active !== length && (
        <Number1 onClick={() => onSelect(active + 2)}>{length}</Number1>
      )} */}
      {/* {active !== length - 1 && active !== length && (
        <>
          <Number1>{next + 1}</Number1>
          <Number1 onClick={() => onSelect(length)}>{next + 2}</Number1>
        </>
      )} */}
      {/* <button
        style={{ marginLeft: "5rem" }}
        
        disabled={currentPage === 1}
      >
        Previous
      </button> */}
      {/* Render page buttons */}
      {/* {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          style={{ fontWeight: currentPage === index + 1 ? "bold" : "normal" }}
        >
          {index + 1}
        </button>
      ))} */}
      {/* Render next button */}
      {length > 1 && (
        <>
          {active !== length && (
            <span onClick={() => onSelect(active + 1)} className="is_next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </span>
          )}
        </>
      )}
    </UlPageNumbers>
  );
}

const UlPageNumbers = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding-top: 2rem;
`;
const SpanPageNumbers = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  background-color: #2f4799;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  cursor:pointer;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  color: var(--lg);
  font-size: 15px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Frame10 = styled.img`
  width: 18px;
  height: 48px;
  margin-left: 50px;
`;
const Number1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalStormDust15px}
  height: 21px;
  margin-bottom: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
  cursor: pointer;
`;
