import React from "react";
import Spanbox from "../Spanbox";
import styled from "styled-components";


class Size extends React.Component {
  render() {
    const { className, spanboxProps } = this.props;

    return (
      <Size1 className={`size ${className || ""}`}>
        <Spanbox size30M={spanboxProps.size30M} className={spanboxProps.className} />
      </Size1>
    );
  }
}

const Size1 = styled.div`
  position: absolute;
  height: 37px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 128px;

  &.size.qty {
    left: 142px;
  }
`;

export default Size;
