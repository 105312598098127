import React from "react";
import Title32 from "../Title32";
import Buttons2 from "../Buttons2";
import styled from "styled-components";


class TheBanner extends React.Component {
  render() {
    const { theBanner, title32Props, buttons2Props } = this.props;

    return (
      <TheBanner1 className="lng-banner" style={{ backgroundImage: `url(${theBanner})` }}>
        <AutoLayoutVertical>
          <Title32 ourBest={title32Props.ourBest} forWomens={title32Props.forWomens} />
          <Buttons2 group3Props={buttons2Props.group3Props} />
        </AutoLayoutVertical>
      </TheBanner1>
    );
  }
}

const TheBanner1 = styled.div`
   margin-left:181px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 993px;
  align-items: flex-start;
  padding: 110px 25px;
  position: relative;
  background-size: cover;
  background-position: 50% -4%;
`;

const AutoLayoutVertical = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 191px;
  align-items: flex-start;
  gap: 28px;
  position: relative;
`;

export default TheBanner;
