import { Button, Form, Input, Modal, Rate, Space } from 'antd'
import React from 'react'

export default function AddProductReview() {
  return (
    <Modal className='add-review-modal' open={true} footer={<Space>
        <Button>Cancel</Button>
        <Button style={{ backgroundColor: '#f4792c', color: 'white' }}>Submit!</Button>
      </Space>
    }>
      <h1 style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 20 }}>Add Product Review</h1>
      <Form layout='vertical'>
        <Form.Item name='title' label='Title'>
          <Input placeholder='Enter Review Title' />
        </Form.Item>
        <Form.Item name='description' label='Description'>
          <Input.TextArea rows={4} placeholder='Describe your experience ...' />
        </Form.Item>
        <Form.Item name='rating' label='Rating'>
          <Rate allowHalf style={{ fontSize: 40 }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
