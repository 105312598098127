import { dev } from "./baseurl";

export async function CashDeliveryApi(wallet) {
  const data = await dev.post("/hob/cod/", {
    wallet
  });
  return data;
}

// export const function CashDeliveryApi() {
//   const data = await dev.put()
// }

export async function verifyCashDeliveryApi(otp) {
  const data = await dev.post("/hob/cod/confirm/", {
    pin: parseInt(otp),
  });
  return data;
}

export async function addBankAccountApi(
  ifsc,
  accountNumber,
  accountHolderName
) {
  // SBIN0011214;
  // USER;
  // 0987654321675439;
  const data = await dev.post("/orders/add-bank-details/", {
    ifsc_code: ifsc,
    account_name: accountHolderName,
    account_number: accountNumber,
  });
  return data;
}

export async function getBankDetailApi() {
  // SBIN0011214;
  // USER;
  // 0987654321675439;
  const data = await dev.get("/orders/bank-details/");
  return data;
}
