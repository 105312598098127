import React from "react";
import Group from "../Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { Input, Row } from "antd";
import { RxCross2 } from 'react-icons/rx'

class Header4 extends React.Component {
  render() {
    const { className } = this.props;


    const handleCloseSearch = () => {
      const temp = this?.props?.state;
      temp.searchToggle = false;
      this.props.setState(temp);
    }


    return (
      <>
       <Header className={`header ${className || ""}`} style={{position: "fixed", top: "0", left: "0", zIndex: "1000"}}>
        <Link to="/">
          <Image className="image hlogo" src="/img/hbLogo.png" alt="Image" />
        </Link>

        <Group  state={this?.props?.state}  setState={this?.props?.setState} />
      </Header>
    
    </>

    );
  }
}

const Header = styled.header`
  width: 100%;
  position: relative;
  flex-shrink: 1;
  margin-left: 0.5px;
  display: flex;
  flex-direction: row;
  padding: 0 18px 0 0;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  background-color: var(--lg);
  box-shadow: 0px 4px 30px #00000036;
`;

const Image = styled.img`
  width: 197px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  object-fit: contain;
`;

export default Header4;
