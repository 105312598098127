import { Button, Modal, Spin } from "antd";

import { api } from "../api/baseurl";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import axios from "axios";
import {
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
import { Button, Divider, notification, Space } from "antd";
// import type { NotificationPlacement } from "antd/es/notification/interface";

const Context = React.createContext({ name: "Default" });

import { sendOTP, verifyOtp, sendOtpAgain } from "../api/login";
// import Notification from "./Notification";
import { getWalletApi } from "../api/wallet";

// import { sendMobileOtp, verifyOtp } from "../../api/loginApi";
import { useHistory } from "react-router-dom";
import { accountDetailApi } from "../api/account";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import login from "../../images/login.jpg";
// import { Divider } from "antd";
import { Link } from "react-router-dom";

const Login = ({ isOpen, setIsOpen }) => {
  const [api, contextHolder] = notification.useNotification();

  const Ref = useRef(null);
  const [mobile, setMobile] = useState("");
  const [resended, setResended] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [isotp, setIsOTp] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const [otpPage, setOtpPage] = useState(false);

  const [isis, setIsis] = useState(false);

  const [otp, setOtp] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let history = useHistory();

  const [time, setTime] = useState(60);
  const [isExpire, setIsExpire] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (timerStarted) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time <= 0) {
      setIsExpire(true);
      clearInterval(interval);
      setTimerStarted(false);
      setIsMobile(true);
      setErrorMessage("");
    }

    return () => clearInterval(interval);
  }, [timerStarted, time]);

  const seconds = time % 60;
  const minutes = Math.floor(time / 60) % 60;

  const startTimer = () => {
    setTime(60);
    setTimerStarted(true);
  };

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button

  const handleMobileDisabled = (e) => {
    if (e.target.value.length > 10) {
      setPhoneDisabled(true);
    } else if (e.target.value.length === 10) {
      // setPhoneDisabled(true);
      setIsMobile(false);
    } else if (e.target.value.length < 10) {
      setIsMobile(true);
    }
  };

  const handleReset = () => {
    setErrorMessage("");
    setOtpPage(false);
    setIsExpire(false);
    setIsMobile(false);
    setOtp("");
    setResended(true);
  };

  const fetchWareHouse = async () => {
    const { data } = await getWherehouseApi(latitude, longitude);

    if (data.success) {
      const wherehouseId = data.data.warehouse_id;
      Cookies.set("warehouse_id", wherehouseId);
    }
  };

  const SendOtp = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (otpPage) {
      if (otp === "") {
        setIsOTp(true);
      } else {
        setIsLoading(true);
        const { data } = await verifyOtp(mobile, otp);
        setIsLoading(false);

        if (data.success === true) {
          notification.success({
            message: data.message,
            duration: 1,
            placement: "bottom",
          });
          setOtp("");
          setIsOpen(false);
          setOtpPage(false);
          localStorage.setItem("login", JSON.stringify(data.data));
          const profileDetail = await accountDetailApi(data?.data?._id);
          const wallet = await getWalletApi();

          if (wallet.data.success) {
            notification.success({
              message: wallet.data.message,
            });
            localStorage.setItem("wallet", JSON.stringify(wallet.data.data));
          } else {
            history.push("/");
            notification.error({
              message: wallet.data.message,
            });
          }

          if (profileDetail.data.success) {
            if (
              profileDetail?.data?.data?.name === "" &&
              profileDetail?.data?.data?.email === ""
            ) {
              setIsOpen(false);
              setOtpPage(false);
              setErrorMessage(false);
              history.push({
                pathname: `/my-profile?isEdit`,
                id: data?.data?._id,
              });
              window.location.reload(false);
            } else {
              setOtpPage(false);
              setIsOpen(false);
              history.push(location.pathname + location.search);
              setErrorMessage(false);
              window.location.reload(false);
            }
          }

          // getting wallet data and store in localstorage

          //   localStorage.setItem("username", data.data.name);
          //   localStorage.setItem("role", data.data.role);
          //   localStorage.setItem("designation", data.data.designation);
        } else {
          notification.error({
            message: data.message,
            duration: 1,
            placement: "bottom",
          });

          setErrorMessage(data.message);
        }
      }
    } else if (resended) {
      setIsExpire(false);
      setIsLoading(true);
      const { data } = await sendOtpAgain(mobile);
      setIsLoading(false);
      if (data.success === true) {
        notification.success({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });

        startTimer();
        setOtpPage(true);
        setErrorMessage("");
        setResended(false);
      } else {
        notification.error({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });
        setErrorMessage(data.message);
      }
    } else {
      setIsExpire(false);
      setIsLoading(true);
      const { data } = await sendOTP(mobile);
      setIsLoading(false);
      if (data.success === true) {
        notification.success({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });
        startTimer();
        setOtpPage(true);
        setIsMobile(true);
        setErrorMessage("");
      } else {
        notification.error({
          message: data.message,
          duration: 1,
          placement: "bottom",
        });
        setErrorMessage(data.message);
      }
    }
  };
  const location = useLocation();

  const handleCancel = () => {
    setIsOpen(false);
    setOtpPage(false);
    setIsMobile(false);
    history.push(location.pathname + location.search);
  };

  return (
    <>
      <Modal width={800} onCancel={handleCancel} footer={[""]} open={isOpen}>
        <div className="login-box">
          <div className="d-flex justify-content-center">
            <div className="w-100">
              <div className="">
                {/* <divHeader className="bg-transparent ">
                <div className="text-muted text-center ">
                  <small>Sign in with</small>
                </div>
              </divHeader> */}
                <div className="">
                  <h3
                    // style={{ marginBottom: "1rem" }}
                    className="second-heading"
                  >
                    Login
                  </h3>

                  <form onSubmit={SendOtp} role="form">
                    <div style={{ textAlign: "center" }} className="mt-2">
                      {(otpPage && (
                        <>
                          <p className="para">
                            {" "}
                            {(isExpire && (
                              <span>
                                OTP Expired &nbsp;
                                <span
                                  onClick={handleReset}
                                  style={{ fontWeight: 500, fontSize: "16px" }}
                                  className="link-para"
                                >
                                  {" "}
                                  Resend Otp
                                </span>
                              </span>
                            )) || (
                              <span style={{ color: "FF3055" }}>
                                verify using OTP, valid Till 
                                {minutes.toString().padStart(2, "0")}:
                                {seconds.toString().padStart(2, "0")}
                              </span>
                            )}
                          </p>

                          <div
                            style={{ marginTop: "1rem" }}
                            className={isotp ? "has-danger" : ""}
                          >
                            {/* <label for="otp">Enter OTP</label> */}
                            <input
                              id="otp"
                              className="input-group-alternative"
                              value={otp}
                              disabled={isExpire}
                              placeholder="Enter OTP"
                              type="number"
                              autoComplete="new-email"
                              onChange={(e) => (
                                otp.length === 3 && setIsMobile(false),
                                setOtp(e.target.value),
                                setErrorMessage("")
                              )}
                              onKeyDown={(event) => {
                                if (
                                  event.keyCode === 38 ||
                                  event.keyCode === 40
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {errorMessage !== "" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ alignSelf: "center" }}>
                                  <p style={{ color: "red" }}>
                                    <small>
                                      {errorMessage !== "" && errorMessage}
                                    </small>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    alignSelf: "center",
                                    marginLeft: "1rem",
                                  }}
                                >
                                  <span
                                    onClick={handleReset}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "16px",
                                    }}
                                    className="link-para"
                                  >
                                    {" "}
                                    Resend Otp
                                  </span>
                                </div>
                              </div>
                            )}

                            {isotp && (
                              <p style={{ color: "red" }}>
                                <small>Enter Otp</small>
                              </p>
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: "1.5rem",
                              marginBottom: "1rem ",
                            }}
                            className="text-center mt-3"
                          >
                            <Spin spinning={isLoading}>
                              <button
                                disabled={isMobile}
                                color="primary"
                                style={{ background: '#f4792c' }}
                                className={`btn btn-primary border-0 ${
                                  isMobile === true && "disabled"
                                }`}
                                type="submit"
                              >
                                Verify Otp
                              </button>
                            </Spin>
                          </div>
                        </>
                      )) || (
                        <>
                          <p className="para">
                            Kindly enter the 10-digit mobile number
                          </p>
                          {/* <label for="mobileNumber">Enter Mobile Number</label> */}
                          <div style={{ marginTop: "1rem" }}>
                            <input
                              id="mobileNumber"
                              value={mobile}
                              placeholder="Enter Your Mobile Number"
                              type="number"
                              autoComplete="new-email"
                              className="input-group-alternative"
                              disabled={phoneDisabled}
                              maxLength={10}
                              onChange={(e) => (
                                mobile.length === 8 && setIsMobile(true),
                                mobile.length === 9 && setIsMobile(false),
                                handleMobileDisabled(e),
                                setMobile(e.target.value),
                                setErrorMessage("")
                              )}
                              onPaste={(e) => {
                                setMobile(e.target.value);
                                handleMobileDisabled(e);
                                setErrorMessage("");
                              }}
                              onKeyDown={(e) => {
                                if (e.keyCode === 38 || e.keyCode === 40) {
                                  e.preventDefault();
                                }
                                if (
                                  event.key === "Backspace" ||
                                  event.key === "Delete" ||
                                  event.key === "ArrowLeft" ||
                                  event.key === "ArrowRight" ||
                                  event.key === "Enter"
                                ) {
                                  return;
                                }
                                let charCode =
                                  typeof e.which == "undefined"
                                    ? e.keyCode
                                    : e.which;
                                let charStr = String.fromCharCode(charCode);
                                if (!charStr.match(/^[0-9]+$/)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <span className="red">
                            {mobile.length > 10 && (
                              <>
                                <p className="para">
                                  This is not a Valid Number{" "}
                                  <span
                                    onClick={(e) => setPhoneDisabled(false)}
                                    className="link-para"
                                  >
                                    Re-Enter-Number
                                  </span>{" "}
                                </p>
                              </>
                            )}
                          </span>
                          <p style={{ color: "red" }}>
                            <small>{errorMessage !== "" && errorMessage}</small>
                          </p>
                          <div
                            style={{
                              marginTop: "1.5rem",
                              marginBottom: "1rem ",
                            }}
                            className="text-center"
                          >
                            {/* disabled={isMobile} */}
                            <Spin spinning={isLoading}>
                              <button
                                disabled={isMobile}
                                color="primary"
                                type="submit"
                                style={{ background: '#f4792c' }}
                                className={`btn btn-primary border-0 ${
                                  isMobile === true && "disabled"
                                }`}
                              >
                                {(resended && "Re Send OTP") || "Send OTP"}
                              </button>
                            </Spin>
                          </div>
                        </>
                      )}
                    </div>
                    <p
                      style={{ fontSize: "12px", lineHeight: 1.4 }}
                      className="para"
                    >
                      By signing in, I agree to{" "}
                      <Link to="/termsandconditions">
                        <span className="link-para"> Terms & Condition </span>{" "}
                      </Link>
                      &nbsp; and&nbsp;
                      <Link to="/privacypolicy">
                        <span className="link-para"> Privacy Policy </span>
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
