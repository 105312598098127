import React from "react";
import { useEffect } from "react";
import "./FooterLinks.css";

const ReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class="footer-container">
      <div class="details" id="details-container">
        <div
          id="details-2"
          class="details-content"
          style={{ display: "block" }}
        >
          <div style={{ "text-align": "center" }}>
            <h1>Return Policy</h1>
          </div>
          <br />

          <hr class="solid" />

          <ol class="faq-list list-return">
            <li>
              <h4 class="faq-heading"> Process of Return:</h4>
              <div class="read faq-text">
                <p>
                  We have a 15(Fifteen) day return policy, which means you have
                  15 days after receiving your item to request a return. To be
                  eligible for a return, your item must be in the same condition
                  that you received it, unworn or unused, with tags, and in its
                  original packaging.
                </p>
                <p>
                To start a return, you can go to your order section to raise a request.You can always contact us for any return question at &nbsp;
                  <a href="mailto: help@houseofbelieve.com" target="_blank">
                   help@houseofbelieve.com 
                  </a>
                  &nbsp; or you can call us at{" "}
                  <a href="tel:18003091345">18003091345</a>
                </p>
              
              </div>
            </li>
            <li>
              <h4 class="faq-heading"> Damages and issues:</h4>
              <div class="read faq-text">
                <p>
                  Please inspect your order upon reception and contact us
                  immediately if the item is defective, damaged or if you
                  receive the wrong item, so that we can evaluate the issue and
                  make it accurate.
                </p>
              </div>
            </li>
            <li>
              <h4 class="faq-heading"> Exceptions / non-returnable items: </h4>
              <div class="read faq-text">
                <p>
                  Certain types of items cannot be returned such as custom
                  products (special orders or personalized items). Please get in
                  touch if you have questions or concerns about your specific
                  item. Unfortunately, we cannot accept returns on sale items or
                  gift cards.{" "}
                </p>
              </div>
            </li>
            <li>
              <h4 class="faq-heading">Exchanges</h4>
              <div class="read faq-text">
                <p>
                  The fastest way to ensure you get what you want is to return
                  the item you have, and once the return is accepted, make a
                  separate purchase for the new item.
                </p>
              </div>
            </li>

            <li>
              <h4 class="faq-heading">Refunds:</h4>
              <div class="read faq-text">
                <p>
                  {" "}
                  We will notify you once we’ve received and inspected your
                  return, and let you know if the refund was approved or not. If
                  approved, you’ll be automatically refunded on your original
                  payment method. Please remember it can take 7 Business Days
                  time for your bank or credit card company to process and post
                  the refund too.
                </p>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ReturnPolicy;
