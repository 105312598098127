import { Space } from 'antd'
import CheckableTag from 'antd/es/tag/CheckableTag'
import React from 'react'
import { useHistory } from 'react-router-dom'

export default function FilterSection({ name, tagsArr, selectedTags, params }) {
  const history = useHistory()

  const handleChange = (tag, checked) => {
    if (checked) {
      params.append(name.toLowerCase(), tag);
      history.push({ search: params.toString() })
      console.log('xyz',params);
    }
    else {
      const tempParams = params.getAll(name.toLowerCase());
      params.delete(name.toLowerCase());
      console.log('zxcvbn', tempParams, tag, tempParams.includes(tag));
      tempParams?.forEach((param) => param !== tag ? params.append(name.toLowerCase(), param) : null)
      history.push({ search: params.toString() })
    }
  }

  return (
    <>
        <div className="rounded px-3 fw-bold py-1 my-4" style={{ backgroundColor: '#efefef', fontSize: 18 }}>{name}</div>
        <div style={{ border: '1px solid #efefef' }} />

        { tagsArr && <Space className='mt-3' size={[0, 8]} wrap>
            { tagsArr?.map((tag) => (
            <CheckableTag
                key={tag}
                style={{ boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' }}
                checked={selectedTags.includes(tag)}
                onChange={(checked) => handleChange(tag, checked)}
            >
                <span style={{ fontSize: 14 }}>{tag}</span>
            </CheckableTag>
            ))}
        </Space>}
    </>
  )
}
