import React from "react";
import styled from "styled-components";


class Checkbox extends React.Component {
  render() {
    return (
      <ActiveNoDarkModeNo>
        <OverlapGroup>
          <VariantFilled src="/img/variant-filled.svg" alt="Variant=Filled" />
        </OverlapGroup>
      </ActiveNoDarkModeNo>
    );
  }
}

const ActiveNoDarkModeNo = styled.div`
  position: absolute;
  height: 20px;
  top: 1px;
  left: 31px;
  display: flex;
  align-items: flex-start;
  min-width: 20px;
  background-color: var(--lg);
  border-radius: 4px;
  border: 2px solid;
  border-color: #e9e9f2;
`;

const OverlapGroup = styled.div`
  height: 20px;
  display: flex;
  padding: 1.7px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 20px;
  background-color: #0040a6;
  border-radius: 4px;
`;

const VariantFilled = styled.img`
  width: 17px;
  height: 17px;
`;

export default Checkbox;
