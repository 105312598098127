import React, { useEffect } from "react";
import { Upload, Modal, notification } from "antd";
// import ImgCrop from "antd-img-crop";
import { useState } from "react";
import { UpadteProfileApi, UploadProfilePhotoApi } from "../../api/account";
// import { useState } from "react";

const UPloadProfile = ({ getAccountDetail, id, image }) => {
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const profileImageHandle = async ({ file }) => {
    const { data } = await UploadProfilePhotoApi(id, file);
    setFileList([file]);
    if (data.success) {
      getAccountDetail();
    } else {
      notification.error({
        message: data.message,
        duration: 1,
        placement:"bottom"
      });
    }

    // if (newFileList.length <= 1) return setFileList(newFileList);
    // setFileList(newFileList[newFileList.length - 1]);
    // setFileList(newFileList);

    // if (newFileList.length > 1) {
    //   //   array[array.length - 1] = newItem;
    //   //   setFileList((newFileList.array[newFileList.length - 1] = newFileList[1]));
    // } else {

    // }
  };

  useEffect(() => {
    if (image) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: image,
        },
      ]);
    }
  }, [image]);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  return (
    <>
      {/* <ImgCrop rotationSlider>
        <Upload
          url="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onChange={(file) => profileImageHandle(file)}
          onPreview={handlePreview}
          multiple={false}
        >
          {fileList.length < 4 && "+ Upload"}
        </Upload>
      </ImgCrop> */}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
export default UPloadProfile;
