import React from "react";
import styled from "styled-components";
import {
  YesevaoneNormalWhite36px,
  SnellroundhandBoldSapphire36px,
  LatoLightLogCabin18px,
  ValignTextMiddle,
} from "../../styledMixins";

class Title22 extends React.Component {
  render() {
    const { ourBest, forWomens } = this.props;

    return (
      <Title className="title-name">
        {/* <OurBest>{ourBest}</OurBest> */}
        <ForWomens >{forWomens}</ForWomens>
        {/* <Sleeplessness>SLEEPLESSNESS.</Sleeplessness> */}
      </Title>
    );
  }
}

const Title = styled.div`
  display: flex;
  margin-bottom: -24px;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 10px;
  position: relative;
`;

const OurBest = styled.h1`
  ${ValignTextMiddle}
  ${SnellroundhandBoldSapphire36px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 38px;
  white-space: nowrap;
`;

const ForWomens = styled.div`
  ${ValignTextMiddle}
  ${YesevaoneNormalWhite36px}
            position: relative;
  width: fit-content;

  background:#373736;
  font-family:brandon-grotesque;
  font-weight: bolder;
  // font-size: 40px;
  -webkit-background-clip: text !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-align: center;
  letter-spacing: 0;
  line-height: 48px;
  white-space: nowrap;
`;

const Sleeplessness = styled.div`
  ${ValignTextMiddle}
  ${LatoLightLogCabin18px}
            position: relative;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

export default Title22;
